import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import { config } from "./utils/constants";

i18n.use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            load: "languageOnly",
            backend: {
                backends: [LocalStorageBackend, HttpBackend],
                backendOptions: [
                    {
                        defaultVersion: config.version,
                        expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
                    },
                    {
                        loadPath: config.url + "/translations/{{lng}}",
                        allowMultiLoading: true
                    }
                ]
            },
            preload: ["pt", "en", "es"],
            keySeparator: false,
            fallbackLng: "pt",
            debug: true,

            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            }
        },
        undefined
    );

export default i18n;
