import { action, makeObservable, observable } from "mobx";
import { CastingAttributeType } from "../models";

export class CastingTypesStore {
    @observable.deep types: CastingAttributeType[] = [] as CastingAttributeType[];

    constructor() {
        makeObservable(this);
    }

    @action setTypes(types: CastingAttributeType[]) {
        this.types = types;
    }

    @action addType(type: CastingAttributeType) {
        this.types.push(type);
    }

    @action updateType(type: CastingAttributeType) {
        const index = this.types.findIndex(c => c._id === type._id);
        if (index > -1) {
            this.types[index] = type;
        }
    }

    @action getTypeById(id: string): CastingAttributeType | undefined {
        return this.types.find(c => c._id === id);
    }
}
