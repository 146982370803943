import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Translation } from "../../models";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";
import { Formik } from "formik";
import { Button, Form, Icon, Table } from "tabler-react";
import { getTranslations, newTranslation, updateTranslation } from "../../services/translation.service";

export const TranslationsManagement = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, translationStore } = props.rootStore!;
        const { t } = useTranslation();

        const [editing, setEditing] = useState(false);
        const [openNew, setOpenNew] = useState(false);
        const [focused, setFocused] = useState<undefined | Translation>(undefined);
        const [sort, setSort] = useState("key");
        const [sortKey, setSortKey] = useState<"asc" | "desc">("asc");
        const [sortEN, setSortEN] = useState<"asc" | "desc">("asc");
        const [sortPT, setSortPT] = useState<"asc" | "desc">("asc");
        const [sortES, setSortES] = useState<"asc" | "desc">("asc");

        const onSubmitSkill = async (values: any) => {
            loadingStore.triggerLoading();
            setOpenNew(false);
            if (editing && focused) {
                updateTranslation(focused._id!, { en: values.en, pt: values.pt, es: values.es })
                    .then(translation => {
                        setEditing(false);
                        setFocused(undefined);
                        loadingStore.stopLoading();
                        translationStore.updateTranslation(translation);
                        toast.success(t("success.updated"));
                    })
                    .catch(() => {
                        setOpenNew(true);
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            } else {
                newTranslation(values)
                    .then(translation => {
                        loadingStore.stopLoading();
                        translationStore.addTranslation(translation);
                        toast.success(t("success.created-translation"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.creating-translation"));
                    });
            }
        };

        useEffect(() => {
            loadingStore.triggerLoading();
            if (translationStore.translations.length === 0) {
                getTranslations()
                    .then(translations => {
                        translationStore.setTranslations(translations);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        return (
            <>
                {openNew && (
                    <Popup
                        title={editing ? t("translation.edit") : t("translation.new")}
                        onClose={() => {
                            setOpenNew(false);
                            setEditing(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                key: focused?.key ?? "",
                                en: focused?.en ?? "",
                                pt: focused?.pt ?? "",
                                es: focused?.es ?? ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.key) {
                                    errors.key = t("form.errors.required");
                                }
                                if (!values.pt) {
                                    errors.pt = t("form.errors.required");
                                }
                                if (!values.en) {
                                    errors.en = t("form.errors.required");
                                }
                                if (!values.es) {
                                    errors.es = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmitSkill}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("translation.key")}</Form.Label>
                                            <Form.Input
                                                disabled={editing}
                                                name="key"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.key}
                                                error={errors && errors.key}
                                                placeholder="translations.section.my-value"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("translation.value-pt")}</Form.Label>
                                            <Form.Input
                                                name="pt"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.pt}
                                                error={errors && errors.pt}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            <Form.Label>{t("translation.value-en")}</Form.Label>
                                            <Form.Input
                                                name="en"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.en}
                                                error={errors && errors.en}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            <Form.Label>{t("translation.value-es")}</Form.Label>
                                            <Form.Input
                                                name="es"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.es}
                                                error={errors && errors.es}
                                            />
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {/*<Button*/}
                {/*    color="primary"*/}
                {/*    style={{ marginBottom: "10px" }}*/}
                {/*    onClick={() => {*/}
                {/*        setFocused(undefined);*/}
                {/*        setEditing(false);*/}
                {/*        setOpenNew(true);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t("translation.new")}*/}
                {/*</Button>*/}
                <Table className="table-striped">
                    <Table.Header>
                        <Table.Row className="centered-row">
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("key");
                                    setSortKey(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        translationStore.sortByKey(newSort);
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("translation.key")}</div>
                                    {sort === "key" && sortKey === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "key" && sortKey === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("pt");
                                    setSortPT(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        translationStore.sortByLanguage(newSort, "pt");
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("translation.value-pt")}</div>
                                    {sort === "pt" && sortPT === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "pt" && sortPT === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("en");
                                    setSortEN(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        translationStore.sortByLanguage(newSort, "en");
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("translation.value-en")}</div>
                                    {sort === "en" && sortEN === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "en" && sortEN === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("es");
                                    setSortES(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        translationStore.sortByLanguage(newSort, "es");
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("translation.value-es")}</div>
                                    {sort === "es" && sortES === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "es" && sortES === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {translationStore.translations.map((translation, i) => (
                            <Table.Row
                                key={`translation_${i}`}
                                className="clickable-row"
                                onClick={() => {
                                    setFocused(translation);
                                    setEditing(true);
                                    setOpenNew(true);
                                }}
                            >
                                <Table.Col>{translation.key}</Table.Col>
                                <Table.Col>{translation.pt}</Table.Col>
                                <Table.Col>{translation.en}</Table.Col>
                                <Table.Col>{translation.es}</Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
    })
);
