import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { Login } from "./Login";
import { GrantEnum, Stores } from "../models/generic";
import { inject, observer } from "mobx-react";
import { Profile } from "./Profile";
import { Cast } from "./Cast";
import { Search } from "./Search";
import { Details } from "./Details";
import { PublicProfile } from "./PublicProfile";
import { FirstLogin } from "./FirstLogin";
import { Home as HomeReact } from "./Home";
import { Data } from "./Data";
import { Casting } from "./Casting";
import { CastingDetails } from "./CastingDetails";
import { Finance } from "./Finance";
import { FinanceDetails } from "./FinanceDetails";
import { ContractAgreement } from "./ContractAgreement";

export const Routes = inject("rootStore")(
    observer((props: Stores) => {
        const { sessionStore } = props.rootStore!;

        return (
            <Switch>
                <PrivateRoute exact path="/">
                    {!sessionStore?.session?.profile?.updatedAt ||
                    (!Boolean(sessionStore?.session?.profile?.termsAgreedAt) &&
                        !Boolean(sessionStore?.session?.profile?.lgpdAgreedAt)) ? (
                        <FirstLogin />
                    ) : !sessionStore?.session?.profile?.updatedAt ||
                      !Boolean(sessionStore?.session?.profile?.termsAndConditionsAgreedAt) ? (
                        <ContractAgreement />
                    ) : sessionStore?.session?.profile?.role?.grants?.some(c => c === GrantEnum.ARTIST) ? (
                        <Profile />
                    ) : (
                        <HomeReact />
                    )}
                </PrivateRoute>
                <PrivateRoute path="/terms-and-conditions">
                    <ContractAgreement />
                </PrivateRoute>
                <PrivateRoute path="/profile">
                    <Profile />
                </PrivateRoute>
                <PrivateRoute path="/details">
                    <Details />
                </PrivateRoute>
                <PrivateRoute path="/cast">
                    <Cast />
                </PrivateRoute>
                <PrivateRoute path="/search">
                    <Search />
                </PrivateRoute>
                <PrivateRoute path="/data">
                    <Data />
                </PrivateRoute>
                <PrivateRoute path="/casting">
                    <Casting />
                </PrivateRoute>
                <PrivateRoute path="/casting-details">
                    <CastingDetails />
                </PrivateRoute>
                <PrivateRoute path="/finance">
                    <Finance />
                </PrivateRoute>
                <PrivateRoute path="/finance-details">
                    <FinanceDetails />
                </PrivateRoute>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/profiles">
                    <PublicProfile />
                </Route>
            </Switch>
        );
    })
);

const PrivateRoute = inject("rootStore")(
    observer(({ children, rootStore, ...rest }: RouteProps & Stores) => {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    rootStore?.sessionStore.logged ? (
                        children
                    ) : location.pathname.includes("/profiles/") ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
                }
            />
        );
    })
);
