import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { GeneralInfo } from "./profile-sections/GeneralInfo";
import { ContractData } from "./profile-sections/ContractData";
import { PersonalInfo } from "./profile-sections/PersonalInfo";
import { SocialNetworks } from "./profile-sections/SocialNetworks";
import { Skills } from "./profile-sections/Skills";
import { Videos } from "./profile-sections/Videos";
import { Pictures } from "./profile-sections/Pictures";
import { TalentCompanyData } from "./profile-sections/TalentCompanyData";
import { Career } from "./profile-sections/Career";
import { Bio } from "./profile-sections/Bio";
import { getProfileById } from "../services/profile.service";
import { Validation } from "./profile-sections/Validation";

export const Details = inject("rootStore")(
    observer((props: Stores) => {
        const {
            sessionStore,
            profileStore,
            loadingStore,
            routingStore: { push, location }
        } = props.rootStore!;

        useEffect(() => {
            if (!profileStore.focusedProfile._id && (sessionStore.isDirector || sessionStore.isBooker)) {
                if (location.search) {
                    const id = new URLSearchParams(location.search).get("id");
                    if (id) {
                        loadingStore.triggerLoading();
                        getProfileById(id)
                            .then(profile => {
                                profileStore.setFocusedProfile(profile);
                                loadingStore.stopLoading();
                                push("/details");
                            })
                            .catch(() => {
                                loadingStore.stopLoading();
                                push("/cast");
                            });
                    } else {
                        push("/cast");
                    }
                } else {
                    push("/cast");
                }
            }
        }, []);
        return (
            <div className="flex-table">
                <div className="flex-row">
                    <div className="flex-column">
                        <PersonalInfo />
                        <SocialNetworks />
                        <Pictures />
                        <Videos />
                        <Skills />
                    </div>
                    <div className="flex-column">
                        <GeneralInfo />
                        <ContractData />
                        <TalentCompanyData />
                        <Career />
                        <Bio />
                        <Validation />
                    </div>
                </div>
            </div>
        );
    })
);
