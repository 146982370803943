import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Card, Form, Header } from "tabler-react";
import {
    updatePartialInfo,
    updatePartialInfoAdmin,
    updatePartialInfoWithApproval
} from "../../services/profile.service";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { StatusEnum } from "../../models";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";

export const Bio = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [extraInfoEditable, setExtraInfoEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const [showPopup, setShowPopup] = useState(false);
        const { profileStore, loadingStore, sessionStore } = props.rootStore!;
        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            if (sessionStore.isSameAsFocused) {
                updatePartialInfo("bio", {
                    description: values.description
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else if ((sessionStore.isDirector || sessionStore.isBooker) && profileStore.focusedProfile._id) {
                updatePartialInfoAdmin(profileStore.focusedProfile._id, "bio", {
                    description: values.description
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        setExtraInfoEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            }
        };
        const onSubmitWithApproval = async (values: any) => {
            loadingStore.triggerLoading();
            updatePartialInfoWithApproval("bio", {
                description: values.description
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    toast.success(t("success.waiting-approval"));
                    setShowPopup(false);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };
        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
        }, [profileStore.focusedProfile]);
        return (
            <>
                {showPopup && (
                    <Popup
                        title="Bio"
                        onClose={() => setShowPopup(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            enableReinitialize
                            initialValues={{ description: profileStore.focusedProfile.bio?.description }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.description) {
                                    errors.description = t("form.errors.required");
                                }
                                return errors;
                            }}
                            onSubmit={onSubmitWithApproval}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>{t("profile.bio_description")}</Form.Label>
                                        <Form.Textarea
                                            rows={6}
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.description}
                                            error={errors && errors.description}
                                        />
                                    </Form.Group>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" icon="save">
                                            {t("save")}
                                        </Button>
                                        <Button
                                            color="danger"
                                            icon="x"
                                            onClick={() => {
                                                resetForm();
                                                setShowPopup(false);
                                            }}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Card>
                    <Card.Body>
                        {sessionStore.isSameAsFocused && canEdit && !editable && (
                            <div className="flex-row justify-content-end">
                                <Button
                                    color="primary"
                                    icon="edit"
                                    onClick={() => {
                                        if (
                                            profileStore.focusedProfile.status === StatusEnum.EDITING ||
                                            (!sessionStore.isSameAsFocused &&
                                                (sessionStore.isBooker || sessionStore.isDirector))
                                        ) {
                                            setEditable(true);
                                        } else {
                                            setShowPopup(true);
                                        }
                                    }}
                                >
                                    {t("edit")}
                                </Button>
                            </div>
                        )}
                        <Header.H3>Bio</Header.H3>
                        <Formik
                            enableReinitialize
                            initialValues={{ description: profileStore.focusedProfile.bio?.description }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.description) {
                                    errors.description = t("form.errors.required");
                                }
                                return errors;
                            }}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>{t("profile.bio_description")}</Form.Label>
                                        <Form.Textarea
                                            rows={6}
                                            disabled={!editable}
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.description}
                                            error={errors && errors.description}
                                        />
                                    </Form.Group>
                                    {sessionStore.isSameAsFocused && canEdit && (
                                        <Form.Footer>
                                            {editable && (
                                                <Button type="submit" color="primary" icon="save">
                                                    {t("save")}
                                                </Button>
                                            )}
                                            {editable && (
                                                <Button
                                                    color="danger"
                                                    icon="x"
                                                    onClick={() => {
                                                        setEditable(false);
                                                        resetForm();
                                                    }}
                                                >
                                                    {t("cancel")}
                                                </Button>
                                            )}
                                        </Form.Footer>
                                    )}
                                    {!sessionStore.isSameAsFocused &&
                                        (sessionStore.isDirector || sessionStore.isBooker) && (
                                            <Form.Footer>
                                                {!extraInfoEditable && (
                                                    <Button
                                                        color="primary"
                                                        icon="edit"
                                                        onClick={(e: any) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setExtraInfoEditable(true);
                                                            setEditable(true);
                                                        }}
                                                    >
                                                        {t("edit")}
                                                    </Button>
                                                )}
                                                {extraInfoEditable && (
                                                    <Button type="submit" color="primary" icon="save">
                                                        {t("save")}
                                                    </Button>
                                                )}
                                                {extraInfoEditable && (
                                                    <Button
                                                        color="danger"
                                                        icon="x"
                                                        onClick={(e: any) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setEditable(false);
                                                            setExtraInfoEditable(false);
                                                            resetForm();
                                                        }}
                                                    >
                                                        {t("cancel")}
                                                    </Button>
                                                )}
                                            </Form.Footer>
                                        )}
                                </form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </>
        );
    })
);
