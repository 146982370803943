import { action, makeObservable, observable } from "mobx";
import { Casting, CastingAttribute, CastingProfile, Profile } from "../models";

export class CastingStore {
    @observable.deep castings: Casting[] = [] as Casting[];
    @observable.deep users: Profile[] = [] as Profile[];
    @observable focusedCasting: Casting = {} as Casting;
    @observable.deep focusedCastingProfiles: CastingProfile[] = [];

    constructor() {
        makeObservable(this);
    }

    @action setCastings(castings: Casting[]) {
        this.castings = castings;
    }

    @action setFocusedCasting(casting: Casting) {
        this.focusedCasting = casting;
        this.focusedCastingProfiles = [];
    }

    @action setFocusedCastingProfiles(castingProfiles: CastingProfile[]) {
        this.focusedCastingProfiles = castingProfiles;
    }

    @action addCasting(casting: Casting) {
        this.castings.push(casting);
    }

    @action addCastingProfile(castingProfile: CastingProfile) {
        this.focusedCastingProfiles.push(castingProfile);
    }

    @action removeCastingProfile(id: string) {
        const index = this.focusedCastingProfiles.findIndex(p => p._id === id);
        if (index > -1) {
            this.focusedCastingProfiles.splice(index, 1);
        }
    }

    @action updateCasting(casting: Casting) {
        const index = this.castings.findIndex(c => c._id === casting._id);
        if (index > -1) {
            this.castings[index] = casting;
        }
    }

    @action removeCasting(casting: Casting) {
        const index = this.castings.findIndex(c => c._id === casting._id);
        if (index > -1) {
            this.castings.splice(index, 1);
        }
    }

    @action updateCastingProfile(castingProfile: CastingProfile) {
        const index = this.focusedCastingProfiles.findIndex(c => c._id === castingProfile._id);
        if (index > -1) {
            this.focusedCastingProfiles[index] = castingProfile;
        }
    }

    @action getCastingById(id: string): Casting | undefined {
        return this.castings.find(c => c._id === id);
    }

    @action filter(client: string, title: string, status: number, clients: CastingAttribute[]): Casting[] {
        let castings = [...this.castings];

        const findId = (value: string) => clients.find(c => c.value.toLowerCase().includes(value.toLowerCase()))?._id;

        if (client) {
            castings = castings.filter(c => c.clients.some(cl => cl === findId(client)));
        }

        if (title) {
            castings = castings.filter(c => c.title.toLowerCase().includes(title.toLowerCase()));
        }

        if (status) {
            castings = castings.filter(c => c.status == status);
        }

        return castings;
    }

    @action sortByClient(sortMethod: "asc" | "desc", clients: CastingAttribute[]) {
        const unsortedAttributes = [...this.castings];

        const findValue = (id: string) => clients.find(c => c._id == id)?.value;

        unsortedAttributes.sort((p1, p2) => {
            if (!p1.clients || !p1.clients.length || !p2.clients || !p2.clients.length) {
                return 0;
            }

            if (sortMethod === "desc") {
                return (findValue(p2.clients?.[0]) ?? "").localeCompare(findValue(p1.clients?.[0]) ?? "");
            }

            return (findValue(p1.clients?.[0]) ?? "").localeCompare(findValue(p2.clients?.[0]) ?? "");
        });

        this.setCastings(unsortedAttributes);
    }

    @action sortByTitle(sortMethod: "asc" | "desc") {
        const unsortedAttributes = [...this.castings];

        unsortedAttributes.sort((p1, p2) => {
            if (!p1.title || !p1.title) {
                return 0;
            }

            if (sortMethod === "desc") {
                return p2.title.localeCompare(p1.title);
            }

            return p1.title.localeCompare(p2.title);
        });

        this.setCastings(unsortedAttributes);
    }

    @action sortByStatus(sortMethod: "asc" | "desc") {
        const unsortedAttributes = [...this.castings];

        unsortedAttributes.sort((p1, p2) => {
            if (!p1.status || !p1.status) {
                return 0;
            }

            if (sortMethod === "desc") {
                return p2.status < p1.status ? -1 : 1;
            }

            return p1.status < p2.status ? -1 : 1;
        });

        this.setCastings(unsortedAttributes);
    }

    @action sortByCreatedAt(sortMethod: "asc" | "desc") {
        const unsortedAttributes = [...this.castings];

        unsortedAttributes.sort((p1, p2) => {
            if (!p1.createdAt) {
                if (p2.createdAt) {
                    if (sortMethod === "desc") {
                        return 1;
                    }
                }
                return -1;
            }
            if (!p2.createdAt) {
                if (p1.createdAt) {
                    if (sortMethod === "asc") {
                        return 1;
                    }
                }
                return -1;
            }
            const d1 = new Date(p1.createdAt);
            const d2 = new Date(p2.createdAt);
            if (d1.getTime() === d2.getTime()) {
                return 0;
            }
            if (sortMethod === "asc") {
                return d1.getTime() > d2.getTime() ? 1 : -1;
            }
            return d2.getTime() > d1.getTime() ? 1 : -1;
        });

        this.setCastings(unsortedAttributes);
    }

    @action setUsers(users: Profile[]) {
        this.users = users;
    }
}
