import { Button, Card, Form, Header } from "tabler-react";
import classNames from "classnames";
import { Formik } from "formik";
import { countries, states } from "../../utils/countries";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { toast } from "react-toastify";
import { updateProfile, updateProfileAdmin } from "../../services/profile.service";
import { Profile, StatusEnum } from "../../models";
import { formatDate, parseDate } from "../../utils/formats";
import InputMask from "react-input-mask";

export const GeneralInfo = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const { profileStore, loadingStore, sessionStore } = props.rootStore!;
        const [profile, setProfile] = useState<Profile | undefined>(undefined);
        const [extraInfoEditable, setExtraInfoEditable] = useState(false);
        const [artisticName, setArtisticName] = useState("");

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
            setProfile(profileStore.focusedProfile);
            setArtisticName(profileStore.focusedProfile.artisticName);
        }, [profileStore.focusedProfile]);

        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            if (sessionStore.isSameAsFocused) {
                updateProfile({
                    fullName: values.full_name.trim(),
                    birthDate: parseDate(values.birth_date),
                    gender: values.gender,
                    email: values.email,
                    phoneResidential: values.phone_residential,
                    phoneCellphone: values.phone_cellphone,
                    phoneOther: values.phone_other,
                    citizenship: values.citizenship,
                    birthState: values.birth_state
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else if ((sessionStore.isDirector || sessionStore.isBooker) && profileStore.focusedProfile._id) {
                updateProfileAdmin(profileStore.focusedProfile._id, {
                    fullName: values.full_name.trim(),
                    artisticName: values.artistic_name.trim(),
                    birthDate: parseDate(values.birth_date),
                    gender: values.gender,
                    email: values.email,
                    phoneResidential: values.phone_residential,
                    phoneCellphone: values.phone_cellphone,
                    phoneOther: values.phone_other,
                    citizenship: values.citizenship,
                    birthState: values.birth_state
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setExtraInfoEditable(false);
                        setEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(response => {
                        if (response.status === 409) {
                            toast.error(t("error.artistic-name-duplicated"));
                        } else {
                            toast.error(t("error.updating"));
                        }
                        loadingStore.stopLoading();
                    });
            }
        };
        const genders = [
            { label: t("profile.gender.feminine"), value: "F" },
            { label: t("profile.gender.masculine"), value: "M" },
            { label: t("profile.gender.non-binary"), value: "NB" }
        ];
        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.general_info")}</Header.H3>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            artistic_name: profile?.artisticName || "",
                            full_name: profile?.fullName || "",
                            birth_date: profile?.birthDate ? formatDate(new Date(profile.birthDate)) : "",
                            gender: profile?.gender || "",
                            email: profile?.email || "",
                            phone_residential: profile?.phoneResidential || "",
                            phone_cellphone: profile?.phoneCellphone || "",
                            phone_other: profile?.phoneOther || "",
                            citizenship: profile?.citizenship || "",
                            birth_state: profile?.birthState || ""
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (!sessionStore.isSameAsFocused && (sessionStore.isDirector || sessionStore.isBooker)) {
                                if (!values.artistic_name) {
                                    errors.artisticName = t("form.errors.required");
                                }
                            }
                            if (!values.full_name) {
                                errors.full_name = t("form.errors.required");
                            }
                            if (!values.birth_date || values.birth_date === "__/__/____") {
                                errors.birth_date = t("form.errors.required");
                            }
                            if (!values.gender) {
                                errors.gender = t("form.errors.required");
                            }
                            if (!values.email) {
                                errors.email = t("form.errors.required");
                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                errors.email = t("form.errors.invalid");
                            }
                            if (!values.phone_cellphone || values.phone_cellphone === "(__)_____-____") {
                                errors.phone_cellphone = t("form.errors.required");
                            }
                            if (!values.phone_other || values.phone_other === "(__)_____-____") {
                                errors.phone_other = t("form.errors.required");
                            }
                            if (!values.citizenship) {
                                errors.citizenship = t("form.errors.required");
                            }
                            if (values.citizenship === "BR" && !values.birth_state) {
                                errors.birth_state = t("form.errors.required");
                            }

                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.artistic_name")}</Form.Label>
                                            <Form.Input
                                                disabled={!extraInfoEditable}
                                                name="artistic_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.artistic_name}
                                                error={errors && errors.artistic_name}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.full_name")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="full_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.full_name}
                                                error={errors && errors.full_name}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.birth_date")}</Form.Label>
                                            <InputMask
                                                name="birth_date"
                                                mask="99/99/9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.birth_date}
                                                readOnly={!editable}
                                            >
                                                {(inputProps: any) => (
                                                    <Form.Input
                                                        error={errors && errors.birth_date}
                                                        invalid={!!errors.birth_date}
                                                        className={!editable ? "disabled input-disabled" : ""}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.gender.label")}</Form.Label>
                                            {genders.map(gender => (
                                                <Form.Radio
                                                    disabled={!editable}
                                                    key={`gender_${gender.value}`}
                                                    label={gender.label}
                                                    name="gender"
                                                    value={gender.value}
                                                    isInline
                                                    invalid={!!errors.gender}
                                                    checked={values.gender === gender.value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={classNames({
                                                        disabled: !editable
                                                    })}
                                                />
                                            ))}
                                            {errors.gender && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.gender}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.citizenship")}</Form.Label>
                                            <Form.Select
                                                disabled={!editable}
                                                name="citizenship"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.citizenship}
                                                error={errors && errors.citizenship}
                                            >
                                                <option value="">{t("select")}</option>
                                                {countries.map(country => (
                                                    <option key={`country_${country.code}`} value={country.code}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    {values.citizenship === "BR" && (
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.birth_state")}</Form.Label>
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="birth_state"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.birth_state}
                                                    error={errors && errors.birth_state}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {states.map(state => (
                                                        <option key={`state_${state.code}`} value={state.code}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    )}
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.email")}</Form.Label>
                                            <Form.Input
                                                name="email"
                                                type={"email"}
                                                disabled={!editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.email}
                                                error={errors && errors.email}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.phone_residential")}</Form.Label>
                                            <InputMask
                                                name="phone_residential"
                                                mask="(99)9999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.phone_residential}
                                                readOnly={!editable}
                                            >
                                                {(inputProps: any) => (
                                                    <Form.Input
                                                        error={errors && errors.phone_residential}
                                                        invalid={!!errors.phone_residential}
                                                        className={!editable ? "disabled input-disabled" : ""}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.phone_cellphone")}</Form.Label>
                                            <InputMask
                                                name="phone_cellphone"
                                                mask="(99)99999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.phone_cellphone}
                                                readOnly={!editable}
                                            >
                                                {(inputProps: any) => (
                                                    <Form.Input
                                                        error={errors && errors.phone_cellphone}
                                                        invalid={!!errors.phone_cellphone}
                                                        className={!editable ? "disabled input-disabled" : ""}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.phone_other")}</Form.Label>
                                            <InputMask
                                                name="phone_other"
                                                mask="(99)99999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.phone_other}
                                                readOnly={!editable}
                                            >
                                                {(inputProps: any) => (
                                                    <Form.Input
                                                        error={errors && errors.phone_other}
                                                        invalid={!!errors.phone_other}
                                                        className={!editable ? "disabled input-disabled" : ""}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                        </Form.Group>
                                    </div>
                                </div>
                                {sessionStore.isSameAsFocused && canEdit && (
                                    <Form.Footer>
                                        {editable && (
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                        )}
                                        {editable && canEdit && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditable(false);
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                                {!sessionStore.isSameAsFocused && (sessionStore.isDirector || sessionStore.isBooker) && (
                                    <Form.Footer>
                                        {!extraInfoEditable && (
                                            <Button
                                                color="primary"
                                                icon="edit"
                                                onClick={() => {
                                                    setExtraInfoEditable(true);
                                                    setEditable(true);
                                                }}
                                            >
                                                {t("edit")}
                                            </Button>
                                        )}
                                        {extraInfoEditable && (
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                        )}
                                        {extraInfoEditable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditable(false);
                                                    setExtraInfoEditable(false);
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
