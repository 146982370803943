import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Class } from "../../models";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";
import { Formik } from "formik";
import { Button, Form, Icon, Table } from "tabler-react";
import { getClasses, newClass, updateClass } from "../../services/classes.service";
import removeAccents from "remove-accents";
import { formatArtistName } from "../../utils/formats";

export const ClassesManagement = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, classesStore } = props.rootStore!;
        const { t } = useTranslation();

        const [editing, setEditing] = useState(false);
        const [openNew, setOpenNew] = useState(false);
        const [focused, setFocused] = useState<undefined | Class>(undefined);
        const [sort, setSort] = useState("key");
        const [sortEN, setSortEN] = useState<"asc" | "desc">("asc");
        const [sortPT, setSortPT] = useState<"asc" | "desc">("asc");
        const [sortES, setSortES] = useState<"asc" | "desc">("asc");

        const onSubmitClass = async (values: any) => {
            loadingStore.triggerLoading();
            setOpenNew(false);
            if (editing && focused) {
                updateClass(focused._id!, {
                    nameEN: values.nameEN.trim(),
                    namePT: values.namePT.trim(),
                    nameES: values.namePT.trim()
                })
                    .then(classObject => {
                        setEditing(false);
                        setFocused(undefined);
                        loadingStore.stopLoading();
                        classesStore.updateClass(classObject);
                        toast.success(t("success.updated"));
                    })
                    .catch(() => {
                        setOpenNew(true);
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            } else {
                const newClassObject = {
                    namePT: values.namePT.trim(),
                    nameEN: values.nameEN.trim(),
                    nameES: values.nameES.trim(),
                    key: removeAccents(formatArtistName(values.nameEN.trim()))
                };
                newClass(newClassObject)
                    .then(classObject => {
                        loadingStore.stopLoading();
                        classesStore.addClass(classObject);
                        toast.success(t("success.created-class"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.creating-class"));
                    });
            }
        };

        useEffect(() => {
            loadingStore.triggerLoading();
            if (classesStore.classes.length === 0) {
                getClasses()
                    .then(classes => {
                        classesStore.setClasses(classes);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        return (
            <>
                {openNew && (
                    <Popup
                        title={editing ? t("classes.edit") : t("classes.new")}
                        onClose={() => {
                            setOpenNew(false);
                            setEditing(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                key: focused?.key ?? "",
                                nameEN: focused?.nameEN ?? "",
                                namePT: focused?.namePT ?? "",
                                nameES: focused?.nameES ?? ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.namePT || values.namePT.length < 3) {
                                    errors.namePT = t("form.errors.required");
                                }
                                if (!values.nameEN || values.nameEN.length < 3) {
                                    errors.nameEN = t("form.errors.required");
                                }
                                if (!values.nameES || values.nameES.length < 3) {
                                    errors.nameES = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmitClass}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("classes.name-pt")}</Form.Label>
                                            <Form.Input
                                                name="namePT"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.namePT}
                                                error={errors && errors.namePT}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            <Form.Label>{t("classes.name-en")}</Form.Label>
                                            <Form.Input
                                                name="nameEN"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.nameEN}
                                                error={errors && errors.nameEN}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            <Form.Label>{t("classes.name-es")}</Form.Label>
                                            <Form.Input
                                                name="nameES"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.nameES}
                                                error={errors && errors.nameES}
                                            />
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Button
                    color="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                        setFocused(undefined);
                        setEditing(false);
                        setOpenNew(true);
                    }}
                >
                    {t("classes.new")}
                </Button>
                <Table className="table-striped">
                    <Table.Header>
                        <Table.Row className="centered-row">
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("pt");
                                    setSortPT(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        classesStore.sortByLanguage(newSort, "namePT");
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("classes.name-pt")}</div>
                                    {sort === "pt" && sortPT === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "pt" && sortPT === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("en");
                                    setSortEN(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        classesStore.sortByLanguage(newSort, "nameEN");
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("classes.name-en")}</div>
                                    {sort === "en" && sortEN === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "en" && sortEN === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("es");
                                    setSortES(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        classesStore.sortByLanguage(newSort, "nameES");
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("classes.name-es")}</div>
                                    {sort === "es" && sortES === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "es" && sortES === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {classesStore.classes.map((classObject, i) => (
                            <Table.Row
                                key={`translation_${i}`}
                                className="clickable-row"
                                onClick={() => {
                                    setFocused(classObject);
                                    setEditing(true);
                                    setOpenNew(true);
                                }}
                            >
                                <Table.Col>{classObject.namePT}</Table.Col>
                                <Table.Col>{classObject.nameEN}</Table.Col>
                                <Table.Col>{classObject.nameES}</Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
    })
);
