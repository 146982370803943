import { action, makeObservable, observable } from "mobx";
import { Translation } from "../models";

export class TranslationStore {
    @observable.deep translations: Translation[] = [] as Translation[];

    constructor() {
        makeObservable(this);
    }

    @action setTranslations(translations: Translation[]) {
        this.translations = translations;
    }

    @action updateTranslation(translation: Translation) {
        const index = this.translations.findIndex(c => c._id === translation._id);
        if (index > -1) {
            this.translations[index] = translation;
        }
    }

    @action getTranslationById(id: string): Translation | undefined {
        return this.translations.find(c => c._id === id);
    }

    @action addTranslation(translation: Translation) {
        this.translations.push(translation);
    }

    @action sortByKey(sortMethod: "asc" | "desc", translations?: Translation[]) {
        const unsortedTranslations = translations ? translations : [...this.translations];

        unsortedTranslations.sort((p1, p2) => {
            if (!p1.key || !p2.key) {
                return 0;
            }
            if (p1.key === p2.key) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1.key.localeCompare(p2.key);
            }
            return p2.key.localeCompare(p1.key);
        });

        if (!translations) {
            this.setTranslations(unsortedTranslations);
        }
    }

    @action sortByLanguage(sortMethod: "asc" | "desc", language: keyof Translation, translations?: Translation[]) {
        const unsortedTranslations = translations ? translations : [...this.translations];

        unsortedTranslations.sort((p1, p2) => {
            if (!(language in p1) || !(language in p2)) {
                return 0;
            }
            if (p1[language] === p2[language]) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1[language]!.localeCompare(p2[language]!);
            }
            return p2[language]!.localeCompare(p1[language]!);
        });

        if (!translations) {
            this.setTranslations(unsortedTranslations);
        }
    }
}
