import React, { useState } from "react";
import { Button, Card, Form, Header, Icon, StandaloneFormPage } from "tabler-react";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { performLogin, performPreLogin } from "../services/login.service";
import { LoginRequest } from "../models";
import { forgotPassword, getProfileDetails } from "../services/profile.service";
import { cuilValidator, validateFiscalCode } from "../services/util.service";
import { toast } from "react-toastify";
import { Popup } from "./Popup";
import { config } from "../utils/constants";

interface LoginProps extends Stores {}

export const Login = inject("rootStore")(
    observer((props: LoginProps) => {
        const { t, i18n } = useTranslation();
        const { loadingStore, routingStore, sessionStore } = props.rootStore!;
        const [preLogin, setPreLogin] = useState(true);
        const [showForgotPassword, setForgotPassword] = useState(false);
        const [emailSent, setEmailSet] = useState("");
        const [mask, setMask] = useState<Array<RegExp | string>>([
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ]);

        const checkFiscalCode = async (fiscalCode: string) => {
            if (cuilValidator(fiscalCode)) {
                setMask([/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/]);
                await i18n.changeLanguage("es");
            } else if (validateFiscalCode(fiscalCode)) {
                setMask([/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]);
                await i18n.changeLanguage("pt");
            } else {
                setMask([/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]);
            }
        };

        const onSubmit = async (values: LoginRequest, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            try {
                if (!preLogin) {
                    try {
                        const response = await performLogin(values);
                        if (response.token && response) {
                            const profile = await getProfileDetails(response);
                            if (profile) {
                                sessionStore.setCurrentSession({ ...response, profile });
                                routingStore.push("/");
                            } else {
                                setErrors({
                                    check2f: t("form.login.errors.wrong-2f")
                                });
                            }
                        } else {
                            setErrors({
                                check2f: t("form.login.errors.wrong-2f")
                            });
                        }
                    } catch (e) {
                        setErrors({
                            check2f: t("form.login.errors.wrong-2f")
                        });
                    }
                } else {
                    try {
                        const profile = await performPreLogin({ ...values, check2f: undefined });
                        if (profile && profile._id) {
                            setPreLogin(false);
                            toast.success(t("success.code_sent"));
                        } else {
                            setErrors({
                                username: t("form.login.errors.invalid-user")
                            });
                        }
                    } catch (e) {
                        let message;
                        switch (e.status) {
                            case 401:
                                message = t("form.login.errors.wrong-password");
                                break;
                            case 404:
                                message = t("form.login.errors.invalid-user");
                                break;
                        }
                        setErrors({
                            username: message
                        });
                    }
                }
                loadingStore.stopLoading();
            } catch (e) {
                setErrors({
                    username: t("form.errors.general")
                });
                loadingStore.stopLoading();
            }
        };

        const onSubmitForgot = async (values: any, { resetForm }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            setForgotPassword(false);
            forgotPassword(values.fiscalCode)
                .then(result => {
                    loadingStore.stopLoading();
                    if (result.email) {
                        setForgotPassword(true);
                        setEmailSet(result.email);
                        toast.success(t("success.email_sent"));
                        resetForm();
                    } else {
                        setForgotPassword(true);
                        toast.error(t("error.sending_email"));
                    }
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    setForgotPassword(true);
                    toast.error(t("error.sending_email"));
                });
        };

        return (
            <>
                {showForgotPassword && (
                    <Popup title={t("form.login.forgotPassword")} onClose={() => setForgotPassword(false)}>
                        <Formik
                            initialValues={{
                                fiscalCode: ""
                            }}
                            validate={async values => {
                                let errors: any = {};
                                if (!values.fiscalCode) {
                                    errors.fiscalCode = t("form.errors.required");
                                } else if (!validateFiscalCode(values.fiscalCode)) {
                                    errors.username = t("form.errors.invalid");
                                } else {
                                    await checkFiscalCode(values.fiscalCode);
                                }
                                return errors;
                            }}
                            onSubmit={onSubmitForgot}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-table">
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                {emailSent ? (
                                                    <span>
                                                        {t("form.login.emailSent")}
                                                        <br />
                                                        {emailSent}
                                                    </span>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.Label>{t("form.login.usernameLabel")}</Form.Label>
                                                        <Form.MaskedInput
                                                            {...({
                                                                placeholder: "00000000000",
                                                                mask
                                                            } as any)}
                                                            name="fiscalCode"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.fiscalCode}
                                                            invalid={errors && errors.fiscalCode}
                                                        />
                                                    </Form.Group>
                                                )}
                                            </div>
                                        </div>
                                        {!emailSent && (
                                            <Form.Footer>
                                                <Button type="submit" color="primary" icon="save">
                                                    {t("proceed")}
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    icon="x"
                                                    onClick={() => {
                                                        resetForm();
                                                    }}
                                                >
                                                    {t("cancel")}
                                                </Button>
                                            </Form.Footer>
                                        )}
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        check2f: ""
                    }}
                    validate={async values => {
                        // same as above, but feel free to move this into a class method now.
                        let errors: Partial<LoginRequest> = {};
                        await checkFiscalCode(values.username);

                        if (!values.username) {
                            errors.username = t("form.errors.required");
                        } else if (!validateFiscalCode(values.username) && !cuilValidator(values.username)) {
                            errors.username = t("form.errors.invalid");
                        }
                        if (!values.password) {
                            errors.password = t("form.errors.required");
                        }
                        if (!preLogin) {
                            if (!values.check2f) {
                                errors.check2f = t("form.errors.required");
                            }
                        }
                        return errors;
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <StandaloneFormPage imageURL="./assets/logo-gaia.png">
                                <Card>
                                    <div className="card-body p-6">
                                        <Header.H3>{t("form.login.title")}</Header.H3>
                                        <Form.Group>
                                            <Form.Label>{t("form.login.usernameLabel")}</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>
                                                        <Icon name="user" />
                                                    </Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.MaskedInput
                                                    {...({
                                                        placeholder: "00000000000",
                                                        mask
                                                    } as any)}
                                                    name="username"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.username}
                                                    invalid={errors && errors.username}
                                                    disabled={!preLogin}
                                                />
                                                {errors && errors.username && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.username}
                                                    </span>
                                                )}
                                            </Form.InputGroup>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t("form.login.passwordLabel")}</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>
                                                        <Icon name="key" />
                                                    </Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.password}
                                                    error={errors && errors.password}
                                                    disabled={!preLogin}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                        {preLogin && (
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    setForgotPassword(true);
                                                    setEmailSet("");
                                                }}
                                            >
                                                {t("form.login.forgotPassword")}
                                            </a>
                                        )}
                                        {!preLogin && (
                                            <Form.Group>
                                                <Form.Label>{t("form.login.checkLabel")}</Form.Label>
                                                <Form.InputGroup>
                                                    <Form.InputGroupPrepend>
                                                        <Form.InputGroupText>
                                                            <Icon name="user-check" />
                                                        </Form.InputGroupText>
                                                    </Form.InputGroupPrepend>
                                                    <Form.Input
                                                        type="password"
                                                        name="check2f"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.check2f}
                                                        error={errors && errors.check2f}
                                                    />
                                                </Form.InputGroup>
                                            </Form.Group>
                                        )}
                                        <Form.Footer>
                                            <Button type="submit" color="primary" icon="check" block>
                                                {t("form.login.buttonText")}
                                            </Button>
                                            <span className="version">{config.version}</span>
                                        </Form.Footer>
                                    </div>
                                </Card>
                            </StandaloneFormPage>
                        </form>
                    )}
                </Formik>
            </>
        );
    })
);
