import { Button, Card, Form, Header, Icon } from "tabler-react";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CircleLoader } from "react-spinners";
import { useDropzone } from "react-dropzone";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { v4 as uuid } from "uuid";
import { deleteFile, uploadVideos, uploadVideosAdmin, uploadVideosApproval } from "../../services/profile.service";
import { config } from "../../utils/constants";
import { StatusEnum } from "../../models";
import { toast } from "react-toastify";
import { Gallery } from "../Gallery";
import { FileUpload, reducerFileUpload } from "../../reducers";

interface Video {
    url: string;
    isPresentation?: boolean;
    isScenes?: boolean;
}

export const Videos = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [extraInfoEditable, setExtraInfoEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const [isSending, setIsSending] = useState(false);
        const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
        const [presentationVideo, setPresentationVideo] = useState<File | undefined>(undefined);
        const [scenesVideo, setScenesVideo] = useState<File | undefined>(undefined);
        const { profileStore, sessionStore, loadingStore } = props.rootStore!;
        const [files, dispatchFiles] = useReducer(reducerFileUpload, []);
        const maxFileSize = 65 * 1024 * 1024;
        const maxFiles = 6;

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
        }, [profileStore.focusedProfile]);

        const totalFiles = useMemo(
            () =>
                (profileStore.focusedProfile.presentationVideo ? 1 : presentationVideo ? 1 : 0) +
                (profileStore.focusedProfile.scenesVideo ? 1 : scenesVideo ? 1 : 0) +
                (profileStore.focusedProfile.videos ? profileStore.focusedProfile.videos.length : 0) +
                files.length,
            [profileStore.focusedProfile, files, presentationVideo, scenesVideo]
        );

        useEffect(() => {
            if (!profileStore.focusedProfile.presentationVideo && !presentationVideo) {
                setIsSendButtonDisabled(true);
            } else if (!presentationVideo && files.length === 0 && !scenesVideo) {
                setIsSendButtonDisabled(true);
            } else {
                if (totalFiles > maxFiles) {
                    setIsSendButtonDisabled(true);
                } else {
                    setIsSendButtonDisabled(false);
                }
            }
        }, [totalFiles, presentationVideo, scenesVideo, profileStore.focusedProfile]);

        const onDropAccepted = useCallback(
            (acceptedFiles: any) => {
                for (const file of acceptedFiles) {
                    if (totalFiles < maxFiles) {
                        if (file.size <= maxFileSize) {
                            dispatchFiles({
                                type: "append",
                                file: {
                                    filename: file.name,
                                    file,
                                    id: uuid()
                                }
                            });
                        } else {
                            toast.error(`${t("error.photo-exceed-quota")} (${maxFileSize / 1024 / 1024}) mb`);
                        }
                    } else {
                        toast.error(t("error.exceed-amount"));
                    }
                }
            },
            [files, totalFiles]
        );

        const onSubmit = async () => {
            setEditable(false);
            setIsSending(true);
            const filesToSend = files.map(f => f.file);
            if (presentationVideo || scenesVideo) {
                loadingStore.triggerLoading();
            }
            if (sessionStore.isSameAsFocused) {
                if (profileStore.focusedProfile.status === StatusEnum.EDITING) {
                    uploadVideos(presentationVideo, scenesVideo, filesToSend)
                        .then(profile => {
                            if (profile) {
                                loadingStore.stopLoading();
                                setIsSending(false);
                                setEditable(false);
                                dispatchFiles({
                                    type: "clear"
                                });
                                setPresentationVideo(undefined);
                                setScenesVideo(undefined);
                                profileStore.updateProfile(profile);
                                toast.success(t("success.uploaded"));
                            }
                        })
                        .catch(() => {
                            loadingStore.stopLoading();
                            setEditable(true);
                            setIsSending(false);
                            toast.error(t("error.uploading"));
                        });
                } else {
                    uploadVideosApproval(presentationVideo, scenesVideo, filesToSend)
                        .then(profile => {
                            if (profile) {
                                loadingStore.stopLoading();
                                setIsSending(false);
                                setEditable(false);
                                dispatchFiles({
                                    type: "clear"
                                });
                                setPresentationVideo(undefined);
                                setScenesVideo(undefined);
                                profileStore.updateProfile(profile);
                                toast.success(t("success.waiting-approval"));
                            }
                        })
                        .catch(() => {
                            loadingStore.stopLoading();
                            setEditable(true);
                            setIsSending(false);
                            toast.error(t("error.uploading"));
                        });
                }
            } else if ((sessionStore.isDirector || sessionStore.isBooker) && profileStore.focusedProfile._id) {
                uploadVideosAdmin(profileStore.focusedProfile._id, presentationVideo, scenesVideo, filesToSend)
                    .then(profile => {
                        if (profile) {
                            loadingStore.stopLoading();
                            setIsSending(false);
                            setEditable(false);
                            setExtraInfoEditable(false);
                            dispatchFiles({
                                type: "clear"
                            });
                            setPresentationVideo(undefined);
                            setScenesVideo(undefined);
                            profileStore.updateProfile(profile);
                            toast.success(t("success.uploaded"));
                        }
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setEditable(true);
                        setExtraInfoEditable(true);
                        setIsSending(false);
                        toast.error(t("error.uploading"));
                    });
            }
        };
        const onDelete = (file: string) => {
            loadingStore.triggerLoading();
            deleteFile(profileStore.focusedProfile._id!, file)
                .then(profile => {
                    if (profile) {
                        loadingStore.stopLoading();
                        profileStore.updateProfile(profile);
                        toast.success(t("success.deleted"));
                    }
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error(t("error.deleting"));
                });
        };
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDropAccepted,
            onDropRejected: fileRejections => {
                const exceedAmount = fileRejections.some(v => v.errors.some(e => e.code === "too-many-files"));
                const exceedQuota = fileRejections.some(v => v.errors.some(e => e.code === "file-too-large"));
                if (exceedAmount) {
                    toast.error(t("error.exceed-amount"));
                }
                if (exceedQuota) {
                    toast.error(`${t("error.photo-exceed-quota")} (${maxFileSize / 1024 / 1024}) mb`);
                }
            },
            accept: ".avi, .mp4, .mov, .mkv",
            maxFiles: maxFiles - totalFiles
        });
        const videos = [
            ...(profileStore.focusedProfile?.presentationVideo
                ? [{ url: config.host + profileStore.focusedProfile?.presentationVideo, isPresentation: true }]
                : []),
            ...(profileStore.focusedProfile?.scenesVideo
                ? [{ url: config.host + profileStore.focusedProfile?.scenesVideo, isScenes: true }]
                : []),
            ...(profileStore.focusedProfile?.videos?.map(p => ({ url: config.host + encodeURI(p) })) ?? [])
        ] as Video[];
        const onCancel = () => {
            dispatchFiles({
                type: "clear"
            });
            setScenesVideo(undefined);
            setPresentationVideo(undefined);
            setEditable(false);
        };
        const removeFile = useCallback((file: FileUpload) => {
            dispatchFiles({
                type: "remove",
                file
            });
        }, []);
        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)} disabled={isSending}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.videos")}</Header.H3>
                    <Gallery
                        label={t("click-to-expand")}
                        isVideo
                        videos={videos}
                        editable={
                            (editable && profileStore.focusedProfile.status === StatusEnum.EDITING) ||
                            (!sessionStore.isSameAsFocused && (sessionStore.isBooker || sessionStore.isDirector))
                        }
                        deleteLabel={t("delete-video")}
                        onDeleteAction={(video: string) => onDelete(video)}
                    />
                    <Form.Group style={{ marginTop: 15 }}>
                        <Form.Label>
                            {t("profile.presentation_video")}{" "}
                            {profileStore.focusedProfile.presentationVideo && (
                                <Icon name="check" style={{ marginLeft: 5 }} />
                            )}
                        </Form.Label>
                        <Form.FileInput
                            disabled={!editable}
                            label={t("select_file")}
                            className={classNames({ disabled: !editable })}
                            accept=".avi, .mp4, .mov, .mkv"
                            onChange={event => {
                                if (event.target.files && event.target.files[0]) {
                                    if (event.target.files[0].size <= maxFileSize) {
                                        setPresentationVideo(event.target.files[0]);
                                    } else {
                                        toast.error(
                                            `${t("error.video-exceed-quota")} (${maxFileSize / 1024 / 1024}) mb`
                                        );
                                    }
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t("profile.scenes_video")}
                            {profileStore.focusedProfile.scenesVideo && <Icon name="check" style={{ marginLeft: 5 }} />}
                        </Form.Label>
                        <Form.FileInput
                            disabled={!editable}
                            label={t("select_file")}
                            className={classNames({ disabled: !editable })}
                            accept=".avi, .mp4, .mov, .mkv"
                            onChange={event => {
                                if (event.target.files && event.target.files[0]) {
                                    if (event.target.files[0].size <= maxFileSize) {
                                        setScenesVideo(event.target.files[0]);
                                    } else {
                                        toast.error(
                                            `${t("error.video-exceed-quota")} (${maxFileSize / 1024 / 1024}) mb`
                                        );
                                    }
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group style={{ marginTop: 15 }}>
                        {t("upload.total-files")}: {totalFiles}/{maxFiles}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("profile.other_videos")}</Form.Label>
                        <div
                            {...(editable ? getRootProps() : undefined)}
                            className={classNames("file-uploader", {
                                disabled: !editable,
                                dragging: isDragActive
                            })}
                        >
                            <input {...getInputProps()} disabled={!editable} />
                            {isDragActive ? (
                                <p className="file-uploader-label">{t("uploader_drop")}</p>
                            ) : (
                                <p className="file-uploader-label">{t("uploader")}</p>
                            )}
                            <div className="file-list">
                                {files.map((file, index) => (
                                    <div key={`image_${index}`} className="file-detail">
                                        {file.filename}
                                        {!isSending && (
                                            <Button
                                                icon="x"
                                                onClick={(e: any) => {
                                                    removeFile(file);
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            />
                                        )}
                                        {isSending && <CircleLoader size={20} color={"black"} />}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Form.Group style={{ marginTop: 15 }}>
                            {maxFiles - totalFiles < 0 ? 0 : maxFiles - totalFiles} {t("upload.remaining-files")}{" "}
                            {maxFileSize / 1024 / 1024} mb {t("upload.each")}.
                        </Form.Group>
                    </Form.Group>
                    {sessionStore.isSameAsFocused && canEdit && (
                        <div className="flex-row">
                            {editable && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    icon="save"
                                    onClick={onSubmit}
                                    disabled={isSendButtonDisabled}
                                >
                                    {t("save")}
                                </Button>
                            )}
                            {editable && (
                                <Button color="danger" icon="x" onClick={onCancel}>
                                    {t("cancel")}
                                </Button>
                            )}
                        </div>
                    )}
                    {!sessionStore.isSameAsFocused && (sessionStore.isBooker || sessionStore.isDirector) && (
                        <div className="flex-row">
                            {!extraInfoEditable && (
                                <Button
                                    color="primary"
                                    icon="edit"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setExtraInfoEditable(true);
                                        setEditable(true);
                                    }}
                                >
                                    {t("edit")}
                                </Button>
                            )}
                            {extraInfoEditable && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    icon="save"
                                    onClick={onSubmit}
                                    disabled={isSendButtonDisabled}
                                >
                                    {t("save")}
                                </Button>
                            )}
                            {extraInfoEditable && (
                                <Button
                                    color="danger"
                                    icon="x"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditable(false);
                                        setExtraInfoEditable(false);
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                            )}
                            <Button
                                type="button"
                                color="success"
                                icon="arrow-down-circle"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    window.open(config.url + "/files/videos/" + profileStore.focusedProfile._id);
                                }}
                            >
                                Download
                            </Button>
                        </div>
                    )}
                </Card.Body>
            </Card>
        );
    })
);
