import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, Form, Header } from "tabler-react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Casting, CastingAttributeType } from "../../models";
import { updateCasting } from "../../services/casting.service";
import { toast } from "react-toastify";
import classNames from "classnames";

export const Specification = inject("rootStore")(
    observer((props: Stores) => {
        const { castingStore, castingAttributeStore, loadingStore } = props.rootStore!;
        const [editable, setEditable] = useState(false);
        const { t } = useTranslation();

        const onSubmit = async (values: Partial<Casting>, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            if (castingStore.focusedCasting?._id) {
                updateCasting(castingStore.focusedCasting._id, values)
                    .then(casting => {
                        castingStore.setFocusedCasting(casting);
                        castingStore.updateCasting(casting);
                        toast.success(t("success.updated"));
                        loadingStore.stopLoading();
                        setEditable(false);
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else {
                toast.error(t("error.updating"));
                loadingStore.stopLoading();
            }
        };

        const animatedComponents = makeAnimated();

        const users = useMemo(() => castingStore.users.map(u => ({ label: u.artisticName, value: u._id })), [
            castingStore.users
        ]);

        const clients = useMemo(
            () =>
                castingAttributeStore.attributes
                    .filter(attr => (attr.type as CastingAttributeType).key === "client")
                    .map(attr => ({ label: attr.value, value: attr._id })),
            [castingAttributeStore.attributes]
        );

        const findLabel = useCallback(
            (id?: string) => castingAttributeStore.attributes.find(attr => attr._id == id)?.value ?? "",
            [castingAttributeStore.attributes]
        );

        const findUser = useCallback(
            (id?: string) => castingStore.users.find(attr => attr._id == id)?.artisticName ?? "",
            [castingStore.users]
        );

        return (
            <Card>
                <Card.Body>
                    {!editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("casting.specification")}</Header.H3>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            clients: castingStore.focusedCasting.clients ?? [],
                            title: castingStore.focusedCasting.title,
                            category: castingStore.focusedCasting.category,
                            agent: castingStore.focusedCasting.agent,
                            assistants: castingStore.focusedCasting.assistants ?? []
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (values.clients?.length === 0) {
                                errors.clients = t("form.errors.required");
                            }
                            if (!values.title || values.title.length < 3) {
                                errors.title = t("form.errors.required");
                            }
                            if (!values.category) {
                                errors.category = t("form.errors.required");
                            }
                            if (!values.agent) {
                                errors.agent = t("form.errors.required");
                            }
                            if (values.assistants?.length === 0) {
                                errors.assistants = t("form.errors.required");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.client")}</Form.Label>
                                            {editable ? (
                                                <>
                                                    <Select
                                                        name="clients"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={values.clients?.map(cli => ({
                                                            value: cli,
                                                            label: findLabel(cli)
                                                        }))}
                                                        isMulti
                                                        options={clients}
                                                        placeholder={t("select")}
                                                        noOptionsMessage={() => "--"}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "clients",
                                                                value?.map((v: any) => v.value)
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={classNames({
                                                            "invalid-multiselect": !!errors.clients
                                                        })}
                                                    />
                                                    {errors.clients && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.clients}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <ul>
                                                    {values.clients?.map(v => (
                                                        <li>{findLabel(v)}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.title")}</Form.Label>
                                            {editable ? (
                                                <Form.Input
                                                    disabled={!editable}
                                                    name="title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.title}
                                                    value={values.title}
                                                />
                                            ) : (
                                                values.title
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.category")}</Form.Label>
                                            {editable ? (
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="category"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.category}
                                                    value={values.category}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {castingAttributeStore.attributes
                                                        .filter(
                                                            attr =>
                                                                (attr.type as CastingAttributeType).key === "category"
                                                        )
                                                        .map(attr => (
                                                            <option key={attr._id} value={attr._id}>
                                                                {attr.value}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            ) : (
                                                findLabel(values.category)
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.agent")}</Form.Label>
                                            {editable ? (
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="agent"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.agent}
                                                    value={values.agent}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {users.map(user => (
                                                        <option key={user.value} value={user.value}>
                                                            {user.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            ) : (
                                                findUser(values.agent)
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.assistants")}</Form.Label>
                                            {editable ? (
                                                <>
                                                    <Select
                                                        name="assistants"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={values.assistants?.map(ass => ({
                                                            value: ass,
                                                            label: users.find(u => u.value == ass)?.label
                                                        }))}
                                                        isMulti
                                                        options={users}
                                                        placeholder={t("select")}
                                                        noOptionsMessage={() => "--"}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "assistants",
                                                                value?.map((v: any) => v.value)
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={classNames({
                                                            "invalid-multiselect": !!errors.assistants
                                                        })}
                                                    />
                                                    {errors.assistants && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.assistants}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <ul>
                                                    {values.assistants?.map(v => (
                                                        <li>{findUser(v)}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Footer>
                                    {editable && (
                                        <Button type="submit" color="primary" icon="save">
                                            {t("save")}
                                        </Button>
                                    )}
                                    {editable && (
                                        <Button
                                            color="danger"
                                            icon="x"
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setEditable(false);
                                                resetForm();
                                            }}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    )}
                                </Form.Footer>
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
