import { Avatar, Button, Card, Form, Header } from "tabler-react";
import { Formik } from "formik";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { config } from "../../utils/constants";
import { updatePersonalInfo, updatePersonalInfoAdmin } from "../../services/profile.service";
import { toast } from "react-toastify";
import { Class, StatusEnum } from "../../models";
import { ImageCropper } from "../ImageCropper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { formatArtistName } from "../../utils/formats";
import { getClasses } from "../../services/classes.service";

export const stamps = [
    {
        value: "act",
        label: "Act"
    },
    {
        value: "street",
        label: "Street"
    },
    {
        value: "digital",
        label: "Digital"
    },
    {
        value: "open",
        label: "Open"
    },
    {
        value: "fashion",
        label: "Fashion"
    },
    {
        value: "orion",
        label: "Orion"
    }
];

export const PersonalInfo = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const { profileStore, loadingStore, sessionStore, classesStore } = props.rootStore!;
        const [editable, setEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const [extraInfoEditable, setExtraInfoEditable] = useState(false);
        const [file, setFile] = useState<File | undefined>();
        const [fileName, setFileName] = useState("123");
        const [image, setImage] = useState<Blob | undefined>(undefined);
        const [imageBase64, setImageBase64] = useState<string | undefined>(undefined);
        const [showCrop, setShowCrop] = useState(false);

        const heights: number[] = [];
        const weigths: number[] = [];
        for (let i = 30; i <= 215; i++) {
            heights.push(i);
        }

        for (let i = 1; i <= 250; i++) {
            weigths.push(i);
        }

        const reader = new FileReader();
        reader.addEventListener(
            "load",
            function () {
                // convert image file to base64 string
                setImageBase64(reader.result as string);
            },
            false
        );

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
        }, [profileStore.focusedProfile]);

        useEffect(() => {
            if (classesStore.classes.length === 0) {
                loadingStore.triggerLoading();
                getClasses()
                    .then(classes => {
                        classesStore.setClasses(classes);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                    });
            }
        }, [classesStore.classes]);

        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            if (sessionStore.isSameAsFocused) {
                updatePersonalInfo(image, file, {
                    clothingSize: Number(values.clothing_size),
                    eyes: values.eyes,
                    footSize: Number(values.foot_size),
                    hair: values.hair,
                    height: Number(values.height),
                    weight: Number(values.weight)
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        sessionStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        setImage(undefined);
                        setImageBase64(undefined);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else if ((sessionStore.isDirector || sessionStore.isBooker) && profileStore.focusedProfile._id) {
                updatePersonalInfoAdmin(profileStore.focusedProfile._id, image, file, {
                    clothingSize: Number(values.clothing_size),
                    eyes: values.eyes,
                    footSize: Number(values.foot_size),
                    hair: values.hair,
                    height: Number(values.height),
                    weight: Number(values.weight),
                    ethnicity: values.ethnicity,
                    skin: values.skin,
                    classes: values.classes,
                    exclusive: values.exclusivity === "yes",
                    stamp: values.stamp
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setExtraInfoEditable(false);
                        setEditable(false);
                        setImage(undefined);
                        setImageBase64(undefined);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            }
        };

        const hairs = [
            { label: t("profile.hair.black"), value: "black" },
            { label: t("profile.hair.brown"), value: "brown" },
            { label: t("profile.hair.blond"), value: "blond" },
            { label: t("profile.hair.ginger"), value: "ginger" },
            { label: t("profile.hair.grey"), value: "grey" },
            { label: t("profile.hair.colored"), value: "colored" }
        ];
        const eyes = [
            { label: t("profile.eyes.black"), value: "black" },
            { label: t("profile.eyes.brown"), value: "brown" },
            { label: t("profile.eyes.green"), value: "green" },
            { label: t("profile.eyes.blue"), value: "blue" },
            { label: t("profile.eyes.grey"), value: "grey" },
            { label: t("profile.eyes.violet"), value: "violet" },
            { label: t("profile.eyes.heterochromic"), value: "heterochromic" }
        ];
        const colors = [
            { label: t("profile.skin.color1"), value: "color1" },
            { label: t("profile.skin.color2"), value: "color2" },
            { label: t("profile.skin.color3"), value: "color3" },
            { label: t("profile.skin.color4"), value: "color4" },
            { label: t("profile.skin.color5"), value: "color5" },
            { label: t("profile.skin.color6"), value: "color6" }
        ];
        const ethnicities = [
            { label: t("profile.ethnicity.african"), value: "african" },
            { label: t("profile.ethnicity.brazilian"), value: "brazilian" },
            { label: t("profile.ethnicity.european"), value: "european" },
            { label: t("profile.ethnicity.asian"), value: "asian" },
            { label: t("profile.ethnicity.arabic"), value: "arabic" },
            { label: t("profile.ethnicity.native"), value: "native" },
            { label: t("profile.ethnicity.latin"), value: "latin" }
        ];
        const picture = profileStore.focusedProfile?.photo
            ? config.host + encodeURI(profileStore.focusedProfile?.photo)
            : undefined;
        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.characteristics")}</Header.H3>
                    <div className="profile-group center">
                        <Avatar size="xxl" color="gray-dark" imageURL={imageBase64 ?? picture} />
                        <Header.H1>{profileStore.focusedProfile.artisticName}</Header.H1>
                        {editable && !imageBase64 && !picture && (
                            <span className="invalid-feedback" style={{ display: "block" }}>
                                {t("form.errors.required")}
                            </span>
                        )}
                    </div>
                    <div className="flex-row">
                        <div className="flex-column">
                            <Form.Group>
                                <Form.Label>{t("profile.photo")}</Form.Label>
                                <Form.FileInput
                                    disabled={!editable}
                                    label={t("select_file")}
                                    className={classNames({ disabled: !editable })}
                                    name="picture"
                                    accept="image/*"
                                    onChange={event => {
                                        if (event.target.files && event.target.files.length > 0) {
                                            setFile(event.target.files[0]);
                                            reader.readAsDataURL(event.target.files[0]);
                                            setShowCrop(true);
                                        }
                                    }}
                                    key={fileName}
                                />
                                <ImageCropper
                                    circular={true}
                                    imageBase64={imageBase64}
                                    onCancel={useCallback(() => {
                                        setShowCrop(false);
                                        setImage(undefined);
                                        setImageBase64(undefined);
                                        setFileName("");
                                    }, [])}
                                    onSuccess={useCallback(base64 => {
                                        setImageBase64(base64);
                                        setShowCrop(false);
                                    }, [])}
                                    resize
                                    setImage={setImage}
                                    visible={showCrop}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            height: profileStore.focusedProfile.personalInfo?.height ?? 170,
                            weight: profileStore.focusedProfile.personalInfo?.weight ?? 50,
                            clothing_size: profileStore.focusedProfile.personalInfo?.clothingSize ?? 38,
                            foot_size: profileStore.focusedProfile.personalInfo?.footSize ?? 36,
                            hair: profileStore.focusedProfile.personalInfo?.hair,
                            eyes: profileStore.focusedProfile.personalInfo?.eyes,
                            skin: profileStore.focusedProfile.personalInfo?.skin,
                            ethnicity: profileStore.focusedProfile.personalInfo?.ethnicity,
                            classes: profileStore.focusedProfile.personalInfo?.classes
                                ? profileStore.focusedProfile.personalInfo?.classes
                                : [],
                            exclusivity: !!profileStore.focusedProfile.exclusive ? "yes" : "no",
                            stamp: profileStore.focusedProfile.personalInfo?.stamp ?? "open"
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (!values.height) {
                                errors.height = t("form.errors.required");
                            }
                            if (!values.weight) {
                                errors.weight = t("form.errors.required");
                            }
                            if (!values.clothing_size) {
                                errors.clothing_size = t("form.errors.required");
                            }
                            if (!values.foot_size) {
                                errors.foot_size = t("form.errors.required");
                            }
                            if (!values.hair) {
                                errors.hair = t("form.errors.required");
                            }
                            if (!values.eyes) {
                                errors.eyes = t("form.errors.required");
                            }
                            if (
                                !sessionStore.isSameAsFocused &&
                                (sessionStore.isDirector || sessionStore.isBooker) &&
                                !values.skin
                            ) {
                                errors.skin = t("form.errors.required");
                            }
                            if (
                                !sessionStore.isSameAsFocused &&
                                (sessionStore.isDirector || sessionStore.isBooker) &&
                                !values.ethnicity
                            ) {
                                errors.ethnicity = t("form.errors.required");
                            }
                            if (
                                !sessionStore.isSameAsFocused &&
                                (sessionStore.isDirector || sessionStore.isBooker) &&
                                !values.classes
                            ) {
                                errors.classes = t("form.errors.required");
                            }
                            if (
                                !sessionStore.isSameAsFocused &&
                                (sessionStore.isDirector || sessionStore.isBooker) &&
                                !values.exclusivity
                            ) {
                                errors.exclusivity = t("form.errors.required");
                            }
                            if (
                                !sessionStore.isSameAsFocused &&
                                (sessionStore.isDirector || sessionStore.isBooker) &&
                                !values.stamp
                            ) {
                                errors.stamp = t("form.errors.required");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.height")} (cm)</Form.Label>
                                            <Form.Select
                                                disabled={!editable}
                                                name="height"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.height}
                                                error={errors && errors.height}
                                            >
                                                {heights.map(i => (
                                                    <option value={i}>{i} cm</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.weight")} (Kg)</Form.Label>
                                            <Form.Select
                                                disabled={!editable}
                                                name="weight"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.weight}
                                                error={errors && errors.weight}
                                            >
                                                {weigths.map(i => (
                                                    <option value={i}>{i} kg</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.clothing_size")}</Form.Label>
                                            <Form.Ratio
                                                disabled={!editable}
                                                className={classNames("profile-ratio", {
                                                    disabled: !editable
                                                })}
                                                max={60}
                                                min={34}
                                                step={2}
                                                name="clothing_size"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.clothing_size}
                                                error={errors && errors.clothing_size}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.foot_size")}</Form.Label>
                                            <Form.Ratio
                                                disabled={!editable}
                                                className={classNames("profile-ratio", {
                                                    disabled: !editable
                                                })}
                                                max={50}
                                                min={32}
                                                step={1}
                                                name="foot_size"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.foot_size}
                                                error={errors && errors.foot_size}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.hair.label")}</Form.Label>
                                            {hairs.map(hair => (
                                                <Form.Radio
                                                    key={`hair_${hair.value}`}
                                                    disabled={!editable}
                                                    label={hair.label}
                                                    name="hair"
                                                    value={hair.value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.hair}
                                                    checked={values.hair === hair.value}
                                                    className={classNames({
                                                        disabled: !editable
                                                    })}
                                                />
                                            ))}
                                            {errors.hair && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.hair}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.eyes.label")}</Form.Label>
                                            {eyes.map(eye => (
                                                <Form.Radio
                                                    key={`eye_${eye.value}`}
                                                    disabled={!editable}
                                                    label={eye.label}
                                                    name="eyes"
                                                    value={eye.value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.eyes}
                                                    checked={values.eyes === eye.value}
                                                    className={classNames({
                                                        disabled: !editable
                                                    })}
                                                />
                                            ))}
                                            {errors.eyes && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.eyes}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                {((!sessionStore.isDirector && !sessionStore.isBooker) ||
                                    sessionStore.isSameAsFocused) && (
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.stamp")}</Form.Label>
                                                <span
                                                    className={`badge badge-${
                                                        profileStore.focusedProfile.personalInfo?.stamp ?? "open"
                                                    }`}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    {profileStore.focusedProfile.personalInfo?.stamp ?? "open"}
                                                </span>
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.public-page")}</Form.Label>
                                                <a
                                                    href={`${config.host}profiles/${formatArtistName(
                                                        profileStore.focusedProfile.artisticName
                                                    )}`}
                                                    target="_blank"
                                                    title={t("profile.public-page")}
                                                >
                                                    <FontAwesomeIcon icon={faIdCard} />
                                                </a>
                                            </Form.Group>
                                        </div>
                                    </div>
                                )}
                                {(sessionStore.isDirector || sessionStore.isBooker) && !sessionStore.isSameAsFocused && (
                                    <>
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>{t("profile.skin.label")}</Form.Label>
                                                    {colors.map(color => (
                                                        <Form.Radio
                                                            key={`skin_${color.value}`}
                                                            disabled={!extraInfoEditable}
                                                            label={color.label}
                                                            name="skin"
                                                            value={color.value}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors.skin}
                                                            checked={values.skin === color.value}
                                                            className={classNames({
                                                                disabled: !extraInfoEditable
                                                            })}
                                                        />
                                                    ))}
                                                    {errors.skin && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.skin}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>{t("profile.ethnicity.label")}</Form.Label>
                                                    {ethnicities.map((v, index) => (
                                                        <Form.Radio
                                                            disabled={!extraInfoEditable}
                                                            key={`ethnicity_${index}`}
                                                            label={v.label}
                                                            name="ethnicity"
                                                            value={v.value}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors && errors.ethnicity}
                                                            checked={values.ethnicity === v.value}
                                                            className={classNames({
                                                                disabled: !extraInfoEditable
                                                            })}
                                                            invalid={!!errors.ethnicity}
                                                        />
                                                    ))}
                                                    {errors.ethnicity && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.ethnicity}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>{t("profile.class.label")}</Form.Label>
                                                    <Form.SelectGroup canSelectMultiple pills>
                                                        {classesStore.classes.map(v => (
                                                            <Form.SelectGroupItem
                                                                key={`class_${v.key}`}
                                                                label={
                                                                    v[
                                                                        `name${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Class
                                                                    ]
                                                                }
                                                                name="classes"
                                                                value={v.key}
                                                                disabled={!extraInfoEditable}
                                                                onChange={handleChange}
                                                                checked={!!values.classes.find(d => d === v.key)}
                                                                className={classNames({ disabled: !extraInfoEditable })}
                                                            />
                                                        ))}
                                                    </Form.SelectGroup>
                                                </Form.Group>
                                            </div>
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>{t("profile.exclusivity")}</Form.Label>
                                                    <Form.Radio
                                                        disabled={!extraInfoEditable}
                                                        key={`exclusivity_yes`}
                                                        label={t("yes")}
                                                        name="exclusivity"
                                                        value="yes"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors && errors.exclusivity}
                                                        checked={values.exclusivity === "yes"}
                                                        className={classNames({
                                                            disabled: !extraInfoEditable
                                                        })}
                                                        invalid={!!errors.exclusivity}
                                                    />
                                                    <Form.Radio
                                                        disabled={!extraInfoEditable}
                                                        key={`exclusivity_no`}
                                                        label={t("no")}
                                                        name="exclusivity"
                                                        value="no"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors && errors.exclusivity}
                                                        checked={values.exclusivity === "no"}
                                                        className={classNames({
                                                            disabled: !extraInfoEditable
                                                        })}
                                                        invalid={!!errors.exclusivity}
                                                    />
                                                    {errors.exclusivity && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.exclusivity}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>{t("profile.stamp")}</Form.Label>
                                                    {stamps.map((stamp, i) => (
                                                        <Form.Radio
                                                            disabled={!extraInfoEditable}
                                                            key={`stamps_${i}`}
                                                            label={stamp.label}
                                                            name="stamp"
                                                            value={stamp.value}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors && errors.stamp}
                                                            checked={values.stamp === stamp.value}
                                                            className={classNames({
                                                                disabled: !extraInfoEditable
                                                            })}
                                                            invalid={!!errors.stamp}
                                                        />
                                                    ))}
                                                    {errors.stamp && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.stamp}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {sessionStore.isSameAsFocused && canEdit && (
                                    <Form.Footer>
                                        {editable && (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                icon="save"
                                                disabled={!imageBase64 && !picture}
                                            >
                                                {t("save")}
                                            </Button>
                                        )}
                                        {editable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditable(false);
                                                    setImage(undefined);
                                                    setFile(undefined);
                                                    setImageBase64(undefined);
                                                    setFileName("");
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                                {!sessionStore.isSameAsFocused && (sessionStore.isDirector || sessionStore.isBooker) && (
                                    <Form.Footer>
                                        {!extraInfoEditable && (
                                            <Button
                                                color="primary"
                                                icon="edit"
                                                onClick={() => {
                                                    setEditable(true);
                                                    setExtraInfoEditable(true);
                                                }}
                                            >
                                                {t("edit")}
                                            </Button>
                                        )}
                                        {extraInfoEditable && (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                icon="save"
                                                disabled={!imageBase64 && !picture}
                                            >
                                                {t("save")}
                                            </Button>
                                        )}
                                        {extraInfoEditable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditable(false);
                                                    setExtraInfoEditable(false);
                                                    setImage(undefined);
                                                    setFile(undefined);
                                                    setImageBase64(undefined);
                                                    setFileName("");
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
