import { get } from "./request.service";
import { config } from "../utils/constants";

export function getProtectedFile(file: string): Promise<Blob> {
    return get(`${config.url}/files/download/${file}`, undefined, {}, true);
}

export function getFile(address: string): Promise<Blob> {
    return get(`${address}`, undefined, {}, true);
}
