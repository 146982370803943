import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { useTranslation } from "react-i18next";
import React from "react";
import { Formik } from "formik";
import { Button, Form } from "tabler-react";
import { agreeToTermsAndConditions } from "../services/profile.service";
import { toast } from "react-toastify";

export const ContractAgreement = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const {
            loadingStore,
            sessionStore,
            profileStore,
            routingStore: { push }
        } = props.rootStore!;
        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            agreeToTermsAndConditions({
                agreed: true
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    sessionStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    loadingStore.stopLoading();
                    push("/");
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };
        return (
            <Formik
                initialValues={{
                    agreed: []
                }}
                validate={values => {
                    let errors: any = {};
                    if (!values.agreed || values.agreed[0] !== "yes") {
                        errors.agreed = t("form.errors.required");
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="flex-table">
                            <div className="flex-row">
                                <div className="flex-column">
                                    <div className="terms">
                                        <p>
                                            <strong>{t("terms-and-conditions", "Termos - Agenciamento KOZMOS")}</strong>
                                        </p>
                                        <p>
                                            <strong>1. PARTES</strong>
                                        </p>
                                        <p>
                                            Este Termo de Agenciamento e Promoção de Imagem é celebrado entre KOZMOS,
                                            doravante denominada "Agência", e "Artista", regendo-se pelos princípios e
                                            regras abaixo estabelecidos.
                                        </p>
                                        <p>
                                            <strong>2. CONSIDERAÇÕES INICIAIS</strong>
                                        </p>
                                        <p>
                                            Considerando a expertise da Agência na promoção de imagem de artistas,
                                            priorizando não apenas o talento, mas também a postura profissional e ética.
                                            <br />
                                            <br />
                                            Considerando o desejo do Artista em direcionar sua carreira de maneira
                                            consistente, confiando na capacidade da Agência em construir um nome e uma
                                            imagem pública sólidos.
                                            <br />
                                            <br />
                                            Ambas as partes comprometem-se a estabelecer uma parceria baseada na
                                            confiança mútua e boa-fé, empregando seus melhores esforços para o sucesso
                                            dessa colaboração.
                                        </p>
                                        <p>
                                            <strong>3. OBJETIVO E TAXA DE AGENCIAMENTO</strong>
                                        </p>
                                        <p>
                                            O Artista contrata a Agência para divulgar, promover, negociar e intermediar
                                            sua contratação artística, autorizando a divulgação de seu nome, imagem, voz
                                            e currículo.
                                            <br />
                                            A Taxa de Agenciamento incidirá sobre a receita líquida de cada contrato e
                                            suas renovações, conforme percentuais estabelecidos a seguir:
                                            <br />
                                            <br />
                                            20% do cachê percebido por obra certa.
                                            <br />
                                            20% dos valores derivados de subprodutos dos contratos, como renovações,
                                            extensões de escopo, horas ou diárias extras, multas e afins.
                                            <br />
                                            20% dos valores decorrentes do uso da imagem do Artista.
                                            <br />
                                            20% do valor originado de contratos anteriores à assinatura deste, a partir
                                            da primeira negociação pela Agência.
                                            <br />
                                            <br />
                                            Na publicidade, o Artista pagará à Agência a Taxa de Agenciamento de 20%,
                                            independente de valores recebidos diretamente pela Agência.
                                            <br />
                                            <br />
                                            O Artista se compromete a efetuar o pagamento da Taxa de Agenciamento no
                                            prazo máximo de 10 dias a contar do recebimento do cachê.
                                            <br />
                                            <br />
                                            Em casos de recebimento direto pela agência, esta transferirá os valores
                                            devidos ao artista no prazo máximo de 10 dias, descontando a taxa de
                                            agenciamento e encargos fiscais - estipulados em mais 20%.
                                            <br />
                                            <br />O Artista é responsável pelos impostos, taxas e encargos decorrentes
                                            de seus serviços, sem implicação para a Agência, que não mantém vínculo
                                            empregatício com o Artista.
                                        </p>
                                        <p>
                                            <strong>4. MATERIAL PARA DIVULGAÇÃO E DADOS CADASTRAIS</strong>
                                        </p>
                                        <p>
                                            O Artista preencherá no Sistema Gaia, gerido pela KOZMOS, material
                                            fotográfico, material de vídeo e outros necessários para divulgação
                                            exclusiva pela Agência e compromete-se a manter esses materiais atualizados.
                                            <br />
                                            <br />O Artista manterá seus dados cadastrais atualizados e informará à
                                            Agência sobre quaisquer modificações relevantes.
                                        </p>
                                        <p>
                                            <strong>5. ORIENTAÇÃO ARTÍSTICA</strong>
                                        </p>
                                        <p>
                                            A Agência, baseada em sua experiência, avaliará e orientará o Artista nas
                                            ofertas de trabalho, considerando aspectos objetivos e subjetivos das
                                            propostas. A Agência poderá desaconselhar trabalhos potencialmente danosos,
                                            mas a decisão final será do Artista.
                                        </p>
                                        <p>
                                            <strong>6. PROFISSIONALISMO</strong>
                                        </p>
                                        <p>
                                            O Artista compromete-se a manter postura profissional e imagem pública
                                            adequadas, comparecendo pontualmente nos locais e horários fixados por
                                            agências e produtoras, sob pena de rescisão imediata deste Contrato.
                                        </p>
                                        <p>
                                            <strong>7. PRAZO E RESCISÃO</strong>
                                        </p>
                                        <p>
                                            Este Contrato tem prazo indeterminado e pode ser rescindido por ambas as
                                            partes a qualquer momento, mediante aviso prévio por escrito de 7 dias. A
                                            rescisão não implica ônus ou multa, garantindo apenas as obrigações
                                            decorrentes de contratos assinados durante a vigência deste Contrato.
                                        </p>
                                        <p>
                                            <strong>8. FORO</strong>
                                        </p>
                                        <p>
                                            As partes elegem o Foro Central da Comarca de São Paulo para dirimir
                                            quaisquer litígios oriundos deste Contrato.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-column">
                                    <Form.Checkbox
                                        isInline
                                        label={t("agree_terms")}
                                        name="agreed"
                                        value="yes"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={values.agreed[0] === "yes"}
                                    />
                                    {errors && errors.agreed && (
                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.agreed}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <Form.Footer>
                                <Button type="submit" color="primary" icon="save">
                                    {t("proceed")}
                                </Button>
                                <Button
                                    color="danger"
                                    icon="x"
                                    onClick={() => {
                                        sessionStore.clearCurrentSession();
                                        push("/login");
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                            </Form.Footer>
                        </div>
                    </form>
                )}
            </Formik>
        );
    })
);
