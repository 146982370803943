import React, { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { useTranslation } from "react-i18next";
import { Button } from "tabler-react";
import { getCroppedImg } from "../services/util.service";
import { toast } from "react-toastify";
import { Popup } from "./Popup";
import "react-image-crop/dist/ReactCrop.css";

interface ImageCropperProps extends Stores {
    aspectRatio?: number;
    circular?: boolean;
    unit?: "%" | "px";
    imageBase64: string | undefined;
    onCancel: () => void;
    onSuccess: (base64: string) => void;
    resize: boolean;
    setImage: Dispatch<SetStateAction<Blob | undefined>>;
    visible: boolean;
    width?: number;
    style?: CSSProperties;
}

export const ImageCropper = inject("rootStore")(
    observer((props: ImageCropperProps) => {
        const { t } = useTranslation();
        const [crop, setCrop] = useState<Crop>({
            x: 25,
            y: 0,
            unit: props.unit ?? "%",
            aspect: props.aspectRatio ?? 1,
            width: props.width ?? 50
        });
        const [width, setWidth] = useState(0);
        const [height, setHeight] = useState(0);
        return props.visible && props.imageBase64 ? (
            <Popup
                title={t("profile.crop-photo")}
                showHeader={true}
                onClose={props.onCancel}
                style={{
                    maxWidth: "80vw",
                    maxHeight: "90vh",
                    ...props.style
                }}
            >
                <div
                    style={{ display: "flex", flexDirection: "column" }}
                    ref={e => {
                        if (e?.clientWidth && e.clientHeight) {
                            setWidth(e.clientWidth);
                            setHeight(e.clientHeight);
                        }
                    }}
                >
                    {props.imageBase64 && (
                        <ReactCrop
                            src={props.imageBase64}
                            crop={crop}
                            circularCrop={props.circular}
                            onChange={crop => {
                                setCrop(crop);
                            }}
                            keepSelection
                            imageStyle={{ maxHeight: "800px" }}
                        />
                    )}
                </div>
                <div>
                    <Button
                        color="primary"
                        icon="save"
                        onClick={async () => {
                            try {
                                const { blob, base64 } = await getCroppedImg(
                                    props.imageBase64!,
                                    crop,
                                    width,
                                    height,
                                    props.resize
                                );
                                if (blob && base64) {
                                    props.setImage(blob);
                                    props.onSuccess(base64);
                                } else {
                                    toast.error(t("error.croppping"));
                                }
                            } catch (e) {
                                toast.error(t("error.croppping"));
                            }
                        }}
                        style={{
                            marginTop: 5
                        }}
                    >
                        {t("crop")}
                    </Button>
                </div>
            </Popup>
        ) : null;
    })
);
