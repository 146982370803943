import { action, makeObservable, observable } from "mobx";
import { Class } from "../models";

export class ClassesStore {
    @observable.deep classes: Class[] = [] as Class[];

    constructor() {
        makeObservable(this);
    }

    @action setClasses(classes: Class[]) {
        this.classes = classes;
    }

    @action updateClass(classObject: Class) {
        const index = this.classes.findIndex(c => c._id === classObject._id);
        if (index > -1) {
            this.classes[index] = classObject;
        }
    }

    @action getClassById(id: string): Class | undefined {
        return this.classes.find(c => c._id === id);
    }

    @action addClass(classObject: Class) {
        this.classes.push(classObject);
    }

    @action sortByKey(sortMethod: "asc" | "desc", classes?: Class[]) {
        const unsortedClasses = classes ? classes : [...this.classes];

        unsortedClasses.sort((p1, p2) => {
            if (!p1.key || !p2.key) {
                return 0;
            }
            if (p1.key === p2.key) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1.key.localeCompare(p2.key);
            }
            return p2.key.localeCompare(p1.key);
        });

        if (!classes) {
            this.setClasses(unsortedClasses);
        }
    }

    @action sortByLanguage(sortMethod: "asc" | "desc", language: keyof Class, classes?: Class[]) {
        const unsortedClasses = classes ? classes : [...this.classes];

        unsortedClasses.sort((p1, p2) => {
            if (!(language in p1) || !(language in p2)) {
                return 0;
            }
            if (p1[language] === p2[language]) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1[language]!.localeCompare(p2[language]!);
            }
            return p2[language]!.localeCompare(p1[language]!);
        });

        if (!classes) {
            this.setClasses(unsortedClasses);
        }
    }
}
