import { LoadingStore } from "./loading.store";
import { RouterStore } from "mobx-react-router";
import { SessionStore } from "./session.store";
import { ProfileStore } from "./profile.store";
import { SkillsStore } from "./skills.store";
import { SegmentsStore } from "./segments.store";
import { CastingAttributeStore } from "./casting-attribute.store";
import { TranslationStore } from "./translation.store";
import { CastingTypesStore } from "./casting-types.store";
import { ClassesStore } from "./classes.store";
import { CastingStore } from "./casting.store";
import { FinanceStore } from "./finance.store";

export class RootStore {
    loadingStore: LoadingStore;
    routingStore: RouterStore;
    sessionStore: SessionStore;
    profileStore: ProfileStore;
    skillsStore: SkillsStore;
    segmentsStore: SegmentsStore;
    castingStore: CastingStore;
    castingAttributeStore: CastingAttributeStore;
    castingTypesStore: CastingTypesStore;
    translationStore: TranslationStore;
    classesStore: ClassesStore;
    financeStore: FinanceStore;
    constructor() {
        this.loadingStore = new LoadingStore();
        this.routingStore = new RouterStore();
        this.sessionStore = new SessionStore(this);
        this.profileStore = new ProfileStore();
        this.skillsStore = new SkillsStore();
        this.segmentsStore = new SegmentsStore();
        this.castingAttributeStore = new CastingAttributeStore();
        this.castingTypesStore = new CastingTypesStore();
        this.translationStore = new TranslationStore();
        this.classesStore = new ClassesStore();
        this.castingStore = new CastingStore();
        this.financeStore = new FinanceStore();
    }
}
