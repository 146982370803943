import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { isMobile } from "../../utils/utils";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Popup } from "../Popup";
import { useTranslation } from "react-i18next";
import { AdvancedSearch } from "../AdvancedSearch";
import {
    CastingProfile as CastingProfileTyping,
    CastingProfileArtist,
    CastingProfileBonus,
    CastingProfileStatus,
    Profile
} from "../../models";
import { Button, Card, Form } from "tabler-react";
import { formatArtistName, formatCurrency } from "../../utils/formats";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { config } from "../../utils/constants";
import { getNamePlaceholder } from "../../services/util.service";
import { downloadZip } from "../../services/profile.service";
import download from "downloadjs";
import {
    addArtistToProfile,
    removeArtistsFromProfile,
    updateArtistComments,
    updateArtistStatus
} from "../../services/casting.service";
import removeAccents from "remove-accents";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { ExportToCsv } from "export-to-csv";

interface CastingProfileProps extends Stores {
    profile: CastingProfileTyping;
}

export const CastingProfile = inject("rootStore")(
    observer((props: CastingProfileProps) => {
        const { loadingStore, castingStore, castingAttributeStore, profileStore } = props.rootStore!;
        const [openSearch, setOpenSearch] = useState(false);
        const [profiles, setProfiles] = useState<Profile[]>([]);
        const [results, setResults] = useState<Profile[]>([]);
        const [profileIds, setProfileIds] = useState<string[]>([]);
        const [profileIdsSearch, setProfileIdsSearch] = useState<string[]>([]);
        const [select, setSelect] = useState(false);
        const [showDelete, setShowDelete] = useState(false);
        const [showStatus, setShowStatus] = useState(false);
        const [showComments, setShowComments] = useState(false);
        const [status, setStatus] = useState<CastingProfileStatus | undefined>(undefined);
        const [showPopup, setShowPopup] = useState(false);
        const [showPopupMultipleSelection, setShowPopupMultipleSelection] = useState(false);
        const [temporaryProfiles, setTemporaryProfiles] = useState<string[]>([]);
        const [selectedArtist, setSelectedArtist] = useState<string | undefined>();
        const { t } = useTranslation();

        // Export methods
        const castingProfileBonus = [
            { value: "", label: t("select") },
            { value: CastingProfileBonus.BONUS, label: t("casting.profile.bonus") },
            { value: CastingProfileBonus.SINGLE_PAYMENT, label: t("casting.profile.single-payment") }
        ];

        const findLabel = useCallback(
            (id?: string) => castingAttributeStore.attributes.find(attr => attr._id == id)?.value ?? "",
            [castingAttributeStore.attributes]
        );
        const getProfileTitleComplement = (profile: CastingProfileTyping): string => {
            const bonus =
                profile.bonus == CastingProfileBonus.BONUS
                    ? castingProfileBonus.find(bonus => bonus.value == CastingProfileBonus.BONUS)?.label
                    : "";
            return `R$${formatCurrency(Number(profile.price ?? 0))} ${bonus}`.trim();
        };
        const users = useMemo(() => castingStore.users.map(u => ({ label: u.artisticName, value: u._id })), [
            castingStore.users
        ]);
        const findUserName = (id: string) => users.find(u => u.value == id)?.label;
        const territories = useMemo(
            () => [
                { label: t("casting.territory.brazil"), value: "brazil" },
                { label: t("casting.territory.world"), value: "world" },
                { label: t("casting.territory.americas"), value: "americas" },
                { label: t("casting.territory.north-america"), value: "north-america" },
                { label: t("casting.territory.south-america"), value: "south-america" },
                { label: t("casting.territory.europe"), value: "europe" },
                { label: t("casting.territory.asia"), value: "asia" },
                { label: t("casting.territory.oceania"), value: "oceania" }
            ],
            []
        );
        const exportCsv = () => {
            const csvOptions = {
                filename: "campanha-blip",
                fieldSeparator: ";",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                headers: [
                    "telefone",
                    "nomeArtistico",
                    "cliente",
                    "segmento",
                    "exclusividade",
                    "territorio",
                    "midias",
                    "veiculacao",
                    "diariasFilmagem",
                    "diariasExtras",
                    "prevFilmagem",
                    "localTrabalho",
                    "cache",
                    "mei",
                    "agenteAssistente",
                    "status"
                ]
            };
            const csvExporter = new ExportToCsv(csvOptions);
            const cliente = castingStore.focusedCasting.clients?.map(s => findLabel(s))?.join("; ") ?? "";
            const segmento = castingStore.focusedCasting.segments?.map(s => findLabel(s)).join("; ") ?? "";
            const territorio = territories.find(t => t.value === castingStore.focusedCasting.territory)?.label ?? "";
            const veiculacao = castingStore.focusedCasting.frequency ?? 0;
            const cache = getProfileTitleComplement(props.profile);
            const getStatus = (id: string) =>
                t(
                    `casting.profile-status.${translateStatus(
                        props.profile.artists?.find(a => a.profile === id)?.status ?? CastingProfileStatus.PRE_SELECTED
                    )}`
                );
            const agenteAssistente = castingStore.focusedCasting.assistants?.map(ass => findUserName(ass)) ?? "";
            const data = profiles
                .filter(p => profileIds.includes(String(p._id)))
                .map(p => ({
                    telefone: p.phoneCellphone?.replace(/\D/g, "") ?? "",
                    nomeArtistico: p.artisticName,
                    cliente,
                    segmento,
                    exclusividade: "",
                    territorio,
                    midias: "",
                    veiculacao,
                    diariasFilmagem: "",
                    diariasExtras: "",
                    prevFilmagem: "",
                    localTrabalho: "",
                    cache,
                    mei: "",
                    agenteAssistente,
                    status: getStatus(String(p._id))
                }));
            csvExporter.generateCsv(data);
        };

        useEffect(() => {
            if (props.profile.artists) {
                setProfiles(profileStore.profiles.filter(p => props.profile.artists?.some(a => a.profile === p._id)));
            }
        }, [props.profile, profileStore.profiles]);

        const renderArtist = useCallback(
            (
                picture: string | undefined,
                profile: Profile,
                profiles: string[],
                setProfiles: Dispatch<SetStateAction<string[]>>,
                badge = true,
                comments = null
            ) => {
                const status = translateStatus(
                    props.profile.artists?.find(a => a.profile === profile._id)?.status ??
                        CastingProfileStatus.PRE_SELECTED
                );
                return (
                    <Card key={`profile_${profile._id}`}>
                        <Card.Body>
                            {picture && (
                                <img
                                    src={picture}
                                    className="search-thumbnail"
                                    onClick={() => {
                                        window.open(`/details?id=${profile._id}`);
                                    }}
                                    alt={profile.artisticName}
                                    title={profile.artisticName}
                                />
                            )}
                            {!picture && (
                                <div
                                    className="search-empty-thumbnail"
                                    onClick={() => {
                                        window.open(`/details?id=${profile._id}`);
                                    }}
                                    title={profile.artisticName}
                                >
                                    <span>{getNamePlaceholder(profile.artisticName)}</span>
                                </div>
                            )}
                            <Form.Checkbox
                                label={profile.artisticName}
                                name={`profiles`}
                                value={profile._id}
                                onChange={(event: any) => {
                                    const id = event.target.value;
                                    setProfiles(prev => {
                                        if (prev.includes(id)) {
                                            const index = prev.findIndex(p => p === id);
                                            const array = [...prev];
                                            array.splice(index, 1);
                                            return array;
                                        }
                                        return [...prev, id];
                                    });
                                }}
                                checked={profiles.findIndex(p => p === profile._id) > -1}
                            />
                            {badge && (
                                <div className="badge-casting-wrapper">
                                    <div className={`badge badge-casting-${status}`}>
                                        {t(`casting.profile-status.${status}`)}
                                    </div>
                                </div>
                            )}
                            {comments}
                        </Card.Body>
                    </Card>
                );
            },
            [props.profile.artists]
        );

        const addArtistsToProfile = (profiles: string[]) => {
            if (profiles.length > 0 && props.profile._id) {
                loadingStore.triggerLoading();
                addArtistToProfile(profiles, props.profile._id)
                    .then(profile => {
                        castingStore.updateCastingProfile(profile);
                        loadingStore.stopLoading();
                        setOpenSearch(false);
                        setProfileIds([]);
                        toast.success(t("success.added-artists-to-profile"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.adding-artists-to-profile"));
                    });
            }
        };

        const removeArtists = () => {
            if (profiles.length > 0 && props.profile._id) {
                loadingStore.triggerLoading();
                removeArtistsFromProfile(profileIds, props.profile._id)
                    .then(profile => {
                        castingStore.updateCastingProfile(profile);
                        loadingStore.stopLoading();
                        setOpenSearch(false);
                        setProfileIds([]);
                        toast.success(t("success.removed-artists-from-profile"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.removing-artists-from-profile"));
                    });
            }
        };

        const changeStatus = async () => {
            if (profileIds.length > 0 && props.profile._id && status) {
                try {
                    loadingStore.triggerLoading();
                    setShowStatus(false);
                    const castingProfile = await updateArtistStatus(profileIds, props.profile._id, status);
                    castingStore.updateCastingProfile(castingProfile);
                    loadingStore.stopLoading();
                    setProfileIds([]);
                    toast.success(t("success.changed-artists-status"));
                } catch (e) {
                    console.error(e);
                    loadingStore.stopLoading();
                    toast.error(t("error.changing-artists-status"));
                    setShowStatus(true);
                }
            }
        };

        const updateComments = async ({ comments }: { comments: string }) => {
            if (selectedArtist && props.profile._id) {
                try {
                    loadingStore.triggerLoading();
                    setShowComments(false);
                    const castingProfile = await updateArtistComments(selectedArtist, props.profile._id, comments);
                    castingStore.updateCastingProfile(castingProfile);
                    loadingStore.stopLoading();
                    setProfileIds([]);
                    toast.success(t("success.changed-artists-comments"));
                } catch (e) {
                    console.error(e);
                    loadingStore.stopLoading();
                    toast.error(t("error.changing-artists-comments"));
                    setShowComments(true);
                }
            }
        };

        const downloadMultipleFiles = (types: Array<"photos" | "composite" | "presentation" | "restrictions">) => {
            loadingStore.triggerLoading();
            downloadZip({
                ids: temporaryProfiles,
                types,
                language: t("languageCode"),
                profileId: props.profile._id
            })
                .then(blob => {
                    loadingStore.stopLoading();
                    setShowPopup(false);
                    const isPt = t("languageCode") === "pt";
                    return download(blob, isPt ? "arquivos.zip" : "files.zip");
                })
                .catch(response => {
                    if (response.status == 423) {
                        loadingStore.stopLoading();
                        toast.success(t("success.sent-by-email"));
                    } else {
                        loadingStore.stopLoading();
                        setShowPopup(true);
                        toast.error(t("error.downloading"));
                    }
                });
        };

        const onReadProfilesByText = ({ profiles: profilesString }: { profiles: string }) => {
            const filteredProfiles = profilesString.split("\n");
            const successProfiles = [];
            try {
                filteredProfiles.forEach(name => {
                    const profileToBeSelected = profiles.find(
                        p =>
                            removeAccents(p.artisticName).trim().toLowerCase() ===
                            removeAccents(name).trim().toLowerCase()
                    );
                    if (profileToBeSelected && !profileIds.some(id => profileToBeSelected?._id === id)) {
                        setProfileIds(prev => [...prev, profileToBeSelected?._id as string]);
                        successProfiles.push(profileToBeSelected);
                    }
                });
            } catch (e) {
                toast.error(
                    t(
                        "casting.profile.multiple-selection-error",
                        "Erro ao processar lista de nomes para múltipla seleção"
                    )
                );
                setShowPopupMultipleSelection(false);
            }

            if (successProfiles.length > 0) {
                toast.success(
                    t(
                        "casting.profile.multiple-selection-success",
                        `Lista de nomes para múltipla seleção processada com sucesso. ${successProfiles.length} artistas selecionados!`
                    )
                );
                setShowPopupMultipleSelection(false);
            } else {
                toast.warning(
                    t(
                        "casting.profile.multiple-selection-no-selection",
                        "Lista processada com zero artistas correspondentes"
                    )
                );
                setShowPopupMultipleSelection(false);
            }
        };

        const downloadOptions = ["composite", "photos", "presentation", "restrictions"];

        return (
            <div>
                {showPopup && (
                    <Popup
                        title={t("search.download-material")}
                        onClose={() => setShowPopup(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik enableReinitialize initialValues={{ types: [] }} onSubmit={() => {}}>
                            {({ values, handleChange }) => (
                                <>
                                    <Form.Group>
                                        <Form.Label>{t("search.select-download-material")}</Form.Label>
                                        {downloadOptions.map(id => (
                                            <Form.Checkbox
                                                key={`option_${id}`}
                                                label={t("search.download-" + id)}
                                                name="types"
                                                value={id}
                                                onChange={handleChange}
                                                checked={values.types.findIndex(p => p === id) > -1}
                                            />
                                        ))}
                                    </Form.Group>
                                    <Button
                                        color="primary"
                                        disabled={values.types.length === 0}
                                        onClick={() => downloadMultipleFiles(values.types ?? [])}
                                    >
                                        {t("search.download-material")}
                                    </Button>
                                </>
                            )}
                        </Formik>
                    </Popup>
                )}
                {showStatus && (
                    <Popup
                        title={t("casting.profile.change-status")}
                        onClose={() => setShowStatus(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>
                            <Form.Label>{t("casting.profile.artist-status")}</Form.Label>
                            <Form.Select
                                value={status}
                                onChange={(e: any) => {
                                    if (e.target.value !== "") {
                                        setStatus(Number(e.target.value));
                                    }
                                }}
                            >
                                <option value="">{t("select")}</option>
                                <option value={CastingProfileStatus.DISREGARDED}>
                                    {t("casting.profile-status.disregarded")}
                                </option>
                                <option value={CastingProfileStatus.PRE_SELECTED}>
                                    {t("casting.profile-status.pre-selected")}
                                </option>
                                <option value={CastingProfileStatus.CONSULTED}>
                                    {t("casting.profile-status.consulted")}
                                </option>
                                <option value={CastingProfileStatus.SENT}>{t("casting.profile-status.sent")}</option>
                                <option value={CastingProfileStatus.SELECTED}>
                                    {t("casting.profile-status.selected")}
                                </option>
                                <option value={CastingProfileStatus.EDITED}>
                                    {t("casting.profile-status.edited")}
                                </option>
                                <option value={CastingProfileStatus.BACKUP}>
                                    {t("casting.profile-status.backup")}
                                </option>
                                <option value={CastingProfileStatus.APPROVED}>
                                    {t("casting.profile-status.approved")}
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={() => {
                                setShowStatus(false);
                                changeStatus();
                            }}
                        >
                            {t("edit")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setShowStatus(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("cancel")}
                        </Button>
                    </Popup>
                )}
                {showDelete && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setShowDelete(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.delete_artists")}</Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={() => {
                                setShowDelete(false);
                                removeArtists();
                            }}
                        >
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                {showComments && (
                    <Popup
                        title={t("artist.comments")}
                        onClose={() => setShowComments(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                comments: props.profile.artists?.find(a => a.profile === selectedArtist)?.comments ?? ""
                            }}
                            enableReinitialize
                            onSubmit={updateComments}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>{t("artist.comments")}</Form.Label>
                                        <Form.Textarea
                                            rows={5}
                                            name="comments"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.comments}
                                        />
                                    </Form.Group>
                                    <Button type="submit" color="success" icon="check">
                                        {t("save")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        icon="x"
                                        onClick={() => {
                                            setShowComments(false);
                                            setSelectedArtist(undefined);
                                        }}
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        {t("cancel")}
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openSearch && (
                    <Popup
                        title={t("casting.profile.add-artists")}
                        onClose={() => {
                            setOpenSearch(false);
                        }}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <AdvancedSearch
                            onSearch={results => {
                                setResults(results);
                                setProfileIdsSearch([]);
                            }}
                            onError={() => setResults([])}
                        />
                        <div className="search-grid-popup">
                            {results
                                .filter(profile => !props.profile.artists?.some(a => a.profile === profile._id))
                                .map((profile, index) => {
                                    const picture = profile?.photo
                                        ? config.host + encodeURI(profile?.photo)
                                        : undefined;
                                    return renderArtist(picture, profile, profileIdsSearch, setProfileIdsSearch, false);
                                })}
                        </div>
                        <Button
                            color="primary"
                            disabled={profileIdsSearch.length === 0}
                            onClick={async () => {
                                setOpenSearch(false);
                                await addArtistsToProfile(profileIdsSearch);
                            }}
                        >
                            {t("casting.profile.add-artists")}
                        </Button>
                    </Popup>
                )}
                {showPopupMultipleSelection && (
                    <Popup
                        title={t("casting.profile.multiple-selection", "Múltipla seleção")}
                        onClose={() => {
                            setShowPopupMultipleSelection(false);
                        }}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            enableReinitialize
                            initialValues={{ profiles: "" }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.profiles) {
                                    errors.profiles = t("form.errors.required");
                                }
                                return errors;
                            }}
                            onSubmit={onReadProfilesByText}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t("casting.profile.multiple-selection-text", "Seleção de nomes:")}
                                        </Form.Label>
                                        <Form.Textarea
                                            rows={6}
                                            name="profiles"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.profiles}
                                            error={errors && (errors.profiles as string)}
                                        />
                                    </Form.Group>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" icon="save">
                                            {t("proceed")}
                                        </Button>
                                        <Button
                                            color="danger"
                                            icon="x"
                                            onClick={() => {
                                                resetForm();
                                                setShowPopupMultipleSelection(false);
                                            }}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <div>
                    <Button color="primary" onClick={() => setOpenSearch(true)}>
                        {t("casting.profile.add-artists")}
                    </Button>
                    <Button color="primary" onClick={() => setShowDelete(true)} disabled={profileIds.length === 0}>
                        {t("casting.profile.remove-artists")}
                    </Button>
                    <Button
                        color="primary"
                        disabled={profiles.length === 0}
                        onClick={() => {
                            select ? setProfileIds([]) : setProfileIds(profiles.map(p => p._id!));
                            setSelect(prev => !prev);
                        }}
                    >
                        {select ? t("search.deselect-all") : t("search.select-all")}
                    </Button>
                    <Button color="primary" onClick={() => setShowStatus(true)} disabled={profileIds.length === 0}>
                        {t("casting.profile.change-status")}
                    </Button>
                    <Button
                        color="primary"
                        disabled={profileIds.length === 0}
                        onClick={() => {
                            setTemporaryProfiles(profileIds);
                            setShowPopup(true);
                        }}
                    >
                        {t("search.download-material")}
                    </Button>
                    <Button
                        color="primary"
                        disabled={profileIds.length === 0}
                        onClick={() => {
                            const links = profiles
                                .filter(r => profileIds.includes(r._id ?? ""))
                                .map(
                                    profile =>
                                        `${
                                            profile.artisticName
                                        }: https://gaia.kozmos.com.br/profiles/${formatArtistName(
                                            profile.artisticName
                                        )}`
                                );
                            copy(links.join("\n"))
                                .then(() => toast.success(t("success.copied")))
                                .catch(() => toast.error(t("error.copying")));
                        }}
                    >
                        {t("search.copy-public-link")}
                    </Button>
                    <Button type="submit" color="primary" onClick={exportCsv} disabled={profileIds.length === 0}>
                        Exportar CSV
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setShowPopupMultipleSelection(true);
                        }}
                    >
                        {t("casting.profile.multiple-selection", "Múltipla Seleção")}
                    </Button>
                </div>
                <div className="search-grid">
                    {profiles
                        .sort((p1, p2) => castingSort(p1, p2, props.profile.artists ?? []))
                        .map((profile, index) => {
                            const picture = profile?.photo ? config.host + encodeURI(profile?.photo) : undefined;
                            return renderArtist(
                                picture,
                                profile,
                                profileIds,
                                setProfileIds,
                                true,
                                <div className="artist-comments">
                                    <FontAwesomeIcon
                                        icon={faComments}
                                        onClick={() => {
                                            setSelectedArtist(profile._id);
                                            setShowComments(true);
                                        }}
                                        title={t("artist.comments")}
                                        style={{ cursor: "pointer" }}
                                        color={
                                            !!props.profile.artists?.find(a => a.profile === profile._id)?.comments
                                                ? "#fa4654"
                                                : undefined
                                        }
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    })
);

function castingSort(p1: Profile, p2: Profile, artists: CastingProfileArtist[]): number {
    const statusP1 = artists?.find(a => a.profile === p1._id)?.status ?? CastingProfileStatus.PRE_SELECTED;
    const statusP2 = artists?.find(a => a.profile === p2._id)?.status ?? CastingProfileStatus.PRE_SELECTED;

    if (statusP1 === statusP2) {
        return removeAccents(p1.artisticName).localeCompare(removeAccents(p2.artisticName));
    }
    return getWeight(statusP1) > getWeight(statusP2) ? 1 : -1;
}

function getWeight(status: CastingProfileStatus) {
    switch (status) {
        case CastingProfileStatus.APPROVED:
            return 1;
        case CastingProfileStatus.BACKUP:
            return 2;
        case CastingProfileStatus.EDITED:
            return 3;
        case CastingProfileStatus.SELECTED:
            return 4;
        case CastingProfileStatus.SENT:
            return 5;
        case CastingProfileStatus.CONSULTED:
            return 6;
        case CastingProfileStatus.PRE_SELECTED:
            return 7;
        case CastingProfileStatus.DISREGARDED:
            return 8;
    }
}

function translateStatus(status: CastingProfileStatus): string {
    switch (status) {
        case CastingProfileStatus.APPROVED:
            return "approved";
        case CastingProfileStatus.CONSULTED:
            return "consulted";
        case CastingProfileStatus.BACKUP:
            return "backup";
        case CastingProfileStatus.EDITED:
            return "edited";
        case CastingProfileStatus.SELECTED:
            return "selected";
        case CastingProfileStatus.SENT:
            return "sent";
        case CastingProfileStatus.PRE_SELECTED:
            return "pre-selected";
        case CastingProfileStatus.DISREGARDED:
            return "disregarded";
    }
}
