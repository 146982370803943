import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { ReactElement } from "react";
import { Site, Header as TablerHeader } from "tabler-react";
import { useTranslation } from "react-i18next";

export const PublicHeader = inject("rootStore")(
    observer((props: Stores): ReactElement | null => {
        const { t } = useTranslation();
        const {
            routingStore: { push }
        } = props.rootStore!;

        return (
            <Site.Header align="left">
                <div
                    className="logo"
                    onClick={() => {
                        window.open("https://gaia.kozmos.com.br", "_self");
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <Site.Logo src="https://gaia.kozmos.com.br/assets/logo-gaia.png" alt="Gaia" />
                    <TablerHeader.H3>{t("title")}</TablerHeader.H3>
                </div>
            </Site.Header>
        );
    })
);
