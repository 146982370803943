import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useEffect, useState } from "react";
import {
    getCastingAttributes,
    getCastingTypes,
    newCastingAttribute,
    updateCastingAttribute
} from "../../services/casting.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CastingAttribute, CastingAttributeType } from "../../models";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";
import { Formik } from "formik";
import { Button, Form, Icon, Table } from "tabler-react";

interface CastingAttributeManagementProps extends Stores {
    filter: "attribute" | "status";
}

export const CastingAttributeManagement = inject("rootStore")(
    observer((props: CastingAttributeManagementProps) => {
        const { loadingStore, castingAttributeStore, castingTypesStore } = props.rootStore!;
        const { t } = useTranslation();
        const [openNew, setOpenNew] = useState(false);
        const [editing, setEditing] = useState(false);
        const [focusedAttribute, setFocusedAttribute] = useState<undefined | CastingAttribute>(undefined);
        const [sort, setSort] = useState("value");
        const [sortType, setSortType] = useState<"asc" | "desc">("asc");
        const [sortValue, setSortValue] = useState<"asc" | "desc">("asc");
        const [filterType, setFilterType] = useState("");

        const onSubmit = async (values: CastingAttribute) => {
            loadingStore.triggerLoading();
            setOpenNew(false);
            if (editing && focusedAttribute) {
                updateCastingAttribute(focusedAttribute._id!, values)
                    .then(attribute => {
                        setEditing(false);
                        setFocusedAttribute(undefined);
                        loadingStore.stopLoading();
                        castingAttributeStore.updateAttribute(attribute);
                        toast.success(t("success.updated"));
                    })
                    .catch(() => {
                        setOpenNew(true);
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            } else {
                newCastingAttribute(values)
                    .then(attribute => {
                        loadingStore.stopLoading();
                        castingAttributeStore.addAttribute(attribute);
                        toast.success(t("success.created-attribute"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.creating-attribute"));
                    });
            }
        };

        useEffect(() => {
            if (castingAttributeStore.attributes.length === 0 || castingTypesStore.types.length === 0) {
                loadingStore.triggerLoading();
                Promise.all([getCastingTypes(), getCastingAttributes()])
                    .then(([types, attributes]) => {
                        castingTypesStore.setTypes(types);
                        castingAttributeStore.setAttributes(attributes);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        return (
            <>
                {openNew && (
                    <Popup
                        title={editing ? t("casting.attributes.edit") : t("casting.attributes.new")}
                        onClose={() => {
                            setOpenNew(false);
                            setEditing(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                type: (focusedAttribute?.type as CastingAttributeType)?._id ?? "",
                                value: focusedAttribute?.value ?? ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.type) {
                                    errors.type = t("form.errors.required");
                                }
                                if (!values.value) {
                                    errors.value = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.attributes.type.label")}</Form.Label>
                                            <Form.Select
                                                disabled={editing}
                                                name="type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && (values.type as string)}
                                                error={errors && errors.type}
                                            >
                                                <option value="">{t("select")}</option>
                                                {castingTypesStore.types
                                                    .slice()
                                                    .sort((a, b) => {
                                                        const descA =
                                                            a[
                                                                `name${t(
                                                                    "languageCode"
                                                                ).toUpperCase()}` as keyof CastingAttributeType
                                                            ];
                                                        const descB =
                                                            b[
                                                                `name${t(
                                                                    "languageCode"
                                                                ).toUpperCase()}` as keyof CastingAttributeType
                                                            ];
                                                        if (!descA || !descB) {
                                                            return 0;
                                                        }
                                                        return descA.localeCompare(descB);
                                                    })
                                                    .filter(t => t.type === props.filter)
                                                    .map(type => (
                                                        <option value={type._id} key={`type_${type._id}`}>
                                                            {
                                                                type[
                                                                    `name${t(
                                                                        "languageCode"
                                                                    ).toUpperCase()}` as keyof CastingAttributeType
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.attributes.value")}</Form.Label>
                                            <Form.Input
                                                name="value"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.value}
                                                error={errors && errors.value}
                                            />
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Button
                    color="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                        setFocusedAttribute(undefined);
                        setEditing(false);
                        setOpenNew(true);
                    }}
                >
                    {t("casting.attributes.new")}
                </Button>
                <Table className="table-striped">
                    <Table.Header>
                        <Table.Row className="centered-row">
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("type");
                                    setSortType(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        castingAttributeStore.sortByType(newSort, t("languageCode"));
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div className="sortable-column-with-filter">
                                        {t("casting.attributes.type.label")}
                                        <Form.Select
                                            value={filterType}
                                            onChange={(e: any) => setFilterType(e.target.value)}
                                        >
                                            <option value="">{t("select")}</option>
                                            {castingTypesStore.types
                                                .slice()
                                                .sort((a, b) => {
                                                    const descA =
                                                        a[
                                                            `name${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof CastingAttributeType
                                                        ];
                                                    const descB =
                                                        b[
                                                            `name${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof CastingAttributeType
                                                        ];
                                                    if (!descA || !descB) {
                                                        return 0;
                                                    }
                                                    return descA.localeCompare(descB);
                                                })
                                                .filter(t => t.type === props.filter)
                                                .map(type => (
                                                    <option key={type._id} value={type._id}>
                                                        {
                                                            type[
                                                                `name${t(
                                                                    "languageCode"
                                                                ).toUpperCase()}` as keyof CastingAttributeType
                                                            ]
                                                        }
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </div>
                                    {sort === "type" && sortType === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "type" && sortType === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("value");
                                    setSortValue(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        castingAttributeStore.sortByValue(newSort);
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("casting.attributes.value")}</div>
                                    {sort === "value" && sortValue === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "value" && sortValue === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {castingAttributeStore.attributes
                            .filter(attr => (attr.type as CastingAttributeType)?.type === props.filter)
                            .filter(attr =>
                                filterType ? (attr.type as CastingAttributeType)._id === filterType : true
                            )
                            .map((attribute, i) => (
                                <Table.Row
                                    key={`attribute_${i}`}
                                    className="clickable-row"
                                    onClick={() => {
                                        setFocusedAttribute(attribute);
                                        setEditing(true);
                                        setOpenNew(true);
                                    }}
                                >
                                    <Table.Col>
                                        {
                                            (attribute.type as CastingAttributeType)[
                                                `name${t("languageCode").toUpperCase()}` as keyof CastingAttributeType
                                            ]
                                        }
                                    </Table.Col>
                                    <Table.Col>{attribute.value}</Table.Col>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </>
        );
    })
);
