import React, { CSSProperties, ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { Button, Card } from "tabler-react";
import { createPortal } from "react-dom";

interface Props {
    title?: string;
    onClose?: () => void;
    children: ReactNode;
    open?: boolean;
    style?: CSSProperties;
    bodyStyle?: CSSProperties;
    showHeader?: boolean;
    hidden?: boolean;
}

export function Popup({
    title,
    onClose,
    children,
    open = true,
    style,
    showHeader = true,
    bodyStyle
}: Props): ReactElement | null {
    const [close, setClose] = useState<boolean>(!open);

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        document.body.style.top = `-${window.scrollY}px`;
        return () => {
            document.body.style.overflowY = "";
            document.body.style.top = "";
        };
    }, []);

    const onCloseAction = useCallback(() => {
        onClose && onClose();
        document.body.style.overflowY = "";
        document.body.style.top = "";
    }, [onClose]);

    if (close) {
        return null;
    }
    return createPortal(
        <div className="loading" onClick={!showHeader ? onCloseAction : undefined}>
            <Card style={style}>
                {showHeader && (
                    <Card.Header>
                        <Card.Title>{title}</Card.Title>
                        <Card.Options>
                            <Button
                                icon="x"
                                onClick={() => {
                                    setClose(true);
                                    onCloseAction();
                                }}
                            />
                        </Card.Options>
                    </Card.Header>
                )}
                <Card.Body style={{ maxHeight: "70vh", overflowX: "auto", ...bodyStyle }}>{children}</Card.Body>
            </Card>
        </div>,
        document.body
    );
}
