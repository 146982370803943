import { action, makeObservable, observable } from "mobx";
import { Skill } from "../models";

export class SkillsStore {
    @observable.deep skills: Skill[] = [] as Skill[];

    constructor() {
        makeObservable(this);
    }

    @action setSkills(skills: Skill[]) {
        this.skills = skills;
    }

    @action updateSkill(skill: Skill) {
        const index = this.skills.findIndex(c => c._id === skill._id);
        if (index > -1) {
            this.skills[index] = skill;
        }
    }

    @action getSkillById(id: string): Skill | undefined {
        return this.skills.find(c => c._id === id);
    }

    @action getSkillByType(type: string): Skill[] {
        return this.skills.filter(c => c.type === type);
    }

    @action addSkill(skill: Skill) {
        this.skills.push(skill);
    }

    @action sortByType(sortMethod: "asc" | "desc", types: Array<{ value: string; label: string }>, skills?: Skill[]) {
        const unsortedSkills = skills ? skills : [...this.skills];
        const sortedTypes = [...types].sort((a, b) => {
            if (!a.label || !b.label) {
                return 0;
            }
            if (a.label === b.label) {
                return 0;
            }
            if (sortMethod === "asc") {
                return a.label.localeCompare(b.label);
            }
            return b.label.localeCompare(a.label);
        });
        console.warn(sortedTypes);

        unsortedSkills.sort((p1, p2) => {
            if (!p1.type || !p2.type) {
                return 0;
            }
            if (p1.type === p2.type) {
                return 0;
            }
            const p1Weight = sortedTypes.findIndex(type => p1.type === type.value);
            const p2Weight = sortedTypes.findIndex(type => p2.type === type.value);
            return p1Weight > p2Weight ? -1 : 1;
        });

        if (!skills) {
            this.setSkills(unsortedSkills);
        }
    }

    @action sortByDescription(sortMethod: "asc" | "desc", language: "pt" | "en", skills?: Skill[]) {
        const unsortedSkills = skills ? skills : [...this.skills];

        unsortedSkills.sort((p1, p2) => {
            const key = `description${language.toUpperCase()}` as keyof Skill;
            if (!(key in p1) || !(key in p2)) {
                return 0;
            }
            if (p1[key] === p2[key]) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1[key]!.localeCompare(p2[key]!);
            }
            return p2[key]!.localeCompare(p1[key]!);
        });

        if (!skills) {
            this.setSkills(unsortedSkills);
        }
    }
}
