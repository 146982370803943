import { Avatar, Button, Dropdown, Form, Icon, Table } from "tabler-react";
import React, { useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import {
    activateProfile,
    deactivateProfile,
    getProfiles,
    inviteProfile,
    requestChanges,
    resendInvite,
    suspendProfile,
    uploadComposite
} from "../services/profile.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { config } from "../utils/constants";
import {
    checkDateComposite,
    getNamePlaceholder,
    validateFiscalCode,
    hiphenizeName,
    cuilValidator
} from "../services/util.service";
import { Popup } from "./Popup";
import { Formik, FormikHelpers } from "formik";
import { Class, InviteProfileRequest, Profile, StatusEnum } from "../models";
import { formatArtistName, formatContractData, formatDate, formatFiscalCode } from "../utils/formats";
import copy from "clipboard-copy";
import classNames from "classnames";
import { isMobile } from "../utils/utils";
import { InfiniteBody } from "./utils/InfiniteBody";
import { CompositeGenerator } from "./composite/CompositeGenerator";
import download from "downloadjs";
import { getProtectedFile } from "../services/files.service";
import { getClasses } from "../services/classes.service";
import { stamps } from "./profile-sections/PersonalInfo";

const limit = 30;

export const Cast = inject("rootStore")(
    observer((props: Stores) => {
        const [openNew, setOpenNew] = useState(false);
        const [openDeactivate, setOpenDeactivate] = useState(false);
        const [openSuspend, setOpenSuspend] = useState(false);
        const [openChanges, setOpenChanges] = useState(false);
        const [openUpload, setOpenUpload] = useState(false);
        const [openResend, setOpenResend] = useState(false);
        const [openCreateComposite, setOpenCreateComposite] = useState(false);
        const [file, setFile] = useState<File | undefined>(undefined);
        const [focusedProfile, setFocusedProfile] = useState<Profile | undefined>(undefined);
        const [reason, setReason] = useState("");
        const [reasonSuspend, setReasonSuspend] = useState("");
        const [filterName, setFilterName] = useState("");
        const [filterComposite, setFilterComposite] = useState<string>("");
        const [filterValidation, setFilterValidation] = useState<string>("");
        const [filterStatus, setFilterStatus] = useState<number>(-1);
        const [emailResend, setEmailResend] = useState("");
        const [sortUpdate, setSortUpdate] = useState<"asc" | "desc">("desc");
        const [sortName, setSortName] = useState<"asc" | "desc">("desc");
        const [sort, setSort] = useState("date");
        const [page, setPage] = useState(1);
        const [forceUpdate, setForceUpdate] = useState(false);
        const [mask, setMask] = useState<Array<RegExp | string>>([
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ]);

        const [openAlertComposite, setOpenAlertComposite] = useState(false);

        const { profileStore, loadingStore, classesStore } = props.rootStore!;
        const { t } = useTranslation();

        const colors = [
            { label: t("profile.skin.color1"), value: "color1" },
            { label: t("profile.skin.color2"), value: "color2" },
            { label: t("profile.skin.color3"), value: "color3" },
            { label: t("profile.skin.color4"), value: "color4" },
            { label: t("profile.skin.color5"), value: "color5" },
            { label: t("profile.skin.color6"), value: "color6" }
        ];
        const ethnicities = [
            { label: t("profile.ethnicity.african"), value: "african" },
            { label: t("profile.ethnicity.brazilian"), value: "brazilian" },
            { label: t("profile.ethnicity.european"), value: "european" },
            { label: t("profile.ethnicity.asian"), value: "asian" },
            { label: t("profile.ethnicity.arabic"), value: "arabic" },
            { label: t("profile.ethnicity.native"), value: "native" },
            { label: t("profile.ethnicity.latin"), value: "latin" }
        ];

        const checkFiscalCode = (fiscalCode: string) => {
            if (cuilValidator(fiscalCode)) {
                setMask([/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/]);
            } else if (validateFiscalCode(fiscalCode)) {
                setMask([/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]);
            } else {
                setMask([/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]);
            }
        };

        useEffect(() => {
            if (profileStore.profiles.length === 0) {
                loadingStore.triggerLoading();
                getProfiles()
                    .then(profiles => {
                        profileStore.setProfiles(profiles);
                        profileStore.sortByUpdate("desc");
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            }
        }, []);

        useEffect(() => {
            if (classesStore.classes.length === 0) {
                loadingStore.triggerLoading();
                getClasses()
                    .then(classes => {
                        classesStore.setClasses(classes);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                    });
            }
        }, [classesStore.classes]);

        const totalProfiles = useMemo(() => {
            if (filterName || filterComposite || filterValidation || filterStatus > -1)
                return profileStore.filter(filterName, filterStatus, filterComposite, filterValidation).length;
            return profileStore.profiles.length;
        }, [filterName, filterComposite, filterValidation, filterStatus, profileStore.profiles, profileStore.filter]);

        const profiles = useMemo(() => {
            if (filterName || filterComposite || filterValidation || filterStatus > -1)
                return profileStore.filter(filterName, filterStatus, filterComposite, filterValidation, limit * page);
            return profileStore.profiles.slice(0, limit * page);
        }, [
            filterName,
            filterComposite,
            filterValidation,
            filterStatus,
            profileStore.profiles,
            profileStore.filter,
            page,
            forceUpdate
        ]);

        const onSubmit = async (values: InviteProfileRequest, { resetForm }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            setOpenNew(false);
            const request: InviteProfileRequest = {
                ...values,
                artisticName: values.artisticName.trim(),
                isExclusive: values.isExclusive === "yes",
                fiscalCode: formatFiscalCode(values.fiscalCode)
            };
            inviteProfile(request)
                .then(result => {
                    loadingStore.stopLoading();
                    if (result._id) {
                        toast.success(t("success.invited"));
                        profileStore.addProfile(result);
                        resetForm();
                    } else {
                        setOpenNew(true);
                        toast.error(t("error.inviting"));
                    }
                })
                .catch((response: Response) => {
                    loadingStore.stopLoading();
                    setOpenNew(true);

                    switch (response.status) {
                        case 400:
                            toast.error(t("error.inviting"));
                            break;
                        case 403:
                            if (response.statusText.includes("DUPLICATED_EMAIL")) {
                                toast.error(t("error.inviting-duplicated-email"));
                            } else if (response.statusText.includes("DUPLICATED_FISCAL_CODE")) {
                                toast.error(t("error.inviting-duplicated-fc"));
                            }
                            break;
                        case 404:
                            toast.error(t("error.inviting"));
                            break;
                    }
                });
        };

        const onActivate = (profile: Profile) => {
            if (profile) {
                loadingStore.triggerLoading();
                activateProfile(profile)
                    .then(profile => {
                        loadingStore.stopLoading();
                        toast.success(t("success.updated"));
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            }
        };
        const onDeactivate = () => {
            if (focusedProfile && reason) {
                loadingStore.triggerLoading();
                setOpenDeactivate(false);
                deactivateProfile(focusedProfile, reason)
                    .then(profile => {
                        setReason("");
                        loadingStore.stopLoading();
                        toast.success(t("success.updated"));
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setOpenDeactivate(true);
                        toast.error(t("error.updating"));
                    });
            }
        };

        const onSuspend = () => {
            if (focusedProfile && reasonSuspend) {
                loadingStore.triggerLoading();
                setOpenSuspend(false);
                suspendProfile(focusedProfile, reasonSuspend)
                    .then(profile => {
                        setReason("");
                        loadingStore.stopLoading();
                        toast.success(t("success.updated"));
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setOpenSuspend(true);
                        toast.error(t("error.updating"));
                    });
            }
        };

        const onRequestChanges = () => {
            if (focusedProfile && reason) {
                loadingStore.triggerLoading();
                setOpenChanges(false);
                requestChanges(focusedProfile, reason)
                    .then(profile => {
                        setReason("");
                        loadingStore.stopLoading();
                        toast.success(t("success.requested"));
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setOpenChanges(true);
                        toast.error(t("error.requesting"));
                    });
            }
        };

        const onResendInvite = () => {
            if (focusedProfile && emailResend) {
                loadingStore.triggerLoading();
                setOpenResend(false);
                resendInvite(focusedProfile, emailResend)
                    .then(profile => {
                        setFocusedProfile(undefined);
                        loadingStore.stopLoading();
                        toast.success(t("success.invite-resent"));
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setOpenResend(true);
                        toast.error(t("error.resending-invite"));
                    });
            }
        };

        const onFinishComposite = (profile: Profile) => {
            profileStore.updateProfile(profile);
            setOpenCreateComposite(false);
            setFocusedProfile(undefined);
            setForceUpdate(prev => !prev);
        };

        const onUpload = () => {
            if (file && focusedProfile) {
                loadingStore.triggerLoading();
                setOpenUpload(false);
                uploadComposite(focusedProfile, file)
                    .then(profile => {
                        setFile(undefined);
                        loadingStore.stopLoading();
                        toast.success(t("success.updated"));
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setOpenUpload(true);
                        toast.error(t("error.updating"));
                    });
            }
        };
        return (
            <>
                <CompositeGenerator
                    visible={openCreateComposite}
                    onCancel={() => setOpenCreateComposite(false)}
                    onSuccess={onFinishComposite}
                    profile={focusedProfile}
                />
                {openAlertComposite && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setOpenAlertComposite(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.override-composite")}</Form.Group>
                        <Form.Footer>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setOpenAlertComposite(false);
                                    setOpenCreateComposite(true);
                                }}
                            >
                                {t("proceed")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                {openNew && (
                    <Popup
                        title={t("profile.new")}
                        onClose={() => setOpenNew(false)}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                artisticName: "",
                                fiscalCode: "",
                                email: "",
                                isExclusive: "no",
                                classes: [],
                                stamp: "open",
                                skin: "",
                                ethnicity: ""
                            }}
                            validate={values => {
                                let errors: InviteProfileRequest = {} as InviteProfileRequest;
                                if (values.fiscalCode) {
                                    checkFiscalCode(values.fiscalCode);
                                }
                                if (!values.artisticName) {
                                    errors.artisticName = t("form.errors.required");
                                }
                                if (!values.email) {
                                    errors.email = t("form.errors.required");
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                    errors.email = t("form.errors.invalid");
                                }
                                if (!values.fiscalCode) {
                                    errors.fiscalCode = t("form.errors.required");
                                } else if (
                                    !validateFiscalCode(values.fiscalCode) &&
                                    !cuilValidator(values.fiscalCode)
                                ) {
                                    errors.fiscalCode = t("form.errors.invalid");
                                }
                                if (!values.stamp) {
                                    errors.artisticName = t("form.errors.required");
                                }
                                if (values.classes.length === 0) {
                                    errors.classes = t("form.errors.required");
                                }
                                if (!values.skin) {
                                    errors.skin = t("form.errors.required");
                                }
                                if (!values.ethnicity) {
                                    errors.ethnicity = t("form.errors.required");
                                }
                                return errors;
                            }}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("profile.artistic_name")}</Form.Label>
                                            <Form.Input
                                                name="artisticName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.artisticName}
                                                error={errors && errors.artisticName}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("profile.email")}</Form.Label>
                                            <Form.Input
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.email}
                                                error={errors && errors.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("profile.fiscal_code")}</Form.Label>
                                            <Form.MaskedInput
                                                {...({
                                                    placeholder: "00000000000",
                                                    mask
                                                } as any)}
                                                name="fiscalCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.fiscalCode}
                                                invalid={errors.fiscalCode}
                                            />
                                            {errors.fiscalCode && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.fiscalCode}
                                                </span>
                                            )}
                                            <Form.Label style={{ marginTop: 10 }}>
                                                {t("profile.isExclusive")}
                                            </Form.Label>
                                            <Form.Radio
                                                label={t("yes")}
                                                name="isExclusive"
                                                value="yes"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.isExclusive === "yes"}
                                            />
                                            <Form.Radio
                                                isInline
                                                label={t("no")}
                                                name="isExclusive"
                                                value="no"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.isExclusive === "no"}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            <Form.Label>{t("profile.stamp")}</Form.Label>
                                            {stamps.map((stamp, i) => (
                                                <Form.Radio
                                                    key={`stamps_${i}`}
                                                    label={stamp.label}
                                                    name="stamp"
                                                    value={stamp.value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.stamp}
                                                    checked={values.stamp === stamp.value}
                                                    invalid={!!errors.stamp}
                                                />
                                            ))}
                                            {errors.stamp && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.stamp}
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.skin.label")}</Form.Label>
                                                {colors.map(color => (
                                                    <Form.Radio
                                                        key={`skin_${color.value}`}
                                                        label={color.label}
                                                        name="skin"
                                                        value={color.value}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors.skin}
                                                        checked={values.skin === color.value}
                                                    />
                                                ))}
                                                {errors.skin && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.skin}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.ethnicity.label")}</Form.Label>
                                                {ethnicities.map((v, index) => (
                                                    <Form.Radio
                                                        key={`ethnicity_${index}`}
                                                        label={v.label}
                                                        name="ethnicity"
                                                        value={v.value}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.ethnicity === v.value}
                                                        invalid={!!errors.ethnicity}
                                                    />
                                                ))}
                                                {errors.ethnicity && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.ethnicity}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("profile.class.label")}</Form.Label>
                                            <Form.SelectGroup canSelectMultiple pills>
                                                {classesStore.classes.map(v => (
                                                    <Form.SelectGroupItem
                                                        key={`class_${v.key}`}
                                                        label={
                                                            v[`name${t("languageCode").toUpperCase()}` as keyof Class]
                                                        }
                                                        name="classes"
                                                        value={v.key}
                                                        onChange={handleChange}
                                                        checked={!!values.classes.find(d => d === v.key)}
                                                        className={classNames({
                                                            invalid: !!errors.classes
                                                        })}
                                                    />
                                                ))}
                                            </Form.SelectGroup>
                                            {errors.classes && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.classes}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openDeactivate && (
                    <Popup
                        title={t("cast.menu.deactivate")}
                        onClose={() => setOpenDeactivate(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>
                            <Form.Label>{t("cast.reason")}</Form.Label>
                            <Form.Textarea
                                onChange={(event: any) => {
                                    setReason(event.target.value);
                                }}
                                onBlur={(event: any) => {
                                    setReason(event.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Footer>
                            <Button color="primary" onClick={onDeactivate} disabled={!reason}>
                                {t("proceed")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                {openSuspend && (
                    <Popup
                        title={t("cast.menu.suspend")}
                        onClose={() => setOpenSuspend(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>
                            <Form.Label>{t("cast.reason")}</Form.Label>
                            <Form.Textarea
                                onChange={(event: any) => {
                                    setReasonSuspend(event.target.value);
                                }}
                                onBlur={(event: any) => {
                                    setReasonSuspend(event.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Footer>
                            <Button color="primary" onClick={onSuspend} disabled={!reasonSuspend}>
                                {t("proceed")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                {openChanges && (
                    <Popup
                        title={t("cast.menu.request_change")}
                        onClose={() => setOpenChanges(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>
                            <Form.Label>{t("cast.reason")}</Form.Label>
                            <Form.Textarea
                                onChange={(event: any) => {
                                    setReason(event.target.value);
                                }}
                                onBlur={(event: any) => {
                                    setReason(event.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Footer>
                            <Button color="primary" onClick={onRequestChanges} disabled={!reason}>
                                {t("proceed")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                {openUpload && (
                    <Popup
                        title={t("cast.menu.upload")}
                        onClose={() => setOpenUpload(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <form encType="multipart/form-data" onSubmit={onUpload}>
                            <Form.Group>
                                <Form.Label>{t("select_file")}</Form.Label>
                                <Form.FileInput
                                    label={t("select_file")}
                                    name="picture"
                                    accept="image/*"
                                    onChange={event => {
                                        if (event.target.files && event.target.files.length > 0) {
                                            setFile(event.target.files[0]);
                                        }
                                    }}
                                />
                            </Form.Group>
                            {focusedProfile?.composite && (
                                <Form.Group>
                                    <Form.Label style={{ color: "red" }}>
                                        <strong>{t("cast.override_alert")}</strong>
                                    </Form.Label>
                                </Form.Group>
                            )}
                            <Form.Footer>
                                <Button type="submit" color="primary" disabled={!file}>
                                    {t("save")}
                                </Button>
                            </Form.Footer>
                        </form>
                    </Popup>
                )}
                {openResend && (
                    <Popup
                        title={t("cast.menu.resend-invite")}
                        onClose={() => setOpenResend(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>
                            <Form.Label>{t("profile.email")}</Form.Label>
                            <Form.Input
                                type="email"
                                value={emailResend}
                                onChange={(event: any) => setEmailResend(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Footer>
                            <Button color="primary" onClick={onResendInvite} disabled={!emailResend}>
                                {t("send")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                <Button color="primary" style={{ marginBottom: "10px" }} onClick={() => setOpenNew(true)}>
                    {t("profile.new")}
                </Button>
                <Table>
                    <Table.Header className="infinite-loading-header">
                        <Table.Row className="centered-row">
                            <Table.ColHeader style={{ width: "100px" }}>{t("cast.picture")}</Table.ColHeader>
                            <Table.ColHeader>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortName(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    profileStore.sortByName(newSort);
                                                    return newSort;
                                                });
                                                setSort("name");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("cast.artistic_name")}</div>
                                            {sort === "name" && sortName === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "name" && sortName === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Input
                                            value={filterName}
                                            onChange={(e: any) => setFilterName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "15%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        {t("cast.status")}
                                        <Form.Select
                                            value={filterStatus}
                                            onChange={(e: any) => setFilterStatus(e.target.value)}
                                        >
                                            <option value={-1}>{t("select")}</option>
                                            <option value={0}>{t(`status.0`)}</option>
                                            <option value={1}>{t(`status.1`)}</option>
                                            <option value={2}>{t(`status.2`)}</option>
                                            <option value={3}>{t(`status.3`)}</option>
                                            <option value={4}>{t(`status.4`)}</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("date");
                                    setSortUpdate(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        profileStore.sortByUpdate(newSort);
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ width: "15%", cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("cast.last_update")}</div>
                                    {sort === "date" && sortUpdate === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "date" && sortUpdate === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "15%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        Composite
                                        <Form.Select
                                            value={filterComposite}
                                            onChange={(e: any) => setFilterComposite(e.target.value)}
                                        >
                                            <option value="">{t("select")}</option>
                                            <option value="up-to-date">{t("cast.composite.up-to-date")}</option>
                                            <option value="outdated">{t("cast.composite.outdated")}</option>
                                            <option value="expired">{t("cast.composite.expired")}</option>
                                            <option value="nonexistent">{t("cast.composite.nonexistent")}</option>
                                            <option value="no-date">{t("cast.composite.no-date")}</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "10%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        {t("cast.validation")}
                                        <Form.Select
                                            value={filterValidation}
                                            onChange={(e: any) => setFilterValidation(e.target.value)}
                                        >
                                            <option value="">{t("select")}</option>
                                            <option value="ok">{t("cast.validation.ok")}</option>
                                            <option value="not-ok">{t("cast.validation.not-ok")}</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "70px" }}>{t("cast.actions")}</Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <InfiniteBody hasMoreItems={limit * page < totalProfiles} isInfinite setPage={setPage}>
                        {profiles.map(profile => {
                            const picture = profile?.photo ? config.host + encodeURI(profile?.photo) : undefined;
                            const statusComposite = checkDateComposite(!!profile.composite, profile.compositeDate);
                            return (
                                <Table.Row
                                    key={profile._id}
                                    className="clickable-row"
                                    title={
                                        profile.status !== StatusEnum.SUSPENDED
                                            ? profile.artisticName
                                            : profile.reasonSuspended
                                    }
                                >
                                    <Table.Col
                                        style={{ width: "100px" }}
                                        onClick={() => {
                                            window.open(`/details?id=${profile._id}`);
                                        }}
                                    >
                                        {picture && (
                                            <Avatar className="thumbnail" color="gray-dark" imageURL={picture} />
                                        )}
                                        {!picture && (
                                            <Avatar className="thumbnail" color="gray-dark">
                                                {getNamePlaceholder(profile.artisticName)}
                                            </Avatar>
                                        )}
                                    </Table.Col>
                                    <Table.Col
                                        onClick={() => {
                                            window.open(`/details?id=${profile._id}`);
                                        }}
                                    >
                                        {profile.artisticName}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "15%" }}
                                        onClick={() => {
                                            window.open(`/details?id=${profile._id}`);
                                        }}
                                    >
                                        {t(`status.${profile.status}`)}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "15%" }}
                                        onClick={() => {
                                            window.open(`/details?id=${profile._id}`);
                                        }}
                                    >
                                        {profile.updatedAt ? formatDate(new Date(profile.updatedAt)) : "-"}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "15%" }}
                                        alignContent="center"
                                        onClick={() => {
                                            window.open(`/details?id=${profile._id}`);
                                        }}
                                    >
                                        <div
                                            className={classNames("badge-composite", `badge-${statusComposite}`)}
                                            title={t(`cast.composite.${statusComposite}`)}
                                        />
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "10%" }}
                                        alignContent="center"
                                        onClick={() => {
                                            window.open(`/details?id=${profile._id}`);
                                        }}
                                    >
                                        <span className="validation">
                                            {profile.approval && Object.keys(profile.approval).length > 0 ? "!" : ""}
                                        </span>
                                    </Table.Col>
                                    <Table.Col style={{ width: "70px", cursor: "auto" }}>
                                        <Dropdown
                                            className="custom-dropdown"
                                            color="primary"
                                            isNavLink
                                            triggerClassName="pr-0 leading-none"
                                            triggerContent={<Icon name="more-vertical" />}
                                            position="bottom-end"
                                            arrow={true}
                                            arrowPosition="right"
                                            toggle={false}
                                            itemsObject={[
                                                {
                                                    icon: "mail",
                                                    value: t("cast.menu.resend-invite"),
                                                    className:
                                                        profile.status !== StatusEnum.EDITING || !profile.updatedAt
                                                            ? "not-visible"
                                                            : "",
                                                    onClick: () => {
                                                        setFocusedProfile(profile);
                                                        setEmailResend(profile.email);
                                                        setOpenResend(true);
                                                    }
                                                },
                                                {
                                                    icon: "check-circle",
                                                    value: t("cast.menu.activate"),
                                                    className:
                                                        profile.status !== StatusEnum.VALIDATING &&
                                                        profile.status !== StatusEnum.INACTIVE &&
                                                        profile.status !== StatusEnum.SUSPENDED
                                                            ? "not-visible"
                                                            : "",
                                                    onClick: () => {
                                                        onActivate(profile);
                                                    }
                                                },
                                                {
                                                    icon: "x-circle",
                                                    value: t("cast.menu.deactivate"),
                                                    onClick: () => {
                                                        setFocusedProfile(profile);
                                                        setOpenDeactivate(true);
                                                    }
                                                },
                                                {
                                                    icon: "slash",
                                                    value: t("cast.menu.suspend"),
                                                    className:
                                                        profile.status !== StatusEnum.ACTIVE ? "not-visible" : "",
                                                    onClick: () => {
                                                        setFocusedProfile(profile);
                                                        setOpenSuspend(true);
                                                    }
                                                },
                                                {
                                                    icon: "alert-circle",
                                                    value: t("cast.menu.request_change"),
                                                    className:
                                                        profile.status !== StatusEnum.ACTIVE &&
                                                        profile.status !== StatusEnum.VALIDATING &&
                                                        profile.status !== StatusEnum.EDITING
                                                            ? "not-visible"
                                                            : undefined,
                                                    onClick: () => {
                                                        setFocusedProfile(profile);
                                                        setOpenChanges(true);
                                                    }
                                                },
                                                {
                                                    icon: "star",
                                                    value: t("cast.menu.create-composite"),
                                                    className:
                                                        profile.status !== StatusEnum.ACTIVE
                                                            ? "not-visible"
                                                            : undefined,
                                                    onClick: () => {
                                                        setFocusedProfile(profile);
                                                        if (profile.composite) {
                                                            setOpenAlertComposite(true);
                                                        } else setOpenCreateComposite(true);
                                                    }
                                                },
                                                {
                                                    icon: "paperclip",
                                                    value: t("cast.menu.upload"),
                                                    className:
                                                        profile.status !== StatusEnum.ACTIVE
                                                            ? "not-visible"
                                                            : undefined,
                                                    onClick: () => {
                                                        setFocusedProfile(profile);
                                                        setOpenUpload(true);
                                                    }
                                                },
                                                {
                                                    icon: "eye",
                                                    value: t("cast.menu.view"),
                                                    className: !profile.composite ? "not-visible" : undefined,
                                                    onClick: () =>
                                                        window.open(
                                                            config.host + encodeURI(profile.composite!),
                                                            "_blank"
                                                        )
                                                },
                                                {
                                                    icon: "download",
                                                    value: t("cast.menu.download"),
                                                    className: !profile.composite ? "not-visible" : undefined,
                                                    onClick: () => {
                                                        const fileId = profile.composite?.split("/").pop();
                                                        loadingStore.triggerLoading();
                                                        if (fileId) {
                                                            getProtectedFile(fileId)
                                                                .then(blob => {
                                                                    loadingStore.stopLoading();
                                                                    return download(
                                                                        blob,
                                                                        `${hiphenizeName(
                                                                            profile.artisticName
                                                                        )}_KOZMOS (COMPOSITE).${fileId
                                                                            .split(".")
                                                                            .pop()}`
                                                                    );
                                                                })
                                                                .catch(() => {
                                                                    loadingStore.stopLoading();
                                                                    toast.error(t("error.downloading"));
                                                                });
                                                        } else {
                                                            loadingStore.stopLoading();
                                                            toast.error(t("error.downloading"));
                                                        }
                                                    }
                                                },
                                                {
                                                    icon: "share-2",
                                                    value: t("cast.menu.public_link"),
                                                    className:
                                                        profile.status === StatusEnum.EDITING
                                                            ? "not-visible"
                                                            : undefined,
                                                    onClick: () => {
                                                        copy(
                                                            `${config.host}profiles/${formatArtistName(
                                                                profile.artisticName
                                                            )}`
                                                        )
                                                            .then(() => toast.success(t("success.copied")))
                                                            .catch(() => toast.error(t("error.copying")));
                                                    }
                                                },
                                                {
                                                    icon: "file-text",
                                                    value: t("cast.menu.copy-contract-data"),
                                                    className:
                                                        profile.status !== StatusEnum.ACTIVE &&
                                                        profile.status !== StatusEnum.SUSPENDED
                                                            ? "not-visible"
                                                            : undefined,
                                                    onClick: () => {
                                                        copy(formatContractData(profile, t))
                                                            .then(() => toast.success(t("success.copied")))
                                                            .catch(() => toast.error(t("error.copying")));
                                                    }
                                                }
                                            ]}
                                        />
                                    </Table.Col>
                                </Table.Row>
                            );
                        })}
                    </InfiniteBody>
                </Table>
            </>
        );
    })
);
