import { useTranslation } from "react-i18next";
import { Button, Card, Form, Header, Icon } from "tabler-react";
import React, { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { updatePartialInfo } from "../../services/profile.service";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { SocialNetworks as SocialNetworkType, StatusEnum } from "../../models";

export const SocialNetworks = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const { profileStore, loadingStore, sessionStore } = props.rootStore!;

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
        }, [profileStore.focusedProfile]);

        const onSubmit = async (values: SocialNetworkType) => {
            loadingStore.triggerLoading();

            updatePartialInfo("socialNetworks", {
                facebook: values.facebook,
                instagram: values.instagram,
                instagramFollowers: values.instagramFollowers ? Number(values.instagramFollowers) : undefined,
                tiktok: values.tiktok,
                tiktokFollowers: values.tiktokFollowers ? Number(values.tiktokFollowers) : undefined,
                vimeo: values.vimeo,
                youtube: values.youtube,
                youtubeFollowers: values.youtubeFollowers ? Number(values.youtubeFollowers) : undefined,
                twitter: values.twitter,
                twitterFollowers: values.twitterFollowers ? Number(values.twitterFollowers) : undefined,
                kwai: values.kwai,
                kwaiFollowers: values.kwaiFollowers ? Number(values.kwaiFollowers) : undefined
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    setEditable(false);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };

        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.social_networks")}</Header.H3>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            instagram: profileStore.focusedProfile.socialNetworks?.instagram,
                            instagramFollowers: profileStore.focusedProfile.socialNetworks?.instagramFollowers,
                            tiktok: profileStore.focusedProfile.socialNetworks?.tiktok,
                            tiktokFollowers: profileStore.focusedProfile.socialNetworks?.tiktokFollowers,
                            facebook: profileStore.focusedProfile.socialNetworks?.facebook,
                            youtube: profileStore.focusedProfile.socialNetworks?.youtube,
                            youtubeFollowers: profileStore.focusedProfile.socialNetworks?.youtubeFollowers,
                            vimeo: profileStore.focusedProfile.socialNetworks?.vimeo,
                            twitter: profileStore.focusedProfile.socialNetworks?.twitter,
                            twitterFollowers: profileStore.focusedProfile.socialNetworks?.twitterFollowers,
                            kwai: profileStore.focusedProfile.socialNetworks?.kwai,
                            kwaiFollowers: profileStore.focusedProfile.socialNetworks?.kwaiFollowers
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>Instagram</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>@</Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="instagram"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.instagram}
                                                    error={errors && errors.instagram}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.instagram-followers")}</Form.Label>
                                            <Form.Input
                                                type="number"
                                                disabled={!editable}
                                                name="instagramFollowers"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.instagramFollowers}
                                                error={errors && errors.instagramFollowers}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>TikTok</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>@</Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="tiktok"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.tiktok}
                                                    error={errors && errors.tiktok}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.tiktok-followers")}</Form.Label>
                                            <Form.Input
                                                type="number"
                                                disabled={!editable}
                                                name="tiktokFollowers"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.tiktokFollowers}
                                                error={errors && errors.tiktokFollowers}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>Facebook</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>https://facebook.com/</Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="facebook"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.facebook}
                                                    error={errors && errors.facebook}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>YouTube</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend title="https://youtube.com/user/">
                                                    <Form.InputGroupText>
                                                        <Icon prefix="fe" name="user" />
                                                    </Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="youtube"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.youtube}
                                                    error={errors && errors.youtube}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.youtube-followers")}</Form.Label>
                                            <Form.Input
                                                type="number"
                                                disabled={!editable}
                                                name="youtubeFollowers"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.youtubeFollowers}
                                                error={errors && errors.youtubeFollowers}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>Vimeo</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>https://vimeo.com/</Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="vimeo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.vimeo}
                                                    error={errors && errors.vimeo}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>Twitter</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>@</Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="twitter"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.twitter}
                                                    error={errors && errors.twitter}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.twitter-followers")}</Form.Label>
                                            <Form.Input
                                                type="number"
                                                disabled={!editable}
                                                name="twitterFollowers"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.twitterFollowers}
                                                error={errors && errors.twitterFollowers}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>Kwai</Form.Label>
                                            <Form.InputGroup>
                                                <Form.InputGroupPrepend>
                                                    <Form.InputGroupText>@</Form.InputGroupText>
                                                </Form.InputGroupPrepend>
                                                <Form.Input
                                                    placeholder={t("profile.username_placeholder")}
                                                    disabled={!editable}
                                                    name="kwai"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.kwai}
                                                    error={errors && errors.kwai}
                                                />
                                            </Form.InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.kwai-followers")}</Form.Label>
                                            <Form.Input
                                                type="number"
                                                disabled={!editable}
                                                name="kwaiFollowers"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.kwaiFollowers}
                                                error={errors && errors.kwaiFollowers}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                {sessionStore.isSameAsFocused && canEdit && (
                                    <Form.Footer>
                                        {editable && (
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                        )}
                                        {editable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={() => {
                                                    setEditable(false);
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
