import React, { ReactElement, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Popup } from "./Popup";
import { Button, Form, Icon } from "tabler-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "../utils/utils";

interface Video {
    url: string;
    isPresentation?: boolean;
    isScenes?: boolean;
}

interface GalleryProps {
    label: string;
    isVideo: boolean;
    images?: string[];
    videos?: Video[];
    editable?: boolean;
    deleteLabel?: string;
    onDeleteAction?: (file: string) => void;
    onClick?: (url: string) => void;
    className?: string;
}

export function Gallery(props: GalleryProps): ReactElement {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [focusedVideo, setFocusedVideo] = useState("");
    const [videoHeight, setVideoHeight] = useState(0);
    const [focusedPicture, setFocusedPicture] = useState("");
    const [focusedToDelete, setFocusedToDelete] = useState("");
    const [type, setType] = useState<"photo" | "video">("photo");
    // const [checkedFiles, setCheckedFiles] = useState<string[]>([]);

    return (
        <>
            {showDelete && (
                <Popup
                    title={t("confirmation.label")}
                    onClose={() => setShowDelete(false)}
                    style={{ width: isMobile() ? "80vw" : "50vw" }}
                >
                    <Form.Group>{t("confirmation.delete_file")}</Form.Group>
                    <Button
                        color="success"
                        icon="check"
                        onClick={() => {
                            setShowDelete(false);
                            props.onDeleteAction?.(focusedToDelete);
                        }}
                        disabled={!focusedToDelete}
                    >
                        {t("yes")}
                    </Button>
                    <Button
                        color="primary"
                        icon="x"
                        onClick={() => {
                            setShowDelete(false);
                        }}
                        style={{
                            marginLeft: "10px"
                        }}
                    >
                        {t("no")}
                    </Button>
                </Popup>
            )}
            {showModal && (
                <Popup
                    showHeader={false}
                    onClose={() => setShowModal(false)}
                    style={{ width: "80vw", height: "80vh" }}
                    bodyStyle={{ maxHeight: "100%" }}
                >
                    {type === "photo" && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                        >
                            <img src={focusedPicture} alt="" />
                        </div>
                    )}
                    {type === "video" && (
                        <div
                            style={{ display: "flex", justifyContent: "center", height: "100%" }}
                            ref={ref => {
                                if (ref?.clientHeight) {
                                    setVideoHeight(ref.clientHeight);
                                }
                            }}
                        >
                            <video
                                width="100%"
                                height={videoHeight ?? "100%"}
                                controls={true}
                                autoPlay={true}
                                preload="metadata"
                            >
                                <source src={`${focusedVideo}#t=0.1`} type="video/mp4" />
                            </video>
                        </div>
                    )}
                </Popup>
            )}
            {props.images && props.images.length > 0 && (
                <div className={classNames("gallery", props.className)}>
                    {props.images.map((image, i) => (
                        <div
                            key={`image_${i}`}
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center"
                            }}
                            className="image-thumbnail"
                            title={props.label}
                            aria-label={props.label}
                            role="button"
                            onClick={() => {
                                if (props.onClick) {
                                    props.onClick(image);
                                } else {
                                    setFocusedPicture(image);
                                    setType("photo");
                                    setShowModal(true);
                                }
                            }}
                        >
                            <DeleteComponent
                                editable={props.editable ?? false}
                                deleteLabel={props.deleteLabel}
                                onClick={() => {
                                    setFocusedToDelete(image.split("/").pop() ?? "");
                                    setShowDelete(true);
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
            {props.videos && props.videos.length > 0 && (
                <div className={classNames("gallery", props.className)}>
                    {props.videos.map((video, i) => (
                        <div
                            key={`video_${i}`}
                            className={classNames("video-thumbnail", {
                                "video-presentation": video.isPresentation,
                                "video-scenes": video.isScenes
                            })}
                            title={props.label}
                            aria-label={props.label}
                            role="button"
                            onClick={
                                isMobile()
                                    ? undefined
                                    : () => {
                                          if (props.onClick) {
                                              props.onClick(video.url);
                                          } else {
                                              setFocusedVideo(video.url);
                                              setType("video");
                                              setShowModal(true);
                                          }
                                      }
                            }
                        >
                            <DeleteComponent
                                editable={props.editable ?? false}
                                deleteLabel={props.deleteLabel}
                                onClick={() => {
                                    setFocusedToDelete(video.url.split("/").pop() ?? "");
                                    setShowDelete(true);
                                }}
                            />
                            <video width="100%" height="100%" controls={isMobile()} autoPlay={false} preload="metadata">
                                <source src={`${video.url}#t=0.1`} type="video/mp4" />
                            </video>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

interface DeleteComponentProps {
    editable: boolean;
    deleteLabel?: string;
    onClick: () => void;
}

interface SelectComponentProps {
    checked: boolean;
    editable: boolean;
    onClick: () => void;
}

function DeleteComponent(props: DeleteComponentProps): ReactElement | null {
    return props.editable ? (
        <div
            title={props.deleteLabel}
            onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClick();
            }}
            className="delete-badge"
        >
            <Icon name="x" color="white" />
        </div>
    ) : null;
}

function SelectComponent(props: SelectComponentProps): ReactElement {
    return (
        <div
            onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClick();
            }}
            className={classNames("thumbnail-checkbox", { "checked-checkbox": props.checked })}
            style={{ visibility: props.editable ? "visible" : "hidden" }}
        >
            <input name="gallery-checkbox" type="checkbox" checked={props.checked} onChange={props.onClick} />
        </div>
    );
}
