import { Session, Skill } from "../models";
import { get, post, put } from "./request.service";
import { config } from "../utils/constants";

export async function getSkills(session?: Session): Promise<Skill[]> {
    return get(`${config.url}/skills`, session);
}

export async function newSkill(attribute: Skill): Promise<Skill> {
    return post(`${config.url}/skills/new`, attribute);
}

export async function updateSkill(id: string, attribute: Skill): Promise<Skill> {
    return put(`${config.url}/skills/${id}/update`, attribute);
}
