import { EntityWithId, Entity } from "./generic";

export interface Finance extends Entity, EntityWithId {
    identification?: string;
    casting?: string; //Casting ID
    status: FinanceStatus;
    user?: string; // User ID
    entries?: FinanceEntry[];
}

export interface FinanceEntry extends EntityWithId {
    type: FinanceEntryType;
    description: string;
    obs: string;
    linkArtist: FinanceEntryLink;
    artist: string[];
    amount: number;
    estimatedDate: Date;
    status: FinanceEntryStatus;
    fiscalNote?: string;
}

export enum FinanceStatus {
    IN_PROGRESS,
    FINISHED
}

export enum FinanceEntryType {
    IN,
    OUT
}

export enum FinanceEntryLink {
    UNLINK,
    LINK
}

export enum FinanceEntryStatus {
    PENDING = 0,
    FINISHED = 1,
    PENDING_NF = 2,
    PENDING_PAYMENT = 3
}

export enum FinanceDescriptionInType {
    ARTIST_PAYMENT = 0,
    PAYMENT_EXTERNAL_CUSTOMERS = 1,
    OTHER_ENTRY = 2
}

export enum FinanceDescriptionOutType {
    PAYMENT_ARTIST_PF = 3,
    PAYMENT_ARTIST_PJ = 4,
    OPERACIONAL_COSTS = 5,
    OTHER_OUT = 6
}
