import { action, makeObservable, observable } from "mobx";
import { Profile } from "../models";
import { checkDateComposite, comparator, deepSearch } from "../services/util.service";

export class ProfileStore {
    @observable.deep profiles: Profile[] = [] as Profile[];
    @observable focusedProfile: Profile = {} as Profile;

    constructor() {
        makeObservable(this);
    }

    @action setProfiles(profiles: Profile[]) {
        this.profiles = profiles;
    }

    @action updateProfile(profile: Profile) {
        const index = this.profiles.findIndex(c => c._id === profile._id);
        if (index > -1) {
            this.profiles[index] = profile;
        }
        this.setFocusedProfile(profile);
    }

    @action addProfile(profile: Profile) {
        this.profiles.push(profile);
    }

    @action getProfileById(id: string): Profile | undefined {
        return this.profiles.find(c => c._id === id);
    }

    @action setFocusedProfile(profile: Profile) {
        this.focusedProfile = profile;
    }

    @action filterProfiles(filter: string, limit?: number) {
        if (limit !== undefined) {
            return this.profiles.filter(profile => deepSearch(profile, filter)).slice(0, limit);
        }
        return this.profiles.filter(profile => deepSearch(profile, filter));
    }

    @action filter(name: string, status: number, composite: string, validation: string, limit?: number) {
        let profiles = this.profiles;
        if (name) {
            profiles = profiles.filter(profile => comparator(profile.artisticName, name));
        }
        if (status > -1) {
            profiles = profiles.filter(profile => profile.status === Number(status));
        }
        if (!!composite) {
            profiles = profiles.filter(profile =>
                comparator(checkDateComposite(!!profile.composite, profile.compositeDate), composite)
            );
        }
        if (!!validation) {
            profiles = profiles.filter(profile =>
                validation === "ok"
                    ? !profile.approval || Object.keys(profile.approval).length === 0
                    : profile.approval && Object.keys(profile.approval).length > 0
            );
        }
        if (limit !== undefined) {
            return profiles.slice(0, limit);
        }
        return profiles;
    }

    @action clearCurrentSession() {
        this.profiles = [];
        this.focusedProfile = {} as Profile;
    }

    @action sortByUpdate(sortMethod: "asc" | "desc", profiles?: Profile[]) {
        const unsortedProfiles = profiles ? profiles : [...this.profiles];

        unsortedProfiles.sort((p1, p2) => {
            if (!p1.updatedAt) {
                if (p2.updatedAt) {
                    if (sortMethod === "desc") {
                        return 1;
                    }
                }
                return -1;
            }
            if (!p2.updatedAt) {
                if (p1.updatedAt) {
                    if (sortMethod === "asc") {
                        return 1;
                    }
                }
                return -1;
            }
            const d1 = new Date(p1.updatedAt);
            const d2 = new Date(p2.updatedAt);
            if (d1.getTime() === d2.getTime()) {
                return 0;
            }
            if (sortMethod === "asc") {
                return d1.getTime() > d2.getTime() ? 1 : -1;
            }
            return d2.getTime() > d1.getTime() ? 1 : -1;
        });

        if (!profiles) {
            this.setProfiles(unsortedProfiles);
        }
    }

    @action sortByName(sortMethod: "asc" | "desc", profiles?: Profile[]) {
        const unsortedProfiles = profiles ? profiles : [...this.profiles];

        unsortedProfiles.sort((p1, p2) => {
            if (!p1.artisticName || !p2.artisticName) {
                return 0;
            }
            if (p1.artisticName === p2.artisticName) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1.artisticName.localeCompare(p2.artisticName);
            }
            return p2.artisticName.localeCompare(p1.artisticName);
        });

        if (!profiles) {
            this.setProfiles(unsortedProfiles);
        }
    }
}
