import { Session, Skill, Translation } from "../models";
import { get, post, put } from "./request.service";
import { config } from "../utils/constants";

export async function getTranslations(session?: Session): Promise<Translation[]> {
    return get(`${config.url}/translations/all`, session);
}

export async function newTranslation(translation: Translation): Promise<Translation> {
    return post(`${config.url}/translations/new`, translation);
}

export async function updateTranslation(
    id: string,
    translation: { en: string; pt: string; es: string }
): Promise<Translation> {
    return put(`${config.url}/translations/${id}/update`, translation);
}
