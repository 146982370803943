import { action, makeObservable, observable } from "mobx";
import { CastingAttribute, CastingAttributeType } from "../models";

export class CastingAttributeStore {
    @observable.deep attributes: CastingAttribute[] = [] as CastingAttribute[];

    constructor() {
        makeObservable(this);
    }

    @action setAttributes(attributes: CastingAttribute[]) {
        this.attributes = attributes;
    }

    @action addAttribute(attribute: CastingAttribute) {
        this.attributes.push(attribute);
    }

    @action updateAttribute(attribute: CastingAttribute) {
        const index = this.attributes.findIndex(c => c._id === attribute._id);
        if (index > -1) {
            this.attributes[index] = attribute;
        }
    }

    @action getAttributeById(id: string): CastingAttribute | undefined {
        return this.attributes.find(c => c._id === id);
    }

    @action sortByType(sortMethod: "asc" | "desc", language: "pt" | "en", attributes?: CastingAttribute[]) {
        const unsortedAttributes = attributes ? attributes : [...this.attributes];

        unsortedAttributes.sort((p1, p2) => {
            const key = `name${language.toUpperCase()}` as keyof CastingAttributeType;
            const p1Type = p1.type as CastingAttributeType;
            const p2Type = p2.type as CastingAttributeType;
            if (!(key in p1Type) || !(key in p2Type)) {
                return 0;
            }
            if (p1Type[key] === p2Type[key]) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1Type[key]!.localeCompare(p2Type[key]!);
            }
            return p2Type[key]!.localeCompare(p1Type[key]!);
        });

        if (!attributes) {
            this.setAttributes(unsortedAttributes);
        }
    }

    @action sortByValue(sortMethod: "asc" | "desc", attributes?: CastingAttribute[]) {
        const unsortedAttributes = attributes ? attributes : [...this.attributes];

        unsortedAttributes.sort((p1, p2) => {
            if (!p1.value || !p2.value) {
                return 0;
            }
            if (p1.value === p2.value) {
                return 0;
            }
            if (sortMethod === "asc") {
                return p1.value.localeCompare(p2.value);
            }
            return p2.value.localeCompare(p1.value);
        });

        if (!attributes) {
            this.setAttributes(unsortedAttributes);
        }
    }
}
