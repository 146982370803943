import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { Button, Card, Form, Header } from "tabler-react";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { Profile } from "../../models";
import { Gallery } from "../Gallery";
import { config } from "../../utils/constants";
import { approveSection, deleteFile, rejectSection } from "../../services/profile.service";
import { toast } from "react-toastify";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";

export const Validation = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [showApprovalConfirmation, setApprovalConfirmation] = useState(false);
        const [showRejectConfirmation, setRejectConfirmation] = useState(false);
        const [section, setSection] = useState<keyof Profile | undefined>(undefined);
        const { profileStore, loadingStore, sessionStore } = props.rootStore!;

        const maxVideoFiles = 6;
        const maxPictureFiles = 15;

        const totalVideoFiles = useMemo(
            () =>
                (profileStore.focusedProfile.presentationVideo ? 1 : 0) +
                (profileStore.focusedProfile.scenesVideo ? 1 : 0) +
                (profileStore.focusedProfile.videos ? profileStore.focusedProfile.videos.length : 0),
            [profileStore.focusedProfile]
        );

        const onApprove = async () => {
            loadingStore.triggerLoading();
            if (section) {
                setApprovalConfirmation(false);
                approveSection(profileStore.focusedProfile, section)
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        setSection(undefined);
                        toast.success(t("success.section-approved"));
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.approving"));
                        setApprovalConfirmation(true);
                        loadingStore.stopLoading();
                    });
            }
        };

        const onReject = async () => {
            loadingStore.triggerLoading();
            if (section) {
                setRejectConfirmation(false);
                rejectSection(profileStore.focusedProfile, section)
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        setSection(undefined);
                        toast.success(t("success.section-rejected"));
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.rejecting"));
                        setRejectConfirmation(true);
                        loadingStore.stopLoading();
                    });
            }
        };

        if (sessionStore.isSameAsFocused || (!sessionStore.isBooker && !sessionStore.isDirector)) {
            return null;
        }

        const onDelete = (file: string) => {
            loadingStore.triggerLoading();
            deleteFile(profileStore.focusedProfile._id!, file)
                .then(profile => {
                    if (profile) {
                        loadingStore.stopLoading();
                        profileStore.updateProfile(profile);
                        toast.success(t("success.deleted"));
                    }
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error(t("error.deleting"));
                });
        };

        return (
            <>
                {showApprovalConfirmation && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setApprovalConfirmation(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.approve-section")}</Form.Group>
                        <Form.Footer>
                            <Button color="primary" onClick={onApprove}>
                                {t("proceed")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                {showRejectConfirmation && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setRejectConfirmation(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.reject-section")}</Form.Group>
                        <Form.Footer>
                            <Button color="primary" onClick={onReject}>
                                {t("proceed")}
                            </Button>
                        </Form.Footer>
                    </Popup>
                )}
                {profileStore.focusedProfile?.approval?.presentationVideo && (
                    <Card>
                        <Card.Body>
                            <Header.H3 className="header-with-buttons">
                                {t("profile.validate")} {t("profile.presentation_video")}
                                <div className="flex-row justify-content-end">
                                    <Button
                                        color="primary"
                                        icon="thumbs-up"
                                        onClick={() => {
                                            setSection("presentationVideo");
                                            setApprovalConfirmation(true);
                                        }}
                                        title={t("approve")}
                                    />
                                    <Button
                                        color="danger"
                                        icon="thumbs-down"
                                        onClick={() => {
                                            setSection("presentationVideo");
                                            setRejectConfirmation(true);
                                        }}
                                        title={t("reject")}
                                    />
                                </div>
                            </Header.H3>

                            <Gallery
                                label={t("click-to-expand")}
                                isVideo
                                videos={[
                                    {
                                        url: config.host + profileStore.focusedProfile?.approval?.presentationVideo,
                                        isPresentation: true
                                    }
                                ]}
                                editable
                                onDeleteAction={(video: string) => onDelete(video)}
                            />
                        </Card.Body>
                    </Card>
                )}
                {profileStore.focusedProfile?.approval?.scenesVideo && (
                    <Card>
                        <Card.Body>
                            <Header.H3 className="header-with-buttons">
                                {t("profile.validate")} - {t("profile.scenes_video")}
                                <div className="flex-row justify-content-end">
                                    <Button
                                        color="primary"
                                        icon="thumbs-up"
                                        onClick={() => {
                                            setSection("scenesVideo");
                                            setApprovalConfirmation(true);
                                        }}
                                        title={t("approve")}
                                    />
                                    <Button
                                        color="danger"
                                        icon="thumbs-down"
                                        onClick={() => {
                                            setSection("scenesVideo");
                                            setRejectConfirmation(true);
                                        }}
                                        title={t("reject")}
                                    />
                                </div>
                            </Header.H3>

                            <Gallery
                                label={t("click-to-expand")}
                                isVideo
                                videos={[
                                    {
                                        url: config.host + profileStore.focusedProfile?.approval?.scenesVideo,
                                        isScenes: true
                                    }
                                ]}
                                editable
                                deleteLabel={t("delete-image")}
                                onDeleteAction={(video: string) => onDelete(video)}
                            />
                        </Card.Body>
                    </Card>
                )}
                {(profileStore.focusedProfile?.approval?.videos?.length ?? 0) > 0 && (
                    <Card>
                        <Card.Body>
                            <Header.H3 className="header-with-buttons">
                                {t("profile.validate")} {t("profile.other_videos")}
                                <div className="flex-row justify-content-end">
                                    <Button
                                        color="primary"
                                        icon="thumbs-up"
                                        onClick={() => {
                                            setSection("videos");
                                            setApprovalConfirmation(true);
                                        }}
                                        title={t("approve")}
                                    />
                                    <Button
                                        color="danger"
                                        icon="thumbs-down"
                                        onClick={() => {
                                            setSection("videos");
                                            setRejectConfirmation(true);
                                        }}
                                        title={t("reject")}
                                    />
                                </div>
                            </Header.H3>

                            <Gallery
                                label={t("click-to-expand")}
                                isVideo
                                videos={profileStore.focusedProfile.approval?.videos?.map(v => ({
                                    url: config.host + v
                                }))}
                                editable
                                onDeleteAction={(video: string) => onDelete(video)}
                            />

                            <Form.Group style={{ marginTop: 15 }}>
                                {t("upload.total-files")}: {totalVideoFiles}/{maxVideoFiles}
                            </Form.Group>
                        </Card.Body>
                    </Card>
                )}
                {(profileStore.focusedProfile?.approval?.pictures?.length ?? 0) > 0 && (
                    <Card>
                        <Card.Body>
                            <Header.H3 className="header-with-buttons">
                                {t("profile.validate")} {t("profile.photos")}
                                <div className="flex-row justify-content-end">
                                    <Button
                                        color="primary"
                                        icon="thumbs-up"
                                        onClick={() => {
                                            setSection("pictures");
                                            setApprovalConfirmation(true);
                                        }}
                                        title={t("approve")}
                                    />
                                    <Button
                                        color="danger"
                                        icon="thumbs-down"
                                        onClick={() => {
                                            setSection("pictures");
                                            setRejectConfirmation(true);
                                        }}
                                        title={t("reject")}
                                    />
                                </div>
                            </Header.H3>

                            <Gallery
                                label={t("click-to-expand")}
                                images={profileStore.focusedProfile.approval?.pictures?.map(p => config.host + p)}
                                isVideo={false}
                                editable
                                onDeleteAction={(image: string) => onDelete(image)}
                            />
                            <Form.Group style={{ marginTop: 15 }}>
                                {t("upload.total-files")}:{" "}
                                {(profileStore.focusedProfile.pictures?.length ?? 0) +
                                    (profileStore.focusedProfile.approval?.pictures?.length ?? 0)}
                                /{maxPictureFiles}
                            </Form.Group>
                        </Card.Body>
                    </Card>
                )}
                {profileStore.focusedProfile.approval?.bio?.description && (
                    <Card>
                        <Card.Body>
                            <Header.H3 className="header-with-buttons">
                                {t("profile.validate")} Bio
                                <div className="flex-row justify-content-end">
                                    <Button
                                        color="primary"
                                        icon="thumbs-up"
                                        onClick={() => {
                                            setSection("bio");
                                            setApprovalConfirmation(true);
                                        }}
                                        title={t("approve")}
                                    />
                                    <Button
                                        color="danger"
                                        icon="thumbs-down"
                                        onClick={() => {
                                            setSection("bio");
                                            setRejectConfirmation(true);
                                        }}
                                        title={t("reject")}
                                    />
                                </div>
                            </Header.H3>

                            <Form.Group>
                                <Form.Label>{t("profile.bio_description")}</Form.Label>
                                <div>{profileStore.focusedProfile.approval?.bio?.description}</div>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                )}
            </>
        );
    })
);
