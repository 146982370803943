import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import classNames from "classnames";

interface TabContainerProps {
    defaultTab?: number;
}

export function TabContainer(props: PropsWithChildren<TabContainerProps>) {
    const [active, setActive] = useState(props.defaultTab ?? 0);
    const [content, setContent] = useState<ReactNode>(null);
    return (
        <TabContext.Provider value={{ active, setActive, setContent }}>
            <div className="tab-container">{props.children}</div>
            <div className="tab-content">{content}</div>
        </TabContext.Provider>
    );
}

interface TabProps {
    index: number;
    title: string;
}

export function Tab(props: PropsWithChildren<TabProps>) {
    const { active, setActive, setContent } = useContext(TabContext);

    const isActive = useMemo(() => active === props.index, [active, props.index]);

    useEffect(() => {
        if (isActive) {
            setContent(props.children);
        }
    }, [isActive]);

    return (
        <div
            className={classNames("tab-header", { "tab-active": isActive })}
            onClick={() => setActive(props.index)}
            role="button"
        >
            <span>{props.title}</span>
        </div>
    );
}

const TabContext = createContext<{
    active: number;
    setActive: Dispatch<SetStateAction<number>>;
    setContent: Dispatch<SetStateAction<ReactNode>>;
}>({
    active: 0,
    setActive: () => {},
    setContent: () => {}
});
