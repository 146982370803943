import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Popup } from "./Popup";
import { isMobile } from "../utils/utils";
import { Formik } from "formik";
import { Button, Card, Dropdown, Form, Icon, Table } from "tabler-react";
import Select from "react-select";
import {
    FinanceDescriptionInType,
    FinanceDescriptionOutType,
    FinanceEntry,
    FinanceEntryLink,
    FinanceEntryStatus,
    FinanceEntryType
} from "../models";
import InputMask from "react-input-mask";
import { editEntry, editStatus, newEntry, removeEntry, removeEntryFiscalNote } from "../services/finance.service";
import { toast } from "react-toastify";
import { formatCurrency, formatDate, parseCurrency, parseDate } from "../utils/formats";
import { getProfiles, getUsers } from "../services/profile.service";
import makeAnimated from "react-select/animated";
import { getProtectedFile } from "../services/files.service";
import download from "downloadjs";

const animatedComponents = makeAnimated();

export const FinanceDetails = inject("rootStore")(
    observer((props: Stores) => {
        const {
            financeStore,
            castingStore,
            loadingStore,
            profileStore,
            routingStore: { push }
        } = props.rootStore!;
        const { t } = useTranslation();
        const [showDetails, setShowDetails] = useState(false);
        const [openDelete, setOpenDelete] = useState(false);
        const [openDeleteFiscalNote, setOpenDeleteFiscalNote] = useState(false);
        const [openEditStatus, setOpenEditStatus] = useState(false);
        const [sort, setSort] = useState("");
        const [sortType, setSortType] = useState("asc");
        const [sortDescription, setSortDescription] = useState("asc");
        const [sortStatus, setSortStatus] = useState("asc");
        const [sortAmount, setSortAmount] = useState("asc");
        const [sortEstimatedDate, setSortEstimatedDate] = useState("asc");
        const [filterType, setFilterType] = useState("");
        const [filterDescription, setFilterDescription] = useState("");
        const [filterStatus, setFilterStatus] = useState("");
        const [filterAmount, setFilterAmount] = useState("");
        const [filterEstimatedDate, setFilterEstimatedDate] = useState("");
        const [fileName, setFileName] = useState("");
        const [focusedEntry, setFocusedEntry] = useState<FinanceEntry>();

        const convertToString = (date?: Date) => {
            if (!date) {
                return "";
            }
            return formatDate(new Date(date)).toString();
        };
        useEffect(() => {
            if (!financeStore.focusedFinance._id) {
                push("/finance");
            }
            if (castingStore.users.length === 0) {
                loadingStore.triggerLoading();
                getUsers()
                    .then(users => {
                        castingStore.setUsers(users);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            }
        }, []);

        useEffect(() => {
            if (profileStore.profiles.length === 0) {
                loadingStore.triggerLoading();
                getProfiles()
                    .then(profiles => {
                        profileStore.setProfiles(profiles);
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            }
        }, []);

        const profiles = useMemo(
            () => profileStore.profiles.map(attr => ({ label: attr.artisticName, value: attr._id })),
            [profileStore.profiles]
        );

        const findCastingTitle = (value?: string) =>
            `Casting: ${castingStore.castings.find(c => c._id == value)?.title}`;

        const onAddEntry = async (values: any) => {
            values.file && setFileName(values.file.name);
            loadingStore.triggerLoading();
            setShowDetails(false);
            newEntry(financeStore.focusedFinance, values.file, {
                type: values.type === "in" ? FinanceEntryType.IN : FinanceEntryType.OUT,
                description: values.description,
                estimatedDate: parseDate(values.estimatedDate),
                obs: values.obs,
                linkArtist: values.linkArtist === "noLink" ? FinanceEntryLink.UNLINK : FinanceEntryLink.LINK,
                artist: values.artist,
                amount: parseCurrency(values.amount)
            })
                .then(finance => {
                    loadingStore.stopLoading();
                    financeStore.updateFinance(finance);
                    financeStore.setFocusedFinance(finance);
                    toast.success(t("success.created-finance-entry"));
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error(t("error.creating-finance-entry"));
                });
        };

        const onEditEntry = async (values: any) => {
            setShowDetails(false);
            loadingStore.triggerLoading();
            editEntry(financeStore.focusedFinance, values.file, {
                ...focusedEntry,
                type: values.type === "in" ? FinanceEntryType.IN : FinanceEntryType.OUT,
                description: values.description,
                estimatedDate: parseDate(values.estimatedDate),
                obs: values.obs,
                linkArtist: values.linkArtist === "noLink" ? FinanceEntryLink.UNLINK : FinanceEntryLink.LINK,
                artist: values.artist,
                amount: parseCurrency(values.amount)
            })
                .then(finance => {
                    loadingStore.stopLoading();
                    financeStore.updateFinance(finance);
                    financeStore.setFocusedFinance(finance);
                    setFocusedEntry(undefined);
                    toast.success(t("success.updated-finance-entry"));
                })
                .catch(() => {
                    setShowDetails(true);
                    loadingStore.stopLoading();
                    toast.error(t("error.creating-finance-entry"));
                });
        };

        const onRemoveEntry = async () => {
            if (focusedEntry) {
                loadingStore.triggerLoading();
                setOpenDelete(false);
                removeEntry(financeStore.focusedFinance, focusedEntry)
                    .then(finance => {
                        loadingStore.stopLoading();
                        financeStore.updateFinance(finance);
                        financeStore.setFocusedFinance(finance);
                        setFocusedEntry(undefined);
                        toast.success(t("success.finance-entry-deleted"));
                    })
                    .catch(() => {
                        setOpenDelete(true);
                        loadingStore.stopLoading();
                        toast.error(t("error.deleting-finance-entry"));
                    });
            }
        };

        const onRemoveEntryFiscalNote = async () => {
            if (focusedEntry) {
                loadingStore.triggerLoading();
                setOpenDeleteFiscalNote(false);
                loadingStore.triggerLoading();
                removeEntryFiscalNote(financeStore.focusedFinance, focusedEntry)
                    .then(finance => {
                        loadingStore.stopLoading();
                        financeStore.updateFinance(finance);
                        financeStore.setFocusedFinance(finance);
                        setFocusedEntry(undefined);
                        toast.success(t("success.finance-entry-fiscal-note-deleted"));
                    })
                    .catch(() => {
                        setOpenDeleteFiscalNote(true);
                        loadingStore.stopLoading();
                        toast.error(t("error.deleting-finance-entry-fiscal-note"));
                    });
            }
        };

        const onEditStatus = async (values: any) => {
            if (focusedEntry) {
                loadingStore.triggerLoading();
                setOpenEditStatus(false);
                editStatus(financeStore.focusedFinance, focusedEntry, Number(values.status))
                    .then(finance => {
                        loadingStore.stopLoading();
                        financeStore.updateFinance(finance);
                        financeStore.setFocusedFinance(finance);
                        toast.success(t("success.updated"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            }
        };

        const entries = useMemo(() => {
            let entries = [...(financeStore.focusedFinance.entries ?? [])];

            if (filterDescription) {
                entries = entries.filter(c => c.description.toLocaleString()?.includes(filterDescription));
            }

            if (filterType) {
                entries = entries.filter(c => c.type === Number(filterType));
            }

            if (filterAmount) {
                entries = entries.filter(c => c.amount.toLocaleString()?.includes(filterAmount));
            }

            if (filterEstimatedDate) {
                entries = entries.filter(
                    c => c.estimatedDate && formatDate(new Date(c.estimatedDate)).includes(filterEstimatedDate)
                );
            }

            if (filterStatus) {
                entries = entries.filter(c => c.status === Number(filterStatus));
            }

            return entries;
        }, [
            financeStore.focusedFinance.entries,
            filterType,
            filterEstimatedDate,
            filterDescription,
            filterAmount,
            filterStatus
        ]);

        const findUser = useCallback(
            (id?: string) => castingStore.users.find(attr => attr._id == id)?.artisticName ?? "",
            [castingStore.users]
        );

        return (
            <>
                {showDetails && (
                    <Popup
                        title={focusedEntry ? t("finance.entry.menu.edit-entry") : t("finance.entry.new")}
                        onClose={() => {
                            setShowDetails(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                type: focusedEntry?.type === 1 ? "out" : "in",
                                description: focusedEntry?.description ?? "",
                                obs: focusedEntry?.obs ?? "",
                                linkArtist: focusedEntry?.linkArtist === FinanceEntryLink.LINK ? "link" : "noLink",
                                artist: focusedEntry?.artist ?? [],
                                amount: focusedEntry?.amount ? formatCurrency(focusedEntry?.amount) : "",
                                estimatedDate: convertToString(focusedEntry?.estimatedDate) ?? "",
                                file: null
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (!values.type) {
                                    errors.type = t("form.errors.required");
                                }
                                if (!values.description) {
                                    errors.description = t("form.errors.required");
                                }
                                if (!values.obs) {
                                    errors.obs = t("form.errors.required");
                                }
                                if (!values.linkArtist) {
                                    errors.linkArtist = t("form.errors.required");
                                }
                                if (values.linkArtist === "link" && !values.artist) {
                                    errors.linkArtist = t("form.errors.required");
                                }
                                if (!values.amount) {
                                    errors.amount = t("form.errors.required");
                                } else if (!/^[+-]?\d{1,3}(?:.?\d{3})*(?:\,\d{2})?$/.test(values.amount)) {
                                    errors.amount = t("form.errors.invalid");
                                }
                                if (!values.estimatedDate || values.estimatedDate === "__/__/____") {
                                    errors.birth_date = t("form.errors.required");
                                }
                                if (values.file && !fileName.endsWith(".pdf")) {
                                    errors.file = t("form.errors.invalid");
                                }
                            }}
                            enableReinitialize
                            onSubmit={focusedEntry ? onEditEntry : onAddEntry}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.type")}</Form.Label>
                                            <Form.Radio
                                                label={t("finance.entry.type.in")}
                                                name="type"
                                                value="in"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.type === "in"}
                                                error={errors.type}
                                            />
                                            <Form.Radio
                                                label={t("finance.entry.type.out")}
                                                name="type"
                                                value="out"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.type === "out"}
                                                error={errors.type}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.description")}</Form.Label>
                                            <Form.Select
                                                name="description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.description}
                                                error={errors && errors.description}
                                            >
                                                <option value="">{t("select")}</option>
                                                {values.type === "in" && (
                                                    <>
                                                        <option value={FinanceDescriptionInType.ARTIST_PAYMENT}>
                                                            {t("finance.entry.observation.artist-payment")}
                                                        </option>
                                                        <option
                                                            value={FinanceDescriptionInType.PAYMENT_EXTERNAL_CUSTOMERS}
                                                        >
                                                            {t("finance.entry.observation.payment-external-customers")}
                                                        </option>
                                                        <option value={FinanceDescriptionInType.OTHER_ENTRY}>
                                                            {t("finance.entry.observation.other-entry")}
                                                        </option>
                                                    </>
                                                )}
                                                {values.type === "out" && (
                                                    <>
                                                        <option value={FinanceDescriptionOutType.PAYMENT_ARTIST_PF}>
                                                            {t("finance.entry.observation.payment-artist-pf")}
                                                        </option>
                                                        <option value={FinanceDescriptionOutType.PAYMENT_ARTIST_PJ}>
                                                            {t("finance.entry.observation.payment-artist-pj")}
                                                        </option>
                                                        <option value={FinanceDescriptionOutType.OPERACIONAL_COSTS}>
                                                            {t("finance.entry.observation.operacional-costs")}
                                                        </option>
                                                        <option value={FinanceDescriptionOutType.OTHER_OUT}>
                                                            {t("finance.entry.observation.other-out")}
                                                        </option>
                                                    </>
                                                )}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.observation")}</Form.Label>
                                            <Form.Input
                                                name="obs"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.obs}
                                                error={errors && errors.obs}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.artists")}</Form.Label>
                                            <Form.Radio
                                                label={t("finance.entry.type.noLink")}
                                                name="linkArtist"
                                                value="noLink"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.linkArtist === "noLink"}
                                                error={errors.linkArtist}
                                            />
                                            <Form.Radio
                                                label={t("finance.entry.type.link")}
                                                name="linkArtist"
                                                value="link"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.linkArtist === "link"}
                                                error={errors.linkArtist}
                                            />
                                            {values.linkArtist === "link" && (
                                                <Select
                                                    name="artist"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    defaultValue={values.artist?.map(artist => ({
                                                        value: artist,
                                                        label: profiles.find(p => p.value === artist)?.label
                                                    }))}
                                                    isMulti
                                                    options={profiles}
                                                    placeholder={t("select")}
                                                    noOptionsMessage={() => "--"}
                                                    onChange={value => {
                                                        setFieldValue(
                                                            "artist",
                                                            value?.map((v: any) => v.value)
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    className={classNames({
                                                        "invalid-multiselect": !!errors.artist
                                                    })}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.amount")}</Form.Label>
                                            <Form.Input
                                                name="amount"
                                                type="currency"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.amount}
                                                error={errors && errors.amount}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.estimated-date")}</Form.Label>
                                            <InputMask
                                                name="estimatedDate"
                                                mask="99/99/9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.estimatedDate}
                                            >
                                                {(inputProps: any) => (
                                                    <Form.Input
                                                        error={errors && errors.estimatedDate}
                                                        invalid={!!errors.estimatedDate}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.entry.file")}</Form.Label>
                                            <Form.Input
                                                disabled={!!focusedEntry?.fiscalNote}
                                                name="file"
                                                type="file"
                                                onChange={(e: any) => {
                                                    if (e.currentTarget.files.length > 0) {
                                                        setFieldValue("file", e.currentTarget.files[0]);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                accept="application/pdf"
                                            />
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openEditStatus && (
                    <Popup
                        title={t("finance.entry.status")}
                        onClose={() => {
                            setOpenEditStatus(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                status: ""
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (!values.status) {
                                    errors.status = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onEditStatus}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Select
                                                name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.status}
                                                error={errors && errors.status}
                                            >
                                                <option value="">{t("select")}</option>
                                                {focusedEntry && focusedEntry.type === FinanceEntryType.IN && (
                                                    <>
                                                        <option
                                                            value={FinanceEntryStatus.PENDING_NF}
                                                            disabled={!!focusedEntry.fiscalNote}
                                                        >
                                                            {t("finance.entry.status.pendingNF")}
                                                        </option>
                                                        <option value={FinanceEntryStatus.PENDING_PAYMENT}>
                                                            {t("finance.entry.status.pendingPayment")}
                                                        </option>
                                                        <option value={FinanceEntryStatus.FINISHED}>
                                                            {t("finance.entry.status.finished")}
                                                        </option>
                                                    </>
                                                )}
                                                {focusedEntry && focusedEntry.type === FinanceEntryType.OUT && (
                                                    <>
                                                        <option value={FinanceEntryStatus.PENDING}>
                                                            {t("finance.entry.status.pending")}
                                                        </option>
                                                        <option value={FinanceEntryStatus.FINISHED}>
                                                            {t("finance.entry.status.finished")}
                                                        </option>
                                                    </>
                                                )}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openDelete && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setOpenDelete(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.delete_entry")}</Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={async () => {
                                setOpenDelete(false);
                                await onRemoveEntry();
                            }}
                        >
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setOpenDelete(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                {openDeleteFiscalNote && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setOpenDeleteFiscalNote(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.delete_entry-fiscal-note")}</Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={async () => {
                                setOpenDeleteFiscalNote(false);
                                await onRemoveEntryFiscalNote();
                            }}
                        >
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setOpenDeleteFiscalNote(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                <div className="finance-details">
                    <h2>
                        {financeStore.focusedFinance.identification ??
                            findCastingTitle(financeStore.focusedFinance.casting)}
                    </h2>
                    <div className="tab-container">
                        <div className={classNames("tab-header", { "tab-active": true })}>
                            <span>{t("finance.entries")}</span>
                        </div>
                    </div>
                    <div className="tab-content">
                        <Card>
                            <Card.Body>
                                <Button
                                    color="primary"
                                    style={{ marginBottom: "10px" }}
                                    onClick={() => {
                                        setFocusedEntry(undefined);
                                        setShowDetails(true);
                                    }}
                                >
                                    {t("finance.entry.new")}
                                </Button>
                                <Card>
                                    <Card.Body>
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <div className="flex-row no-margins">
                                                    <strong>{t("finance.entry.created-by")}</strong>:{" "}
                                                    {findUser(financeStore.focusedFinance.user)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <div className="flex-row no-margins">
                                                    <strong>{t("finance.entry.total-in")}</strong>:{" "}
                                                    {formatCurrency(
                                                        entries
                                                            .filter(e => e.type === FinanceEntryType.IN)
                                                            .reduce((acc, cur) => acc + cur.amount, 0)
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-column">
                                                <div className="flex-row no-margins">
                                                    <strong>{t("finance.entry.total-out")}</strong>:{" "}
                                                    {formatCurrency(
                                                        entries
                                                            .filter(e => e.type === FinanceEntryType.OUT)
                                                            .reduce((acc, cur) => acc + cur.amount, 0)
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-column">
                                                <div className="flex-row no-margins">
                                                    <strong>{t("finance.entry.tax-reserve")}</strong>:{" "}
                                                    {formatCurrency(
                                                        entries
                                                            .filter(e => e.description === "3")
                                                            .reduce((acc, cur) => acc + cur.amount / 3, 0)
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-row no-margins">
                                            <div className="flex-column">
                                                <div className="flex-row no-margins">
                                                    <strong>{t("finance.entry.balance")}</strong>:{" "}
                                                    {formatCurrency(
                                                        entries
                                                            .filter(e => e.type === FinanceEntryType.IN)
                                                            .reduce((acc, cur) => acc + cur.amount, 0) -
                                                            entries
                                                                .filter(e => e.type === FinanceEntryType.OUT)
                                                                .reduce((acc, cur) => acc + cur.amount, 0) -
                                                            entries
                                                                .filter(e => e.description === "3")
                                                                .reduce((acc, cur) => acc + cur.amount / 3, 0)
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-column">
                                                <div className="flex-row no-margins">
                                                    <strong>{t("finance.entry.margin")}</strong>:{" "}
                                                    {formatCurrency(
                                                        ((entries
                                                            .filter(e => e.type === FinanceEntryType.IN)
                                                            .reduce((acc, cur) => acc + cur.amount, 0) -
                                                            entries
                                                                .filter(e => e.type === FinanceEntryType.OUT)
                                                                .reduce((acc, cur) => acc + cur.amount, 0)) /
                                                            entries
                                                                .filter(e => e.type === FinanceEntryType.IN)
                                                                .reduce((acc, cur) => acc + cur.amount, 0)) *
                                                            100
                                                    )}
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Table className="table-striped">
                                    <Table.Header>
                                        <Table.Row className="centered-row">
                                            <Table.ColHeader>
                                                <div className="flex-row no-margins">
                                                    <div className="flex-column no-margins">
                                                        <div
                                                            className="sortable-column"
                                                            title={t("click-to-sort")}
                                                            onClick={() => {
                                                                setSortType(prev => (prev === "asc" ? "desc" : "asc"));
                                                                setSort("type");
                                                            }}
                                                            role="button"
                                                        >
                                                            <div>{t("finance.entry.type")}</div>
                                                            {sort === "type" && sortType === "desc" && (
                                                                <Icon
                                                                    name="chevron-down"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                            {sort === "type" && sortType === "asc" && (
                                                                <Icon
                                                                    name="chevron-up"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                        </div>
                                                        <Form.Select
                                                            value={filterType}
                                                            onChange={(e: any) => setFilterType(e.target.value)}
                                                        >
                                                            <option value="">{t("select")}</option>
                                                            <option value={FinanceEntryType.IN}>
                                                                {t("finance.entry.type.in")}
                                                            </option>
                                                            <option value={FinanceEntryType.OUT}>
                                                                {t("finance.entry.type.out")}
                                                            </option>
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </Table.ColHeader>
                                            <Table.ColHeader>
                                                <div className="flex-row no-margins">
                                                    <div className="flex-column no-margins">
                                                        <div
                                                            className="sortable-column"
                                                            title={t("click-to-sort")}
                                                            onClick={() => {
                                                                setSortDescription(prev =>
                                                                    prev === "asc" ? "desc" : "asc"
                                                                );
                                                                setSort("description");
                                                            }}
                                                            role="button"
                                                        >
                                                            <div>{t("finance.entry.description")}</div>
                                                            {sort === "description" && sortDescription === "desc" && (
                                                                <Icon
                                                                    name="chevron-down"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                            {sort === "description" && sortDescription === "asc" && (
                                                                <Icon
                                                                    name="chevron-up"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                        </div>
                                                        <Form.Input
                                                            value={filterDescription}
                                                            onChange={(e: any) => setFilterDescription(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Table.ColHeader>
                                            <Table.ColHeader>
                                                <div className="flex-row no-margins">
                                                    <div className="flex-column no-margins">
                                                        <div
                                                            className="sortable-column"
                                                            title={t("click-to-sort")}
                                                            onClick={() => {
                                                                setSortAmount(prev =>
                                                                    prev === "asc" ? "desc" : "asc"
                                                                );
                                                                setSort("description");
                                                            }}
                                                            role="button"
                                                        >
                                                            <div>{t("finance.entry.amount")}</div>
                                                            {sort === "amount" && sortAmount === "desc" && (
                                                                <Icon
                                                                    name="chevron-down"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                            {sort === "amount" && sortAmount === "asc" && (
                                                                <Icon
                                                                    name="chevron-up"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                        </div>
                                                        <Form.Input
                                                            value={filterAmount}
                                                            onChange={(e: any) => setFilterAmount(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Table.ColHeader>
                                            <Table.ColHeader>
                                                <div className="flex-row no-margins">
                                                    <div className="flex-column no-margins">
                                                        <div
                                                            className="sortable-column"
                                                            title={t("click-to-sort")}
                                                            onClick={() => {
                                                                setSortEstimatedDate(prev =>
                                                                    prev === "asc" ? "desc" : "asc"
                                                                );
                                                                setSort("estimatedDate");
                                                            }}
                                                            role="button"
                                                        >
                                                            <div>{t("finance.entry.estimated-date")}</div>
                                                            {sort === "estimatedDate" &&
                                                                sortEstimatedDate === "desc" && (
                                                                    <Icon
                                                                        name="chevron-down"
                                                                        style={{ fontWeight: "bold" }}
                                                                    />
                                                                )}
                                                            {sort === "estimatedDate" &&
                                                                sortEstimatedDate === "asc" && (
                                                                    <Icon
                                                                        name="chevron-up"
                                                                        style={{ fontWeight: "bold" }}
                                                                    />
                                                                )}
                                                        </div>
                                                        <Form.Input
                                                            value={filterEstimatedDate}
                                                            onChange={(e: any) =>
                                                                setFilterEstimatedDate(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Table.ColHeader>
                                            <Table.ColHeader style={{ width: "15%" }}>
                                                <div className="flex-row no-margins">
                                                    <div className="flex-column no-margins">
                                                        <div
                                                            className="sortable-column"
                                                            title={t("click-to-sort")}
                                                            onClick={() => {
                                                                setSortStatus(prev =>
                                                                    prev === "asc" ? "desc" : "asc"
                                                                );
                                                                setSort("status");
                                                            }}
                                                            role="button"
                                                        >
                                                            <div>{t("finance.entry.status")}</div>
                                                            {sort === "status" && sortStatus === "desc" && (
                                                                <Icon
                                                                    name="chevron-down"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                            {sort === "status" && sortStatus === "asc" && (
                                                                <Icon
                                                                    name="chevron-up"
                                                                    style={{ fontWeight: "bold" }}
                                                                />
                                                            )}
                                                        </div>
                                                        <Form.Select
                                                            value={filterStatus}
                                                            onChange={(e: any) => setFilterStatus(e.target.value)}
                                                        >
                                                            <option value="">{t("select")}</option>
                                                            <option value={FinanceEntryStatus.PENDING}>
                                                                {t("finance.entry.status.pending")}
                                                            </option>
                                                            <option value={FinanceEntryStatus.PENDING_PAYMENT}>
                                                                {t("finance.entry.status.pendingPayment")}
                                                            </option>
                                                            <option value={FinanceEntryStatus.PENDING_NF}>
                                                                {t("finance.entry.status.pendingNF")}
                                                            </option>
                                                            <option value={FinanceEntryStatus.FINISHED}>
                                                                {t("finance.entry.status.finished")}
                                                            </option>
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </Table.ColHeader>
                                            <Table.ColHeader style={{ width: "70px" }}>
                                                {t("finance.entry.actions")}
                                            </Table.ColHeader>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {entries.map((entry, index) => (
                                            <Table.Row key={entry._id} className="clickable-row">
                                                <Table.Col
                                                    onClick={() => {
                                                        setFocusedEntry(entry);
                                                        setShowDetails(true);
                                                    }}
                                                >
                                                    {t(
                                                        `finance.entry.type.${
                                                            entry.type === FinanceEntryType.IN ? "in" : "out"
                                                        }`
                                                    )}
                                                </Table.Col>
                                                <Table.Col
                                                    onClick={() => {
                                                        setFocusedEntry(entry);
                                                        setShowDetails(true);
                                                    }}
                                                >
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionInType.ARTIST_PAYMENT &&
                                                        t("finance.entry.observation.artist-payment")}
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionInType.PAYMENT_EXTERNAL_CUSTOMERS &&
                                                        t("finance.entry.observation.payment-external-customers")}
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionInType.OTHER_ENTRY &&
                                                        t("finance.entry.observation.other-entry")}
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionOutType.PAYMENT_ARTIST_PF &&
                                                        t("finance.entry.observation.payment-artist-pf")}
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionOutType.PAYMENT_ARTIST_PJ &&
                                                        t("finance.entry.observation.payment-artist-pj")}
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionOutType.OPERACIONAL_COSTS &&
                                                        t("finance.entry.observation.operacional-costs")}
                                                    {Number(entry.description) ===
                                                        FinanceDescriptionOutType.OTHER_OUT &&
                                                        t("finance.entry.observation.other-out")}
                                                </Table.Col>
                                                <Table.Col
                                                    onClick={() => {
                                                        setFocusedEntry(entry);
                                                        setShowDetails(true);
                                                    }}
                                                >
                                                    {formatCurrency(entry.amount)}
                                                </Table.Col>
                                                <Table.Col
                                                    onClick={() => {
                                                        setFocusedEntry(entry);
                                                        setShowDetails(true);
                                                    }}
                                                >
                                                    {entry.estimatedDate
                                                        ? formatDate(new Date(entry.estimatedDate))
                                                        : "-"}
                                                </Table.Col>
                                                <Table.Col
                                                    onClick={() => {
                                                        setFocusedEntry(entry);
                                                        setShowDetails(true);
                                                    }}
                                                >
                                                    {entry.status === FinanceEntryStatus.PENDING &&
                                                        t("finance.entry.status.pending")}
                                                    {entry.status === FinanceEntryStatus.PENDING_NF &&
                                                        t("finance.entry.status.pendingNF")}
                                                    {entry.status === FinanceEntryStatus.PENDING_PAYMENT &&
                                                        t("finance.entry.status.pendingPayment")}
                                                    {entry.status === FinanceEntryStatus.FINISHED &&
                                                        t("finance.entry.status.finished")}
                                                </Table.Col>
                                                <Table.Col style={{ width: "70px", cursor: "auto" }}>
                                                    <Dropdown
                                                        className="custom-dropdown"
                                                        color="primary"
                                                        isNavLink
                                                        triggerClassName="pr-0 leading-none"
                                                        triggerContent={<Icon name="more-vertical" />}
                                                        position="bottom-end"
                                                        arrow={true}
                                                        arrowPosition="right"
                                                        toggle={false}
                                                        itemsObject={[
                                                            {
                                                                icon: "edit-3",
                                                                value: t("finance.entry.menu.change-status"),
                                                                onClick: () => {
                                                                    setFocusedEntry(entry);
                                                                    setOpenEditStatus(true);
                                                                }
                                                            },
                                                            {
                                                                icon: "x-circle",
                                                                value: t("finance.entry.menu.delete"),
                                                                onClick: () => {
                                                                    setFocusedEntry(entry);
                                                                    setOpenDelete(true);
                                                                }
                                                            },
                                                            {
                                                                icon: "edit-3",
                                                                value: t("finance.entry.menu.edit-entry"),
                                                                onClick: () => {
                                                                    setFocusedEntry(entry);
                                                                    setShowDetails(true);
                                                                }
                                                            },
                                                            {
                                                                icon: "download",
                                                                value: t("finance.entry.menu.download-fiscal-note"),
                                                                className: !entry.fiscalNote ? "not-visible" : "",
                                                                onClick: () => {
                                                                    const fileId = entry.fiscalNote!.split("/").pop();
                                                                    loadingStore.triggerLoading();
                                                                    if (fileId) {
                                                                        getProtectedFile(fileId)
                                                                            .then(blob => {
                                                                                loadingStore.stopLoading();
                                                                                return download(blob, fileId);
                                                                            })
                                                                            .catch(() => {
                                                                                loadingStore.stopLoading();
                                                                                toast.error(t("error.downloading"));
                                                                            });
                                                                    } else {
                                                                        loadingStore.stopLoading();
                                                                        toast.error(t("error.downloading"));
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                icon: "x-circle",
                                                                value: t("finance.entry.menu.remove-fiscal-note"),
                                                                className: !entry.fiscalNote ? "not-visible" : "",
                                                                onClick: () => {
                                                                    setFocusedEntry(entry);
                                                                    setOpenDeleteFiscalNote(true);
                                                                }
                                                            }
                                                        ]}
                                                    />
                                                </Table.Col>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </>
        );
    })
);
