import { Session, Finance, FinanceEntry } from "../models";
import { get, post, del, put, postMultiPart, putMultiPart } from "./request.service";
import { config } from "../utils/constants";

declare type Option<T> = T | undefined;

export async function getFinances(session?: Session): Promise<Finance[]> {
    return get(`${config.url}/finance/all`, session);
}

export async function newFinance(finance: Finance): Promise<Finance> {
    return post(`${config.url}/finance/new`, finance);
}

export async function deleteFinance(finance: Finance): Promise<Finance> {
    return del(`${config.url}/finance/${finance._id}/delete`);
}

export async function newEntry(
    finance: Finance,
    fiscalNote: Option<File>,
    entry: Partial<FinanceEntry>
): Promise<Finance> {
    const formData = new FormData();
    formData.append("financeDetailsRequest", JSON.stringify(entry));
    if (fiscalNote) {
        formData.append("file", fiscalNote);
    }
    return postMultiPart(`${config.url}/finance/${finance._id}/entry`, formData);
}

export async function editEntry(
    finance: Finance,
    fiscalNote: Option<File>,
    entry: Partial<FinanceEntry>
): Promise<Finance> {
    const formData = new FormData();
    formData.append("financeDetailsRequest", JSON.stringify(entry));
    if (fiscalNote) {
        formData.append("file", fiscalNote);
    }
    return postMultiPart(`${config.url}/finance/${finance._id}/entry/${entry._id}`, formData);
}

export async function editStatus(finance: Finance, entry: FinanceEntry, status: number): Promise<Finance> {
    return put(`${config.url}/finance/${finance._id}/entry/${entry._id}/status`, { status });
}

export async function removeEntry(finance: Finance, entry: FinanceEntry): Promise<Finance> {
    return del(`${config.url}/finance/${finance._id}/entry/${entry._id}`);
}

export async function removeEntryFiscalNote(finance: Finance, entry: FinanceEntry): Promise<Finance> {
    return del(`${config.url}/finance/${finance._id}/entry/${entry._id}/fiscal-note`);
}
