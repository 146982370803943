import { action, makeObservable, observable } from "mobx";
import { Segment } from "../models";

export class SegmentsStore {
    @observable.deep segments: Segment[] = [] as Segment[];

    constructor() {
        makeObservable(this);
    }

    @action setSegments(segments: Segment[]) {
        this.segments = segments;
    }

    @action updateSegment(segment: Segment) {
        const index = this.segments.findIndex(c => c._id === segment._id);
        if (index > -1) {
            this.segments[index] = segment;
        }
    }

    @action getSegmentById(id: string): Segment | undefined {
        return this.segments.find(c => c._id === id);
    }
}
