import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { useTranslation } from "react-i18next";
import { Button, Form } from "tabler-react";
import { GeneralInfo } from "./profile-sections/GeneralInfo";
import { ContractData } from "./profile-sections/ContractData";
import { PersonalInfo } from "./profile-sections/PersonalInfo";
import { SocialNetworks } from "./profile-sections/SocialNetworks";
import { Skills } from "./profile-sections/Skills";
import { Videos } from "./profile-sections/Videos";
import { Pictures } from "./profile-sections/Pictures";
import { TalentCompanyData } from "./profile-sections/TalentCompanyData";
import { Career } from "./profile-sections/Career";
import { Bio } from "./profile-sections/Bio";
import { finalizeEditing, getProfileDetails } from "../services/profile.service";
import { toast } from "react-toastify";
import { StatusEnum } from "../models";
import { Popup } from "./Popup";
import { isMobile } from "../utils/utils";

export const Profile = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [isReady, setIsReady] = useState(false);
        const [editable, setEditable] = useState(false);
        const [open, setOpen] = useState(false);
        const [pendingSections, setPendingSections] = useState<string[]>([]);
        const {
            sessionStore,
            profileStore,
            routingStore: { push },
            loadingStore
        } = props.rootStore!;

        useEffect(() => {
            const profile = profileStore.focusedProfile;
            if (
                !profile?._id ||
                !profile?.artisticName ||
                !profile?.bio ||
                !profile?.career ||
                !profile?.contractData ||
                !profile?.personalInfo ||
                !profile?.pictures ||
                profile.pictures.length === 0 ||
                !profile?.skills ||
                !profile?.socialNetworks ||
                !profile.talentCompany ||
                profile.talentCompany.ownCompany == undefined ||
                (profile?.talentCompany?.ownCompany === true &&
                    (!profile.talentCompany.commercialRegisterNumber || !profile.talentCompany?.companyName)) ||
                !profile?.fullName ||
                !profile?.photo ||
                !profile?.birthDate ||
                !profile?.citizenship ||
                (profile.citizenship === "BR" && !profile?.birthState) ||
                !profile?.gender ||
                !profile?.phoneCellphone ||
                !profile?.phoneOther ||
                !profile?.presentationVideo
            ) {
                setIsReady(false);
                const pending = [];
                if (
                    !profile?._id ||
                    !profile?.artisticName ||
                    !profile?.fullName ||
                    !profile?.birthDate ||
                    !profile?.citizenship ||
                    (profile.citizenship === "BR" && !profile?.birthState) ||
                    !profile?.gender ||
                    !profile?.phoneCellphone ||
                    !profile?.phoneOther
                ) {
                    pending.push("profile.general_info");
                }
                if (!profile.bio) {
                    pending.push("Bio");
                }
                if (!profile.career) {
                    pending.push("profile.career");
                }
                if (!profile.contractData || !profile.contractData.idCard) {
                    pending.push("profile.contract_data");
                }
                if (!profile.personalInfo) {
                    pending.push("profile.characteristics");
                }
                if (!profile.pictures || profile.pictures.length === 0) {
                    pending.push("profile.photos");
                }
                if (!profile.skills) {
                    pending.push("profile.skills.label");
                }
                if (!profile.socialNetworks) {
                    pending.push("profile.social_networks");
                }
                if (
                    !profile.talentCompany ||
                    profile.talentCompany.ownCompany == undefined ||
                    (profile.talentCompany?.ownCompany === true &&
                        (!profile.talentCompany.companyName || !profile.talentCompany.commercialRegisterNumber))
                ) {
                    pending.push("profile.talent_company_data");
                }
                if (!profile.presentationVideo) {
                    pending.push("profile.videos");
                }
                setPendingSections(pending);
            } else {
                setPendingSections([]);
                setIsReady(true);
            }
            if (profile.status === StatusEnum.EDITING || profile.status === StatusEnum.ACTIVE) {
                setEditable(true);
            }
        }, [profileStore.focusedProfile]);

        useEffect(() => {
            loadingStore.triggerLoading();
            getProfileDetails()
                .then(profile => {
                    profileStore.updateProfile(profile);
                    sessionStore.updateProfile(profile);
                    loadingStore.stopLoading();
                })
                .catch(response => {
                    if (response.status === 401) {
                        profileStore.clearCurrentSession();
                        sessionStore.clearCurrentSession();
                        push("/login");
                    } else {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    }
                });
            // }
        }, []);

        const onSubmit = () => {
            setOpen(false);
            loadingStore.triggerLoading();
            finalizeEditing()
                .then(profile => {
                    profileStore.updateProfile(profile);
                    sessionStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    setEditable(false);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                    setOpen(true);
                });
        };
        return (
            <>
                {open && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setOpen(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.send_profile")}</Form.Group>
                        <Button color="success" icon="check" onClick={onSubmit}>
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setOpen(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                <div className="flex-table">
                    <div className="flex-row">
                        <div className="flex-column">
                            <PersonalInfo />
                            <SocialNetworks />
                            <Pictures />
                            <Videos />
                            <Skills />
                        </div>
                        <div className="flex-column">
                            <GeneralInfo />
                            <ContractData />
                            <TalentCompanyData />
                            <Career />
                            <Bio />
                            {profileStore.focusedProfile.status === StatusEnum.EDITING &&
                                (sessionStore.isArtist || sessionStore.isBooker) && (
                                    <>
                                        <Button
                                            color="primary"
                                            disabled={!isReady || !editable}
                                            onClick={() => setOpen(true)}
                                        >
                                            {t("profile.finish_register")}
                                        </Button>
                                        {editable && pendingSections.length > 0 && (
                                            <span className="warning-profile">
                                                {t("error.pending_sections")}{" "}
                                                {pendingSections
                                                    .map(k => t(k))
                                                    .sort()
                                                    .join(", ")}
                                            </span>
                                        )}
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </>
        );
    })
);
