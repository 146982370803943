import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, Card, Form, Header, Table } from "tabler-react";
import { Segment, StatusEnum, ValidContract } from "../../models";
import { updatePartialInfo, updatePartialInfoAdmin } from "../../services/profile.service";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { getSegments } from "../../services/segments.service";
import { formatMonthYear } from "../../utils/formats";
import { setDate } from "../../services/util.service";
import { Popup } from "../Popup";
import { v4 as uuid } from "uuid";
import { isMobile } from "../../utils/utils";

export const Career = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [extraInfoEditable, setExtraInfoEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const [segments, setSegments] = useState<Segment[]>([]);
        const { profileStore, loadingStore, segmentsStore, sessionStore } = props.rootStore!;
        const [showActiveContract, setShowActiveContract] = useState(false);
        const [validContracts, setValidContracts] = useState<ValidContract[]>([]);
        const [focusedContract, setFocusedContract] = useState<ValidContract | undefined>(undefined);
        const onSubmit = async (values: any, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            if (sessionStore.isSameAsFocused) {
                updatePartialInfo("career", {
                    anyValidContract: values.any_valid_contract === "yes",
                    doYouPlayExtra: values.do_you_play_extra === "yes",
                    howManyAgenciesBesidesKozmos: values.how_many_agencies_besides_kozmos,
                    validContracts:
                        values.any_valid_contract === "yes"
                            ? validContracts.map((vc: ValidContract) => {
                                  const parsedDate = (vc.startDate as string).split("/");
                                  let startDate;
                                  if (parsedDate.length === 2) {
                                      startDate = setDate(1, Number(parsedDate[0]) - 1, Number(parsedDate[1]));
                                  }
                                  return {
                                      ...vc,
                                      exclusivity: vc.exclusivity === "yes",
                                      startDate: startDate ? startDate : ""
                                  };
                              }) ?? []
                            : []
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else if ((sessionStore.isDirector || sessionStore.isBooker) && profileStore.focusedProfile._id) {
                updatePartialInfoAdmin(profileStore.focusedProfile._id, "career", {
                    anyValidContract: values.any_valid_contract === "yes",
                    doYouPlayExtra: values.do_you_play_extra === "yes",
                    howManyAgenciesBesidesKozmos: values.how_many_agencies_besides_kozmos,
                    validContracts:
                        values.any_valid_contract === "yes"
                            ? validContracts.map((vc: ValidContract) => {
                                  const parsedDate = (vc.startDate as string).split("/");
                                  let startDate;
                                  if (parsedDate.length === 2) {
                                      startDate = setDate(1, Number(parsedDate[0]) - 1, Number(parsedDate[1]));
                                  }
                                  return {
                                      ...vc,
                                      exclusivity: vc.exclusivity === "yes",
                                      startDate: startDate ? startDate : ""
                                  };
                              }) ?? []
                            : []
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        setExtraInfoEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            }
        };

        const onSubmitActiveContract = (values: any) => {
            if (!focusedContract) {
                setValidContracts(prev => {
                    const newContract = values;
                    if (values.exclusivity === "no") {
                        newContract.startDate = "";
                        newContract.duration = "";
                        newContract.agency = "";
                    }
                    prev.push({
                        uuid: uuid(),
                        ...newContract
                    });
                    return [...prev];
                });
            } else {
                setValidContracts(prev => {
                    const find = validContracts.find(c => c.uuid === focusedContract.uuid);

                    if (find) {
                        find.segment = values.segment;
                        find.exclusivity = values.exclusivity;
                        find.startDate = values.startDate;
                        find.duration = values.duration;
                        find.agency = values.agency;
                        find.obs = values.obs;
                        return [...prev];
                    }
                    return prev;
                });
            }
            setShowActiveContract(false);
        };

        const removeContract = useCallback(
            (e: any, index: number) => {
                e.preventDefault();
                e.stopPropagation();
                setValidContracts(prev => {
                    const newArray = [...prev];
                    newArray.splice(index, 1);
                    return [...newArray];
                });
            },
            [setValidContracts]
        );

        useEffect(() => {
            if (segmentsStore.segments.length === 0) {
                loadingStore.triggerLoading();
                getSegments()
                    .then(segments => {
                        segmentsStore.setSegments(segments);
                        setSegments(segments);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                setSegments(segmentsStore.segments);
            }
        }, []);

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
            setValidContracts(
                profileStore.focusedProfile.career?.validContracts.map(vc => ({
                    ...vc,
                    exclusivity: (vc.exclusivity as boolean) ? "yes" : "no",
                    startDate: vc.startDate ? formatMonthYear(new Date(vc.startDate)) : ""
                })) ?? ([] as ValidContract[])
            );
        }, [profileStore.focusedProfile]);

        return (
            <>
                {showActiveContract && (
                    <Popup
                        title={t("profile.active_contract")}
                        onClose={() => setShowActiveContract(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                segment: focusedContract?.segment ?? "",
                                exclusivity: focusedContract?.exclusivity ?? "",
                                startDate: focusedContract?.startDate ?? "",
                                duration: focusedContract?.duration ?? "",
                                agency: focusedContract?.agency ?? "",
                                obs: focusedContract?.obs ?? ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.segment) {
                                    errors.segment = t("form.errors.required");
                                }
                                if (!values.exclusivity) {
                                    errors.exclusivity = t("form.errors.required");
                                }
                                if (!values.agency) {
                                    errors.agency = t("form.errors.required");
                                }
                                if (!values.startDate) {
                                    errors.startDate = t("form.errors.required");
                                }
                                if (!values.duration) {
                                    errors.duration = t("form.errors.required");
                                } else if (values.duration.toString().includes("_")) {
                                    const value = values.duration.toString().replace("_", "");
                                    if (value.length === 1) {
                                        values.duration = "0" + value;
                                    }
                                }
                                return errors;
                            }}
                            onSubmit={onSubmitActiveContract}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.valid_contract.segment")}</Form.Label>
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="segment"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.segment}
                                                    value={values.segment}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {segments.map((v, indexSegment) => (
                                                        <option key={`segment_${indexSegment}`} value={v._id}>
                                                            {
                                                                v[
                                                                    `description${t(
                                                                        "languageCode"
                                                                    ).toUpperCase()}` as keyof Segment
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.valid_contract.exclusivity")}</Form.Label>
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="exclusivity"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.exclusivity}
                                                    value={values.exclusivity as string}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    <option value="yes">{t("yes")}</option>
                                                    <option value="no">{t("no")}</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.valid_contract.start_date")}</Form.Label>
                                                <Form.MaskedInput
                                                    {...({
                                                        placeholder: "12/2020",
                                                        mask: [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                                                    } as any)}
                                                    disabled={!editable}
                                                    name="startDate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={errors && !!errors.startDate}
                                                    value={values.startDate as string}
                                                />
                                                {errors.startDate && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.startDate}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.valid_contract.duration")}</Form.Label>
                                                <Form.MaskedInput
                                                    {...({
                                                        placeholder: "60",
                                                        mask: [/\d/, /\d/]
                                                    } as any)}
                                                    disabled={!editable}
                                                    name="duration"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={errors && !!errors.duration}
                                                    value={values.duration as string}
                                                />
                                                {errors.duration && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.duration}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.valid_contract.agency")}</Form.Label>
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="agency"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.agency}
                                                    value={values.agency}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    <option value="kozmos">KOZMOS</option>
                                                    <option value="other">{t("other")}</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.valid_contract.obs")}</Form.Label>
                                                <Form.Textarea
                                                    rows={2}
                                                    disabled={!editable}
                                                    name="obs"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.obs}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {editable && (
                                        <Form.Footer>
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        </Form.Footer>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Card>
                    <Card.Body>
                        {sessionStore.isSameAsFocused && canEdit && !editable && (
                            <div className="flex-row justify-content-end">
                                <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                    {t("edit")}
                                </Button>
                            </div>
                        )}
                        <Header.H3>{t("profile.career")}</Header.H3>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                do_you_play_extra: profileStore.focusedProfile.career?.doYouPlayExtra ? "yes" : "no",
                                how_many_agencies_besides_kozmos:
                                    profileStore.focusedProfile.career?.howManyAgenciesBesidesKozmos,
                                any_valid_contract:
                                    profileStore.focusedProfile.career?.anyValidContract !== undefined
                                        ? profileStore.focusedProfile.career.anyValidContract
                                            ? "yes"
                                            : "no"
                                        : ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.do_you_play_extra) {
                                    errors.do_you_play_extra = t("form.errors.required");
                                }
                                if (!values.how_many_agencies_besides_kozmos) {
                                    errors.how_many_agencies_besides_kozmos = t("form.errors.required");
                                }
                                if (!values.any_valid_contract) {
                                    errors.any_valid_contract = t("form.errors.required");
                                }
                                return errors;
                            }}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.do_you_play_extra")}</Form.Label>
                                                <Form.Radio
                                                    isInline
                                                    label={t("yes")}
                                                    name="do_you_play_extra"
                                                    value="yes"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.do_you_play_extra}
                                                    checked={values && values.do_you_play_extra === "yes"}
                                                />
                                                <Form.Radio
                                                    isInline
                                                    label={t("no")}
                                                    name="do_you_play_extra"
                                                    value="no"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.do_you_play_extra}
                                                    checked={values && values.do_you_play_extra === "no"}
                                                />
                                                {errors.do_you_play_extra && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.do_you_play_extra}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.how_many_agencies_besides_kozmos")}</Form.Label>
                                                <Form.Radio
                                                    label={t("profile.other_agencies.only_kozmos")}
                                                    name="how_many_agencies_besides_kozmos"
                                                    value="only_kozmos"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.how_many_agencies_besides_kozmos}
                                                    checked={
                                                        values &&
                                                        values.how_many_agencies_besides_kozmos === "only_kozmos"
                                                    }
                                                />
                                                <Form.Radio
                                                    label={t("profile.other_agencies.one")}
                                                    name="how_many_agencies_besides_kozmos"
                                                    value="one"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.how_many_agencies_besides_kozmos}
                                                    checked={
                                                        values && values.how_many_agencies_besides_kozmos === "one"
                                                    }
                                                />
                                                <Form.Radio
                                                    label={t("profile.other_agencies.two")}
                                                    name="how_many_agencies_besides_kozmos"
                                                    value="two"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.how_many_agencies_besides_kozmos}
                                                    checked={
                                                        values && values.how_many_agencies_besides_kozmos === "two"
                                                    }
                                                />
                                                <Form.Radio
                                                    label={t("profile.other_agencies.three_plus")}
                                                    name="how_many_agencies_besides_kozmos"
                                                    value="three_plus"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.how_many_agencies_besides_kozmos}
                                                    checked={
                                                        values &&
                                                        values.how_many_agencies_besides_kozmos === "three_plus"
                                                    }
                                                />
                                                {errors.how_many_agencies_besides_kozmos && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.how_many_agencies_besides_kozmos}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.any_valid_contract")}</Form.Label>
                                                <Form.Radio
                                                    isInline
                                                    label={t("yes")}
                                                    name="any_valid_contract"
                                                    value="yes"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.any_valid_contract}
                                                    checked={values && values.any_valid_contract === "yes"}
                                                />
                                                <Form.Radio
                                                    isInline
                                                    label={t("no")}
                                                    name="any_valid_contract"
                                                    value="no"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.any_valid_contract}
                                                    checked={values && values.any_valid_contract === "no"}
                                                />
                                                {errors.any_valid_contract && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.any_valid_contract}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {values.any_valid_contract === "yes" && (
                                        <div className="flex-row">
                                            <Table>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.ColHeader>
                                                            {t("profile.valid_contract.segment")}
                                                        </Table.ColHeader>
                                                        <Table.ColHeader>
                                                            {t("profile.valid_contract.start_date")}
                                                        </Table.ColHeader>
                                                        <Table.ColHeader>
                                                            {t("profile.valid_contract.duration")}
                                                        </Table.ColHeader>
                                                        <Table.ColHeader style={{ width: "75px", textAlign: "right" }}>
                                                            <Button
                                                                type="button"
                                                                icon="plus"
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    backgroundColor: "transparent",
                                                                    border: "unset",
                                                                    minWidth: "1px",
                                                                    boxShadow: "unset",
                                                                    marginLeft: "10px"
                                                                }}
                                                                disabled={!editable}
                                                                onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setFocusedContract(undefined);
                                                                    setShowActiveContract(true);
                                                                }}
                                                            />
                                                        </Table.ColHeader>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {validContracts.map((validContract, index) => (
                                                        <Table.Row key={`row_${index}`}>
                                                            <Table.Col>
                                                                {
                                                                    segmentsStore.getSegmentById(
                                                                        validContract.segment
                                                                    )?.[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Segment
                                                                    ]
                                                                }
                                                            </Table.Col>
                                                            <Table.Col>{validContract.startDate ?? "-"}</Table.Col>
                                                            <Table.Col>{validContract.duration ?? "-"}</Table.Col>
                                                            <Table.Col>
                                                                <Button
                                                                    type="button"
                                                                    icon="edit"
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        backgroundColor: "transparent",
                                                                        border: "unset",
                                                                        minWidth: "1px",
                                                                        boxShadow: "unset",
                                                                        marginLeft: "10px"
                                                                    }}
                                                                    onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        setFocusedContract(validContract);
                                                                        setShowActiveContract(true);
                                                                    }}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    icon="x"
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        backgroundColor: "transparent",
                                                                        border: "unset",
                                                                        minWidth: "1px",
                                                                        boxShadow: "unset",
                                                                        marginLeft: "10px"
                                                                    }}
                                                                    disabled={!editable}
                                                                    onClick={(e: any) => removeContract(e, index)}
                                                                />
                                                            </Table.Col>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )}
                                    {sessionStore.isSameAsFocused && canEdit && (
                                        <Form.Footer>
                                            {editable && (
                                                <Button type="submit" color="primary" icon="save">
                                                    {t("save")}
                                                </Button>
                                            )}
                                            {editable && (
                                                <Button
                                                    color="danger"
                                                    icon="x"
                                                    onClick={(e: any) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setEditable(false);
                                                        setValidContracts(
                                                            profileStore.focusedProfile.career?.validContracts.map(
                                                                vc => ({
                                                                    ...vc,
                                                                    exclusivity: (vc.exclusivity as boolean)
                                                                        ? "yes"
                                                                        : "no",
                                                                    startDate: vc.startDate
                                                                        ? formatMonthYear(new Date(vc.startDate))
                                                                        : ""
                                                                })
                                                            ) ?? ([] as ValidContract[])
                                                        );
                                                        resetForm();
                                                    }}
                                                >
                                                    {t("cancel")}
                                                </Button>
                                            )}
                                        </Form.Footer>
                                    )}
                                    {!sessionStore.isSameAsFocused &&
                                        (sessionStore.isDirector || sessionStore.isBooker) && (
                                            <Form.Footer>
                                                {!extraInfoEditable && (
                                                    <Button
                                                        color="primary"
                                                        icon="edit"
                                                        onClick={() => {
                                                            setExtraInfoEditable(true);
                                                            setEditable(true);
                                                        }}
                                                    >
                                                        {t("edit")}
                                                    </Button>
                                                )}
                                                {extraInfoEditable && (
                                                    <Button type="submit" color="primary" icon="save">
                                                        {t("save")}
                                                    </Button>
                                                )}
                                                {extraInfoEditable && (
                                                    <Button
                                                        color="danger"
                                                        icon="x"
                                                        onClick={(e: any) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setEditable(false);
                                                            setExtraInfoEditable(false);
                                                            setValidContracts(
                                                                profileStore.focusedProfile.career?.validContracts.map(
                                                                    vc => ({
                                                                        ...vc,
                                                                        exclusivity: (vc.exclusivity as boolean)
                                                                            ? "yes"
                                                                            : "no",
                                                                        startDate: vc.startDate
                                                                            ? formatMonthYear(new Date(vc.startDate))
                                                                            : ""
                                                                    })
                                                                ) ?? ([] as ValidContract[])
                                                            );
                                                            resetForm();
                                                        }}
                                                    >
                                                        {t("cancel")}
                                                    </Button>
                                                )}
                                            </Form.Footer>
                                        )}
                                </form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </>
        );
    })
);
