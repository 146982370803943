import { Session } from "../models";

export function setCurrentSession(session?: Session): void {
    if (session) localStorage.setItem("gaia-session", JSON.stringify(session));
    else localStorage.removeItem("gaia-session");
}

export function getCurrentSession(): Session | null {
    const session = localStorage.getItem("gaia-session");
    if (session) {
        return JSON.parse(session) as Session;
    }
    return null;
}
