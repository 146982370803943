import { inject, observer } from "mobx-react";
import React from "react";
import { Card } from "tabler-react";
import { useTranslation } from "react-i18next";
import { Stores } from "../models/generic";
import { FirstLogin } from "./FirstLogin";
import { ContractAgreement } from "./ContractAgreement";

export const Home = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const { sessionStore } = props.rootStore!;
        return (
            <Card>
                <Card.Body>
                    {(sessionStore.session.profile.lgpdAgreedAt || sessionStore.session.profile.termsAgreedAt) &&
                        t("welcome")}
                    {(!sessionStore.session.profile.updatedAt ||
                        (!Boolean(sessionStore.session.profile.termsAgreedAt) &&
                            !Boolean(sessionStore.session.profile.lgpdAgreedAt))) && <FirstLogin />}
                    {(!sessionStore.session.profile.updatedAt ||
                        !Boolean(sessionStore.session.profile.termsAndConditionsAgreedAt)) && <ContractAgreement />}
                </Card.Body>
            </Card>
        );
    })
);
