import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { getCastings } from "../services/casting.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Popup } from "./Popup";
import { isMobile } from "../utils/utils";
import { Formik } from "formik";
import { CastingStatus, Finance as FinanceType, FinanceEntryStatus, FinanceStatus } from "../models";
import { Button, Dropdown, Form, Icon, Table } from "tabler-react";
import { formatDate } from "../utils/formats";
import { deleteFinance, getFinances, newFinance } from "../services/finance.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { InfiniteBody } from "./utils/InfiniteBody";

const limit = 30;

export const Finance = inject("rootStore")(
    observer((props: Stores): ReactElement | null => {
        const {
            loadingStore,
            financeStore,
            castingStore,
            routingStore: { push }
        } = props.rootStore!;
        const [openNew, setOpenNew] = useState(false);
        const [openDelete, setOpenDelete] = useState(false);
        const { t } = useTranslation();

        const [sort, setSort] = useState("value");
        const [sortIdentification, setSortIdentification] = useState<"asc" | "desc">("asc");
        const [sortCreatedAt, setSortCreatedAt] = useState<"asc" | "desc">("asc");
        const [sortStatus, setSortStatus] = useState<"asc" | "desc">("asc");
        const [filterIdentification, setFilterIdentification] = useState("");
        const [filterDate, setFilterDate] = useState("");
        const [filterStatus, setFilterStatus] = useState<number>(-1);
        const [focusedFinanced, setFocusedFinanced] = useState<FinanceType | undefined>();
        const [page, setPage] = useState(1);

        useEffect(() => {
            if (castingStore.castings.length === 0 || financeStore.finances.length === 0) {
                loadingStore.triggerLoading();
                Promise.all([getFinances(), getCastings()])
                    .then(([finances, castings]) => {
                        financeStore.setFinances(finances);
                        financeStore.sortByCreatedAt("desc");
                        castingStore.setCastings(castings);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        const totalFinances = useMemo(() => {
            if (filterDate || filterIdentification || filterStatus !== undefined)
                return financeStore.filter(filterDate, filterIdentification, filterStatus, castingStore.castings)
                    .length;
            return financeStore.finances.length;
        }, [filterDate, filterIdentification, filterStatus, financeStore.finances, castingStore.castings]);

        const findLabel = useCallback(
            (id: string) => `Casting: ${castingStore.castings.find(c => c._id == id)?.title}`,
            [castingStore.castings]
        );

        const finances = useMemo(() => {
            if (filterIdentification || filterDate || filterStatus !== undefined) {
                return financeStore.filter(filterDate, filterIdentification, filterStatus, castingStore.castings);
            }
            return financeStore.finances.slice(0, limit * page);
        }, [financeStore.finances, castingStore.castings, filterIdentification, filterDate, filterStatus]);

        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            setOpenNew(false);
            newFinance(
                values.attach === "yes"
                    ? { casting: values.casting, status: FinanceStatus.IN_PROGRESS }
                    : { identification: values.identification, status: FinanceStatus.IN_PROGRESS }
            )
                .then(finance => {
                    loadingStore.stopLoading();
                    financeStore.addFinance(finance);
                    toast.success(t("success.created-finance"));
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error(t("error.creating-finance"));
                });
        };

        const removeFinance = async () => {
            if (focusedFinanced) {
                loadingStore.triggerLoading();
                setOpenDelete(false);
                setFocusedFinanced(undefined);
                deleteFinance(focusedFinanced)
                    .then(() => {
                        loadingStore.stopLoading();
                        financeStore.removeFinance(focusedFinanced);
                        toast.success(t("success.finance-deleted"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.deleting-finance"));
                    });
            }
        };

        return (
            <>
                {openNew && (
                    <Popup
                        title={t("finance.new")}
                        onClose={() => {
                            setOpenNew(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                attach: "yes",
                                casting: "",
                                identification: ""
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (values.attach === "yes" && !values.casting) {
                                    errors.casting = t("form.errors.required");
                                }
                                if (values.attach === "no" && !values.identification) {
                                    errors.identification = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("finance.attach-to-casting")}</Form.Label>
                                            <Form.Radio
                                                label={t("yes")}
                                                name="attach"
                                                value="yes"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.attach === "yes"}
                                            />
                                            <Form.Radio
                                                isInline
                                                label={t("no")}
                                                name="attach"
                                                value="no"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.attach === "no"}
                                            />
                                        </div>
                                    </div>
                                    {values.attach === "yes" && (
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <Form.Label>{t("finance.casting")}</Form.Label>
                                                <Form.Select
                                                    name="casting"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && (values.casting as string)}
                                                    error={errors && errors.casting}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {castingStore.castings
                                                        .slice()
                                                        .filter(c => c.status === CastingStatus.STARTED)
                                                        .sort((a, b) => {
                                                            if (!a.title || !b.title) {
                                                                return 0;
                                                            }
                                                            return a.title.localeCompare(b.title);
                                                        })
                                                        .map(attr => (
                                                            <option value={attr._id} key={`attr_${attr._id}`}>
                                                                {attr.title}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </div>
                                        </div>
                                    )}
                                    {values.attach === "no" && (
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <Form.Label>{t("finance.identification")}</Form.Label>
                                                <Form.Input
                                                    name="identification"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.identification}
                                                    error={errors && errors.identification}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openDelete && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setOpenDelete(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.delete_finance")}</Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={async () => {
                                setOpenDelete(false);
                                await removeFinance();
                            }}
                        >
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setOpenDelete(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                <Button
                    color="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                        setOpenNew(true);
                    }}
                >
                    {t("finance.new")}
                </Button>
                <Table className="table-striped">
                    <Table.Header className="infinite-loading-header">
                        <Table.Row className="centered-row">
                            <Table.ColHeader style={{ width: "32%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortCreatedAt(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    financeStore.sortByCreatedAt(newSort);
                                                    return newSort;
                                                });
                                                setSort("date");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("finance.date")}</div>
                                            {sort === "date" && sortCreatedAt === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "date" && sortCreatedAt === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Input
                                            value={filterDate}
                                            onChange={(e: any) => setFilterDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "34%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortIdentification(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    financeStore.sortByIdentification(newSort, castingStore.castings);
                                                    return newSort;
                                                });
                                                setSort("identification");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("finance.identification")}</div>
                                            {sort === "identification" && sortIdentification === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "identification" && sortIdentification === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Input
                                            value={filterIdentification}
                                            onChange={(e: any) => setFilterIdentification(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div className="sortable-column">
                                            <div>{t("finance.pendencies")}</div>
                                        </div>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "15%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortStatus(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    financeStore.sortByStatus(newSort);
                                                    return newSort;
                                                });
                                                setSort("status");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("finance.status")}</div>
                                            {sort === "status" && sortStatus === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "status" && sortStatus === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Select
                                            value={filterStatus}
                                            onChange={(e: any) => setFilterStatus(Number(e.target.value))}
                                        >
                                            <option value={-1}>{t("select")}</option>
                                            <option value={FinanceStatus.IN_PROGRESS}>
                                                {t("finance.status.in-progress")}
                                            </option>
                                            <option value={FinanceStatus.FINISHED}>
                                                {t("finance.status.finished")}
                                            </option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "70px" }}>{t("finance.actions")}</Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <InfiniteBody hasMoreItems={limit * page < totalFinances} isInfinite setPage={setPage}>
                        {finances.map(finance => {
                            return (
                                <Table.Row key={finance._id} className="clickable-row">
                                    <Table.Col
                                        onClick={() => {
                                            financeStore.setFocusedFinance(finance);
                                            push("/finance-details");
                                        }}
                                    >
                                        {finance.createdAt ? formatDate(new Date(finance.createdAt)) : "-"}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "35.5%" }}
                                        onClick={() => {
                                            financeStore.setFocusedFinance(finance);
                                            push("/finance-details");
                                        }}
                                    >
                                        {finance.casting ? findLabel(finance.casting) : finance.identification}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "12%" }}
                                        onClick={() => {
                                            financeStore.setFocusedFinance(finance);
                                            push("/finance-details");
                                        }}
                                    >
                                        <span className="validation">
                                            {finance.entries?.some(e => e.status === FinanceEntryStatus.PENDING_NF) ? (
                                                <FontAwesomeIcon
                                                    icon={faFileInvoiceDollar}
                                                    title={t("finance.entry.status.pendingNF")}
                                                />
                                            ) : (
                                                finance.entries?.some(
                                                    e => e.status === FinanceEntryStatus.PENDING_PAYMENT
                                                ) && (
                                                    <FontAwesomeIcon
                                                        icon={faMoneyBill}
                                                        title={t("finance.entry.status.pendingPayment")}
                                                    />
                                                )
                                            )}
                                        </span>
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "14%" }}
                                        onClick={() => {
                                            financeStore.setFocusedFinance(finance);
                                            push("/finance-details");
                                        }}
                                    >
                                        {t(
                                            `finance.status.${
                                                finance.status === FinanceStatus.IN_PROGRESS
                                                    ? "in-progress"
                                                    : "finished"
                                            }`
                                        )}
                                    </Table.Col>
                                    <Table.Col style={{ width: "70px", cursor: "auto" }}>
                                        <Dropdown
                                            className="custom-dropdown"
                                            color="primary"
                                            isNavLink
                                            triggerClassName="pr-0 leading-none"
                                            triggerContent={<Icon name="more-vertical" />}
                                            position="bottom-end"
                                            arrow={true}
                                            arrowPosition="right"
                                            toggle={false}
                                            itemsObject={[
                                                {
                                                    icon: "x-circle",
                                                    value: t("finance.menu.delete"),
                                                    onClick: () => {
                                                        setFocusedFinanced(finance);
                                                        setOpenDelete(true);
                                                    }
                                                }
                                            ]}
                                        />
                                    </Table.Col>
                                </Table.Row>
                            );
                        })}
                    </InfiniteBody>
                </Table>
            </>
        );
    })
);
