import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "tabler-react";
import React from "react";
import { CastingAttributeManagement } from "./data-management/CastingAttributeManagement";
import { SkillsManagement } from "./data-management/SkillsManagement";
import { TranslationsManagement } from "./data-management/TranslationsManagement";
import { ClassesManagement } from "./data-management/ClassesManagement";

export function Data(): ReactElement {
    const [filter, setFilter] = useState("attribute");
    const [showEN, setShowEN] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className="flex-row">
                <div className="flex-column" style={{ flex: "none" }}>
                    <Form.Radio
                        label={t("casting.attributes")}
                        onChange={() => setFilter("attribute")}
                        checked={filter === "attribute"}
                    />
                </div>
                <div className="flex-column" style={{ flex: "none" }}>
                    <Form.Radio
                        label={t("casting.classes")}
                        onChange={() => setFilter("classes")}
                        checked={filter === "classes"}
                    />
                </div>
                <div className="flex-column" style={{ flex: "none" }}>
                    <Form.Radio
                        label={t("casting.skills")}
                        onChange={() => setFilter("skills")}
                        checked={filter === "skills"}
                    />
                </div>
                <div className="flex-column" style={{ flex: "none" }}>
                    <Form.Radio
                        label={t("translations")}
                        onChange={() => setFilter("translations")}
                        checked={filter === "translations"}
                    />
                </div>
            </div>
            {(filter === "attribute" || filter === "status") && <CastingAttributeManagement filter={filter} />}
            {filter === "classes" && <ClassesManagement />}
            {filter === "skills" && <SkillsManagement showExtraLanguages={showEN} setShowExtraLanguages={setShowEN} />}
            {filter === "translations" && <TranslationsManagement />}
        </>
    );
}
