import { cuilValidator, setDate, validateFiscalCode } from "../services/util.service";
import { Profile } from "../models";
import { countries } from "./countries";
import { subYears } from "date-fns";

export function formatCurrency(amount: number = 0): string {
    const maximumFractionDigits = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
    }).resolvedOptions().maximumFractionDigits;
    return amount.toLocaleString("pt-BR", { maximumFractionDigits, minimumFractionDigits: 2 });
}

export function formatCPF(cpf: string) {
    const unformated = cpf.replace(/\D/g, "");
    return `${unformated.substr(0, 3)}.${unformated.substr(3, 3)}.${unformated.substr(6, 3)}-${unformated.substr(
        9,
        2
    )}`;
}

export function formatCUIL(cuil: string) {
    const unformated = cuil.replace(/\D/g, "");
    return `${unformated.substr(0, 2)}-${unformated.substr(2, 8)}-${unformated.substr(10, 1)}`;
}

export function formatFiscalCode(fiscalCode: string) {
    if (validateFiscalCode(fiscalCode)) {
        return formatCPF(fiscalCode);
    }
    if (cuilValidator(fiscalCode)) {
        return formatCUIL(fiscalCode);
    }
    throw Error("Invalid fiscal code");
}

export function parseCurrency(amount: string): number {
    return Number(amount.replace(".", "").replace(",", "."));
}

export function formatDate(date: Date, datetime = false): string {
    return datetime ? date.toLocaleString("pt-BR") : date.toLocaleDateString("pt-BR");
}

export function parseDate(date: string): Date {
    const [day, month, year] = date.split("/");
    if (!day || !month || !year) {
        return new Date();
    }
    return setDate(Number(day), Number(month) - 1, Number(year));
}

export function formatMonthYear(date: Date): string {
    const formattedDate = date.toLocaleDateString("pt-BR");
    const [, month, year] = formattedDate.split("/");
    return `${month}/${year}`;
}

export function formatArtistName(artistName?: string): string {
    return artistName?.toLowerCase().replace(/ /g, "-") ?? "";
}

export function removeAgeFromDate(age: number) {
    return subYears(new Date(), age);
}

export function formatContractData(profile: Profile, t: (key: string) => string): string {
    const companyData =
        profile.talentCompany && profile.talentCompany.ownCompany
            ? `

DADOS EMPRESA

Razão Social: ${profile.talentCompany?.companyName}
Endereço: ${profile.talentCompany?.address}
Bairro: ${profile.talentCompany?.neighborhood}
Cidade: ${profile.talentCompany?.city}
Estado: ${profile.talentCompany?.state}
CEP: ${profile.talentCompany?.zipcode}
CNPJ: ${profile.talentCompany?.commercialRegisterNumber}
Banco: ${profile.talentCompany?.bank}
Agência: ${profile.talentCompany?.agency}
Conta Corrente: ${profile.talentCompany?.account}
Representante legal: ${profile.talentCompany?.legalRepresentative}
RG: ${profile.talentCompany?.idCard}
CPF: ${profile.talentCompany?.fiscalCode}
Email: ${profile.talentCompany?.email}`
            : "";

    const bankData = `Banco: ${profile.contractData?.bank}
Agência: ${profile.contractData?.agency}
Conta com dígito: ${profile.contractData?.account}
Tipo de conta: ${profile.contractData?.accountType ? t(`profile.account_type.${profile.contractData.accountType}`) : ""}
PIX - Tipo: ${profile.contractData?.pixType ? t(`profile.pix_type.${profile.contractData.pixType}`) : ""}
Chave PIX: ${profile.contractData?.pixKey ?? ""}`;
    return `
DADOS ARTISTA

Nome completo: ${profile.fullName}
Endereço: ${profile.contractData?.address}
Complemento: ${profile.contractData?.additionalAddress}
Bairro: ${profile.contractData?.neighborhood}
Cidade: ${profile.contractData?.city}
Estado: ${profile.contractData?.state}
CEP: ${profile.contractData?.zipcode}
CPF: ${profile.contractData?.fiscalCode}
RG: ${profile.contractData?.idCard}
Data de Nascimento: ${profile.birthDate ? formatDate(new Date(profile.birthDate)) : "-"}
DRT: ${profile.contractData?.drt}
Profissão: ${profile.contractData?.profession}
Telefone: ${profile.phoneCellphone}
Nacionalidade: ${profile.citizenship ? countries.find(c => c.code === profile.citizenship)?.name ?? "-" : "-"}
Estado Civil: ${t(`profile.marital_status.${profile.contractData?.maritalStatus}`)}
E-mail: ${profile.email}
${bankData}
${companyData}`;
}
