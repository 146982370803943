import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { getCastingAttributes, getCastingById, getCastingProfile, getCastings } from "../services/casting.service";
import { formatCurrency, formatMonthYear } from "../utils/formats";
import { Tab, TabContainer } from "./Tabs";
import { useTranslation } from "react-i18next";
import { Information } from "./casting-sections/Information";
import { getProfiles, getUsers } from "../services/profile.service";
import { toast } from "react-toastify";
import { Artists } from "./casting-sections/Artists";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "tabler-react";
import { CastingProfile, CastingProfileBonus, CastingProfileStatus } from "../models";
import { ExportToCsv } from "export-to-csv";

export const CastingDetails = inject("rootStore")(
    observer((props: Stores) => {
        const {
            castingStore,
            castingAttributeStore,
            loadingStore,
            profileStore,
            routingStore: { push, location }
        } = props.rootStore!;

        const { t } = useTranslation();

        useEffect(() => {
            if (
                castingAttributeStore.attributes.length === 0 ||
                castingStore.castings.length === 0 ||
                castingStore.users.length === 0
            ) {
                loadingStore.triggerLoading();
                Promise.all([getCastingAttributes(), getCastings(), getUsers()])
                    .then(([attributes, castings, users]) => {
                        castingAttributeStore.setAttributes(attributes);
                        castingStore.setCastings(castings);
                        castingStore.setUsers(users);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        useEffect(() => {
            if (profileStore.profiles.length === 0) {
                loadingStore.triggerLoading();
                getProfiles()
                    .then(profiles => {
                        profileStore.setProfiles(profiles);
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                    });
            }
        }, []);

        useEffect(() => {
            if (!castingStore.focusedCasting._id) {
                if (location.search) {
                    const id = new URLSearchParams(location.search).get("id");
                    if (id) {
                        loadingStore.triggerLoading();
                        getCastingById(id)
                            .then(casting => {
                                castingStore.setFocusedCasting(casting);
                                push("/casting-details");
                            })
                            .catch(() => {
                                loadingStore.stopLoading();
                                push("/casting");
                            });
                    } else {
                        push("/casting");
                    }
                } else {
                    push("/casting");
                }
            }
        }, []);

        useEffect(() => {
            if (castingAttributeStore.attributes.length === 0 || castingStore.users.length === 0) {
                loadingStore.triggerLoading();
                Promise.all([getCastingAttributes(), getUsers()])
                    .then(([attributes, users]) => {
                        castingAttributeStore.setAttributes(attributes);
                        castingStore.setUsers(users);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, [castingStore.focusedCasting]);

        const updateAllData = async () => {
            loadingStore.triggerLoading();
            try {
                const attributes = await getCastingAttributes();
                castingAttributeStore.setAttributes(attributes);
                const castings = await getCastings();
                castingStore.setCastings(castings);
                const users = await getUsers();
                castingStore.setUsers(users);
                const profiles = await getProfiles();
                profileStore.setProfiles(profiles);

                if (castingStore.focusedCasting._id) {
                    const focusedCasting = await getCastingById(castingStore.focusedCasting._id);
                    castingStore.setFocusedCasting(focusedCasting);
                }

                if (castingStore.focusedCasting.profiles) {
                    const profiles = await Promise.all(
                        castingStore.focusedCasting.profiles.map(profile => getCastingProfile(profile))
                    );
                    castingStore.setFocusedCastingProfiles(profiles);
                }
                toast.success(t("success.updated"));
            } catch (e) {
                toast.error(t("error.loading"));
                loadingStore.stopLoading();
            }
        };

        const casting = useMemo(() => castingStore.focusedCasting, [castingStore.focusedCasting]);

        const findLabel = useCallback(
            (id: string) => castingAttributeStore.attributes.find(attr => attr._id == id)?.value,
            [castingAttributeStore.attributes]
        );

        const information = <Information />;
        const artists = useMemo(() => <Artists />, []);

        // Export methods
        const castingProfileBonus = [
            { value: "", label: t("select") },
            { value: CastingProfileBonus.BONUS, label: t("casting.profile.bonus") },
            { value: CastingProfileBonus.SINGLE_PAYMENT, label: t("casting.profile.single-payment") }
        ];
        const getProfileTitleComplement = (profile: CastingProfile): string => {
            const bonus =
                profile.bonus == CastingProfileBonus.BONUS
                    ? castingProfileBonus.find(bonus => bonus.value == CastingProfileBonus.BONUS)?.label
                    : "";
            return `R$${formatCurrency(Number(profile.price ?? 0))} ${bonus}`.trim();
        };
        const users = useMemo(() => castingStore.users.map(u => ({ label: u.artisticName, value: u._id })), [
            castingStore.users
        ]);
        const findUserName = (id: string) => users.find(u => u.value == id)?.label;
        const territories = useMemo(
            () => [
                { label: t("casting.territory.brazil"), value: "brazil" },
                { label: t("casting.territory.world"), value: "world" },
                { label: t("casting.territory.americas"), value: "americas" },
                { label: t("casting.territory.north-america"), value: "north-america" },
                { label: t("casting.territory.south-america"), value: "south-america" },
                { label: t("casting.territory.europe"), value: "europe" },
                { label: t("casting.territory.asia"), value: "asia" },
                { label: t("casting.territory.oceania"), value: "oceania" }
            ],
            []
        );
        const exportCsv = () => {
            const csvOptions = {
                filename: "campanha-blip",
                fieldSeparator: ";",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                headers: [
                    "telefone",
                    "nomeArtistico",
                    "cliente",
                    "segmento",
                    "exclusividade",
                    "territorio",
                    "midias",
                    "veiculacao",
                    "diariasFilmagem",
                    "diariasExtras",
                    "prevFilmagem",
                    "localTrabalho",
                    "cache",
                    "mei",
                    "agenteAssistente",
                    "status"
                ]
            };
            const csvExporter = new ExportToCsv(csvOptions);
            const data: any[] = [];
            castingStore.focusedCastingProfiles.map((profile, index) => {
                const cliente = castingStore.focusedCasting.clients?.map(s => findLabel(s))?.join("; ") ?? "";
                const segmento = castingStore.focusedCasting.segments?.map(s => findLabel(s)).join("; ") ?? "";
                const territorio =
                    territories.find(t => t.value === castingStore.focusedCasting.territory)?.label ?? "";
                const veiculacao = castingStore.focusedCasting.frequency ?? 0;
                const cache = getProfileTitleComplement(profile);
                const getStatus = (id: string) =>
                    t(
                        `casting.profile-status.${translateStatus(
                            profile.artists?.find(a => a.profile === id)?.status ?? CastingProfileStatus.PRE_SELECTED
                        )}`
                    );
                const agenteAssistente = castingStore.focusedCasting.assistants?.map(ass => findUserName(ass)) ?? "";
                const profiles =
                    profile.artists
                        ?.map(p => profileStore.profiles.find(pr => pr._id == p.profile)!)
                        .map(p => ({
                            telefone: p.phoneCellphone?.replace(/\D/g, "") ?? "",
                            nomeArtistico: p.artisticName,
                            cliente,
                            segmento,
                            exclusividade: "",
                            territorio,
                            midias: "",
                            veiculacao,
                            diariasFilmagem: "",
                            diariasExtras: "",
                            prevFilmagem: "",
                            localTrabalho: "",
                            cache,
                            mei: "",
                            agenteAssistente,
                            status: getStatus(String(p._id))
                        })) ?? [];
                data.push(...profiles);
            });
            csvExporter.generateCsv(data);
        };

        return (
            <div className="casting-details">
                <h2 style={{ justifyContent: "space-between" }}>
                    <span style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
                        {casting.clients?.map(v => findLabel(v)).join(" | ")} - {casting.title} -{" "}
                        {casting.createdAt ? formatMonthYear(new Date(casting.createdAt)) : ""}
                    </span>
                    <FontAwesomeIcon
                        icon={faSync}
                        style={{ justifySelf: "flex-end", alignSelf: "center", cursor: "pointer" }}
                        size="sm"
                        onClick={updateAllData}
                        title="Atualizar dados"
                    />
                </h2>
                <Button type="submit" color="primary" style={{ float: "right" }} onClick={exportCsv}>
                    Exportar CSV
                </Button>
                <TabContainer defaultTab={1}>
                    <Tab index={0} title={t("casting.information")}>
                        {information}
                    </Tab>
                    <Tab index={1} title={t("casting.artists")}>
                        {artists}
                    </Tab>
                    <Tab index={2} title={t("casting.demands")}>
                        Test 3
                    </Tab>
                </TabContainer>
            </div>
        );
    })
);

function translateStatus(status: CastingProfileStatus): string {
    switch (status) {
        case CastingProfileStatus.APPROVED:
            return "approved";
        case CastingProfileStatus.CONSULTED:
            return "consulted";
        case CastingProfileStatus.BACKUP:
            return "backup";
        case CastingProfileStatus.EDITED:
            return "edited";
        case CastingProfileStatus.SELECTED:
            return "selected";
        case CastingProfileStatus.SENT:
            return "sent";
        case CastingProfileStatus.PRE_SELECTED:
            return "pre-selected";
        case CastingProfileStatus.DISREGARDED:
            return "disregarded";
    }
}
