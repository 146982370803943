import { Button, Card, Form, Header } from "tabler-react";
import { Formik, FormikHelpers } from "formik";
import { states } from "../../utils/countries";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updatePartialInfo } from "../../services/profile.service";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { cuilValidator, validateFiscalCode } from "../../services/util.service";
import { StatusEnum } from "../../models";
import InputMask from "react-input-mask";
import { formatFiscalCode } from "../../utils/formats";

export const ContractData = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const { profileStore, loadingStore, sessionStore } = props.rootStore!;
        const maritalStatus = [
            { label: t("profile.marital_status.single"), value: "single" },
            { label: t("profile.marital_status.married"), value: "married" },
            { label: t("profile.marital_status.divorced"), value: "divorced" },
            { label: t("profile.marital_status.widow"), value: "widow" }
        ];
        const [mask, setMask] = useState<Array<RegExp | string>>([
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ]);

        const checkFiscalCode = (fiscalCode: string) => {
            if (cuilValidator(fiscalCode)) {
                setMask([/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/]);
            } else if (validateFiscalCode(fiscalCode)) {
                setMask([/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]);
            } else {
                setMask([/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]);
            }
        };

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
        }, [profileStore.focusedProfile]);

        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            updatePartialInfo("contractData", {
                maritalStatus: values.marital_status,
                idCard: values.id_card,
                fiscalCode: formatFiscalCode(values.fiscal_code),
                address: values.address,
                additionalAddress: values.additional_address,
                neighborhood: values.neighborhood,
                zipcode: values.zipcode,
                city: values.city,
                state: values.state,
                drt: values.drt,
                profession: values.profession,
                bank: values.bank,
                agency: values.agency,
                account: values.account,
                accountType: values.account_type,
                pixType: values.pix_type,
                pixKey: values.pix_key
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    setEditable(false);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };
        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.contract_data")}</Header.H3>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            marital_status: profileStore.focusedProfile.contractData?.maritalStatus,
                            id_card: profileStore.focusedProfile.contractData?.idCard,
                            fiscal_code: profileStore.focusedProfile.contractData?.fiscalCode,
                            address: profileStore.focusedProfile.contractData?.address,
                            additional_address: profileStore.focusedProfile.contractData?.additionalAddress,
                            neighborhood: profileStore.focusedProfile.contractData?.neighborhood,
                            zipcode: profileStore.focusedProfile.contractData?.zipcode,
                            city: profileStore.focusedProfile.contractData?.city,
                            state: profileStore.focusedProfile.contractData?.state,
                            drt: profileStore.focusedProfile.contractData?.drt,
                            profession: profileStore.focusedProfile.contractData?.profession,
                            bank: profileStore.focusedProfile.contractData?.bank,
                            agency: profileStore.focusedProfile.contractData?.agency,
                            account: profileStore.focusedProfile.contractData?.account,
                            account_type: profileStore.focusedProfile.contractData?.accountType,
                            pix_type: profileStore.focusedProfile.contractData?.pixType,
                            pix_key: profileStore.focusedProfile.contractData?.pixKey
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (values.fiscal_code) {
                                checkFiscalCode(values.fiscal_code);
                            }
                            if (!values.marital_status) {
                                errors.marital_status = t("form.errors.required");
                            }
                            if (!values.id_card) {
                                errors.id_card = t("form.errors.required");
                            }
                            if (!values.fiscal_code) {
                                errors.fiscal_code = t("form.errors.required");
                            } else if (!validateFiscalCode(values.fiscal_code) && !cuilValidator(values.fiscal_code)) {
                                errors.fiscal_code = t("form.errors.invalid");
                            }
                            if (!values.address) {
                                errors.address = t("form.errors.required");
                            }
                            if (!values.neighborhood) {
                                errors.neighborhood = t("form.errors.required");
                            }
                            if (!values.zipcode) {
                                errors.zipcode = t("form.errors.required");
                            }
                            if (!values.city) {
                                errors.city = t("form.errors.required");
                            }
                            if (!values.state) {
                                errors.state = t("form.errors.required");
                            }
                            if (!values.profession) {
                                errors.profession = t("form.errors.required");
                            }
                            if (!values.bank) {
                                errors.bank = t("form.errors.required");
                            }
                            if (!values.agency || values.agency === "____" || values.agency.includes("_")) {
                                errors.agency = t("form.errors.required");
                            }
                            if (!values.account) {
                                errors.account = t("form.errors.required");
                            } else if (!values.account.includes("-")) {
                                values.account =
                                    values.account.substr(0, values.account.length - 1) +
                                    "-" +
                                    values.account.substr(-1, 1);
                            } else if (
                                values.account.includes("-") &&
                                values.account.charAt(values.account.indexOf("-") + 1) === ""
                            ) {
                                errors.account = t("form.errors.invalid");
                            }
                            if (!values.account_type) {
                                errors.account_type = t("form.errors.required");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.marital_status.label")}</Form.Label>
                                            {maritalStatus.map(m => (
                                                <Form.Radio
                                                    key={`marital_status_${m.value}`}
                                                    isInline
                                                    label={m.label}
                                                    name="marital_status"
                                                    value={m.value}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.marital_status === m.value}
                                                    invalid={!!errors.marital_status}
                                                />
                                            ))}
                                            {errors.marital_status && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.marital_status}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.id_card")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="id_card"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.id_card}
                                                error={errors && errors.id_card}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.fiscal_code")}</Form.Label>
                                            <Form.MaskedInput
                                                {...({
                                                    placeholder: "00000000000",
                                                    mask
                                                } as any)}
                                                disabled
                                                name="fiscal_code"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.fiscal_code}
                                                invalid={errors.fiscal_code}
                                            />
                                            {errors.fiscal_code && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.fiscal_code}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.address")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.address}
                                                error={errors && errors.address}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.additional_address")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="additional_address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.additional_address}
                                                error={errors && errors.additional_address}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.neighborhood")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="neighborhood"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.neighborhood}
                                                error={errors && errors.neighborhood}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.zipcode")}</Form.Label>
                                            <Form.MaskedInput
                                                {...({
                                                    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
                                                    placeholder: "00000-000"
                                                } as any)}
                                                disabled={!editable}
                                                name="zipcode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.zipcode}
                                                invalid={errors.zipcode}
                                            />
                                            {errors.zipcode && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.zipcode}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.city")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="city"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.city}
                                                error={errors && errors.city}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.state")}</Form.Label>
                                            <Form.Select
                                                disabled={!editable}
                                                name="state"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.state}
                                                error={errors && errors.state}
                                            >
                                                <option value="">{t("select")}</option>
                                                {states.map(state => (
                                                    <option key={`state_${state.code}`} value={state.code}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.drt")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="drt"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.drt}
                                                error={errors && errors.drt}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.profession")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="profession"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.profession}
                                                error={errors && errors.profession}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.bank")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="bank"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.bank}
                                                error={errors && errors.bank}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.agency")}</Form.Label>
                                            <InputMask
                                                name="agency"
                                                mask="9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.agency}
                                                disabled={!editable}
                                                readOnly={!editable}
                                                placeholder="0000"
                                                className={!editable ? "disabled input-disabled" : ""}
                                            >
                                                {(inputProps: any) => (
                                                    <Form.Input error={errors && errors.agency} {...inputProps} />
                                                )}
                                            </InputMask>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.account")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="account"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.account}
                                                error={errors && errors.account}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.account_type.label")}</Form.Label>
                                            <Form.Radio
                                                isInline
                                                label={t("profile.account_type.checking")}
                                                name="account_type"
                                                value="checking"
                                                disabled={!editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.account_type === "checking"}
                                                invalid={!!errors.account_type}
                                            />
                                            <Form.Radio
                                                isInline
                                                label={t("profile.account_type.savings")}
                                                name="account_type"
                                                value="savings"
                                                disabled={!editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.account_type === "savings"}
                                                invalid={!!errors.account_type}
                                            />
                                            {errors.account_type && (
                                                <span className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.account_type}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.pix_type.label")}</Form.Label>
                                            <Form.Select
                                                disabled={!editable}
                                                name="pix_type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && (values.pix_type as string)}
                                                error={errors && errors.pix_type}
                                            >
                                                <option value="">{t("select")}</option>
                                                <option value="fiscalCode">{t("profile.pix_type.fiscalCode")}</option>
                                                <option value="email">{t("profile.pix_type.email")}</option>
                                                <option value="phone">{t("profile.pix_type.phone")}</option>
                                                <option value="random">{t("profile.pix_type.random")}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("profile.pix_key")}</Form.Label>
                                            <Form.Input
                                                disabled={!editable}
                                                name="pix_key"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.pix_key}
                                                error={errors && errors.pix_key}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                {sessionStore.isSameAsFocused && canEdit && (
                                    <Form.Footer>
                                        {editable && (
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                        )}
                                        {editable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={() => {
                                                    setEditable(false);
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
