import {
    Casting,
    CastingAttribute,
    CastingAttributeType,
    CastingProfile,
    CastingProfilePriceNumber,
    CastingProfileStatus,
    Session
} from "../models";
import { get, post, put, del } from "./request.service";
import { config } from "../utils/constants";

export async function getCastingTypes(session?: Session): Promise<CastingAttributeType[]> {
    return get(`${config.url}/casting/types`, session);
}

export async function getCastingAttributes(session?: Session): Promise<CastingAttribute[]> {
    return get(`${config.url}/casting/attributes`, session);
}

export async function newCastingAttribute(attribute: CastingAttribute): Promise<CastingAttribute> {
    return post(`${config.url}/casting/new-attribute`, attribute);
}

export async function updateCastingAttribute(id: string, attribute: CastingAttribute): Promise<CastingAttribute> {
    return put(`${config.url}/casting/${id}/update-attribute`, attribute);
}

export async function newCasting(casting: Casting): Promise<Casting> {
    return post(`${config.url}/casting/new`, casting);
}

export async function getCastings(session?: Session): Promise<Casting[]> {
    return get(`${config.url}/casting/all`, session);
}

export async function getCastingById(id: string, session?: Session): Promise<Casting> {
    return get(`${config.url}/casting/${id}/details`, session);
}

export async function updateCasting(id: string, body: Partial<Casting>): Promise<Casting> {
    return put(`${config.url}/casting/${id}/update`, body);
}

export async function deleteCasting(id: string): Promise<boolean> {
    return del(`${config.url}/casting/${id}/delete`);
}

export async function updateCastingProfile(
    id: string,
    body: Partial<CastingProfilePriceNumber>
): Promise<CastingProfile> {
    return put(`${config.url}/casting-profile/${id}/update`, body);
}

export async function newCastingProfile(
    profile: Partial<CastingProfilePriceNumber>,
    profileId: string
): Promise<CastingProfile> {
    return post(`${config.url}/casting/${profileId}/new-profile`, profile);
}

export async function getCastingProfile(profileId: string): Promise<CastingProfile> {
    return get(`${config.url}/casting-profile/${profileId}/details`);
}

export async function addArtistToProfile(artists: string[], profileId: string): Promise<CastingProfile> {
    return put(`${config.url}/casting-profile/${profileId}/add-artist`, {
        artists: artists.map(id => ({
            profile: id,
            status: CastingProfileStatus.PRE_SELECTED
        }))
    });
}

export async function removeArtistsFromProfile(artists: string[], profileId: string): Promise<CastingProfile> {
    return put(`${config.url}/casting-profile/${profileId}/remove-artists`, {
        artists
    });
}

export async function removeProfile(id: string, profileId: string): Promise<Casting> {
    return del(`${config.url}/casting/${id}/remove-profile/${profileId}`);
}

export async function updateArtistStatus(
    artists: string[],
    profileId: string,
    status: CastingProfileStatus
): Promise<CastingProfile> {
    return put(`${config.url}/casting-profile/${profileId}/update-artists-status`, {
        artists: artists.map(id => ({
            profile: id,
            status
        }))
    });
}

export async function updateArtistComments(
    artist: string,
    profileId: string,
    comments?: string
): Promise<CastingProfile> {
    return put(`${config.url}/casting-profile/${profileId}/update-artists-comments`, {
        artist,
        comments
    });
}
