import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, Card, Form, Header } from "tabler-react";
import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { getSkills } from "../../services/skills.service";
import { toast } from "react-toastify";
import { Skill, StatusEnum } from "../../models";
import { updatePartialInfo, updatePartialInfoAdmin } from "../../services/profile.service";

export const Skills = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [extraInfoEditable, setExtraInfoEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const { profileStore, loadingStore, skillsStore, sessionStore } = props.rootStore!;
        const [skills, setSkills] = useState<Skill[]>([]);

        useEffect(() => {
            if (
                profileStore.focusedProfile &&
                (profileStore.focusedProfile.status === StatusEnum.VALIDATING ||
                    profileStore.focusedProfile.status === StatusEnum.INACTIVE)
            ) {
                setCanEdit(false);
            }
        }, [profileStore.focusedProfile]);

        const onSubmit = async (values: any, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            if (sessionStore.isSameAsFocused) {
                updatePartialInfo("skills", {
                    arts: values.arts ?? [],
                    dances: values.dances ?? [],
                    driversLicense: values.driversLicense ?? [],
                    idioms: values.idioms ?? [],
                    music: values.music ?? [],
                    sports: values.sports ?? [],
                    dramaticArts: values.dramaticArts ?? [],
                    circus: values.circus ?? []
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else if ((sessionStore.isDirector || sessionStore.isBooker) && profileStore.focusedProfile._id) {
                updatePartialInfoAdmin(profileStore.focusedProfile._id, "skills", {
                    arts: values.arts ?? [],
                    dances: values.dances ?? [],
                    driversLicense: values.driversLicense ?? [],
                    idioms: values.idioms ?? [],
                    music: values.music ?? [],
                    sports: values.sports ?? [],
                    dramaticArts: values.dramaticArts ?? [],
                    circus: values.circus ?? []
                })
                    .then(profile => {
                        profileStore.updateProfile(profile);
                        toast.success(t("success.updated"));
                        setEditable(false);
                        setExtraInfoEditable(false);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            }
        };

        useEffect(() => {
            if (skillsStore.skills.length === 0) {
                loadingStore.triggerLoading();
                getSkills()
                    .then(skills => {
                        skillsStore.setSkills(skills);
                        setSkills(skillsStore.skills);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                setSkills(skillsStore.skills);
            }
        }, []);

        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.skills.label")}</Header.H3>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            driversLicense: profileStore.focusedProfile.skills?.driversLicense ?? [],
                            idioms: profileStore.focusedProfile.skills?.idioms ?? [],
                            dances: profileStore.focusedProfile.skills?.dances ?? [],
                            music: profileStore.focusedProfile.skills?.music ?? [],
                            arts: profileStore.focusedProfile.skills?.arts ?? [],
                            sports: profileStore.focusedProfile.skills?.sports ?? [],
                            dramaticArts: profileStore.focusedProfile.skills?.dramaticArts ?? [],
                            circus: profileStore.focusedProfile.skills?.circus ?? []
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, handleChange, errors, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.drivers-license")}</Form.Label>
                                    {skills
                                        .filter(v => v.type === "driver-license")
                                        .map((v, index) => (
                                            <Form.Checkbox
                                                key={`driver_license_${index}`}
                                                isInline
                                                label={
                                                    v[`description${t("languageCode").toUpperCase()}` as keyof Skill]
                                                }
                                                name="driversLicense"
                                                value={v._id}
                                                disabled={!editable}
                                                onChange={handleChange}
                                                checked={!!values.driversLicense.find(d => d === v._id)}
                                                className={classNames({ disabled: !editable })}
                                            />
                                        ))}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.idioms")}</Form.Label>
                                    {skills
                                        .filter(v => v.type === "idiom")
                                        .map((v, index) => (
                                            <Form.Checkbox
                                                key={`idioms_${index}`}
                                                isInline
                                                label={
                                                    v[`description${t("languageCode").toUpperCase()}` as keyof Skill]
                                                }
                                                name="idioms"
                                                value={v._id}
                                                disabled={!editable}
                                                onChange={handleChange}
                                                checked={!!values.idioms.find(d => d === v._id)}
                                                className={classNames({ disabled: !editable })}
                                            />
                                        ))}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.dramatic-arts")}</Form.Label>
                                    <Form.SelectGroup canSelectMultiple pills>
                                        {skills
                                            .filter(v => v.type === "dramatic-art")
                                            .map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`dramatic-arts_${index}`}
                                                    label={
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                    name="dramaticArts"
                                                    value={v._id}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    checked={!!values.dramaticArts.find(d => d === v._id)}
                                                    className={classNames({ disabled: !editable })}
                                                />
                                            ))}
                                    </Form.SelectGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.dances")}</Form.Label>
                                    <Form.SelectGroup canSelectMultiple pills>
                                        {skills
                                            .filter(v => v.type === "dance")
                                            .map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`dances_${index}`}
                                                    label={
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                    name="dances"
                                                    value={v._id}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    checked={!!values.dances.find(d => d === v._id)}
                                                    className={classNames({ disabled: !editable })}
                                                />
                                            ))}
                                    </Form.SelectGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.music")}</Form.Label>
                                    <Form.SelectGroup canSelectMultiple pills>
                                        {skills
                                            .filter(v => v.type === "music")
                                            .map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`music_${index}`}
                                                    label={
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                    name="music"
                                                    value={v._id}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    checked={!!values.music.find(d => d === v._id)}
                                                    className={classNames({ disabled: !editable })}
                                                />
                                            ))}
                                    </Form.SelectGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.arts")}</Form.Label>
                                    <Form.SelectGroup canSelectMultiple pills>
                                        {skills
                                            .filter(v => v.type === "art")
                                            .map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`arts_${index}`}
                                                    label={
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                    name="arts"
                                                    value={v._id}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    checked={!!values.arts.find(d => d === v._id)}
                                                    className={classNames({ disabled: !editable })}
                                                />
                                            ))}
                                    </Form.SelectGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.sports")}</Form.Label>
                                    <Form.SelectGroup canSelectMultiple pills>
                                        {skills
                                            .filter(v => v.type === "sport")
                                            .map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`sports_${index}`}
                                                    label={
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                    name="sports"
                                                    value={v._id}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    checked={!!values.sports.find(d => d === v._id)}
                                                    className={classNames({ disabled: !editable })}
                                                />
                                            ))}
                                    </Form.SelectGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("profile.skills.circus")}</Form.Label>
                                    <Form.SelectGroup canSelectMultiple pills>
                                        {skills
                                            .filter(v => v.type === "circus")
                                            .map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`circus_${index}`}
                                                    label={
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                    name="circus"
                                                    value={v._id}
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    checked={!!values.circus.find(d => d === v._id)}
                                                    className={classNames({ disabled: !editable })}
                                                />
                                            ))}
                                    </Form.SelectGroup>
                                </Form.Group>
                                {sessionStore.isSameAsFocused && canEdit && (
                                    <Form.Footer>
                                        {editable && (
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                        )}
                                        {editable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditable(false);
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                                {!sessionStore.isSameAsFocused && (sessionStore.isDirector || sessionStore.isBooker) && (
                                    <Form.Footer>
                                        {!extraInfoEditable && (
                                            <Button
                                                color="primary"
                                                icon="edit"
                                                onClick={() => {
                                                    setExtraInfoEditable(true);
                                                    setEditable(true);
                                                }}
                                            >
                                                {t("edit")}
                                            </Button>
                                        )}
                                        {extraInfoEditable && (
                                            <Button type="submit" color="primary" icon="save">
                                                {t("save")}
                                            </Button>
                                        )}
                                        {extraInfoEditable && (
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditable(false);
                                                    setExtraInfoEditable(false);
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        )}
                                    </Form.Footer>
                                )}
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
