import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { Popup } from "../Popup";
import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { Profile } from "../../models";
import { ImageCropper } from "../ImageCropper";
import { CompositeImage } from "./CompositeImage";
import { Button } from "tabler-react";
import { createComposite } from "../../services/profile.service";
import { toast } from "react-toastify";
import { config } from "../../utils/constants";

interface CompositeGeneratorProps extends Stores {
    visible: boolean;
    onCancel: () => void;
    onSuccess: (profile: Profile) => void;
    profile?: Profile;
    style?: CSSProperties;
}

export const CompositeGenerator = inject("rootStore")(
    observer((props: CompositeGeneratorProps) => {
        const { t } = useTranslation();
        const { loadingStore, profileStore } = props.rootStore!;

        const [mainImage64, setMainImage64] = useState<string | undefined>(undefined);
        const [secondaryImage64_1, setSecondaryImage64_1] = useState<string | undefined>(undefined);
        const [secondaryImage64_2, setSecondaryImage64_2] = useState<string | undefined>(undefined);
        const [secondaryImage64_3, setSecondaryImage64_3] = useState<string | undefined>(undefined);
        const [secondaryImage64_4, setSecondaryImage64_4] = useState<string | undefined>(undefined);

        const [mainImage, setMainImage] = useState<Blob | undefined>(undefined);
        const [secondaryImage1, setSecondaryImage1] = useState<Blob | undefined>(undefined);
        const [secondaryImage2, setSecondaryImage2] = useState<Blob | undefined>(undefined);
        const [secondaryImage3, setSecondaryImage3] = useState<Blob | undefined>(undefined);
        const [secondaryImage4, setSecondaryImage4] = useState<Blob | undefined>(undefined);

        const [openMainImageResizer, setOpenMainImageResizer] = useState<boolean>(false);
        const [openSecondaryImageResizer1, setOpenSecondaryImageResizer1] = useState<boolean>(false);
        const [openSecondaryImageResizer2, setOpenSecondaryImageResizer2] = useState<boolean>(false);
        const [openSecondaryImageResizer3, setOpenSecondaryImageResizer3] = useState<boolean>(false);
        const [openSecondaryImageResizer4, setOpenSecondaryImageResizer4] = useState<boolean>(false);

        const images = [
            ...(props.profile?.photoOriginal ? [config.host + encodeURI(props.profile.photoOriginal)] : []),
            ...(props.profile?.pictures?.map(p => config.host + encodeURI(p)) ?? [])
        ];

        const onSubmit = () => {
            loadingStore.triggerLoading();
            createComposite(
                props.profile?._id!,
                mainImage!,
                secondaryImage1!,
                secondaryImage2!,
                secondaryImage3!,
                secondaryImage4!
            )
                .then(profile => {
                    profileStore.updateProfile(profile);
                    toast.success(t("success.composite-created"));
                    loadingStore.stopLoading();
                    setMainImage64(undefined);
                    setSecondaryImage64_1(undefined);
                    setSecondaryImage64_2(undefined);
                    setSecondaryImage64_3(undefined);
                    setSecondaryImage64_4(undefined);

                    setMainImage(undefined);
                    setSecondaryImage1(undefined);
                    setSecondaryImage2(undefined);
                    setSecondaryImage3(undefined);
                    setSecondaryImage4(undefined);

                    props.onSuccess(profile);
                })
                .catch(() => {
                    toast.error(t("error.creating-composite"));
                    loadingStore.stopLoading();
                });
        };

        return props.visible && !loadingStore.loading ? (
            <>
                <ImageCropper
                    aspectRatio={870 / 1260}
                    imageBase64={mainImage64}
                    onCancel={() => {
                        setMainImage64(undefined);
                        setMainImage(undefined);
                        setOpenMainImageResizer(false);
                    }}
                    onSuccess={base64 => {
                        setMainImage64(base64);
                        setOpenMainImageResizer(false);
                    }}
                    resize={false}
                    setImage={setMainImage}
                    visible={openMainImageResizer}
                />
                <ImageCropper
                    aspectRatio={410 / 614}
                    imageBase64={secondaryImage64_1}
                    onCancel={() => {
                        setSecondaryImage64_1(undefined);
                        setSecondaryImage1(undefined);
                        setOpenSecondaryImageResizer1(false);
                    }}
                    onSuccess={base64 => {
                        setSecondaryImage64_1(base64);
                        setOpenSecondaryImageResizer1(false);
                    }}
                    resize={false}
                    setImage={setSecondaryImage1}
                    visible={openSecondaryImageResizer1}
                />
                <ImageCropper
                    aspectRatio={410 / 614}
                    imageBase64={secondaryImage64_2}
                    onCancel={() => {
                        setSecondaryImage64_2(undefined);
                        setSecondaryImage2(undefined);
                        setOpenSecondaryImageResizer2(false);
                    }}
                    onSuccess={base64 => {
                        setSecondaryImage64_2(base64);
                        setOpenSecondaryImageResizer2(false);
                    }}
                    resize={false}
                    setImage={setSecondaryImage2}
                    visible={openSecondaryImageResizer2}
                />
                <ImageCropper
                    aspectRatio={410 / 614}
                    imageBase64={secondaryImage64_3}
                    onCancel={() => {
                        setSecondaryImage64_3(undefined);
                        setSecondaryImage3(undefined);
                        setOpenSecondaryImageResizer3(false);
                    }}
                    onSuccess={base64 => {
                        setSecondaryImage64_3(base64);
                        setOpenSecondaryImageResizer3(false);
                    }}
                    resize={false}
                    setImage={setSecondaryImage3}
                    visible={openSecondaryImageResizer3}
                />
                <ImageCropper
                    aspectRatio={410 / 614}
                    imageBase64={secondaryImage64_4}
                    onCancel={() => {
                        setSecondaryImage64_4(undefined);
                        setSecondaryImage4(undefined);
                        setOpenSecondaryImageResizer4(false);
                    }}
                    onSuccess={base64 => {
                        setSecondaryImage64_4(base64);
                        setOpenSecondaryImageResizer4(false);
                    }}
                    resize={false}
                    setImage={setSecondaryImage4}
                    visible={openSecondaryImageResizer4}
                />
                {!openMainImageResizer &&
                    !openSecondaryImageResizer1 &&
                    !openSecondaryImageResizer2 &&
                    !openSecondaryImageResizer3 &&
                    !openSecondaryImageResizer4 && (
                        <Popup
                            title={t("cast.composite-creation")}
                            onClose={props.onCancel}
                            style={{
                                width: "60vw",
                                ...props.style
                            }}
                        >
                            <div className="flex-row">
                                <div className="flex-column composite-title">{props.profile?.artisticName}</div>
                                <div className="flex-column">&nbsp;</div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-column main-image">
                                    <CompositeImage
                                        number={0}
                                        images={images}
                                        imageBase64={mainImage64}
                                        setImageBase64={setMainImage64}
                                        onFileLoaded={() => {
                                            setOpenMainImageResizer(true);
                                        }}
                                    />
                                </div>
                                <div className="flex-column" style={{ margin: 0 }}>
                                    <div className="flex-row">
                                        <div className="flex-column secondary-image">
                                            <CompositeImage
                                                number={1}
                                                images={images}
                                                imageBase64={secondaryImage64_1}
                                                setImageBase64={setSecondaryImage64_1}
                                                onFileLoaded={() => {
                                                    setOpenSecondaryImageResizer1(true);
                                                }}
                                            />
                                        </div>
                                        <div className="flex-column secondary-image">
                                            <CompositeImage
                                                number={2}
                                                images={images}
                                                imageBase64={secondaryImage64_2}
                                                setImageBase64={setSecondaryImage64_2}
                                                onFileLoaded={() => {
                                                    setOpenSecondaryImageResizer2(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column secondary-image">
                                            <CompositeImage
                                                number={3}
                                                images={images}
                                                imageBase64={secondaryImage64_3}
                                                setImageBase64={setSecondaryImage64_3}
                                                onFileLoaded={() => {
                                                    setOpenSecondaryImageResizer3(true);
                                                }}
                                            />
                                        </div>
                                        <div className="flex-column secondary-image">
                                            <CompositeImage
                                                number={4}
                                                images={images}
                                                imageBase64={secondaryImage64_4}
                                                setImageBase64={setSecondaryImage64_4}
                                                onFileLoaded={() => {
                                                    setOpenSecondaryImageResizer4(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-column">
                                    <div className="flex-row composite-info">
                                        <strong>nasc:</strong>
                                        <span className="data">
                                            {props.profile?.birthDate
                                                ? new Date(props.profile?.birthDate).getFullYear()
                                                : "0000"}
                                        </span>
                                        <strong>altura:</strong>
                                        <span className="data">
                                            {formatHeight(props.profile?.personalInfo?.height)}
                                        </span>
                                        <strong>man:</strong>
                                        <span className="data">
                                            {props.profile?.personalInfo?.clothingSize ?? "00"}
                                        </span>
                                        <strong>calç:</strong>
                                        <span className="data">{props.profile?.personalInfo?.footSize ?? "00"}</span>
                                    </div>
                                </div>
                                <div className="flex-column" style={{ paddingLeft: 45 }}>
                                    casting@kozmos.com.br
                                </div>
                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    icon="save"
                                    onClick={onSubmit}
                                    style={{
                                        marginTop: 5
                                    }}
                                    disabled={
                                        !mainImage ||
                                        !secondaryImage1 ||
                                        !secondaryImage2 ||
                                        !secondaryImage3 ||
                                        !secondaryImage4
                                    }
                                >
                                    {t("generate-composite")}
                                </Button>
                            </div>
                        </Popup>
                    )}
            </>
        ) : null;
    })
);

function formatHeight(height?: number): string {
    if (!height) {
        return "0.00";
    }
    return Number(height / 100).toFixed(2);
}
