import { Entity, EntityWithId, Role } from "./generic";

export interface Profile extends Entity, EntityWithId, GeneralInformation {
    role: Role;
    password: string;
    bio?: Bio;
    career?: Career;
    contractData?: ContractData;
    personalInfo?: PersonalInformation;
    pictures?: string[];
    skills?: Skills;
    socialNetworks?: SocialNetworks;
    talentCompany?: TalentCompanyData;
    presentationVideo?: string;
    scenesVideo?: string;
    videos?: string[];
    termsAgreedAt?: Date; //Deprecated, to be removed
    lgpdAgreedAt?: Date;
    termsAndConditionsAgreedAt?: Date;
    status: StatusEnum;
    composite?: string;
    compositeDate?: Date;
    exclusive?: boolean;
    shouldChangePassword?: boolean;
    reasonSuspended?: string;
    approval?: {
        bio?: Bio;
        pictures?: string[];
        videos?: string[];
        presentationVideo?: string;
        scenesVideo?: string;
    };
}

export interface Bio {
    description: string;
}

export interface Career {
    doYouPlayExtra: boolean;
    howManyAgenciesBesidesKozmos: string;
    anyValidContract: boolean;
    validContracts: ValidContract[];
}

export interface ValidContract {
    uuid: string;
    segment: string;
    exclusivity: string | boolean;
    startDate: string | Date;
    duration: number | string;
    agency: string;
    obs: string;
    formattedDate?: string;
}

export interface ContractData {
    maritalStatus: string;
    idCard: string;
    fiscalCode: string;
    address: string;
    additionalAddress: string;
    neighborhood: string;
    zipcode: string;
    city: string;
    state: string;
    drt: string;
    profession: string;
    bank: string;
    agency: string;
    account: string;
    accountType: string;
    pixType: "fiscalCode" | "phone" | "random" | "email";
    pixKey: string;
}

export interface GeneralInformation {
    photo?: string;
    photoOriginal?: string;
    artisticName: string;
    fullName: string;
    birthDate?: Date;
    gender?: string;
    email: string;
    phoneResidential?: string;
    phoneCellphone?: string;
    phoneOther?: string;
    citizenship?: string;
    birthState?: string;
}

export interface PersonalInformation {
    height: number;
    weight: number;
    clothingSize: number;
    footSize: number;
    hair: string;
    eyes: string;
    skin?: string;
    ethnicity?: string;
    classes?: string[];
    stamp?: string;
}

export interface Skill extends EntityWithId {
    type: "driver-license" | "idiom" | "dance" | "music" | "art" | "sport" | "dramatic-art" | "circus";
    descriptionPT: string;
    descriptionEN: string;
    descriptionES: string;
}

export interface Skills {
    driversLicense: string[];
    idioms: string[];
    dances: string[];
    music: string[];
    arts: string[];
    sports: string[];
    dramaticArts: string[];
    circus: string[];
}

export interface SocialNetworks {
    instagram?: string;
    instagramFollowers?: number;
    facebook?: string;
    youtube?: string;
    youtubeFollowers?: number;
    vimeo?: string;
    tiktok?: string;
    tiktokFollowers?: number;
    twitter?: string;
    twitterFollowers?: number;
    kwai?: string;
    kwaiFollowers?: number;
}

export interface TalentCompanyData {
    ownCompany: boolean;
    companyName: string;
    companyFantasyName: string;
    commercialRegisterNumber: string;
    address: string;
    additionalAddress?: string;
    neighborhood: string;
    zipcode: string;
    state: string;
    city: string;
    bank: string;
    agency: string;
    account: string;
    accountType: string;
    areYouLegalRepresentative: boolean;
    legalRepresentative: string;
    idCard: string;
    fiscalCode: string;
    email: string;
    isMicroCompany: boolean;
}

export interface Segment extends EntityWithId {
    descriptionPT: string;
    descriptionEN: string;
    descriptionES: string;
}

export interface InviteProfileRequest {
    artisticName: string;
    fiscalCode: string;
    email: string;
    isExclusive: boolean | string;
    classes: string[];
    skin: string;
    ethnicity: string;
    stamp: "open" | "act" | "street" | "digital";
}

export enum StatusEnum {
    INACTIVE = 0,
    ACTIVE = 1,
    EDITING = 2,
    VALIDATING = 3,
    SUSPENDED = 4
}

export interface PublicProfile {
    name: string;
    photo?: string;
    height?: number;
    weight?: number;
    clothingSize?: number;
    footSize?: number;
    hair?: string;
    eyes?: string;
    skills?: Omit<Skill, "_id" | "type">[];
    pictures?: string[];
    videos?: string[];
    age?: number;
    stamp?: string;
    composite?: string;
    bio?: string;
}
