import { Class, Session } from "../models";
import { get, post, put } from "./request.service";
import { config } from "../utils/constants";

export async function getClasses(session?: Session): Promise<Class[]> {
    return get(`${config.url}/classes/all`, session);
}

export async function newClass(translation: Class): Promise<Class> {
    return post(`${config.url}/classes/new`, translation);
}

export async function updateClass(id: string, body: Partial<Class>): Promise<Class> {
    return put(`${config.url}/classes/${id}/update`, body);
}
