import { ReactElement, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { checkToken } from "../services/login.service";

export const SessionChecker = inject("rootStore")(
    observer((props: Stores): ReactElement | null => {
        const {
            routingStore: { push },
            sessionStore,
            profileStore
        } = props.rootStore!;

        const checkTokenAction = () =>
            checkToken()
                .then(response => {
                    if (response && response.status === "expiring" && response.token) {
                        sessionStore.setCurrentSession({
                            ...sessionStore.session,
                            token: response.token
                        });
                    }
                })
                .catch(response => {
                    if (response.status === 401) {
                        profileStore.clearCurrentSession();
                        sessionStore.clearCurrentSession();
                        push("/login");
                    }
                });

        useEffect(() => {
            if (sessionStore.logged) {
                checkTokenAction();
            }
        }, []);

        useEffect(() => {
            const executor = setInterval(async () => {
                if (sessionStore.logged) {
                    await checkTokenAction();
                }
            }, 15 * 60 * 1000); //15 minutes
            if (!sessionStore.logged) {
                clearInterval(executor);
            }
            return () => {
                clearInterval(executor);
            };
        }, [sessionStore.logged]);

        return null;
    })
);
