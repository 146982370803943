import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import { Button, Card, Form } from "tabler-react";
import { useTranslation } from "react-i18next";
import "react-accessible-accordion/dist/fancy-example.css";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";
import { Formik } from "formik";
import {
    getCastingProfile,
    newCastingProfile,
    removeProfile,
    updateCastingProfile
} from "../../services/casting.service";
import { toast } from "react-toastify";
import { CastingProfile, CastingProfileBonus, CastingProfileType } from "../../models";
import { CastingProfile as Profile } from "./CastingProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency, parseCurrency } from "../../utils/formats";

export const Artists = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, castingStore } = props.rootStore!;
        const loaded = useRef<boolean>(false);
        const [openNew, setOpenNew] = useState(false);
        const [focusedProfile, setFocusedProfile] = useState<CastingProfile | undefined>(undefined);
        const [showDelete, setShowDelete] = useState(false);
        const { t } = useTranslation();

        useEffect(() => {
            if (
                !loaded.current &&
                castingStore.focusedCastingProfiles.length === 0 &&
                castingStore.focusedCasting.profiles
            ) {
                loadingStore.triggerLoading();
                Promise.all(castingStore.focusedCasting.profiles.map(profile => getCastingProfile(profile)))
                    .then(profiles => {
                        castingStore.setFocusedCastingProfiles(profiles);
                        loadingStore.stopLoading();
                        loaded.current = true;
                    })
                    .catch(() => loadingStore.stopLoading());
            }
        }, [castingStore.focusedCasting, castingStore.focusedCastingProfiles]);

        const onSubmit = async (values: Partial<CastingProfile>) => {
            if (castingStore.focusedCasting && castingStore.focusedCasting._id) {
                loadingStore.triggerLoading();
                setOpenNew(false);

                if (focusedProfile?._id) {
                    updateCastingProfile(focusedProfile._id, {
                        ...values,
                        price: values.price ? parseCurrency(values.price) : undefined,
                        type: values.type ? values.type : undefined,
                        bonus: values.bonus ? values.bonus : undefined
                    })
                        .then(profile => {
                            castingStore.updateCastingProfile(profile);
                            loadingStore.stopLoading();
                            setFocusedProfile(undefined);
                            toast.success(t("success.updated-casting-profile"));
                        })
                        .catch(() => {
                            loadingStore.stopLoading();
                            toast.error(t("error.updating-casting-profile"));
                            setOpenNew(true);
                        });
                } else {
                    newCastingProfile(
                        {
                            ...values,
                            price: values.price ? parseCurrency(values.price) : undefined,
                            type: values.type ? values.type : undefined,
                            bonus: values.bonus ? values.bonus : undefined
                        },
                        castingStore.focusedCasting._id
                    )
                        .then(profile => {
                            castingStore.addCastingProfile(profile);
                            const updatedCasting = castingStore.focusedCasting;
                            if (!updatedCasting.profiles) {
                                updatedCasting.profiles = [profile._id!];
                            }
                            loadingStore.stopLoading();
                            castingStore.updateCasting(updatedCasting);
                            toast.success(t("success.created-casting-profile"));
                        })
                        .catch(() => {
                            loadingStore.stopLoading();
                            toast.error(t("error.creating-casting-profile"));
                            setOpenNew(true);
                        });
                }
            }
        };

        const deleteProfile = async () => {
            if (castingStore.focusedCasting._id && focusedProfile?._id) {
                try {
                    loadingStore.triggerLoading();
                    setShowDelete(false);
                    const casting = await removeProfile(castingStore.focusedCasting._id, focusedProfile._id);
                    castingStore.removeCastingProfile(focusedProfile._id);
                    loadingStore.stopLoading();
                    castingStore.updateCasting(casting);
                    setFocusedProfile(undefined);
                    toast.success(t("success.deleted-casting-profile"));
                } catch (e) {
                    console.error(e);
                    loadingStore.stopLoading();
                    toast.error(t("error.deleting-casting-profile"));
                    setShowDelete(true);
                }
            }
        };

        const castingProfileTypes = [
            { value: "", label: t("select") },
            { value: CastingProfileType.PRINCIPAL, label: t("casting.profile.type.principal") },
            { value: CastingProfileType.SUPPORTING, label: t("casting.profile.type.supporting") },
            { value: CastingProfileType.PARTICIPATION, label: t("casting.profile.type.participation") }
        ];

        const castingProfileBonus = [
            { value: "", label: t("select") },
            { value: CastingProfileBonus.BONUS, label: t("casting.profile.bonus") },
            { value: CastingProfileBonus.SINGLE_PAYMENT, label: t("casting.profile.single-payment") }
        ];

        const getProfileTitleComplement = (profile: CastingProfile): string => {
            const type = castingProfileTypes.find(type => type.value == profile.type);
            const bonus =
                profile.bonus == CastingProfileBonus.BONUS
                    ? castingProfileBonus.find(bonus => bonus.value == CastingProfileBonus.BONUS)?.label
                    : "";
            return type ? `${type.label} ${formatCurrency(Number(profile.price))} ${bonus}`.trim() : "";
        };

        return (
            <>
                {showDelete && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setShowDelete(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.delete_profile")}</Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={() => {
                                setShowDelete(false);
                                deleteProfile();
                            }}
                        >
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                {openNew && (
                    <Popup
                        title={focusedProfile?._id ? t("casting.edit-profile") : t("casting.new-profile")}
                        onClose={() => {
                            setOpenNew(false);
                            setFocusedProfile(undefined);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw", minWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                resume: focusedProfile?.resume ?? "",
                                briefing: focusedProfile?.briefing ?? "",
                                price: focusedProfile?.price ? formatCurrency(Number(focusedProfile.price)) : "",
                                type: focusedProfile?.type,
                                bonus: focusedProfile?.bonus
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (!values.resume || values.resume.length < 5) {
                                    errors.resume = t("form.errors.required");
                                }
                                if (!values.briefing || values.briefing.length < 5) {
                                    errors.briefing = t("form.errors.required");
                                }
                                if (values.price && !/^[0-9]*$/.test(values.price)) {
                                    errors.price = t("form.errors.invalid");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.profile.resume")}</Form.Label>
                                            <Form.Input
                                                name="resume"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.resume}
                                                error={errors && errors.resume}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.profile.briefing")}</Form.Label>
                                            <Form.Textarea
                                                name="briefing"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.briefing}
                                                error={errors && errors.briefing}
                                                rows={3}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.profile.price")}</Form.Label>
                                            <Form.Input
                                                name="price"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="currency"
                                                value={values && values.price}
                                                error={errors && errors.price}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            <Form.Label>&nbsp;</Form.Label>
                                            <Form.Select
                                                name="bonus"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.bonus}
                                                error={errors && errors.bonus}
                                            >
                                                {castingProfileBonus.map(bonus => (
                                                    <option key={`bonus_${bonus.value}`} value={bonus.value}>
                                                        {bonus.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.profile.type.label")}</Form.Label>
                                            <Form.Select
                                                name="type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.type}
                                                error={errors && errors.type}
                                            >
                                                {castingProfileTypes.map(type => (
                                                    <option key={`type_${type.value}`} value={type.value}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Card>
                    <Card.Body>
                        <Button
                            color="primary"
                            style={{ marginBottom: "10px" }}
                            onClick={() => {
                                setOpenNew(true);
                            }}
                        >
                            {t("casting.new-profile")}
                        </Button>
                        <Accordion allowMultipleExpanded allowZeroExpanded>
                            {castingStore.focusedCastingProfiles.map((profile, index) => (
                                <AccordionItem key={profile._id}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <strong>
                                                {t("casting.profile")} {index + 1}
                                            </strong>{" "}
                                            - {profile.resume}{" "}
                                            {Number(profile.price) > 0 &&
                                                Number(profile.bonus) > 0 &&
                                                Number(profile.type) > 0 && (
                                                    <span className="casting-profile-title-type">
                                                        {getProfileTitleComplement(profile)}
                                                    </span>
                                                )}
                                            <a
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setFocusedProfile(profile);
                                                    setOpenNew(true);
                                                }}
                                                style={{ marginLeft: 15, marginRight: 10 }}
                                                title={t("casting.profile.edit")}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </a>
                                            <a
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setFocusedProfile(profile);
                                                    setShowDelete(true);
                                                }}
                                                title={t("casting.profile.delete")}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </a>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            <span>{profile.briefing}</span>
                                        </p>
                                        <Profile profile={profile} />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Card.Body>
                </Card>
            </>
        );
    })
);
