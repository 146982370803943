import { action, makeObservable, observable } from "mobx";
import { Casting } from "../models";
import { Finance } from "../models/finance";
import { formatDate } from "../utils/formats";

export class FinanceStore {
    @observable.deep finances: Finance[] = [] as Finance[];
    @observable focusedFinance: Finance = {} as Finance;

    constructor() {
        makeObservable(this);
    }

    @action setFinances(finances: Finance[]) {
        this.finances = finances;
    }

    @action setFocusedFinance(finance: Finance) {
        this.focusedFinance = finance;
    }

    @action addFinance(finance: Finance) {
        this.finances = [...this.finances, finance];
    }

    @action updateFinance(finance: Finance) {
        const index = this.finances.findIndex(c => c._id === finance._id);
        if (index > -1) {
            this.finances[index] = finance;
        }
    }

    @action removeFinance(finance: Finance) {
        const index = this.finances.findIndex(c => c._id === finance._id);
        if (index > -1) {
            this.finances.splice(index, 1);
        }
    }

    @action getFinanceById(id: string): Finance | undefined {
        return this.finances.find(c => c._id === id);
    }

    @action filter(date: string, identification: string, status: number, castings: Casting[]): Finance[] {
        let finances = [...this.finances];

        const findId = (value: string) => castings.find(c => c.title.toLowerCase().includes(value.toLowerCase()))?._id;

        if (identification) {
            finances = finances.filter(
                c => c.identification?.includes(identification) || c.casting === findId(identification)
            );
        }

        if (date) {
            finances = finances.filter(c => c.createdAt && formatDate(new Date(c.createdAt)).includes(date));
        }

        if (status > -1) {
            finances = finances.filter(c => c.status == status);
        }

        return finances;
    }

    @action sortByIdentification(sortMethod: "asc" | "desc", castings: Casting[]) {
        const unsortedFinances = [...this.finances];

        const findValue = (id?: string) => castings.find(c => c._id == id)?.title ?? "";

        unsortedFinances.sort((p1, p2) => {
            if (sortMethod === "desc") {
                return (p2.identification || findValue(p2.casting)).localeCompare(
                    p1.identification ?? findValue(p1.casting)
                );
            }

            return (p1.identification || findValue(p1.casting)).localeCompare(
                p2.identification ?? findValue(p2.casting)
            );
        });

        this.setFinances(unsortedFinances);
    }

    @action sortByStatus(sortMethod: "asc" | "desc") {
        const unsortedFinances = [...this.finances];

        unsortedFinances.sort((p1, p2) => {
            if (!p1.status || !p1.status) {
                return 0;
            }

            if (sortMethod === "desc") {
                return p2.status < p1.status ? -1 : 1;
            }

            return p1.status < p2.status ? -1 : 1;
        });

        this.setFinances(unsortedFinances);
    }

    @action sortByCreatedAt(sortMethod: "asc" | "desc") {
        const unsortedAttributes = [...this.finances];

        unsortedAttributes.sort((p1, p2) => {
            if (!p1.createdAt) {
                if (p2.createdAt) {
                    if (sortMethod === "desc") {
                        return 1;
                    }
                }
                return -1;
            }
            if (!p2.createdAt) {
                if (p1.createdAt) {
                    if (sortMethod === "asc") {
                        return 1;
                    }
                }
                return -1;
            }
            const d1 = new Date(p1.createdAt);
            const d2 = new Date(p2.createdAt);
            if (d1.getTime() === d2.getTime()) {
                return 0;
            }
            if (sortMethod === "asc") {
                return d1.getTime() > d2.getTime() ? 1 : -1;
            }
            return d2.getTime() > d1.getTime() ? 1 : -1;
        });

        this.setFinances(unsortedAttributes);
    }
}
