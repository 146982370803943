import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSkills, newSkill, updateSkill } from "../../services/skills.service";
import { useTranslation } from "react-i18next";
import { Skill } from "../../models";
import { Popup } from "../Popup";
import { isMobile } from "../../utils/utils";
import { Formik } from "formik";
import { Button, Form, Icon, Table } from "tabler-react";

interface SkillsManagementProps extends Stores {
    showExtraLanguages: boolean;
    setShowExtraLanguages: Dispatch<SetStateAction<boolean>>;
}

export const SkillsManagement = inject("rootStore")(
    observer((props: SkillsManagementProps) => {
        const { loadingStore, skillsStore } = props.rootStore!;
        const { t } = useTranslation();

        const [editingSkill, setEditingSkill] = useState(false);
        const [openNewSkill, setOpenNewSkill] = useState(false);
        const [focusedSkill, setFocusedSkill] = useState<undefined | Skill>(undefined);
        const [sort, setSort] = useState("");
        const [sortType, setSortType] = useState<"asc" | "desc">("asc");
        const [sortName, setSortName] = useState<"asc" | "desc">("asc");

        useEffect(() => {
            if (!sort && skillsStore.skills.length > 0) {
                skillsStore.sortByDescription("asc", t("languageCode"));
                setSort("name");
            }
        }, [skillsStore.skills, sort]);

        const skillTypes = [
            { value: "driver-license", label: t("profile.skills.drivers-license") },
            { value: "idiom", label: t("profile.skills.idioms") },
            { value: "art", label: t("profile.skills.arts") },
            { value: "sport", label: t("profile.skills.sports") },
            { value: "dance", label: t("profile.skills.dances") },
            { value: "music", label: t("profile.skills.music") },
            { value: "circus", label: t("profile.skills.circus") },
            { value: "dramatic-art", label: t("profile.skills.dramatic-arts") }
        ].sort((a, b) => a.label.localeCompare(b.label));

        const onSubmitSkill = async (values: any) => {
            loadingStore.triggerLoading();
            setOpenNewSkill(false);
            if (editingSkill && focusedSkill) {
                updateSkill(focusedSkill._id!, values)
                    .then(skill => {
                        setEditingSkill(false);
                        setFocusedSkill(undefined);
                        loadingStore.stopLoading();
                        skillsStore.updateSkill(skill);
                        toast.success(t("success.updated"));
                    })
                    .catch(() => {
                        setOpenNewSkill(true);
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            } else {
                newSkill(values)
                    .then(skill => {
                        loadingStore.stopLoading();
                        skillsStore.addSkill(skill);
                        toast.success(t("success.created-skill"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.creating-skill"));
                    });
            }
        };

        useEffect(() => {
            if (skillsStore.skills.length === 0) {
                loadingStore.triggerLoading();
                getSkills()
                    .then(skills => {
                        skillsStore.setSkills(skills);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        return (
            <>
                {openNewSkill && (
                    <Popup
                        title={editingSkill ? t("casting.skills.edit") : t("casting.skills.new")}
                        onClose={() => {
                            setOpenNewSkill(false);
                            setEditingSkill(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                type: focusedSkill?.type ?? "",
                                descriptionEN: focusedSkill?.descriptionEN ?? "",
                                descriptionPT: focusedSkill?.descriptionPT ?? "",
                                descriptionES: focusedSkill?.descriptionES ?? ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.type) {
                                    errors.type = t("form.errors.required");
                                }
                                if (!values.descriptionPT) {
                                    errors.descriptionPT = t("form.errors.required");
                                } else if (!props.showExtraLanguages) {
                                    values.descriptionEN = values.descriptionPT;
                                    values.descriptionES = values.descriptionPT;
                                }
                                if (props.showExtraLanguages) {
                                    if (!values.descriptionEN) {
                                        errors.descriptionEN = t("form.errors.required");
                                    }
                                    if (!values.descriptionES) {
                                        errors.descriptionES = t("form.errors.required");
                                    }
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmitSkill}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.attributes.type.label")}</Form.Label>
                                            <Form.Select
                                                name="type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.type}
                                                error={errors && errors.type}
                                            >
                                                <option value="">{t("select")}</option>
                                                {skillTypes.map((type, i) => (
                                                    <option key={`type_${i}`} value={type.value}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.attributes.show-en")}</Form.Label>
                                            <Form.Radio
                                                label={t("no")}
                                                onChange={() => props.setShowExtraLanguages(false)}
                                                checked={!props.showExtraLanguages}
                                            />
                                            <Form.Radio
                                                label={t("yes")}
                                                onChange={() => props.setShowExtraLanguages(true)}
                                                checked={props.showExtraLanguages}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>
                                                {props.showExtraLanguages
                                                    ? t("casting.attributes.name-pt")
                                                    : t("casting.attributes.name")}
                                            </Form.Label>
                                            <Form.Input
                                                name="descriptionPT"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.descriptionPT}
                                                error={errors && errors.descriptionPT}
                                            />
                                        </div>
                                        {props.showExtraLanguages && (
                                            <>
                                                <div className="flex-column">
                                                    <Form.Label>{t("casting.attributes.name-en")}</Form.Label>
                                                    <Form.Input
                                                        name="descriptionEN"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.descriptionEN}
                                                        error={errors && errors.descriptionEN}
                                                    />
                                                </div>
                                                <div className="flex-column">
                                                    <Form.Label>{t("casting.attributes.name-es")}</Form.Label>
                                                    <Form.Input
                                                        name="descriptionES"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.descriptionES}
                                                        error={errors && errors.descriptionES}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Button
                    color="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                        setFocusedSkill(undefined);
                        setEditingSkill(false);
                        setOpenNewSkill(true);
                    }}
                >
                    {t("casting.skills.new")}
                </Button>
                <Table className="table-striped">
                    <Table.Header>
                        <Table.Row className="centered-row">
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("name");
                                    setSortName(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        skillsStore.sortByDescription(newSort, t("languageCode"));
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("casting.attributes.name")}</div>
                                    {sort === "name" && sortName === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "name" && sortName === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                onClick={() => {
                                    setSort("type");
                                    setSortType(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        skillsStore.sortByType(newSort, skillTypes);
                                        return newSort;
                                    });
                                }}
                                title={t("click-to-sort")}
                                role="button"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="sortable-column">
                                    <div>{t("casting.attributes.type.label")}</div>
                                    {sort === "type" && sortType === "desc" && (
                                        <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "type" && sortType === "asc" && (
                                        <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                            </Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {skillsStore.skills.map((skill, i) => (
                            <Table.Row
                                key={`skill_${i}`}
                                className="clickable-row"
                                onClick={() => {
                                    setFocusedSkill(skill);
                                    setEditingSkill(true);
                                    setOpenNewSkill(true);
                                }}
                            >
                                <Table.Col>
                                    {skill[`description${t("languageCode").toUpperCase()}` as keyof Skill]}
                                </Table.Col>
                                <Table.Col>{t(`profile.skills.${fixSkillTranslationKey(skill.type)}`)}</Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
    })
);

function fixSkillTranslationKey(key: string) {
    switch (key) {
        case "art":
            return "arts";
        case "dance":
            return "dances";
        case "dramatic-art":
            return "dramatic-arts";
        case "driver-license":
            return "drivers-license";
        case "idiom":
            return "idioms";
        case "sport":
            return "sports";
        default:
            return key;
    }
}
