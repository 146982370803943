export function isMobile() {
    return (
        /(android)/i.test(navigator.userAgent) ||
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
            navigator.platform
        ) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}

export function calculateAge(dob: Date) {
    const monthDiff = Date.now() - dob.getTime();
    const ageDate = new Date(monthDiff);
    const year = ageDate.getUTCFullYear();
    return Math.abs(year - 1970);
}
