import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import {
    deleteCasting,
    getCastingAttributes,
    getCastings,
    getCastingTypes,
    newCasting,
    updateCasting
} from "../services/casting.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Popup } from "./Popup";
import { isMobile } from "../utils/utils";
import { Formik } from "formik";
import { Casting as CastingType, CastingAttributeType, CastingStatus } from "../models";
import { Button, Form, Icon, Table, Dropdown } from "tabler-react";
import { formatDate } from "../utils/formats";
import { InfiniteBody } from "./utils/InfiniteBody";

const limit = 30;

export const Casting = inject("rootStore")(
    observer((props: Stores): ReactElement | null => {
        const {
            loadingStore,
            castingAttributeStore,
            castingTypesStore,
            castingStore,
            routingStore: { push }
        } = props.rootStore!;
        const [openNew, setOpenNew] = useState(false);
        const [openEditStatus, setOpenEditStatus] = useState(false);
        const [openDelete, setOpenDelete] = useState(false);
        const { t } = useTranslation();

        const [sort, setSort] = useState("value");
        const [sortClient, setSortClient] = useState<"asc" | "desc">("asc");
        const [sortTitle, setSortTitle] = useState<"asc" | "desc">("asc");
        const [sortCreatedAt, setSortCreatedAt] = useState<"asc" | "desc">("asc");
        const [sortStatus, setSortStatus] = useState<"asc" | "desc">("asc");
        const [filterClient, setFilterClient] = useState("");
        const [filterTitle, setFilterTitle] = useState("");
        const [filterStatus, setFilterStatus] = useState(0);
        const [focusedCasting, setFocusedCasting] = useState<CastingType | undefined>();
        const [page, setPage] = useState(1);

        useEffect(() => {
            // TODO - fix sortByCreatedAt not working properly on first render
            if (
                castingAttributeStore.attributes.length === 0 ||
                castingTypesStore.types.length === 0 ||
                castingStore.castings.length === 0
            ) {
                loadingStore.triggerLoading();
                Promise.all([getCastingTypes(), getCastingAttributes(), getCastings()])
                    .then(([types, attributes, castings]) => {
                        castingStore.setCastings(castings);
                        castingTypesStore.setTypes(types);
                        castingAttributeStore.setAttributes(attributes);
                        castingStore.sortByCreatedAt("desc");
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                loadingStore.stopLoading();
            }
        }, []);

        const findLabel = useCallback(
            (id: string) => castingAttributeStore.attributes.find(attr => attr._id == id)?.value,
            []
        );

        const totalCastings = useMemo(() => {
            if (filterClient || filterTitle || filterStatus)
                return castingStore.filter(filterClient, filterTitle, filterStatus, castingAttributeStore.attributes)
                    .length;
            return castingStore.castings.length;
        }, [filterClient, filterTitle, filterStatus, castingAttributeStore.attributes, castingStore.castings]);

        const castings = useMemo(() => {
            if (filterClient || filterTitle || filterStatus) {
                return castingStore.filter(filterClient, filterTitle, filterStatus, castingAttributeStore.attributes);
            }
            return castingStore.castings.slice(0, limit * page);
        }, [castingStore.castings, castingAttributeStore.attributes, filterClient, filterTitle, filterStatus]);

        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            setOpenNew(false);
            newCasting(values)
                .then(casting => {
                    loadingStore.stopLoading();
                    castingStore.addCasting(casting);
                    toast.success(t("success.created-casting"));
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error(t("error.creating-casting"));
                });
        };

        const onEditStatus = async (values: any) => {
            if (focusedCasting) {
                loadingStore.triggerLoading();
                setOpenEditStatus(false);
                updateCasting(focusedCasting._id!, { status: values.status })
                    .then(casting => {
                        loadingStore.stopLoading();
                        castingStore.updateCasting(casting);
                        toast.success(t("success.updated"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.updating"));
                    });
            }
        };

        const removeCasting = async () => {
            if (focusedCasting) {
                loadingStore.triggerLoading();
                setOpenDelete(false);
                deleteCasting(focusedCasting._id!)
                    .then(() => {
                        loadingStore.stopLoading();
                        castingStore.removeCasting(focusedCasting);
                        toast.success(t("success.casting-deleted"));
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error(t("error.deleting-casting"));
                    });
            }
        };

        return (
            <>
                {openNew && (
                    <Popup
                        title={t("casting.new")}
                        onClose={() => {
                            setOpenNew(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                client: "",
                                title: ""
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (!values.client) {
                                    errors.client = t("form.errors.required");
                                }
                                if (!values.title) {
                                    errors.title = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.client")}</Form.Label>
                                            <Form.Select
                                                name="client"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && (values.client as string)}
                                                error={errors && errors.client}
                                            >
                                                <option value="">{t("select")}</option>
                                                {castingAttributeStore.attributes
                                                    .slice()
                                                    .filter(c => (c.type as CastingAttributeType).key === "client")
                                                    .sort((a, b) => {
                                                        if (!a.value || !b.value) {
                                                            return 0;
                                                        }
                                                        return a.value.localeCompare(b.value);
                                                    })
                                                    .map(attr => (
                                                        <option value={attr._id} key={`attr_${attr._id}`}>
                                                            {attr.value}
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Label>{t("casting.title")}</Form.Label>
                                            <Form.Input
                                                name="title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.title}
                                                error={errors && errors.title}
                                            />
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openEditStatus && (
                    <Popup
                        title={t("casting.status")}
                        onClose={() => {
                            setOpenEditStatus(false);
                        }}
                        style={{ maxWidth: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik
                            initialValues={{
                                status: ""
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (!values.status) {
                                    errors.status = t("form.errors.required");
                                }
                                return errors;
                            }}
                            enableReinitialize
                            onSubmit={onEditStatus}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Select
                                                name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.status}
                                                error={errors && errors.status}
                                            >
                                                <option value="">{t("select")}</option>
                                                <option value={CastingStatus.STARTED}>
                                                    {t("casting.status.started")}
                                                </option>
                                                <option value={CastingStatus.FINISHED}>
                                                    {t("casting.status.finished")}
                                                </option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            {t("save")}
                                        </Button>
                                        <br />
                                    </Form.Footer>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                {openDelete && (
                    <Popup
                        title={t("confirmation.label")}
                        onClose={() => setOpenDelete(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Form.Group>{t("confirmation.delete_casting")}</Form.Group>
                        <Button
                            color="success"
                            icon="check"
                            onClick={() => {
                                setOpenDelete(false);
                                removeCasting();
                            }}
                        >
                            {t("yes")}
                        </Button>
                        <Button
                            color="primary"
                            icon="x"
                            onClick={() => {
                                setOpenDelete(false);
                            }}
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            {t("no")}
                        </Button>
                    </Popup>
                )}
                <Button
                    color="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                        setOpenNew(true);
                    }}
                >
                    {t("casting.new")}
                </Button>
                <Table className="table-striped">
                    <Table.Header className="infinite-loading-header">
                        <Table.Row className="centered-row">
                            <Table.ColHeader style={{ width: "30%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortClient(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    castingStore.sortByClient(
                                                        newSort,
                                                        castingAttributeStore.attributes
                                                    );
                                                    return newSort;
                                                });
                                                setSort("client");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("casting.client")}</div>
                                            {sort === "client" && sortClient === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "client" && sortClient === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Input
                                            value={filterClient}
                                            onChange={(e: any) => setFilterClient(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "30%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortTitle(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    castingStore.sortByTitle(newSort);
                                                    return newSort;
                                                });
                                                setSort("title");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("casting.title")}</div>
                                            {sort === "title" && sortTitle === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "title" && sortTitle === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Input
                                            value={filterTitle}
                                            onChange={(e: any) => setFilterTitle(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader
                                title={t("click-to-sort")}
                                onClick={() => {
                                    setSortCreatedAt(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        castingStore.sortByCreatedAt(newSort);
                                        return newSort;
                                    });
                                    setSort("createdAt");
                                }}
                                style={{ cursor: "pointer" }}
                                role="button"
                            >
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div className="sortable-column">
                                            <div>{t("casting.creation")}</div>
                                            {sort === "createdAt" && sortCreatedAt === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "createdAt" && sortCreatedAt === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "15%" }}>
                                <div className="flex-row no-margins">
                                    <div className="flex-column no-margins">
                                        <div
                                            className="sortable-column"
                                            title={t("click-to-sort")}
                                            onClick={() => {
                                                setSortStatus(prev => {
                                                    const newSort = prev === "asc" ? "desc" : "asc";
                                                    castingStore.sortByStatus(newSort);
                                                    return newSort;
                                                });
                                                setSort("status");
                                            }}
                                            role="button"
                                        >
                                            <div>{t("casting.status")}</div>
                                            {sort === "status" && sortStatus === "desc" && (
                                                <Icon name="chevron-down" style={{ fontWeight: "bold" }} />
                                            )}
                                            {sort === "status" && sortStatus === "asc" && (
                                                <Icon name="chevron-up" style={{ fontWeight: "bold" }} />
                                            )}
                                        </div>
                                        <Form.Select
                                            value={filterStatus}
                                            onChange={(e: any) => setFilterStatus(e.target.value)}
                                        >
                                            <option value="">{t("select")}</option>
                                            <option value={CastingStatus.STARTED}>{t("casting.status.started")}</option>
                                            <option value={CastingStatus.FINISHED}>
                                                {t("casting.status.finished")}
                                            </option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </Table.ColHeader>
                            <Table.ColHeader style={{ width: "70px" }}>{t("casting.actions")}</Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <InfiniteBody hasMoreItems={limit * page < totalCastings} isInfinite setPage={setPage}>
                        {castings.map(casting => {
                            return (
                                <Table.Row key={casting._id} className="clickable-row">
                                    <Table.Col
                                        style={{ width: "30%" }}
                                        onClick={() => {
                                            castingStore.setFocusedCasting(casting);
                                            push("/casting-details");
                                            // window.open(`/casting-details?id=${casting._id}`);
                                        }}
                                    >
                                        {casting.clients.map(c => findLabel(c)).join(" | ")}
                                    </Table.Col>
                                    <Table.Col
                                        onClick={() => {
                                            castingStore.setFocusedCasting(casting);
                                            push("/casting-details");
                                            // window.open(`/casting-details?id=${casting._id}`);
                                        }}
                                    >
                                        {casting.title}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "20%" }}
                                        onClick={() => {
                                            castingStore.setFocusedCasting(casting);
                                            push("/casting-details");
                                            // window.open(`/casting-details?id=${casting._id}`);
                                        }}
                                    >
                                        {casting.createdAt ? formatDate(new Date(casting.createdAt), true) : "-"}
                                    </Table.Col>
                                    <Table.Col
                                        style={{ width: "14%" }}
                                        onClick={() => {
                                            castingStore.setFocusedCasting(casting);
                                            push("/casting-details");
                                            // window.open(`/casting-details?id=${casting._id}`);
                                        }}
                                    >
                                        {t(
                                            `casting.status.${
                                                casting.status === CastingStatus.STARTED ? "started" : "finished"
                                            }`
                                        )}
                                    </Table.Col>
                                    <Table.Col style={{ width: "70px", cursor: "auto" }}>
                                        <Dropdown
                                            className="custom-dropdown"
                                            color="primary"
                                            isNavLink
                                            triggerClassName="pr-0 leading-none"
                                            triggerContent={<Icon name="more-vertical" />}
                                            position="bottom-end"
                                            arrow={true}
                                            arrowPosition="right"
                                            toggle={false}
                                            itemsObject={[
                                                {
                                                    icon: "edit-3",
                                                    value: t("casting.menu.change-status"),
                                                    onClick: () => {
                                                        setFocusedCasting(casting);
                                                        setOpenEditStatus(true);
                                                    }
                                                },
                                                {
                                                    icon: "x-circle",
                                                    value: t("casting.menu.delete"),
                                                    onClick: () => {
                                                        setFocusedCasting(casting);
                                                        setOpenDelete(true);
                                                    }
                                                }
                                            ]}
                                        />
                                    </Table.Col>
                                </Table.Row>
                            );
                        })}
                    </InfiniteBody>
                </Table>
            </>
        );
    })
);
