import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { useTranslation } from "react-i18next";
import React from "react";
import { Formik } from "formik";
import { Button, Form } from "tabler-react";
import { agreeToLgpd } from "../services/profile.service";
import { toast } from "react-toastify";

export const FirstLogin = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const {
            loadingStore,
            sessionStore,
            profileStore,
            routingStore: { push }
        } = props.rootStore!;
        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            agreeToLgpd({
                password: values.newPassword,
                agreed: true
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    sessionStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    loadingStore.stopLoading();
                    push("/");
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };
        return (
            <Formik
                initialValues={{
                    agreed: [],
                    newPassword: "",
                    newPasswordCheck: ""
                }}
                validate={values => {
                    let errors: any = {};
                    if (!values.agreed || values.agreed[0] !== "yes") {
                        errors.agreed = t("form.errors.required");
                    }
                    if (!values.newPassword) {
                        errors.newPassword = t("form.errors.required");
                    }
                    if (!values.newPasswordCheck) {
                        errors.newPasswordCheck = t("form.errors.required");
                    } else if (values.newPassword !== values.newPasswordCheck) {
                        errors.newPasswordCheck = t("form.errors.invalid");
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="flex-table">
                            <div className="flex-row">
                                <div className="flex-column">
                                    <div className="terms">
                                        <p>
                                            <strong>{t("terms")}</strong>
                                        </p>
                                        <p>
                                            Cláusula primeira: A AGÊNCIA coletará, utilizará e armazenará os dados
                                            pessoais do ARTISTA em conformidade com as leis aplicáveis, incluindo a Lei
                                            Federal nº 13.709/2018 (“LGPD”) e com as políticas e normas internas. As
                                            operações de tratamento de dados pessoais realizadas pela AGÊNCIA estão
                                            fundamentadas na execução do contrato de agenciamento e no cumprimento de
                                            obrigações legais, a exemplo do legítimo interesse da AGÊNCIA no exercício
                                            regular de suas funções, responsabilidades e para a condução das suas
                                            atividades previstas.
                                        </p>
                                        <p>
                                            Cláusula segunda: Rescindido o contrato de agenciamento, a AGÊNCIA
                                            armazenará os dados pessoais do ARTISTA para fins de gestão de histórico de
                                            contratos de trabalhos firmado durante o tempo de seu agenciamento,
                                            restantando assegurados os direitos do ARTISTA como titular de dados, os
                                            quais poderão ser exercidos mediante envio de e-mail ao seguinte endereço
                                            eletrônico: gaia@kozmos.com.br.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-column">
                                    <Form.Checkbox
                                        isInline
                                        label={t("agree_terms")}
                                        name="agreed"
                                        value="yes"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={values.agreed[0] === "yes"}
                                    />
                                    {errors && errors.agreed && (
                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.agreed}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-column">
                                    <Form.Group>
                                        <Form.Label>{t("profile.new_password")}</Form.Label>
                                        <Form.Input
                                            type="password"
                                            name="newPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.newPassword}
                                            error={errors && errors.newPassword}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="flex-column">
                                    <Form.Group>
                                        <Form.Label>{t("profile.new_password_confirmation")}</Form.Label>
                                        <Form.Input
                                            type="password"
                                            name="newPasswordCheck"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.newPasswordCheck}
                                            error={errors && errors.newPasswordCheck}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <Form.Footer>
                                <Button type="submit" color="primary" icon="save">
                                    {t("proceed")}
                                </Button>
                                <Button
                                    color="danger"
                                    icon="x"
                                    onClick={() => {
                                        sessionStore.clearCurrentSession();
                                        push("/login");
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                            </Form.Footer>
                        </div>
                    </form>
                )}
            </Formik>
        );
    })
);
