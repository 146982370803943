import { action, computed, makeObservable, observable } from "mobx";
import { Profile, Session, StatusEnum } from "../models";
import { getCurrentSession, setCurrentSession } from "../utils/login.utils";
import { GrantEnum } from "../models/generic";
import { RootStore } from "./root.store";

export class SessionStore {
    private rootStore: RootStore;
    @observable.deep session: Session = {} as Session;

    constructor(rootStore: RootStore) {
        const session = getCurrentSession();
        if (session != null) {
            this.session = session;
        }
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action clearCurrentSession() {
        this.session = {} as Session;
        setCurrentSession();
    }

    @action setCurrentSession(session: Session) {
        this.session = session;
        setCurrentSession(session);
    }

    @action updateProfile(profile: Profile) {
        this.session.profile = profile;
        setCurrentSession(this.session);
    }

    @computed get currentSession() {
        if (!this.session || !this.session.token) {
            return getCurrentSession();
        }
        return this.session;
    }

    @computed get logged(): boolean {
        const sessionStore = this.session ? !!this.session.token : false;
        return sessionStore || !!getCurrentSession()?.token;
    }

    @computed get isDirector(): boolean {
        return (
            this.session.profile &&
            this.session.profile.role &&
            this.session.profile.role?.grants?.some(g => g === GrantEnum.DIRECTOR)
        );
    }

    @computed get isAdministrative(): boolean {
        return (
            this.session.profile &&
            this.session.profile.role &&
            this.session.profile.role?.grants?.some(g => g === GrantEnum.ADMINISTRATIVE)
        );
    }

    @computed get isBooker(): boolean {
        return (
            this.session.profile &&
            this.session.profile.role &&
            this.session.profile.role?.grants?.some(g => g === GrantEnum.BOOKER)
        );
    }

    @computed get isArtist(): boolean {
        return (
            this.session.profile &&
            this.session.profile.role &&
            this.session.profile.role?.grants?.some(g => g === GrantEnum.ARTIST)
        );
    }

    @computed get isSameAsFocused(): boolean {
        return this.session.profile && this.session.profile?._id === this.rootStore.profileStore.focusedProfile?._id;
    }

    @computed get isEditable(): boolean {
        return (
            this.session.profile &&
            (this.session.profile.status === StatusEnum.ACTIVE || this.session.profile.status === StatusEnum.EDITING)
        );
    }
}
