import { Entity, EntityWithId } from "./generic";

export interface CastingAttributeType extends EntityWithId {
    type: "attribute" | "status";
    key: string;
    nameEN: string;
    namePT: string;
    nameES: string;
}

export interface CastingAttribute extends EntityWithId, Entity {
    type: CastingAttributeType | string;
    value: string;
}

export enum CastingStatus {
    STARTED = 1,
    FINISHED = 2
}

export interface Casting extends EntityWithId, Entity {
    clients: string[];
    title: string;
    status: CastingStatus;
    category?: string;
    agent?: string;
    assistants?: string[];

    producers?: string[];
    castingProducers?: string[];
    director?: string;
    territory?: string;
    frequency?: number;

    segments?: string[];

    profiles?: string[]; // CastingProfile[]
}

export interface CastingProfile extends Entity, EntityWithId {
    resume: string;
    briefing: string;
    artists?: CastingProfileArtist[];
    price?: string;
    type?: CastingProfileType;
    bonus?: CastingProfileBonus;
}

export interface CastingProfilePriceNumber extends Omit<CastingProfile, "price"> {
    price: number;
}

export interface CastingProfileArtist extends Entity {
    profile: string;
    status: CastingProfileStatus;
    comments?: string;
}

export enum CastingProfileStatus {
    PRE_SELECTED = 1,
    SENT = 2,
    EDITED = 3,
    APPROVED = 4,
    DISREGARDED = 5,
    SELECTED = 6,
    BACKUP = 7,
    CONSULTED = 8
}

export enum CastingProfileType {
    PRINCIPAL = 1,
    SUPPORTING = 2,
    PARTICIPATION = 3
}

export enum CastingProfileBonus {
    SINGLE_PAYMENT = 1,
    BONUS = 2
}
