import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { ReactElement, useEffect, useState } from "react";
import { AccountDropdown, Nav, Site, Header as TablerHeader, Form, Button } from "tabler-react";
import { useTranslation } from "react-i18next";
import { config } from "../utils/constants";
import { Popup } from "./Popup";
import { Formik, FormikHelpers } from "formik";
import { changePassword, getProfiles, getUsers } from "../services/profile.service";
import { toast } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import { getFinances } from "../services/finance.service";
import { formatCurrency, formatDate } from "../utils/formats";
import { FinanceDescriptionInType, FinanceDescriptionOutType, FinanceEntryStatus, FinanceEntryType } from "../models";
import { getCastingAttributes, getCastings } from "../services/casting.service";

export const Header = inject("rootStore")(
    observer((props: Stores): ReactElement | null => {
        const [collapse, setCollapse] = useState(false);
        const [showChangePassword, setChangePassword] = useState(false);
        const { t } = useTranslation();
        const {
            routingStore: { push: pushToRoutingStore },
            sessionStore,
            profileStore,
            loadingStore,
            financeStore,
            castingStore,
            castingAttributeStore
        } = props.rootStore!;

        const push = (route: string) => {
            if (!Boolean(sessionStore.session?.profile.termsAndConditionsAgreedAt)) {
                pushToRoutingStore("terms-and-conditions");
            } else {
                pushToRoutingStore(route);
            }
        };

        const language = t("languageCode");

        useEffect(() => {
            if (sessionStore.session.profile?.shouldChangePassword) {
                setChangePassword(true);
            }
        }, [sessionStore.session.profile]);

        const navigation = (
            <Nav>
                {(sessionStore.isDirector || sessionStore.isBooker || sessionStore.isArtist) && (
                    <Nav.Item
                        active
                        value={t("menu.profile")}
                        icon="user-check"
                        onClick={() => {
                            push("/profile");
                        }}
                    />
                )}
                {(sessionStore.isDirector || sessionStore.isBooker) && (
                    <>
                        <Nav.Item value={t("menu.cast")} icon="users" onClick={() => push("/cast")} />
                        <Nav.Item value={t("menu.search")} icon="search" onClick={() => push("/search")} />
                        <Nav.Item value={t("menu.casting")} icon="clipboard" onClick={() => push("/casting")} />
                        {sessionStore.isDirector && (
                            <Nav.Item value={t("menu.data")} icon="database" onClick={() => push("/data")} />
                        )}
                    </>
                )}
                {(sessionStore.isDirector || sessionStore.isBooker || sessionStore.isAdministrative) && (
                    <Nav.Item value={t("menu.finance")} icon="dollar-sign" onClick={() => push("/finance")} />
                )}
                <Nav.Item
                    value={t("menu.terms-conditions", "Termos do Agenciamento")}
                    icon="briefcase"
                    onClick={() => window.open("https://www.kozmos.com.br/termos-agenciamento", "_blank")}
                />
                <Nav.Item
                    value={t("menu.help")}
                    icon="help-circle"
                    onClick={() => window.open("http://www.kozmos.com.br/gaia-ajuda", "_blank")}
                />
            </Nav>
        );

        const accountDropdownProps = {
            avatarURL: sessionStore.session.profile?.photo
                ? config.host + encodeURI(sessionStore.session.profile?.photo)
                : undefined,
            name: sessionStore.session.profile?.artisticName ?? sessionStore.session.profile?.fullName,
            description: t(`role.${sessionStore.session.profile?.role.name}`),
            options: [
                {
                    icon: "lock",
                    value: t("menu.change_password"),
                    onClick: () => {
                        setChangePassword(true);
                    }
                },
                ...(sessionStore.isDirector
                    ? [
                          {
                              icon: "database",
                              value: t("menu.export-database"),
                              onClick: () => {
                                  const csvOptions = {
                                      filename: language === "pt" ? "Base-de-dados-Gaia" : "Gaia-Database",
                                      fieldSeparator: ";",
                                      quoteStrings: '"',
                                      decimalSeparator: ".",
                                      showLabels: true,
                                      showTitle: true,
                                      title: language === "pt" ? "Base de dados" : "Database",
                                      useTextFile: false,
                                      useBom: true,
                                      headers: ["Nome artístico", "E-mail", "Celular"]
                                  };
                                  const csvExporter = new ExportToCsv(csvOptions);
                                  if (profileStore.profiles.length > 0) {
                                      const data = profileStore.profiles.map(profile => ({
                                          nomeArtistico: profile.artisticName,
                                          email: profile.email,
                                          celular: profile.phoneCellphone ?? ""
                                      }));
                                      csvExporter.generateCsv(data);
                                  } else {
                                      loadingStore.triggerLoading();
                                      getProfiles()
                                          .then(profiles => {
                                              profileStore.setProfiles(profiles);
                                              const data = profileStore.profiles.map(profile => ({
                                                  nomeArtistico: profile.artisticName,
                                                  email: profile.email,
                                                  celular: profile.phoneCellphone ?? "",
                                                  status: t(`status.${profile.status}`)
                                              }));
                                              loadingStore.stopLoading();
                                              csvExporter.generateCsv(data);
                                          })
                                          .catch(() => {
                                              toast.error(t("error.loading"));
                                              loadingStore.stopLoading();
                                          });
                                  }
                              }
                          },
                          {
                              icon: "printer",
                              value: t("menu.export-finance-registers"),
                              onClick: async () => {
                                  const csvOptions = {
                                      filename: language === "pt" ? "Financeiro-Registros" : "Finance-Registers",
                                      fieldSeparator: ";",
                                      quoteStrings: '"',
                                      decimalSeparator: ".",
                                      showLabels: true,
                                      showTitle: true,
                                      title: language === "pt" ? "Financeiro - Registros" : "Finance - Registers",
                                      useTextFile: false,
                                      useBom: true,
                                      headers: [
                                          "Identificacao",
                                          "Data",
                                          "Saldo",
                                          "Margem",
                                          "Pendencia",
                                          "Cliente",
                                          "Categoria",
                                          "Produtora",
                                          "Agente"
                                      ]
                                  };
                                  const csvExporter = new ExportToCsv(csvOptions);

                                  loadingStore.triggerLoading();
                                  if (financeStore.finances.length === 0) {
                                      const finances = await getFinances();
                                      financeStore.setFinances(finances);
                                  }
                                  if (castingStore.users.length === 0) {
                                      const users = await getUsers();
                                      castingStore.setUsers(users);
                                  }
                                  if (profileStore.profiles.length === 0) {
                                      const profiles = await getProfiles();
                                      profileStore.setProfiles(profiles);
                                  }
                                  if (castingStore.castings.length === 0) {
                                      const castings = await getCastings();
                                      castingStore.setCastings(castings);
                                  }
                                  if (castingAttributeStore.attributes.length === 0) {
                                      const castingAttrs = await getCastingAttributes();
                                      castingAttributeStore.setAttributes(castingAttrs);
                                  }
                                  const findUser = (id?: string) =>
                                      castingStore.users.find(attr => attr._id == id)?.artisticName ?? "";
                                  const findLabel = (id: string) =>
                                      castingAttributeStore.attributes.find(attr => attr._id == id)?.value;
                                  const findIdentification = (id: string) =>
                                      `Casting: ${castingStore.castings.find(c => c._id == id)?.title}`;
                                  const csvEntries = financeStore.finances.map(finance => {
                                      const entries = finance.entries ?? [];
                                      const casting = finance.casting
                                          ? castingStore.castings.find(c => c._id === finance.casting)
                                          : undefined;
                                      const margin =
                                          ((entries
                                              .filter(e => e.type === FinanceEntryType.IN)
                                              .reduce((acc, cur) => acc + cur.amount, 0) -
                                              entries
                                                  .filter(e => e.type === FinanceEntryType.OUT)
                                                  .reduce((acc, cur) => acc + cur.amount, 0) ?? 0) /
                                              entries
                                                  .filter(e => e.type === FinanceEntryType.IN)
                                                  .reduce((acc, cur) => acc + cur.amount, 0) ?? 1) * 100;

                                      return {
                                          identificacao: finance.casting
                                              ? findIdentification(finance.casting)
                                              : finance.identification,
                                          data: finance.createdAt ? formatDate(new Date(finance.createdAt)) : "",
                                          saldo: formatCurrency(
                                              (entries
                                                  .filter(e => e.type === FinanceEntryType.IN)
                                                  .reduce((acc, cur) => acc + cur.amount, 0) ?? 0) -
                                                  (entries
                                                      .filter(e => e.type === FinanceEntryType.OUT)
                                                      .reduce((acc, cur) => acc + cur.amount, 0) ?? 0) -
                                                  (entries
                                                      .filter(e => e.description === "3")
                                                      .reduce((acc, cur) => acc + cur.amount / 3, 0) ?? 0)
                                          ),
                                          margem:
                                              formatCurrency(isNaN(margin) ? 0 : isFinite(margin) ? margin : 0) + "%",
                                          pendencia: finance.entries?.some(
                                              e => e.status === FinanceEntryStatus.PENDING_NF
                                          )
                                              ? t("finance.entry.status.pendingNF")
                                              : finance.entries?.some(
                                                    e => e.status === FinanceEntryStatus.PENDING_PAYMENT
                                                )
                                              ? t("finance.entry.status.pendingPayment")
                                              : "",
                                          cliente: casting?.clients?.map(c => findLabel(c)).join(" | ") ?? "",
                                          categoria: casting?.category ? findLabel(casting.category) : "",
                                          produtora: casting?.producers?.map(p => findLabel(p)).join(" | ") ?? "",
                                          agente: casting?.agent ? findUser(casting.agent) : ""
                                      };
                                  });
                                  csvExporter.generateCsv(csvEntries);
                                  loadingStore.stopLoading();
                              }
                          },
                          {
                              icon: "dollar-sign",
                              value: t("menu.export-finance-entries"),
                              onClick: async () => {
                                  const csvOptions = {
                                      filename: language === "pt" ? "Financeiro-Lancamentos" : "Finance-Entries",
                                      fieldSeparator: ";",
                                      quoteStrings: '"',
                                      decimalSeparator: ".",
                                      showLabels: true,
                                      showTitle: true,
                                      title: language === "pt" ? "Financeiro - Lancamentos" : "Finance - Entries",
                                      useTextFile: false,
                                      useBom: true,
                                      headers: [
                                          "Data",
                                          "Tipo",
                                          "Valor",
                                          "Descrição",
                                          "Observação",
                                          "Status",
                                          "Artista",
                                          "Identificacao",
                                          "Cliente",
                                          "Tipo",
                                          "Produtora"
                                      ]
                                  };
                                  const csvExporter = new ExportToCsv(csvOptions);

                                  loadingStore.triggerLoading();
                                  if (financeStore.finances.length === 0) {
                                      const finances = await getFinances();
                                      financeStore.setFinances(finances);
                                  }
                                  if (profileStore.profiles.length === 0) {
                                      const profiles = await getProfiles();
                                      profileStore.setProfiles(profiles);
                                  }
                                  if (castingStore.castings.length === 0) {
                                      const castings = await getCastings();
                                      castingStore.setCastings(castings);
                                  }
                                  if (castingAttributeStore.attributes.length === 0) {
                                      const castingAttrs = await getCastingAttributes();
                                      castingAttributeStore.setAttributes(castingAttrs);
                                  }
                                  const findLabel = (id: string) =>
                                      castingAttributeStore.attributes.find(attr => attr._id == id)?.value;
                                  const findIdentification = (id: string) =>
                                      `Casting: ${castingStore.castings.find(c => c._id == id)?.title}`;
                                  const findStatus = (status: FinanceEntryStatus) => {
                                      switch (status) {
                                          case FinanceEntryStatus.PENDING:
                                              return t("finance.entry.status.pending");
                                          case FinanceEntryStatus.PENDING_NF:
                                              return t("finance.entry.status.pendingNF");
                                          case FinanceEntryStatus.PENDING_PAYMENT:
                                              return t("finance.entry.status.pendingPayment");
                                          case FinanceEntryStatus.FINISHED:
                                              return t("finance.entry.status.finished");
                                      }
                                  };
                                  const findDescription = (description: string) => {
                                      const descNumber = Number(description);
                                      if (isNaN(descNumber)) {
                                          return description;
                                      }
                                      switch (descNumber) {
                                          case FinanceDescriptionInType.ARTIST_PAYMENT:
                                              return t("finance.entry.observation.artist-payment");
                                          case FinanceDescriptionInType.PAYMENT_EXTERNAL_CUSTOMERS:
                                              return t("finance.entry.observation.payment-external-customers");
                                          case FinanceDescriptionInType.OTHER_ENTRY:
                                              return t("finance.entry.observation.other-entry");
                                          case FinanceDescriptionOutType.PAYMENT_ARTIST_PF:
                                              return t("finance.entry.observation.payment-artist-pf");
                                          case FinanceDescriptionOutType.PAYMENT_ARTIST_PJ:
                                              return t("finance.entry.observation.payment-artist-pj");
                                          case FinanceDescriptionOutType.OPERACIONAL_COSTS:
                                              return t("finance.entry.observation.operacional-costs");
                                          case FinanceDescriptionOutType.OTHER_OUT:
                                              return t("finance.entry.observation.other-out");
                                      }
                                  };
                                  const csvEntries = financeStore.finances.flatMap(finance => {
                                      const entries = finance.entries ?? [];
                                      const casting = finance.casting
                                          ? castingStore.castings.find(c => c._id === finance.casting)
                                          : undefined;

                                      return entries.map(entry => ({
                                          data: entry.estimatedDate ? formatDate(new Date(entry.estimatedDate)) : "",
                                          tipo: t(
                                              `finance.entry.type.${entry.type === FinanceEntryType.IN ? "in" : "out"}`
                                          ),
                                          valor: formatCurrency(entry.amount),
                                          descricao: findDescription(entry.description),
                                          observacao: entry.obs ?? "",
                                          status: findStatus(entry.status),
                                          artista: Array.isArray(entry.artist)
                                              ? entry.artist
                                                    ?.map(
                                                        artist =>
                                                            profileStore.profiles.find(p => p._id === artist)
                                                                ?.artisticName
                                                    )
                                                    .join(" | ")
                                              : entry.artist ?? "",
                                          identificacao: finance.casting
                                              ? findIdentification(finance.casting)
                                              : finance.identification,
                                          cliente: casting?.clients?.map(c => findLabel(c)).join(" | ") ?? "",
                                          categoria: casting?.category ? findLabel(casting.category) : "",
                                          produtora: casting?.producers?.map(p => findLabel(p)).join(" | ") ?? ""
                                      }));
                                  });
                                  csvExporter.generateCsv(csvEntries);
                                  loadingStore.stopLoading();
                              }
                          }
                      ]
                    : []),
                {
                    icon: "log-out",
                    value: t("menu.logoff"),
                    onClick: () => {
                        sessionStore.clearCurrentSession();
                        profileStore.clearCurrentSession();
                        push("/login");
                    }
                }
            ]
        };

        const onSubmit = async (values: any, { resetForm }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            setChangePassword(false);
            changePassword(values.newPassword)
                .then(result => {
                    loadingStore.stopLoading();
                    if (result._id) {
                        toast.success(t("success.updated"));
                        profileStore.updateProfile(result);
                        sessionStore.updateProfile(result);
                        resetForm();
                    } else {
                        setChangePassword(true);
                        toast.error(t("error.updating"));
                    }
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    setChangePassword(true);
                    toast.error(t("error.inviting"));
                });
        };

        return sessionStore.logged ? (
            <>
                {showChangePassword && (
                    <Popup title={t("menu.change_password")} onClose={() => setChangePassword(false)}>
                        <Formik
                            initialValues={{
                                newPassword: "",
                                newPasswordCheck: ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.newPassword) {
                                    errors.newPassword = t("form.errors.required");
                                }
                                if (!values.newPasswordCheck) {
                                    errors.newPasswordCheck = t("form.errors.required");
                                } else if (values.newPassword !== values.newPasswordCheck) {
                                    errors.newPasswordCheck = t("form.errors.invalid");
                                }
                                return errors;
                            }}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-table">
                                        <div className="flex-row">
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>{t("profile.new_password")}</Form.Label>
                                                    <Form.Input
                                                        type="password"
                                                        name="newPassword"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.newPassword}
                                                        error={errors && errors.newPassword}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>{t("profile.new_password_confirmation")}</Form.Label>
                                                    <Form.Input
                                                        type="password"
                                                        name="newPasswordCheck"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.newPasswordCheck}
                                                        error={errors && errors.newPasswordCheck}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <Form.Footer>
                                            <Button type="submit" color="primary" icon="save">
                                                {t("proceed")}
                                            </Button>
                                            <Button
                                                color="danger"
                                                icon="x"
                                                onClick={() => {
                                                    resetForm();
                                                }}
                                            >
                                                {t("cancel")}
                                            </Button>
                                        </Form.Footer>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Popup>
                )}
                <Site.Header align="left" onMenuToggleClick={() => setCollapse(false)}>
                    <div className="logo">
                        <Site.Logo src="./assets/logo-gaia.png" alt="Gaia" />
                        <TablerHeader.H3>{t("title")}</TablerHeader.H3>
                    </div>
                    <ul className="profile">
                        <AccountDropdown {...accountDropdownProps} />
                    </ul>
                </Site.Header>
                {sessionStore.session.profile.updatedAt && <Site.Nav collapse={collapse}>{navigation}</Site.Nav>}
            </>
        ) : null;
    })
);
