import { Button, Card, Form, Header } from "tabler-react";
import { stamps } from "./profile-sections/PersonalInfo";
import { Class, Profile, Skill, StatusEnum } from "../models";
import { Range } from "react-range";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { countries, states } from "../utils/countries";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { IThumbProps, ITrackProps } from "react-range/lib/types";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { advancedSearch } from "../services/profile.service";
import { removeAgeFromDate } from "../utils/formats";
import { toast } from "react-toastify";
import { getSkills } from "../services/skills.service";
import { getClasses } from "../services/classes.service";

const renderTrack = ({ props, children }: { props: ITrackProps; children: ReactNode }) => (
    <div {...props} style={props.style} className="range-slider-track">
        {children}
    </div>
);

const renderThumb = (props: IThumbProps, value: string) => (
    <div {...props} style={props.style} className="range-slider-thumb">
        <div className="range-slider-label">{value}</div>
    </div>
);

interface AdvancedSearchProps extends Stores {
    onSearch: (profiles: Profile[]) => void;
    onError: () => void;
}

export const AdvancedSearch = inject("rootStore")(
    observer((props: AdvancedSearchProps) => {
        const { loadingStore, skillsStore, classesStore } = props.rootStore!;
        const { t } = useTranslation();

        const [ages, setAges] = useState<number[]>([18, 60]);
        const [heights, setHeights] = useState<number[]>([120, 170]);
        const [weights, setWeights] = useState<number[]>([40, 80]);
        const [clothesSizes, setClothesSizes] = useState<number[]>([36, 44]);
        const [footSizes, setFootSizes] = useState<number[]>([34, 44]);
        const [skills, setSkills] = useState<Skill[]>([]);

        // Enabling controls
        const [nameVisible, setNameVisible] = useState(false);
        const [stampsVisible, setStampsVisible] = useState(false);
        const [classesVisible, setClassesVisible] = useState(false);
        const [gendersVisible, setGendersVisible] = useState(false);
        const [skinsVisible, setSkinsVisible] = useState(false);
        const [ethnicitiesVisible, setEthnicitiesVisible] = useState(false);
        const [hairsVisible, setHairsVisible] = useState(false);
        const [eyesVisible, setEyesVisible] = useState(false);
        const [exclusivityVisible, setExclusivityVisible] = useState(false);
        const [drtVisible, setDrtVisible] = useState(false);
        const [extraVisible, setExtraVisible] = useState(false);
        const [driversLicensesVisible, setDriversLicensesVisible] = useState(false);
        const [idiomsVisible, setIdiomsVisible] = useState(false);
        const [dancesVisible, setDancesVisible] = useState(false);
        const [musicsVisible, setMusicsVisible] = useState(false);
        const [artsVisible, setArtsVisible] = useState(false);
        const [sportsVisible, setSportsVisible] = useState(false);
        const [dramaticArtsVisible, setDramaticArtsVisible] = useState(false);
        const [circusVisible, setCircusVisible] = useState(false);
        const [agesVisible, setAgesVisible] = useState(false);
        const [weightsVisible, setWeightsVisible] = useState(false);
        const [heightsVisible, setHeightsVisible] = useState(false);
        const [clothesSizesVisible, setClothesSizesVisible] = useState(false);
        const [footSizesVisible, setFootSizesVisible] = useState(false);
        const [citizenshipVisible, setCitizenshipVisible] = useState(false);
        const [birthStateVisible, setBirthStateVisible] = useState(false);
        const [instagramVisible, setInstagramVisible] = useState(false);
        const [tiktokVisible, setTikTokVisible] = useState(false);
        const [youtubeVisible, setYoutubeVisible] = useState(false);
        const [twitterVisible, setTwitterVisible] = useState(false);
        const [kwaiVisible, setKwaiVisible] = useState(false);
        const [onlyActives, setOnlyActives] = useState(true);

        const genders = [
            { label: t("profile.gender.feminine"), value: "F" },
            { label: t("profile.gender.masculine"), value: "M" },
            { label: t("profile.gender.non-binary"), value: "NB" }
        ];

        const hairs = [
            { label: t("profile.hair.black"), value: "black" },
            { label: t("profile.hair.brown"), value: "brown" },
            { label: t("profile.hair.blond"), value: "blond" },
            { label: t("profile.hair.ginger"), value: "ginger" },
            { label: t("profile.hair.grey"), value: "grey" },
            { label: t("profile.hair.colored"), value: "colored" }
        ];
        const eyes = [
            { label: t("profile.eyes.black"), value: "black" },
            { label: t("profile.eyes.brown"), value: "brown" },
            { label: t("profile.eyes.green"), value: "green" },
            { label: t("profile.eyes.blue"), value: "blue" },
            { label: t("profile.eyes.grey"), value: "grey" },
            { label: t("profile.eyes.violet"), value: "violet" },
            { label: t("profile.eyes.heterochromic"), value: "heterochromic" }
        ];
        const colors = [
            { label: t("profile.skin.color1"), value: "color1" },
            { label: t("profile.skin.color2"), value: "color2" },
            { label: t("profile.skin.color3"), value: "color3" },
            { label: t("profile.skin.color4"), value: "color4" },
            { label: t("profile.skin.color5"), value: "color5" },
            { label: t("profile.skin.color6"), value: "color6" }
        ];
        const ethnicities = [
            { label: t("profile.ethnicity.african"), value: "african" },
            { label: t("profile.ethnicity.brazilian"), value: "brazilian" },
            { label: t("profile.ethnicity.european"), value: "european" },
            { label: t("profile.ethnicity.asian"), value: "asian" },
            { label: t("profile.ethnicity.arabic"), value: "arabic" },
            { label: t("profile.ethnicity.native"), value: "native" },
            { label: t("profile.ethnicity.latin"), value: "latin" }
        ];

        const yesNo = [
            { label: t("yes"), value: "yes" },
            { label: t("no"), value: "no" }
        ];

        useEffect(() => {
            if (skillsStore.skills.length === 0) {
                loadingStore.triggerLoading();
                getSkills()
                    .then(skills => {
                        skillsStore.setSkills(skills);
                        setSkills(skillsStore.skills);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            } else {
                setSkills(skillsStore.skills);
            }
        }, []);

        useEffect(() => {
            if (classesStore.classes.length === 0) {
                loadingStore.triggerLoading();
                getClasses()
                    .then(classes => {
                        classesStore.setClasses(classes);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                    });
            }
        }, [classesStore.classes]);

        const onSubmit = async (values: any) => {
            loadingStore.triggerLoading();
            advancedSearch({
                filters: {
                    ...(nameVisible
                        ? {
                              $or: [
                                  { fullName: { $regex: values.name, $options: "i" } },
                                  { artisticName: { $regex: values.name, $options: "i" } }
                              ]
                          }
                        : {}),
                    ...(stampsVisible
                        ? {
                              $or: [
                                  {
                                      "personalInfo.stamp": {
                                          $in: values.stamps
                                      }
                                  },
                                  ...(values.stamps.findIndex((st: string) => st === "open") > -1
                                      ? [{ "personalInfo.stamp": null }]
                                      : [])
                              ]
                          }
                        : {}),
                    ...(classesVisible ? { "personalInfo.classes": { $in: values.classes } } : {}),
                    ...(gendersVisible ? { gender: { $in: values.genders } } : {}),
                    ...(agesVisible
                        ? {
                              birthDate: {
                                  $gte: removeAgeFromDate(ages[1] + 1),
                                  $lte: removeAgeFromDate(ages[0])
                              }
                          }
                        : {}),
                    ...(skinsVisible ? { "personalInfo.skin": { $in: values.skins } } : {}),
                    ...(ethnicitiesVisible ? { "personalInfo.ethnicity": { $in: values.ethnicities } } : {}),
                    ...(hairsVisible ? { "personalInfo.hair": { $in: values.hairs } } : {}),
                    ...(eyesVisible ? { "personalInfo.eyes": { $in: values.eyes } } : {}),
                    ...(heightsVisible
                        ? {
                              "personalInfo.height": {
                                  $gte: heights[0],
                                  $lte: heights[1]
                              }
                          }
                        : {}),
                    ...(weightsVisible
                        ? {
                              "personalInfo.weight": {
                                  $gte: weights[0],
                                  $lte: weights[1]
                              }
                          }
                        : {}),
                    ...(clothesSizesVisible
                        ? {
                              "personalInfo.clothingSize": {
                                  $gte: clothesSizes[0],
                                  $lte: clothesSizes[1]
                              }
                          }
                        : {}),
                    ...(footSizesVisible
                        ? {
                              "personalInfo.footSize": {
                                  $gte: footSizes[0],
                                  $lte: footSizes[1]
                              }
                          }
                        : {}),
                    ...(exclusivityVisible ? { exclusive: values.exclusivity === "yes" } : {}),
                    ...(drtVisible
                        ? values.drt === "yes"
                            ? { $and: [{ "contractData.drt": { $exists: true } }, { "contractData.drt": { $ne: "" } }] }
                            : { $or: [{ "contractData.drt": { $exists: false } }, { "contractData.drt": { $eq: "" } }] }
                        : {}),
                    ...(extraVisible ? { "career.doYouPlayExtra": values.do_you_play_extra === "yes" } : {}),
                    ...(driversLicensesVisible ? { "skills.driversLicense": { $in: values.driversLicense } } : {}),
                    ...(idiomsVisible ? { "skills.idioms": { $in: values.idioms } } : {}),
                    ...(dramaticArtsVisible ? { "skills.dramaticArts": { $in: values.dramaticArts } } : {}),
                    ...(dancesVisible ? { "skills.dances": { $in: values.dances } } : {}),
                    ...(musicsVisible ? { "skills.music": { $in: values.music } } : {}),
                    ...(artsVisible ? { "skills.arts": { $in: values.arts } } : {}),
                    ...(sportsVisible ? { "skills.sports": { $in: values.sports } } : {}),
                    ...(circusVisible ? { "skills.circus": { $in: values.circus } } : {}),
                    ...(citizenshipVisible ? { citizenship: values.citizenship } : {}),
                    ...(birthStateVisible ? { birthState: values.birthState } : {}),
                    ...(instagramVisible && values.instagramMin
                        ? {
                              $and: [
                                  { "socialNetworks.instagramFollowers": { $exists: true } },
                                  {
                                      "socialNetworks.instagramFollowers": {
                                          $gte: values.instagramMin,
                                          ...(values.instagramMax ? { $lte: values.instagramMax } : {})
                                      }
                                  }
                              ]
                          }
                        : {}),
                    ...(tiktokVisible && values.tiktokMin
                        ? {
                              $and: [
                                  { "socialNetworks.tiktokFollowers": { $exists: true } },
                                  {
                                      "socialNetworks.tiktokFollowers": {
                                          $gte: values.tiktokMin,
                                          ...(values.tiktokMax ? { $lte: values.tiktokMax } : {})
                                      }
                                  }
                              ]
                          }
                        : {}),
                    ...(youtubeVisible && values.youtubeMin
                        ? {
                              $and: [
                                  { "socialNetworks.youtubeFollowers": { $exists: true } },
                                  {
                                      "socialNetworks.youtubeFollowers": {
                                          $gte: values.youtubeMin,
                                          ...(values.youtubeMax ? { $lte: values.youtubeMax } : {})
                                      }
                                  }
                              ]
                          }
                        : {}),
                    ...(twitterVisible && values.twitterMin
                        ? {
                              $and: [
                                  { "socialNetworks.twitterFollowers": { $exists: true } },
                                  {
                                      "socialNetworks.twitterFollowers": {
                                          $gte: values.twitterMin,
                                          ...(values.twitterMax ? { $lte: values.twitterMax } : {})
                                      }
                                  }
                              ]
                          }
                        : {}),
                    ...(kwaiVisible && values.kwaiMin
                        ? {
                              $and: [
                                  { "socialNetworks.kwaiFollowers": { $exists: true } },
                                  {
                                      "socialNetworks.kwaiFollowers": {
                                          $gte: values.kwaiMin,
                                          ...(values.kwaiMax ? { $lte: values.kwaiMax } : {})
                                      }
                                  }
                              ]
                          }
                        : {}),
                    ...(onlyActives ? { status: StatusEnum.ACTIVE } : {})
                }
            })
                .then((results: Profile[]) => {
                    props.onSearch(results);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.searching"));
                    loadingStore.stopLoading();
                    props.onError();
                });
        };

        return (
            <Formik
                enableReinitialize
                initialValues={{
                    name: "",
                    stamps: [],
                    classes: [],
                    genders: [],
                    skins: [],
                    ethnicities: [],
                    hairs: [],
                    eyes: [],
                    exclusivity: "no",
                    drt: "no",
                    do_you_play_extra: "no",
                    driversLicense: [],
                    idioms: [],
                    dances: [],
                    music: [],
                    arts: [],
                    sports: [],
                    dramaticArts: [],
                    circus: [],
                    citizenship: "",
                    birthState: "",
                    instagramMin: undefined,
                    instagramMax: undefined,
                    tiktokMin: undefined,
                    tiktokMax: undefined,
                    youtubeMin: undefined,
                    youtubeMax: undefined,
                    twitterMin: undefined,
                    twitterMax: undefined,
                    kwaiMin: undefined,
                    kwaiMax: undefined
                }}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <Header.H3>{t("search.filters")}</Header.H3>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.artistic_name")}
                                                name="nameVisible"
                                                value="yes"
                                                onChange={() => setNameVisible(prev => !prev)}
                                                checked={nameVisible}
                                            />
                                            {nameVisible && (
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <Form.Input
                                                            type="text"
                                                            placeholder="João Reis"
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.name}
                                                            style={{ minWidth: "50px" }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.stamp")}
                                                name="driversLicense"
                                                value="yes"
                                                onChange={() => setStampsVisible(prev => !prev)}
                                                checked={stampsVisible}
                                            />
                                            {stampsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {stamps.map((stamp, i) => (
                                                        <Form.SelectGroupItem
                                                            key={`stamps_${i}`}
                                                            label={stamp.label}
                                                            name="stamps"
                                                            value={stamp.value}
                                                            onChange={handleChange}
                                                            checked={
                                                                values.stamps.findIndex(st => st === stamp.value) > -1
                                                            }
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.class.label")}
                                                name="classesVisible"
                                                value="yes"
                                                onChange={() => setClassesVisible(prev => !prev)}
                                                checked={classesVisible}
                                            />
                                            {classesVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {classesStore.classes.map(v => (
                                                        <Form.SelectGroupItem
                                                            key={`class_${v.key}`}
                                                            label={
                                                                v[
                                                                    `name${t(
                                                                        "languageCode"
                                                                    ).toUpperCase()}` as keyof Class
                                                                ]
                                                            }
                                                            name="classes"
                                                            value={v.key}
                                                            onChange={handleChange}
                                                            checked={!!values.classes.find(d => d === v.key)}
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.gender.label")}
                                                name="gendersVisible"
                                                value="yes"
                                                onChange={() => setGendersVisible(prev => !prev)}
                                                checked={gendersVisible}
                                            />
                                            {gendersVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {genders.map(v => (
                                                        <Form.SelectGroupItem
                                                            key={`gender_${v.value}`}
                                                            label={v.label}
                                                            name="genders"
                                                            value={v.value}
                                                            onChange={handleChange}
                                                            checked={!!values.genders.find(d => d === v.value)}
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className={`flex-column ${agesVisible ? "range-slider-container" : ""}`}>
                                        <Form.Checkbox
                                            label={t("profile.age")}
                                            name="agesVisible"
                                            value="yes"
                                            onChange={() => setAgesVisible(prev => !prev)}
                                            checked={agesVisible}
                                        />
                                        <div style={{ visibility: agesVisible ? "visible" : "hidden" }}>
                                            <Range
                                                step={1}
                                                min={16}
                                                max={90}
                                                values={ages}
                                                onChange={values => setAges(values)}
                                                renderTrack={renderTrack}
                                                renderThumb={useCallback(
                                                    ({ index, props }) => renderThumb(props, ages[index].toFixed(0)),
                                                    [ages]
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skin.label")}
                                                name="skinsVisible"
                                                value="yes"
                                                onChange={() => setSkinsVisible(prev => !prev)}
                                                checked={skinsVisible}
                                            />
                                            {skinsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {colors.map(v => (
                                                        <Form.SelectGroupItem
                                                            key={`skin_${v.value}`}
                                                            label={v.label}
                                                            name="skins"
                                                            value={v.value}
                                                            onChange={handleChange}
                                                            checked={!!values.skins.find(d => d === v.value)}
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.ethnicity.label")}
                                                name="ethnicitiesVisible"
                                                value="yes"
                                                onChange={() => setEthnicitiesVisible(prev => !prev)}
                                                checked={ethnicitiesVisible}
                                            />
                                            {ethnicitiesVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {ethnicities.map(v => (
                                                        <Form.SelectGroupItem
                                                            key={`ethnicity_${v.value}`}
                                                            label={v.label}
                                                            name="ethnicities"
                                                            value={v.value}
                                                            onChange={handleChange}
                                                            checked={!!values.ethnicities.find(d => d === v.value)}
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.hair.label")}
                                                name="hairsVisible"
                                                value="yes"
                                                onChange={() => setHairsVisible(prev => !prev)}
                                                checked={hairsVisible}
                                            />
                                            {hairsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {hairs.map(v => (
                                                        <Form.SelectGroupItem
                                                            key={`hair_${v.value}`}
                                                            label={v.label}
                                                            name="hairs"
                                                            value={v.value}
                                                            onChange={handleChange}
                                                            checked={!!values.hairs.find(d => d === v.value)}
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.eyes.label")}
                                                name="eyesVisible"
                                                value="yes"
                                                onChange={() => setEyesVisible(prev => !prev)}
                                                checked={eyesVisible}
                                            />
                                            {eyesVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {eyes.map(v => (
                                                        <Form.SelectGroupItem
                                                            key={`eye_${v.value}`}
                                                            label={v.label}
                                                            name="eyes"
                                                            value={v.value}
                                                            onChange={handleChange}
                                                            checked={!!values.eyes.find(d => d === v.value)}
                                                        />
                                                    ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className={`flex-column ${heightsVisible ? "range-slider-container" : ""}`}>
                                        <Form.Checkbox
                                            label={t("profile.height")}
                                            name="heightsVisible"
                                            value="yes"
                                            onChange={() => setHeightsVisible(prev => !prev)}
                                            checked={heightsVisible}
                                        />
                                        <div style={{ visibility: heightsVisible ? "visible" : "hidden" }}>
                                            <Range
                                                step={1}
                                                min={30}
                                                max={215}
                                                values={heights}
                                                onChange={values => setHeights(values)}
                                                renderTrack={renderTrack}
                                                renderThumb={useCallback(
                                                    ({ index, props }) => renderThumb(props, heights[index].toFixed(0)),
                                                    [heights]
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className={`flex-column ${weightsVisible ? "range-slider-container" : ""}`}>
                                        <Form.Checkbox
                                            label={t("profile.weight")}
                                            name="weightsVisible"
                                            value="yes"
                                            onChange={() => setWeightsVisible(prev => !prev)}
                                            checked={weightsVisible}
                                        />
                                        <div style={{ visibility: weightsVisible ? "visible" : "hidden" }}>
                                            <Range
                                                step={1}
                                                min={1}
                                                max={250}
                                                values={weights}
                                                onChange={values => setWeights(values)}
                                                renderTrack={renderTrack}
                                                renderThumb={useCallback(
                                                    ({ index, props }) => renderThumb(props, weights[index].toFixed(0)),
                                                    [weights]
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div
                                        className={`flex-column ${clothesSizesVisible ? "range-slider-container" : ""}`}
                                    >
                                        <Form.Checkbox
                                            label={t("profile.clothing_size")}
                                            name="clothingSizesVisible"
                                            value="yes"
                                            onChange={() => setClothesSizesVisible(prev => !prev)}
                                            checked={clothesSizesVisible}
                                        />
                                        <div style={{ visibility: clothesSizesVisible ? "visible" : "hidden" }}>
                                            <Range
                                                step={1}
                                                min={34}
                                                max={60}
                                                values={clothesSizes}
                                                onChange={values => setClothesSizes(values)}
                                                renderTrack={renderTrack}
                                                renderThumb={useCallback(
                                                    ({ index, props }) =>
                                                        renderThumb(props, clothesSizes[index].toFixed(0)),
                                                    [clothesSizes]
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className={`flex-column ${footSizesVisible ? "range-slider-container" : ""}`}>
                                        <Form.Checkbox
                                            label={t("profile.foot_size")}
                                            name="footSizesVisible"
                                            value="yes"
                                            onChange={() => setFootSizesVisible(prev => !prev)}
                                            checked={footSizesVisible}
                                        />
                                        <div style={{ visibility: footSizesVisible ? "visible" : "hidden" }}>
                                            <Range
                                                step={1}
                                                min={32}
                                                max={50}
                                                values={footSizes}
                                                onChange={values => setFootSizes(values)}
                                                renderTrack={renderTrack}
                                                renderThumb={useCallback(
                                                    ({ index, props }) =>
                                                        renderThumb(props, footSizes[index].toFixed(0)),
                                                    [footSizes]
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.exclusivity")}
                                                name="exclusivityVisible"
                                                value="yes"
                                                onChange={() => setExclusivityVisible(prev => !prev)}
                                                checked={exclusivityVisible}
                                            />
                                            {exclusivityVisible &&
                                                yesNo.map(value => (
                                                    <Form.Radio
                                                        isInline
                                                        key={`exclusivity_${value.value}`}
                                                        label={value.label}
                                                        name="exclusivity"
                                                        value={value.value}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.exclusivity === value.value}
                                                    />
                                                ))}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.drt")}
                                                name="drtVisible"
                                                value="yes"
                                                onChange={() => setDrtVisible(prev => !prev)}
                                                checked={drtVisible}
                                            />
                                            {drtVisible &&
                                                yesNo.map(value => (
                                                    <Form.Radio
                                                        isInline
                                                        key={`drt_${value.value}`}
                                                        label={value.label}
                                                        name="drt"
                                                        value={value.value}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.drt === value.value}
                                                    />
                                                ))}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.do_you_play_extra")}
                                                name="extraVisible"
                                                value="yes"
                                                onChange={() => setExtraVisible(prev => !prev)}
                                                checked={extraVisible}
                                            />
                                            {extraVisible &&
                                                yesNo.map(value => (
                                                    <Form.Radio
                                                        isInline
                                                        key={`do_you_play_extra_${value.value}`}
                                                        label={value.label}
                                                        name="do_you_play_extra"
                                                        value={value.value}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.do_you_play_extra === value.value}
                                                    />
                                                ))}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.drivers-license")}
                                                name="driversLicensesVisible"
                                                value="yes"
                                                onChange={() => setDriversLicensesVisible(prev => !prev)}
                                                checked={driversLicensesVisible}
                                            />
                                            {driversLicensesVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "driver-license")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`driver_license_${index}`}
                                                                isInline
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="driversLicense"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.driversLicense.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.idioms")}
                                                name="idiomsVisible"
                                                value="yes"
                                                onChange={() => setIdiomsVisible(prev => !prev)}
                                                checked={idiomsVisible}
                                            />
                                            {idiomsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "idiom")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`idioms_${index}`}
                                                                isInline
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="idioms"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.idioms.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.dramatic-arts")}
                                                name="dramaticArtsVisible"
                                                value="yes"
                                                onChange={() => setDramaticArtsVisible(prev => !prev)}
                                                checked={dramaticArtsVisible}
                                            />
                                            {dramaticArtsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "dramatic-art")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`dramatic-arts_${index}`}
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="dramaticArts"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.dramaticArts.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.dances")}
                                                name="dancesVisible"
                                                value="yes"
                                                onChange={() => setDancesVisible(prev => !prev)}
                                                checked={dancesVisible}
                                            />
                                            {dancesVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "dance")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`dances_${index}`}
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="dances"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.dances.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.music")}
                                                name="musicsVisible"
                                                value="yes"
                                                onChange={() => setMusicsVisible(prev => !prev)}
                                                checked={musicsVisible}
                                            />
                                            {musicsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "music")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`music_${index}`}
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="music"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.music.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.arts")}
                                                name="artsVisible"
                                                value="yes"
                                                onChange={() => setArtsVisible(prev => !prev)}
                                                checked={artsVisible}
                                            />
                                            {artsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "art")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`arts_${index}`}
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="arts"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.arts.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.sports")}
                                                name="sportsVisible"
                                                value="yes"
                                                onChange={() => setSportsVisible(prev => !prev)}
                                                checked={sportsVisible}
                                            />
                                            {sportsVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "sport")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`sports_${index}`}
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="sports"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.sports.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.skills.circus")}
                                                name="circusVisible"
                                                value="yes"
                                                onChange={() => setCircusVisible(prev => !prev)}
                                                checked={circusVisible}
                                            />
                                            {circusVisible && (
                                                <Form.SelectGroup canSelectMultiple pills>
                                                    {skills
                                                        .filter(v => v.type === "circus")
                                                        .map((v, index) => (
                                                            <Form.SelectGroupItem
                                                                key={`circus_${index}`}
                                                                label={
                                                                    v[
                                                                        `description${t(
                                                                            "languageCode"
                                                                        ).toUpperCase()}` as keyof Skill
                                                                    ]
                                                                }
                                                                name="circus"
                                                                value={v._id}
                                                                onChange={handleChange}
                                                                checked={!!values.circus.find(d => d === v._id)}
                                                            />
                                                        ))}
                                                </Form.SelectGroup>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.citizenship")}
                                                name="citizenshipVisible"
                                                value="yes"
                                                onChange={() => setCitizenshipVisible(prev => !prev)}
                                                checked={citizenshipVisible}
                                            />
                                            {citizenshipVisible && (
                                                <Form.Select
                                                    name="citizenship"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.citizenship}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {countries.map(country => (
                                                        <option key={`country_${country.code}`} value={country.code}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("profile.birth_state")}
                                                name="birthStateVisible"
                                                value="yes"
                                                onChange={() => setBirthStateVisible(prev => !prev)}
                                                checked={birthStateVisible}
                                            />
                                            {birthStateVisible && (
                                                <Form.Select
                                                    name="birthState"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.birthState}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {states.map(state => (
                                                        <option key={`state_${state.code}`} value={state.code}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label="Instagram"
                                                name="instagramVisible"
                                                value="yes"
                                                onChange={() => setInstagramVisible(prev => !prev)}
                                                checked={instagramVisible}
                                            />
                                            {instagramVisible && (
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Min</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="1000"
                                                                name="instagramMin"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.instagramMin}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Max</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="15000"
                                                                name="instagramMax"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.instagramMax}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label="TikTok"
                                                name="tiktokVisible"
                                                value="yes"
                                                onChange={() => setTikTokVisible(prev => !prev)}
                                                checked={tiktokVisible}
                                            />
                                            {tiktokVisible && (
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Min</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="1000"
                                                                name="tiktokMin"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.tiktokMin}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Max</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="15000"
                                                                name="tiktokMax"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.tiktokMax}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label="Youtube"
                                                name="youtubeVisible"
                                                value="yes"
                                                onChange={() => setYoutubeVisible(prev => !prev)}
                                                checked={youtubeVisible}
                                            />
                                            {youtubeVisible && (
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Min</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="1000"
                                                                name="youtubeMin"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.youtubeMin}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Max</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="15000"
                                                                name="youtubeMax"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.youtubeMax}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label="Twitter"
                                                name="twitterVisible"
                                                value="yes"
                                                onChange={() => setTwitterVisible(prev => !prev)}
                                                checked={twitterVisible}
                                            />
                                            {twitterVisible && (
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Min</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="1000"
                                                                name="twitterMin"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.twitterMin}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Max</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="15000"
                                                                name="twitterMax"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.twitterMax}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label="Kwai"
                                                name="kwaiVisible"
                                                value="yes"
                                                onChange={() => setKwaiVisible(prev => !prev)}
                                                checked={kwaiVisible}
                                            />
                                            {kwaiVisible && (
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Min</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="1000"
                                                                name="kwaiMin"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.kwaiMin}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="flex-column">
                                                        <Form.Group>
                                                            <Form.Label>Max</Form.Label>
                                                            <Form.Input
                                                                type="number"
                                                                placeholder="15000"
                                                                name="kwaiMax"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.kwaiMax}
                                                                style={{ minWidth: "50px", maxWidth: "150px" }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Checkbox
                                                label={t("search.only-active-profiles")}
                                                name="onlyActives"
                                                value="yes"
                                                onChange={() => setOnlyActives(prev => !prev)}
                                                checked={onlyActives}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <Button type="submit" color="primary" icon="search">
                                        {t("search.search")}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            setStampsVisible(false);
                                            setClassesVisible(false);
                                            setGendersVisible(false);
                                            setSkinsVisible(false);
                                            setEthnicitiesVisible(false);
                                            setHairsVisible(false);
                                            setEyesVisible(false);
                                            setExclusivityVisible(false);
                                            setDrtVisible(false);
                                            setExtraVisible(false);
                                            setDriversLicensesVisible(false);
                                            setIdiomsVisible(false);
                                            setDancesVisible(false);
                                            setMusicsVisible(false);
                                            setArtsVisible(false);
                                            setSportsVisible(false);
                                            setDramaticArtsVisible(false);
                                            setCircusVisible(false);
                                            setAgesVisible(false);
                                            setWeightsVisible(false);
                                            setHeightsVisible(false);
                                            setClothesSizesVisible(false);
                                            setFootSizesVisible(false);
                                            setCitizenshipVisible(false);
                                            setBirthStateVisible(false);
                                            setInstagramVisible(false);
                                            setYoutubeVisible(false);
                                            setTikTokVisible(false);
                                            setTwitterVisible(false);
                                            setKwaiVisible(false);
                                            setOnlyActives(false);
                                        }}
                                    >
                                        {t("search.clean-filters")}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    })
);
