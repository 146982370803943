import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useEffect } from "react";
import { getCastingAttributes } from "../../services/casting.service";
import { toast } from "react-toastify";
import { getUsers } from "../../services/profile.service";
import { useTranslation } from "react-i18next";
import { Specification } from "./Specification";
import { Details } from "./Details";

export const Information = inject("rootStore")(
    observer((props: Stores) => {
        const { castingStore, castingAttributeStore, loadingStore } = props.rootStore!;
        const { t } = useTranslation();
        useEffect(() => {
            if (castingAttributeStore.attributes.length === 0 || castingStore.users.length === 0) {
                loadingStore.triggerLoading();
                Promise.all([getCastingAttributes(), getUsers()])
                    .then(([attributes, users]) => {
                        castingAttributeStore.setAttributes(attributes);
                        castingStore.setUsers(users);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        toast.error(t("error.loading"));
                        loadingStore.stopLoading();
                    });
            }
        }, []);

        return (
            <div className="flex-table">
                <div className="flex-row">
                    <div className="flex-column">
                        <Specification />
                    </div>
                    <div className="flex-column">
                        <Details />
                    </div>
                </div>
            </div>
        );
    })
);
