import { del, get, post, postMultiPart, postReturnFile, put } from "./request.service";
import { config } from "../utils/constants";
import {
    GeneralInformation,
    InviteProfileRequest,
    PersonalInformation,
    Profile,
    PublicProfile,
    Session
} from "../models";

declare type Option<T> = T | undefined;

export async function getProfileDetails(session?: Session): Promise<Profile> {
    return get(`${config.url}/profile/me`, session);
}

export async function getProfiles(session?: Session): Promise<Profile[]> {
    return get(`${config.url}/profile/all`, session);
}

export async function updatePartialInfo<T extends Profile, K extends keyof T>(type: K, body: T[K]): Promise<Profile> {
    return put(`${config.url}/profile/update-partial/${type}`, body);
}

export async function updatePartialInfoWithApproval<T extends Profile, K extends keyof T>(
    type: K,
    body: T[K]
): Promise<Profile> {
    return put(`${config.url}/profile/update-partial-approval/${type}`, body);
}

export async function updatePartialInfoAdmin<T extends Profile, K extends keyof T>(
    id: string,
    type: K,
    body: T[K]
): Promise<Profile> {
    return put(`${config.url}/profile/${id}/update-partial/${type}`, body);
}

export async function agreeToLgpd(body: any): Promise<Profile> {
    return put(`${config.url}/profile/agree-lgpd`, body);
}

export async function agreeToTermsAndConditions(body: any): Promise<Profile> {
    return put(`${config.url}/profile/agree-terms-conditions`, body);
}

export async function inviteProfile(body: InviteProfileRequest): Promise<Profile> {
    return post(`${config.url}/profile/invite`, body);
}

export async function forgotPassword(fiscalCode: string): Promise<Pick<Profile, "email">> {
    return post(`${config.url}/profile/forgot-password`, { fiscalCode });
}

export async function activateProfile(profile: Profile): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/activate`, {});
}

export async function deactivateProfile(profile: Profile, reason: string): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/deactivate`, { reason });
}

export async function suspendProfile(profile: Profile, reason: string): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/suspend`, { reason });
}

export async function requestChanges(profile: Profile, reason: string): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/request-changes`, { reason });
}

export async function resendInvite(profile: Profile, email: string): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/resend-invite`, { email });
}

export async function finalizeEditing(): Promise<Profile> {
    return get(`${config.url}/profile/finalize-editing`);
}

export async function getPublicProfile(id: string): Promise<PublicProfile> {
    return get(`${config.url}/profile/${id}/public`);
}

export function updateProfile(body: Partial<GeneralInformation>): Promise<Profile> {
    return put(`${config.url}/profile/update`, body);
}

export function updateProfileAdmin(id: string, body: Partial<GeneralInformation>): Promise<Profile> {
    return put(`${config.url}/profile/${id}/update`, body);
}

export async function updatePersonalInfo(
    picture: Option<Blob>,
    originalPicture: Option<File>,
    body: PersonalInformation
): Promise<Profile> {
    const formData = new FormData();
    formData.append("profileRequest", JSON.stringify(body));
    if (picture && originalPicture) {
        formData.append("picture", picture, "picture.jpg");
        formData.append("originalPicture", originalPicture);
    }
    return postMultiPart(`${config.url}/profile/update-personal-info`, formData);
}

export async function updatePersonalInfoAdmin(
    id: string,
    picture: Option<Blob>,
    originalPicture: Option<File>,
    body: PersonalInformation & { exclusive: boolean }
): Promise<Profile> {
    const formData = new FormData();
    formData.append("profileRequest", JSON.stringify(body));
    if (picture && originalPicture) {
        formData.append("picture", picture, "picture.jpg");
        formData.append("originalPicture", originalPicture);
    }
    return postMultiPart(`${config.url}/profile/${id}/update-personal-info`, formData);
}

export function uploadFile(type: string, files: File[]): Promise<Profile> {
    const formData = new FormData();
    files.forEach(file => {
        formData.append("files", file);
    });
    return postMultiPart(`${config.url}/profile/upload-file/${type}`, formData);
}

export function uploadFileApproval(type: string, files: File[]): Promise<Profile> {
    const formData = new FormData();
    files.forEach(file => {
        formData.append("files", file);
    });
    return postMultiPart(`${config.url}/profile/upload-file-approval/${type}`, formData);
}

export function uploadFileAdmin(id: string, type: string, files: File[]): Promise<Profile> {
    const formData = new FormData();
    files.forEach(file => {
        formData.append("files", file);
    });
    return postMultiPart(`${config.url}/profile/${id}/upload-file/${type}`, formData);
}

export function deleteFile(profile: string, file: string): Promise<Profile> {
    return del(`${config.url}/profile/${profile}/delete-file/${file}`);
}

export function uploadVideos(
    presentationVideo: Option<File>,
    scenesVideo: Option<File>,
    files: File[]
): Promise<Profile> {
    const formData = new FormData();
    if (presentationVideo) {
        formData.append("presentationVideo", presentationVideo);
    }
    if (scenesVideo) {
        formData.append("scenesVideo", scenesVideo);
    }
    files.forEach(file => {
        formData.append("files", file);
    });
    return postMultiPart(`${config.url}/profile/upload-videos`, formData);
}

export function uploadVideosApproval(
    presentationVideo: Option<File>,
    scenesVideo: Option<File>,
    files: File[]
): Promise<Profile> {
    const formData = new FormData();
    if (presentationVideo) {
        formData.append("presentationVideo", presentationVideo);
    }
    if (scenesVideo) {
        formData.append("scenesVideo", scenesVideo);
    }
    files.forEach(file => {
        formData.append("files", file);
    });
    return postMultiPart(`${config.url}/profile/upload-videos-approval`, formData);
}

export function uploadVideosAdmin(
    id: string,
    presentationVideo: Option<File>,
    scenesVideo: Option<File>,
    files: File[]
): Promise<Profile> {
    const formData = new FormData();
    if (presentationVideo) {
        formData.append("presentationVideo", presentationVideo);
    }
    if (scenesVideo) {
        formData.append("scenesVideo", scenesVideo);
    }
    files.forEach(file => {
        formData.append("files", file);
    });
    return postMultiPart(`${config.url}/profile/${id}/upload-videos`, formData);
}

export function uploadComposite(profile: Profile, file: File): Promise<Profile> {
    const formData = new FormData();
    formData.append("file", file);
    return postMultiPart(`${config.url}/profile/${profile._id}/upload-composite`, formData);
}

export function changePassword(password: string): Promise<Profile> {
    return post(`${config.url}/profile/change-password`, { password });
}

export function advancedSearch(body: { filters: any }): Promise<Profile[]> {
    return post(`${config.url}/profile/advanced-search`, body);
}

export function downloadZipFiles(body: { ids: string[]; fileType: "composite" | "presentation" }): Promise<Blob> {
    return postReturnFile(`${config.url}/files/zip-files`, body);
}

export function downloadZipPhotos(body: { ids: string[] }): Promise<Blob> {
    return postReturnFile(`${config.url}/files/zip-photos`, body);
}

export function downloadZip(body: {
    ids: string[];
    types: Array<"photos" | "composite" | "presentation" | "restrictions">;
    language: "en" | "pt";
    profileId?: string;
}): Promise<Blob> {
    return postReturnFile(`${config.url}/files/zip`, body);
}

export function getProfileById(id: string): Promise<Profile> {
    return get(`${config.url}/profile/${id}/info`);
}

export function getUsers(): Promise<Profile[]> {
    return get(`${config.url}/profile/users`);
}

export function createComposite(
    id: string,
    mainImage: Blob,
    secondaryImage1: Blob,
    secondaryImage2: Blob,
    secondaryImage3: Blob,
    secondaryImage4: Blob
): Promise<Profile> {
    const formData = new FormData();
    formData.append("mainImage", mainImage, "mainImage.jpg");
    formData.append("secondaryImage1", secondaryImage1, "secondaryImage1.jpg");
    formData.append("secondaryImage2", secondaryImage2, "secondaryImage2.jpg");
    formData.append("secondaryImage3", secondaryImage3, "secondaryImage3.jpg");
    formData.append("secondaryImage4", secondaryImage4, "secondaryImage4.jpg");
    return postMultiPart(`${config.url}/profile/${id}/create-composite`, formData);
}

export async function approveSection(profile: Profile, section: keyof Profile): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/approve-section`, { section });
}

export async function rejectSection(profile: Profile, section: keyof Profile): Promise<Profile> {
    return post(`${config.url}/profile/${profile._id}/reject-section`, { section });
}

// export async function deleteProfile(id: string): Promise<Profile> {
//     return del(`${config.url}/profile/${id}/delete`);
// }
//
// export async function changeRole(id: string, role: string): Promise<Profile> {
//     return put(`${config.url}/profile/${id}/change-role`, { role });
// }
//
// export async function newProfile(body: ProfileRequest): Promise<Profile> {
//     return post(`${config.url}/profile/new`, body);
// }
