import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, Card, Form, Header } from "tabler-react";
import { updatePartialInfo } from "../../services/profile.service";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { states } from "../../utils/countries";
import { StatusEnum } from "../../models";
import InputMask from "react-input-mask";

export const TalentCompanyData = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [editable, setEditable] = useState(false);
        const [canEdit, setCanEdit] = useState(true);
        const [ownCompany, setOwnCompany] = useState<boolean | undefined>(undefined);
        const { profileStore, loadingStore, sessionStore } = props.rootStore!;
        const [isDataFilled, setIsDataFilled] = useState(false);

        useEffect(() => {
            const profile = profileStore.focusedProfile;
            if (profile && (profile.status === StatusEnum.VALIDATING || profile.status === StatusEnum.INACTIVE)) {
                setCanEdit(false);
            }
            setOwnCompany(profile.talentCompany?.ownCompany);
            if (profile.fullName && profile.email && profile.contractData?.fiscalCode && profile.contractData.idCard) {
                setIsDataFilled(true);
            }
        }, [profileStore.focusedProfile]);

        const onSubmit = async (values: any, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            updatePartialInfo("talentCompany", {
                ownCompany: true,
                companyName: values.company_name,
                companyFantasyName: values.company_fantasy_name,
                commercialRegisterNumber: values.commercial_register_number,
                address: values.address,
                additionalAddress: values.additional_address,
                neighborhood: values.neighborhood,
                zipcode: values.zipcode,
                state: values.state,
                city: values.city,
                bank: values.bank,
                agency: values.agency,
                account: values.account,
                accountType: values.account_type,
                areYouLegalRepresentative: values.are_you_legal_representative === "yes",
                legalRepresentative:
                    values.are_you_legal_representative === "yes"
                        ? profileStore.focusedProfile.fullName
                        : values.legal_representative,
                idCard:
                    values.are_you_legal_representative === "yes"
                        ? profileStore.focusedProfile.contractData?.idCard
                        : values.id_card,
                fiscalCode:
                    values.are_you_legal_representative === "yes"
                        ? profileStore.focusedProfile.contractData?.fiscalCode
                        : values.fiscal_code,
                email: values.are_you_legal_representative === "yes" ? profileStore.focusedProfile.email : values.email,
                isMicroCompany: values.is_micro_company === "yes"
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    setEditable(false);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };

        const onSubmitWithoutData = () => {
            loadingStore.triggerLoading();
            updatePartialInfo("talentCompany", {
                ownCompany: false,
                companyName: "",
                companyFantasyName: "",
                commercialRegisterNumber: "",
                address: "",
                additionalAddress: "",
                neighborhood: "",
                zipcode: "",
                state: "",
                city: "",
                bank: "",
                agency: "",
                account: "",
                accountType: "",
                areYouLegalRepresentative: false,
                legalRepresentative: "",
                idCard: "",
                fiscalCode: "",
                email: "",
                isMicroCompany: false
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    toast.success(t("success.updated"));
                    setEditable(false);
                    loadingStore.stopLoading();
                })
                .catch(() => {
                    toast.error(t("error.updating"));
                    loadingStore.stopLoading();
                });
        };

        return (
            <Card>
                <Card.Body>
                    {sessionStore.isSameAsFocused && canEdit && !editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("profile.talent_company_data")}</Header.H3>
                    <div className="flex-row">
                        <div className="flex-column">
                            <Form.Group>
                                <Form.Label>{t("profile.do-you-have-own-company")}</Form.Label>
                                <Form.Radio
                                    isInline
                                    label={t("yes")}
                                    name="do-you-have-own-company"
                                    value="yes"
                                    disabled={!editable}
                                    onChange={() => setOwnCompany(true)}
                                    checked={ownCompany === true}
                                />
                                <Form.Radio
                                    isInline
                                    label={t("no")}
                                    name="do-you-have-own-company"
                                    value="no"
                                    disabled={!editable}
                                    onChange={() => setOwnCompany(false)}
                                    checked={ownCompany === false}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    {!!ownCompany && (
                        <Formik
                            enableReinitialize
                            initialValues={{
                                company_name: profileStore.focusedProfile.talentCompany?.companyName,
                                company_fantasy_name: profileStore.focusedProfile.talentCompany?.companyFantasyName,
                                commercial_register_number:
                                    profileStore.focusedProfile.talentCompany?.commercialRegisterNumber,
                                address: profileStore.focusedProfile.talentCompany?.address,
                                additional_address: profileStore.focusedProfile.talentCompany?.additionalAddress,
                                neighborhood: profileStore.focusedProfile.talentCompany?.neighborhood,
                                zipcode: profileStore.focusedProfile.talentCompany?.zipcode,
                                state: profileStore.focusedProfile.talentCompany?.state,
                                city: profileStore.focusedProfile.talentCompany?.city,
                                bank: profileStore.focusedProfile.talentCompany?.bank,
                                agency: profileStore.focusedProfile.talentCompany?.agency,
                                account: profileStore.focusedProfile.talentCompany?.account,
                                account_type: profileStore.focusedProfile.talentCompany?.accountType,
                                are_you_legal_representative:
                                    profileStore.focusedProfile.talentCompany?.areYouLegalRepresentative !== undefined
                                        ? profileStore.focusedProfile.talentCompany?.areYouLegalRepresentative
                                            ? "yes"
                                            : "no"
                                        : "",
                                legal_representative: profileStore.focusedProfile.talentCompany?.legalRepresentative,
                                id_card: profileStore.focusedProfile.talentCompany?.idCard,
                                fiscal_code: profileStore.focusedProfile.talentCompany?.fiscalCode,
                                email: profileStore.focusedProfile.talentCompany?.email,
                                is_micro_company:
                                    profileStore.focusedProfile.talentCompany?.isMicroCompany !== undefined
                                        ? profileStore.focusedProfile.talentCompany?.isMicroCompany
                                            ? "yes"
                                            : "no"
                                        : ""
                            }}
                            validate={values => {
                                let errors: any = {};
                                if (!values.company_name) {
                                    errors.company_name = t("form.errors.required");
                                }
                                if (!values.company_fantasy_name) {
                                    errors.company_fantasy_name = t("form.errors.required");
                                }
                                if (!values.commercial_register_number) {
                                    errors.commercial_register_number = t("form.errors.required");
                                }
                                if (!values.address) {
                                    errors.address = t("form.errors.required");
                                }
                                if (!values.neighborhood) {
                                    errors.neighborhood = t("form.errors.required");
                                }
                                if (!values.zipcode) {
                                    errors.zipcode = t("form.errors.required");
                                }
                                if (!values.state) {
                                    errors.state = t("form.errors.required");
                                }
                                if (!values.city) {
                                    errors.city = t("form.errors.required");
                                }
                                if (!values.bank) {
                                    errors.bank = t("form.errors.required");
                                }
                                if (!values.agency || values.agency === "____" || values.agency.includes("_")) {
                                    errors.agency = t("form.errors.required");
                                }
                                if (!values.account) {
                                    errors.account = t("form.errors.required");
                                } else if (!values.account.includes("-")) {
                                    values.account =
                                        values.account.substr(0, values.account.length - 1) +
                                        "-" +
                                        values.account.substr(-1, 1);
                                } else if (
                                    values.account.includes("-") &&
                                    values.account.charAt(values.account.indexOf("-") + 1) === ""
                                ) {
                                    errors.account = t("form.errors.invalid");
                                }
                                if (!values.account_type) {
                                    errors.account_type = t("form.errors.required");
                                }
                                if (!values.are_you_legal_representative) {
                                    errors.are_you_legal_representative = t("form.errors.required");
                                }
                                if (values.are_you_legal_representative === "no") {
                                    if (values.are_you_legal_representative === "no" && !values.legal_representative) {
                                        errors.legal_representative = t("form.errors.required");
                                    }
                                    if (!values.id_card) {
                                        errors.id_card = t("form.errors.required");
                                    }
                                    if (!values.fiscal_code) {
                                        errors.fiscal_code = t("form.errors.required");
                                    }
                                    if (!values.email) {
                                        errors.email = t("form.errors.required");
                                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                        errors.email = t("form.errors.invalid");
                                    }
                                }
                                if (!values.is_micro_company) {
                                    errors.is_micro_company = t("form.errors.required");
                                }
                                return errors;
                            }}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.company_name")}</Form.Label>
                                                <Form.Input
                                                    name="company_name"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.company_name}
                                                    error={errors && errors.company_name}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.company_fantasy_name")}</Form.Label>
                                                <Form.Input
                                                    name="company_fantasy_name"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.company_fantasy_name}
                                                    error={errors && errors.company_fantasy_name}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.commercial_register_number")}</Form.Label>
                                                <Form.MaskedInput
                                                    {...({
                                                        placeholder: "00.000.000/0000-00",
                                                        mask: [
                                                            /\d/,
                                                            /\d/,
                                                            ".",
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            ".",
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            "-",
                                                            /\d/,
                                                            /\d/
                                                        ]
                                                    } as any)}
                                                    name="commercial_register_number"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.commercial_register_number}
                                                    invalid={!!errors.commercial_register_number}
                                                />
                                                {errors.commercial_register_number && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.commercial_register_number}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.address")}</Form.Label>
                                                <Form.Input
                                                    name="address"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.address}
                                                    error={errors && errors.address}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.additional_address")}</Form.Label>
                                                <Form.Input
                                                    name="additional_address"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.additional_address}
                                                    error={errors && errors.additional_address}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.neighborhood")}</Form.Label>
                                                <Form.Input
                                                    name="neighborhood"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.neighborhood}
                                                    error={errors && errors.neighborhood}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.zipcode")}</Form.Label>
                                                <Form.MaskedInput
                                                    {...({
                                                        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
                                                        placeholder: "00000-000"
                                                    } as any)}
                                                    name="zipcode"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.zipcode}
                                                    invalid={!!errors.zipcode}
                                                />
                                                {errors.zipcode && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.zipcode}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.city")}</Form.Label>
                                                <Form.Input
                                                    name="city"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.city}
                                                    error={errors && errors.city}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.state")}</Form.Label>
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="state"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.state}
                                                    error={errors && errors.state}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {states.map(state => (
                                                        <option key={`state_${state.code}`} value={state.code}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.bank")}</Form.Label>
                                                <Form.Input
                                                    name="bank"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.bank}
                                                    error={errors && errors.bank}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.agency")}</Form.Label>
                                                <InputMask
                                                    name="agency"
                                                    mask="9999"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.agency}
                                                    disabled={!editable}
                                                    readOnly={!editable}
                                                    placeholder="0000"
                                                    className={!editable ? "disabled input-disabled" : ""}
                                                >
                                                    {(inputProps: any) => (
                                                        <Form.Input error={errors && errors.agency} {...inputProps} />
                                                    )}
                                                </InputMask>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.account")}</Form.Label>
                                                <Form.Input
                                                    name="account"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.account}
                                                    error={errors && errors.account}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.account_type.label")}</Form.Label>
                                                <Form.Radio
                                                    isInline
                                                    label={t("profile.account_type.checking")}
                                                    name="account_type"
                                                    value="checking"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.account_type === "checking"}
                                                    invalid={!!errors.account_type}
                                                />
                                                <Form.Radio
                                                    isInline
                                                    label={t("profile.account_type.savings")}
                                                    name="account_type"
                                                    value="savings"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.account_type === "savings"}
                                                    invalid={!!errors.account_type}
                                                />
                                                {errors.account_type && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.account_type}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.is_micro_company")}</Form.Label>
                                                <Form.Radio
                                                    isInline
                                                    label={t("yes")}
                                                    name="is_micro_company"
                                                    value="yes"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.is_micro_company === "yes"}
                                                    invalid={!!errors.is_micro_company}
                                                />
                                                <Form.Radio
                                                    isInline
                                                    label={t("no")}
                                                    name="is_micro_company"
                                                    value="no"
                                                    disabled={!editable}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.is_micro_company === "no"}
                                                    invalid={!!errors.is_micro_company}
                                                />
                                                {errors.is_micro_company && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.is_micro_company}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.are_you_legal_representative")}</Form.Label>
                                                <Form.Radio
                                                    isInline
                                                    label={t("yes")}
                                                    name="are_you_legal_representative"
                                                    value="yes"
                                                    disabled={!editable || !isDataFilled}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.are_you_legal_representative === "yes"}
                                                    invalid={!!errors.are_you_legal_representative}
                                                />
                                                <Form.Radio
                                                    isInline
                                                    label={t("no")}
                                                    name="are_you_legal_representative"
                                                    value="no"
                                                    disabled={!editable || !isDataFilled}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.are_you_legal_representative === "no"}
                                                    invalid={!!errors.are_you_legal_representative}
                                                />
                                                {errors.are_you_legal_representative && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.are_you_legal_representative}
                                                    </span>
                                                )}
                                                {!isDataFilled && (
                                                    <span className="invalid-feedback" style={{ display: "block" }}>
                                                        {t("warning.are_you_legal_representative")}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {values.are_you_legal_representative === "no" && (
                                        <>
                                            <div className="flex-row">
                                                <div className="flex-column">
                                                    <Form.Group>
                                                        <Form.Label>{t("profile.legal_representative")}</Form.Label>
                                                        <Form.Input
                                                            name="legal_representative"
                                                            disabled={!editable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.legal_representative}
                                                            error={errors && errors.legal_representative}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="flex-column">
                                                    <Form.Group>
                                                        <Form.Label>{t("profile.id_card")}</Form.Label>
                                                        <Form.Input
                                                            name="id_card"
                                                            disabled={!editable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.id_card}
                                                            error={errors && errors.id_card}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="flex-column">
                                                    <Form.Group>
                                                        <Form.Label>{t("profile.fiscal_code")}</Form.Label>
                                                        <Form.MaskedInput
                                                            {...({
                                                                placeholder: "000.000.000-00",
                                                                mask: [
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    ".",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    ".",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    "-",
                                                                    /\d/,
                                                                    /\d/
                                                                ]
                                                            } as any)}
                                                            name="fiscal_code"
                                                            disabled={!editable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.fiscal_code}
                                                            invalid={errors.fiscal_code}
                                                        />
                                                        {errors.fiscal_code && (
                                                            <span
                                                                className="invalid-feedback"
                                                                style={{ display: "block" }}
                                                            >
                                                                {errors.fiscal_code}
                                                            </span>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="flex-column">
                                                    <Form.Group>
                                                        <Form.Label>{t("profile.email")}</Form.Label>
                                                        <Form.Input
                                                            name="email"
                                                            disabled={!editable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.email}
                                                            error={errors && errors.email}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {sessionStore.isSameAsFocused && canEdit && (
                                        <Form.Footer>
                                            {editable && (
                                                <Button type="submit" color="primary" icon="save">
                                                    {t("save")}
                                                </Button>
                                            )}
                                            {editable && (
                                                <Button
                                                    color="danger"
                                                    icon="x"
                                                    onClick={() => {
                                                        setEditable(false);
                                                        resetForm();
                                                    }}
                                                >
                                                    {t("cancel")}
                                                </Button>
                                            )}
                                        </Form.Footer>
                                    )}
                                </form>
                            )}
                        </Formik>
                    )}
                    {sessionStore.isSameAsFocused && canEdit && !ownCompany && (
                        <Form.Footer>
                            {editable && (
                                <Button color="primary" icon="save" onClick={onSubmitWithoutData}>
                                    {t("save")}
                                </Button>
                            )}
                            {editable && (
                                <Button
                                    color="danger"
                                    icon="x"
                                    onClick={() => {
                                        setEditable(false);
                                        setOwnCompany(false);
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                            )}
                        </Form.Footer>
                    )}
                </Card.Body>
            </Card>
        );
    })
);
