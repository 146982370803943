import React, { useEffect, useState } from "react";
import { Button, Card, Form, Header } from "tabler-react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { Profile } from "../models";
import { toast } from "react-toastify";
import { downloadZip } from "../services/profile.service";
import { formatArtistName } from "../utils/formats";
import download from "downloadjs";
import { config } from "../utils/constants";
import { checkDateComposite, getNamePlaceholder } from "../services/util.service";
import classNames from "classnames";
import copy from "clipboard-copy";
import { AdvancedSearch } from "./AdvancedSearch";
import { calculateAge, isMobile } from "../utils/utils";
import { Popup } from "./Popup";

export const Search = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, classesStore, sessionStore } = props.rootStore!;
        const { t } = useTranslation();
        const [menu, toggleMenu] = useState(false);
        const [showPopup, setShowPopup] = useState(false);
        const [temporaryProfiles, setTemporaryProfiles] = useState<string[]>([]);

        const [results, setResults] = useState<Profile[]>([]);
        const [initialValues, setInitialValues] = useState<string[]>([]);
        const [select, setSelect] = useState(false);

        const [refreshForm, setRefreshForm] = useState(false);

        const findClassLabel = (value: string) => classesStore.classes.find(c => c.key === value)?.namePT ?? value;

        const exportJson = () => {
            const jsonResult = results.map(r => ({
                Nome: r.artisticName,
                Fotos: [
                    r.photoOriginal ? config.host + r.photoOriginal : undefined,
                    ...(r.pictures?.map(p => config.host + p) ?? [])
                ].filter(Boolean),
                VideoApresentacao: r.presentationVideo ? config.host + r.presentationVideo : "",
                GaiaSelo: r.personalInfo?.stamp ?? "open",
                GaiaIdade: r.birthDate ? calculateAge(new Date(r.birthDate)) : "",
                GaiaEtnia: r.personalInfo?.ethnicity ? t(`profile.ethnicity.${r.personalInfo?.ethnicity}`) : "",
                GaiaClasses: r.personalInfo?.classes?.map(c => findClassLabel(c)) ?? [],
                GaiaCorDaPele: r.personalInfo?.skin ? t(`profile.skin.${r.personalInfo?.skin}`) : ""
            }));
            const blob = new Blob([JSON.stringify(jsonResult)], {
                type: "application/json"
            });
            return download(blob, "elenco.json");
        };

        const downloadMultipleFiles = (types: Array<"photos" | "composite" | "presentation">) => {
            loadingStore.triggerLoading();
            downloadZip({
                ids: temporaryProfiles,
                types,
                language: t("languageCode")
            })
                .then(blob => {
                    loadingStore.stopLoading();
                    setShowPopup(false);
                    const isPt = t("languageCode") === "pt";
                    return download(blob, isPt ? "arquivos.zip" : "files.zip");
                })
                .catch(response => {
                    if (response.status == 423) {
                        loadingStore.stopLoading();
                        toast.success(t("success.sent-by-email"));
                    } else {
                        loadingStore.stopLoading();
                        setShowPopup(true);
                        toast.error(t("error.downloading"));
                    }
                });
        };

        const downloadOptions = ["composite", "photos", "presentation"];

        return (
            <>
                {showPopup && (
                    <Popup
                        title={t("search.download-material")}
                        onClose={() => setShowPopup(false)}
                        style={{ width: isMobile() ? "80vw" : "50vw" }}
                    >
                        <Formik enableReinitialize initialValues={{ types: [] }} onSubmit={() => {}}>
                            {({ values, handleChange }) => (
                                <>
                                    <Form.Group>
                                        <Form.Label>{t("search.select-download-material")}</Form.Label>
                                        {downloadOptions.map(id => (
                                            <Form.Checkbox
                                                key={`option_${id}`}
                                                label={t("search.download-" + id)}
                                                name="types"
                                                value={id}
                                                onChange={handleChange}
                                                checked={values.types.findIndex(p => p === id) > -1}
                                            />
                                        ))}
                                    </Form.Group>
                                    <Button
                                        color="primary"
                                        disabled={values.types.length === 0}
                                        onClick={() => downloadMultipleFiles(values.types ?? [])}
                                    >
                                        {t("search.download-material")}
                                    </Button>
                                </>
                            )}
                        </Formik>
                    </Popup>
                )}
                <div className={`filters ${!menu ? "filters-closed" : ""}`}>
                    <AdvancedSearch
                        onSearch={results => {
                            toggleMenu(false);
                            setInitialValues([]);
                            setRefreshForm(true);
                            setResults(results);
                        }}
                        onError={() => {
                            toggleMenu(true);
                        }}
                    />
                </div>
                <Card>
                    <Card.Body>
                        <Formik enableReinitialize initialValues={{ profiles: initialValues }} onSubmit={() => {}}>
                            {({ values, handleChange, handleSubmit, resetForm }) => {
                                useEffect(() => {
                                    if (refreshForm) {
                                        resetForm();
                                        setRefreshForm(false);
                                    }
                                }, [refreshForm, resetForm]);
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex-row">
                                            <Button
                                                color="secondary"
                                                icon="menu"
                                                onClick={() => toggleMenu(prev => !prev)}
                                            />
                                            <Header.H3 style={{ margin: "0 10px", alignSelf: "center" }}>
                                                {t("menu.search")}
                                            </Header.H3>
                                        </div>
                                        <Button
                                            color="primary"
                                            disabled={results.length === 0}
                                            onClick={() => {
                                                select
                                                    ? setInitialValues([])
                                                    : setInitialValues(results.map(p => p._id!));
                                                resetForm();
                                                setSelect(prev => !prev);
                                            }}
                                        >
                                            {select ? t("search.deselect-all") : t("search.select-all")}
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={values.profiles.length === 0}
                                            onClick={() => {
                                                setTemporaryProfiles(values.profiles);
                                                setShowPopup(true);
                                            }}
                                        >
                                            {t("search.download-material")}
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={values.profiles.length === 0}
                                            onClick={() => {
                                                const links = results
                                                    .filter(r => values.profiles.includes(r._id ?? ""))
                                                    .map(
                                                        profile =>
                                                            `${
                                                                profile.artisticName
                                                            }: https://gaia.kozmos.com.br/profiles/${formatArtistName(
                                                                profile.artisticName
                                                            )}`
                                                    );
                                                copy(links.join("\n"))
                                                    .then(() => toast.success(t("success.copied")))
                                                    .catch(() => toast.error(t("error.copying")));
                                            }}
                                        >
                                            {t("search.copy-public-link")}
                                        </Button>
                                        {(sessionStore.isDirector || sessionStore.isBooker) && (
                                            <Button
                                                color="primary"
                                                disabled={values.profiles.length === 0}
                                                onClick={() => exportJson()}
                                            >
                                                {t("languageCode") === "pt" ? "Exportar JSON" : "JSON Export"}
                                            </Button>
                                        )}
                                        <div className="search-grid">
                                            {results.map((profile, index) => {
                                                const picture = profile?.photo
                                                    ? config.host + encodeURI(profile?.photo)
                                                    : undefined;
                                                const statusComposite = checkDateComposite(
                                                    !!profile.composite,
                                                    profile.compositeDate
                                                );
                                                return (
                                                    <Card>
                                                        <Card.Body>
                                                            <div
                                                                className={classNames(
                                                                    "badge-composite search-badge",
                                                                    `badge-${statusComposite}`
                                                                )}
                                                                title={t(`cast.composite.${statusComposite}`)}
                                                            />
                                                            {picture && (
                                                                <img
                                                                    src={picture}
                                                                    className="search-thumbnail"
                                                                    onClick={() => {
                                                                        window.open(`/details?id=${profile._id}`);
                                                                    }}
                                                                    alt={profile.artisticName}
                                                                    title={profile.artisticName}
                                                                />
                                                            )}
                                                            {!picture && (
                                                                <div
                                                                    className="search-empty-thumbnail"
                                                                    onClick={() => {
                                                                        window.open(`/details?id=${profile._id}`);
                                                                    }}
                                                                    title={profile.artisticName}
                                                                >
                                                                    <span>
                                                                        {getNamePlaceholder(profile.artisticName)}
                                                                    </span>
                                                                </div>
                                                            )}
                                                            <Form.Checkbox
                                                                label={profile.artisticName}
                                                                name={`profiles`}
                                                                value={profile._id}
                                                                onChange={handleChange}
                                                                checked={
                                                                    values.profiles.findIndex(p => p === profile._id) >
                                                                    -1
                                                                }
                                                            />
                                                        </Card.Body>
                                                    </Card>
                                                );
                                            })}
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Card.Body>
                </Card>
            </>
        );
    })
);
