import { RootStore } from "../stores/root.store";

export interface EntityWithId {
    _id?: string;
}

export interface Entity {
    createdAt?: Date;
    updatedAt?: Date;
}

export interface Role extends EntityWithId {
    name: string;
    grants: GrantEnum[];
}

export enum GrantEnum {
    ARTIST = "ARTIST",
    BOOKER = "BOOKER",
    DIRECTOR = "DIRECTOR",
    ADMINISTRATIVE = "ADMINISTRATIVE"
}

export interface Stores {
    rootStore?: RootStore;
}
