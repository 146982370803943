import React, { useState } from "react";
import { Gallery } from "../Gallery";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import { useTranslation } from "react-i18next";
import { getFile } from "../../services/files.service";
import { toast } from "react-toastify";
import { Popup } from "../Popup";

interface CompositeImageProps extends Stores {
    number: number;
    imageBase64?: string;
    setImageBase64: (imageBase64: string) => void;
    onFileLoaded: () => void;
    images: string[];
}

export const CompositeImage = inject("rootStore")(
    observer((props: CompositeImageProps) => {
        const { t } = useTranslation();
        const { loadingStore } = props.rootStore!;
        const reader = new FileReader();
        reader.addEventListener("load", () => props.setImageBase64(reader.result as string), false);

        const [show, setShow] = useState(false);

        const onClick = (url: string) => {
            loadingStore.triggerLoading();
            getFile(url)
                .then(blob => {
                    reader.readAsDataURL(blob);
                    loadingStore.stopLoading();
                    props.onFileLoaded();
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error(t("error.loading-picture"));
                });
        };

        return !loadingStore.loading ? (
            <>
                {show && (
                    <Popup
                        title={t("select-a-picture")}
                        onClose={() => setShow(false)}
                        style={{
                            width: "50vw"
                        }}
                    >
                        <Gallery
                            className="gallery-no-scroll"
                            label={t("click-to-select")}
                            isVideo={false}
                            images={props.images}
                            editable={false}
                            onClick={onClick}
                        />
                    </Popup>
                )}
                <span
                    title={t("click-to-select")}
                    onClick={() => {
                        setShow(true);
                    }}
                    style={{
                        backgroundImage: props.imageBase64 ? `url(${props.imageBase64})` : undefined
                    }}
                >
                    {!props.imageBase64 ? props.number : ""}
                </span>
            </>
        ) : null;
    })
);
