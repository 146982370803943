import React, { useEffect, useState } from "react";
import { Avatar, Card, Form, Header } from "tabler-react";
import { useTranslation } from "react-i18next";
import { PublicProfile as PublicProfileType, Skill } from "../models";
import { config } from "../utils/constants";
import { getPublicProfile } from "../services/profile.service";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { Gallery } from "./Gallery";

export const PublicProfile = inject("rootStore")(
    observer((props: Stores) => {
        const [profile, setProfile] = useState<PublicProfileType>({} as PublicProfileType);
        const [images, setImages] = useState<string[]>([]);
        const [videos, setVideos] = useState<{ url: string }[]>([]);
        const {
            routingStore: { location, push },
            loadingStore
        } = props.rootStore!;
        const { t } = useTranslation();
        useEffect(() => {
            loadingStore.triggerLoading();
            const hash = location.pathname.split("/").pop();
            if (hash) {
                getPublicProfile(hash)
                    .then(profile => {
                        setProfile(profile);
                        setImages(profile?.pictures?.map(p => config.host + encodeURI(p)) ?? []);
                        setVideos(profile?.videos?.map(p => ({ url: config.host + encodeURI(p) })) ?? []);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        push("/login");
                    });
            } else {
                loadingStore.stopLoading();
                push("/login");
            }
        }, []);
        const hairs = [
            { label: t("profile.hair.black"), value: "black" },
            { label: t("profile.hair.brown"), value: "brown" },
            { label: t("profile.hair.blond"), value: "blond" },
            { label: t("profile.hair.ginger"), value: "ginger" },
            { label: t("profile.hair.grey"), value: "grey" },
            { label: t("profile.hair.colored"), value: "colored" }
        ];
        const eyes = [
            { label: t("profile.eyes.black"), value: "black" },
            { label: t("profile.eyes.brown"), value: "brown" },
            { label: t("profile.eyes.green"), value: "green" },
            { label: t("profile.eyes.blue"), value: "blue" },
            { label: t("profile.eyes.grey"), value: "grey" },
            { label: t("profile.eyes.violet"), value: "violet" },
            { label: t("profile.eyes.heterochromic"), value: "heterochromic" }
        ];
        return (
            <>
                <div className="flex-table">
                    <div className="flex-row">
                        <div className="flex-column">
                            <Card>
                                <Card.Body>
                                    <div className="profile-group center">
                                        <Avatar size="xxl" color="gray-dark" imageURL={config.host + profile.photo} />
                                        <Header.H1>{profile.name}</Header.H1>
                                    </div>

                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.height")} (cm)</Form.Label>
                                                {profile.height} cm
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.weight")} (Kg)</Form.Label>
                                                {profile.weight}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.clothing_size")}</Form.Label>
                                                {profile.clothingSize}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.foot_size")}</Form.Label>
                                                {profile.footSize}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.hair.label")}</Form.Label>
                                                {hairs.find(h => h.value === profile.hair)?.label}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.eyes.label")}</Form.Label>
                                                {eyes.find(e => e.value === profile.eyes)?.label}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.age")}</Form.Label>
                                                {profile.age}
                                            </Form.Group>
                                        </div>
                                        <div className="flex-column">
                                            <Form.Group>
                                                <Form.Label>{t("profile.stamp")}</Form.Label>
                                                <span
                                                    className={`badge badge-${profile.stamp ?? "open"}`}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    {profile.stamp ?? "open"}
                                                </span>
                                            </Form.Group>
                                        </div>
                                        {profile.composite && (
                                            <div className="flex-column">
                                                <Form.Group>
                                                    <Form.Label>Composite</Form.Label>
                                                    <a
                                                        href={`${config.host}${profile.composite}`}
                                                        target="_blank"
                                                        className="logo"
                                                    >
                                                        <FontAwesomeIcon icon={faCloudDownloadAlt} size="2x" />
                                                    </a>
                                                </Form.Group>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Header.H3>Bio</Header.H3>
                                    <div className="flex-row">{profile.bio}</div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Header.H3>{t("profile.skills.label")}</Header.H3>
                                    <Form.Group>
                                        <Form.SelectGroup pills style={{ pointerEvents: "none" }}>
                                            {profile.skills?.map((v, index) => (
                                                <Form.SelectGroupItem
                                                    key={`sports_${index}`}
                                                    label={
                                                        // @ts-ignore
                                                        v[
                                                            `description${t(
                                                                "languageCode"
                                                            ).toUpperCase()}` as keyof Skill
                                                        ]
                                                    }
                                                />
                                            ))}
                                        </Form.SelectGroup>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="flex-column">
                            <Card>
                                <Card.Body>
                                    <Header.H3>{t("profile.photos")}</Header.H3>
                                    <Gallery label={t("click-to-expand")} isVideo={false} images={images} />
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Header.H3>{t("profile.videos")}</Header.H3>
                                    <Gallery label={t("click-to-expand")} isVideo videos={videos} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);
