import React, { Fragment } from "react";
import { Button, Icon, Site } from "tabler-react";
import { useTranslation } from "react-i18next";

export function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <Site.Footer
            copyright={t("footer")}
            nav={
                <Fragment>
                    <Button onClick={() => i18n.changeLanguage("pt")}>
                        <Icon flag name="br" thin isAriaHidden style={{ width: "1.33rem", height: "1rem" }} /> Português
                    </Button>
                    <Button onClick={() => i18n.changeLanguage("en")}>
                        <Icon flag name="us" thin isAriaHidden style={{ width: "1.33rem", height: "1rem" }} /> English
                    </Button>
                    <Button onClick={() => i18n.changeLanguage("es")}>
                        <Icon flag name="es" thin isAriaHidden style={{ width: "1.33rem", height: "1rem" }} /> Español
                    </Button>
                </Fragment>
            }
        />
    );
}
