export interface FileUpload {
    filename: string;
    id: string;
    file: File;
}

export type Action = { type: "append"; file: FileUpload } | { type: "clear" } | { type: "remove"; file: FileUpload };

export function reducerFileUpload(state: FileUpload[], action: Action): FileUpload[] {
    switch (action.type) {
        case "append":
            return [...state, ...(!state.find(v => v.filename === action.file.filename) ? [action.file] : [])];
        case "clear":
            return [];
        case "remove":
            const files = [...state];
            files.splice(
                files.findIndex(f => f.id === action.file.id),
                1
            );
            return files;
    }
}
