import React, { ReactElement } from "react";
import { ScaleLoader } from "react-spinners";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { createPortal } from "react-dom";

export const Loading = inject("rootStore")(
    observer((props: Stores) => {
        return props.rootStore?.loadingStore.loading ? <LazyLoading /> : null;
    })
);

export function LazyLoading(): ReactElement {
    return createPortal(
        <div className="loading">
            <ScaleLoader height={45} width={7} radius={6} margin={5} color={"white"} />
        </div>,
        document.body
    );
}
