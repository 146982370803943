import { inject, observer } from "mobx-react";
import { Stores } from "../../models/generic";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, Form, Header } from "tabler-react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { Casting, CastingAttributeType } from "../../models";
import { updateCasting } from "../../services/casting.service";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import classNames from "classnames";

export const Details = inject("rootStore")(
    observer((props: Stores) => {
        const { castingStore, castingAttributeStore, loadingStore } = props.rootStore!;
        const [editable, setEditable] = useState(false);
        const { t } = useTranslation();

        const onSubmit = async (values: Partial<Casting>, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            if (castingStore.focusedCasting?._id) {
                updateCasting(castingStore.focusedCasting._id, { ...values, frequency: Number(values.frequency) })
                    .then(casting => {
                        castingStore.setFocusedCasting(casting);
                        castingStore.updateCasting(casting);
                        toast.success(t("success.updated"));
                        loadingStore.stopLoading();
                        setEditable(false);
                    })
                    .catch(() => {
                        toast.error(t("error.updating"));
                        loadingStore.stopLoading();
                    });
            } else {
                toast.error(t("error.updating"));
                loadingStore.stopLoading();
            }
        };

        const animatedComponents = makeAnimated();

        const producers = useMemo(
            () =>
                castingAttributeStore.attributes
                    .filter(attr => (attr.type as CastingAttributeType).key === "producer")
                    .map(attr => ({ label: attr.value, value: attr._id })),
            [castingAttributeStore.attributes]
        );

        const castProducers = useMemo(
            () =>
                castingAttributeStore.attributes
                    .filter(attr => (attr.type as CastingAttributeType).key === "cast-producer")
                    .map(attr => ({ label: attr.value, value: attr._id })),
            [castingAttributeStore.attributes]
        );

        const segments = useMemo(
            () =>
                castingAttributeStore.attributes
                    .filter(attr => (attr.type as CastingAttributeType).key === "segment")
                    .map(attr => ({ label: attr.value, value: attr._id })),
            [castingAttributeStore.attributes]
        );

        const directors = useMemo(
            () =>
                castingAttributeStore.attributes
                    .filter(attr => (attr.type as CastingAttributeType).key === "director")
                    .map(attr => ({ label: attr.value, value: attr._id })),
            [castingAttributeStore.attributes]
        );

        const territories = useMemo(
            () => [
                { label: t("casting.territory.brazil"), value: "brazil" },
                { label: t("casting.territory.world"), value: "world" },
                { label: t("casting.territory.americas"), value: "americas" },
                { label: t("casting.territory.north-america"), value: "north-america" },
                { label: t("casting.territory.south-america"), value: "south-america" },
                { label: t("casting.territory.europe"), value: "europe" },
                { label: t("casting.territory.asia"), value: "asia" },
                { label: t("casting.territory.oceania"), value: "oceania" }
            ],
            []
        );

        const findLabel = useCallback(
            (id?: string) => castingAttributeStore.attributes.find(attr => attr._id == id)?.value ?? "",
            [castingAttributeStore.attributes]
        );

        return (
            <Card>
                <Card.Body>
                    {!editable && (
                        <div className="flex-row justify-content-end">
                            <Button color="primary" icon="edit" onClick={() => setEditable(true)}>
                                {t("edit")}
                            </Button>
                        </div>
                    )}
                    <Header.H3>{t("casting.details")}</Header.H3>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            producers: castingStore.focusedCasting.producers ?? [],
                            castingProducers: castingStore.focusedCasting.castingProducers ?? [],
                            director: castingStore.focusedCasting.director ?? "",
                            territory: castingStore.focusedCasting.territory ?? "",
                            frequency: castingStore.focusedCasting.frequency ?? 0,
                            segments: castingStore.focusedCasting.segments ?? []
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (values.producers?.length === 0) {
                                errors.producers = t("form.errors.required");
                            }
                            if (values.castingProducers?.length === 0) {
                                errors.castingProducers = t("form.errors.required");
                            }
                            if (!values.director) {
                                errors.director = t("form.errors.required");
                            }
                            if (!values.territory) {
                                errors.territory = t("form.errors.required");
                            }
                            if (!values.frequency || Number(values.frequency) < 1) {
                                errors.frequency = t("form.errors.required");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.producers")}</Form.Label>
                                            {editable ? (
                                                <>
                                                    <Select
                                                        name="producers"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={values.producers?.map(prod => ({
                                                            value: prod,
                                                            label: findLabel(prod)
                                                        }))}
                                                        isMulti
                                                        options={producers}
                                                        placeholder={t("select")}
                                                        noOptionsMessage={() => "--"}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "producers",
                                                                value?.map((v: any) => v.value)
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={classNames({
                                                            "invalid-multiselect": !!errors.producers
                                                        })}
                                                    />
                                                    {errors.producers && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.producers}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <ul>
                                                    {values.producers?.map(v => (
                                                        <li>{findLabel(v)}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.cast-producers")}</Form.Label>
                                            {editable ? (
                                                <>
                                                    <Select
                                                        name="castingProducers"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={values.castingProducers?.map(prod => ({
                                                            value: prod,
                                                            label: findLabel(prod)
                                                        }))}
                                                        isMulti
                                                        options={castProducers}
                                                        placeholder={t("select")}
                                                        noOptionsMessage={() => "--"}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "castingProducers",
                                                                value?.map((v: any) => v.value)
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={classNames({
                                                            "invalid-multiselect": !!errors.castingProducers
                                                        })}
                                                    />
                                                    {errors.castingProducers && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.castingProducers}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <ul>
                                                    {values.castingProducers?.map(v => (
                                                        <li>{findLabel(v)}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.director")}</Form.Label>
                                            {editable ? (
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="director"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.director}
                                                    value={values.director}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {directors.map(dir => (
                                                        <option key={dir.value} value={dir.value}>
                                                            {dir.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            ) : (
                                                findLabel(values.director)
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.territory")}</Form.Label>
                                            {editable ? (
                                                <Form.Select
                                                    disabled={!editable}
                                                    name="territory"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.territory}
                                                    value={values.territory}
                                                >
                                                    <option value="">{t("select")}</option>
                                                    {territories.map(ter => (
                                                        <option key={ter.value} value={ter.value}>
                                                            {ter.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            ) : (
                                                territories.find(t => t.value === values.territory)?.label
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.frequency")}</Form.Label>
                                            {editable ? (
                                                <Form.Input
                                                    disabled={!editable}
                                                    name="frequency"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors && errors.frequency}
                                                    value={values.frequency}
                                                    type="number"
                                                />
                                            ) : !!values.frequency ? (
                                                values.frequency
                                            ) : (
                                                ""
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <Form.Group>
                                            <Form.Label>{t("casting.segment")}</Form.Label>
                                            {editable ? (
                                                <>
                                                    <Select
                                                        name="segments"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={values.segments?.map(segment => ({
                                                            value: segment,
                                                            label: findLabel(segment)
                                                        }))}
                                                        isMulti
                                                        options={segments}
                                                        placeholder={t("select")}
                                                        noOptionsMessage={() => "--"}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "segments",
                                                                value?.map((v: any) => v.value)
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={classNames({
                                                            "invalid-multiselect": !!errors.segments
                                                        })}
                                                    />
                                                    {errors.segments && (
                                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.segments}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <ul>
                                                    {values.segments?.map(v => (
                                                        <li>{findLabel(v)}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Footer>
                                    {editable && (
                                        <Button type="submit" color="primary" icon="save">
                                            {t("save")}
                                        </Button>
                                    )}
                                    {editable && (
                                        <Button
                                            color="danger"
                                            icon="x"
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setEditable(false);
                                                resetForm();
                                            }}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    )}
                                </Form.Footer>
                            </form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        );
    })
);
