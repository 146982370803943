import { Session } from "../models";
import { getCurrentSession } from "../utils/login.utils";

export async function get(url: string, session?: Session, params = {}, isFile = false): Promise<any> {
    const response = await fetch(url, getParams(session, params));
    if (!response.ok) {
        throw response;
    }
    return await (isFile ? response.blob() : response.json());
}

export function getParams(session?: Session, params = {}) {
    const currentSession = !session ? getCurrentSession() : session;
    return {
        method: "GET",
        headers: {
            ...(currentSession ? { "gaia-api-key": currentSession.token } : {}),
            ...params
        }
    };
}

export async function del(url: string): Promise<any> {
    const response = await fetch(url, delParams());
    if (!response.ok) {
        throw response;
    }
    return await response.json();
}

export function delParams() {
    const currentSession = getCurrentSession();
    return {
        method: "DELETE",
        headers: {
            ...(currentSession ? { "gaia-api-key": currentSession.token } : {})
        }
    };
}

export function postParams(session?: Session, params = {}) {
    const currentSession = !session ? getCurrentSession() : session;
    return {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(currentSession ? { "gaia-api-key": currentSession.token } : {}),
            ...params
        }
    };
}

export function postParamsMultipart(session?: Session, params = {}) {
    const currentSession = !session ? getCurrentSession() : session;
    return {
        method: "POST",
        headers: {
            Accept: "application/json",
            ...(currentSession ? { "gaia-api-key": currentSession.token } : {}),
            ...params
        }
    };
}

export function putParams(session?: Session) {
    const currentSession = !session ? getCurrentSession() : session;
    return {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(currentSession ? { "gaia-api-key": currentSession.token } : {})
        }
    };
}

export function putParamsMultipart(session?: Session, params = {}) {
    const currentSession = !session ? getCurrentSession() : session;
    return {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(currentSession ? { "gaia-api-key": currentSession.token } : {}),
            ...params
        }
    };
}

export async function post(url: string, body = {}, session?: Session, params = {}): Promise<any> {
    const response = await fetch(url, {
        ...postParams(session, params),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw response;
    }
    return await response.json();
}

export async function postReturnFile(url: string, body = {}, session?: Session, params = {}): Promise<Blob> {
    const response = await fetch(url, {
        ...postParams(session, params),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw response;
    }
    return await response.blob();
}

export async function postMultiPart(url: string, body: FormData, session?: Session, params = {}): Promise<any> {
    const response = await fetch(url, {
        ...postParamsMultipart(session, params),
        body
    });
    if (!response.ok) {
        throw response;
    }
    return await response.json();
}

export async function put(url: string, body = {}, session?: Session): Promise<any> {
    const response = await fetch(url, {
        ...putParams(session),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw response;
    }
    return await response.json();
}

export async function putMultiPart(url: string, body: FormData, session?: Session, params = {}): Promise<any> {
    const response = await fetch(url, {
        ...putParamsMultipart(session, params),
        body
    });
    if (!response.ok) {
        throw response;
    }
    return await response.json();
}
